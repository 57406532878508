import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { orderlistaction } from "../../redux/actions";
import { orderList_api, support_listVendor_api } from "../../API/baseUrl";
import ToastMessage from "../../utils/ToastMessage";
import { TableSk } from "../../utils/tableAkeleton";
import { Box, Card, Container, Skeleton, Stack } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import "./index.css";
import LoadingComponent from "../../components/loadingComponent";
import LoaderAnimation from "../../logo-2/loader/loaderanimation";

const Supportlist = () => {
  const documentStatus = localStorage.getItem("documentStatus");
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [pending, setPending] = useState(false);
  const [openstatusModal, setOpenstatusModal] = useState(false);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [sortBy, setSortBy] = useState("DESC");
  const [sortByColumnName, setSortByColumnName] = useState("");
  const [orderId, setOrderId] = useState("");
  const [ticketIdFk, setTicketIdFk] = useState("");
  const dispatch = useDispatch();
  const { orderListData } = useSelector(
    (state) => state?.orderListReduser || {}
  ); 
  const resturantId = localStorage.getItem("restaurantIdFK");
  console.log(pending,"pending")
  // useEffect(() => {
  //     if (orderListData?.status) {
  //         setData(orderListData?.data);
  //         setTotalRows(orderListData?.totalRows);
  //         // setPending(false);
  //         setTicketIdFk(orderListData?.data[0]?.ticketIdFk)

  //     } else {
  //         ToastMessage("error", orderListData?.message);

  //     }
  // }, [orderListData]);

  useEffect(() => {
    document.title = "Support Ticket";
  }, []);

  useEffect(() => {
    if (orderListData) {
      if (orderListData.status) {
        setData(orderListData.data || []); // Default to empty array if data is undefined
        setTotalRows(orderListData.totalRows);
      } else {
        ToastMessage("error", orderListData.message);
      }
      setPending(false); // Set pending to false after data has been processed
    }
  }, [orderListData]);

  useEffect(() => {
    setPending(true);
    const parameter = {
      offset: offset,
      userType: "vendor",
      limit: limit,
      search: searchText,
      columnName: sortByColumnName,
      sortBy: sortBy.toUpperCase(),
      url: `${support_listVendor_api}${resturantId}`,
    };
    dispatch(orderlistaction(parameter));
  }, [offset, limit, searchText, sortByColumnName, sortBy]);

  const handlenavigate = (ticketId) => {
    navigate(`/dashboard/help/${ticketId}`);
  };

  const columns = [
    {
      name: "Id",
      width: "150px",
      selector: (row) => (row?.ticketId ? row.ticketId : "---"),
      column_name: "ticketId",
      reorder: true,
      cell: (row) => (
        <div
          className="id_navigate"
          onClick={() => handlenavigate(row?.ticketIdFk)}
        >
          {row.ticketId ? row.ticketId : "---"}
        </div>
      ),
    },
    {
      name: "User Name",
      selector: (row) => (row.userName ? row.userName : "---"),
      column_name: "userName",
      minWidth: "250px",
      reorder: true,
      cell: (row) => (
        <div className="">{row.userName ? row.userName : "---"}</div>
      ),
    },
    {
      name: "Email",
      minWidth: "250px",
      selector: (row) => (row.email ? row.email : "---"),
      column_name: "email",
      reorder: true,
      cell: (row) => <div className="">{row.email ? row.email : "---"}</div>,
    },
    // {
    //     name: "Country Code",
    //     minWidth: "250px",
    //     selector: (row) => (row.countryCode ? row.countryCode : "---"),
    //     column_name: "countryCode",
    //     reorder: true,
    //     cell: (row) => (
    //         <div className="">
    //             {row.countryCode ? row.countryCode : "---"}
    //         </div>
    //     ),
    // },
    {
      name: "Mobile Number",
      minWidth: "250px",
      selector: (row) => (row.mobileNumber ? row.mobileNumber : "---"),
      column_name: "mobileNumber",
      reorder: true,
      cell: (row) => (
        <div className="">
          {row.countryCode} {row.mobileNumber ? row.mobileNumber : "---"}
        </div>
      ),
    },
    {
      name: "Purpose",
      minWidth: "250px",
      selector: (row) => (row.purpose ? row.purpose : "---"),
      column_name: "purpose",
      reorder: true,
      cell: (row) => (
        <div className="">{row.purpose ? row.purpose : "---"}</div>
      ),
    },
    {
      name: "Status",
      selector: (row) =>
        row.supportTicketStatus ? row.supportTicketStatus : "---",
      column_name: "supportTicketStatus",
      reorder: true,
      cell: (row) => (
        <div className="">
          {row.supportTicketStatus ? row.supportTicketStatus : "---"}
        </div>
      ),
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontSize: "16px",
        fontWeight: 500,
      },
    },
  };

  const handlePerRowsChange = async (newLimit, offset) => {
    setOffset(offset);
    setLimit(newLimit);
  };

  const handlePageChange = (offset) => {
    setOffset(offset);
  };

  const handleChangeStatus = (id) => {
    setOpenstatusModal(true);
    setOrderId(id);
  };

  const handleSort = async (column, sortDirection) => {
    setSortBy(sortDirection);
    setSortByColumnName(column.column_name || "");
  };

  const handleclearSearch = () => {
    setSearchText("");
  };

  const HandleGenerate = () => {
    navigate("/dashboard/createTicket/add");
  };

  return (
    <Box sx={{ overflow: "hidden" }}>
      {documentStatus == 2 || documentStatus == 0 ? (
        <div className="alert_message">
          <h1>Please upload your documents to move forward</h1>
        </div>
      ) : (



        
        <Container maxWidth="false" className="containerdivuser">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="page_heading_div"
          >
            <h3 className="table_heading">Support Ticket List</h3>
            <div className="search_align_container">
              <div className="search-feild">
                <input
                  type="text"
                  placeholder="Search"
                  style={{ outline: "none" }}
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                {searchText ? (
                  <ClearIcon
                    onClick={handleclearSearch}
                    sx={{
                      color: `var(--colorused)`,
                      cursor: "pointer",
                      fontSize: "20px",
                    }}
                  />
                ) : (
                  ""
                )}
              </div>
              <button className="support_ticket" onClick={HandleGenerate}>
                Generate Ticket
              </button>
            </div>
          </Stack>
          <Card className="Table_container m-t-15">
            {pending ? (
              // Show Skeleton when loading
              // Array.from({ length: 5 }).map((_, rowIndex) => (
              //   <div
              //     key={rowIndex}
              //     style={{
              //       display: "flex",
              //       justifyContent: "space-between",
              //       padding: "10px",
              //     }}
              //   >
              //     {Array.from({ length: 6 }).map((_, colIndex) => (
              //       <Skeleton
              //         key={colIndex}
              //         variant="text"
              //         width="150px"
              //         height="40px"
              //       />
              //     ))}
              //   </div>
              // ))
           <LoaderAnimation/>


            ) : data.length === 0 ? (
              <div style={{ padding: "20px", textAlign: "center" }}>
                <h6>There is no record to display.</h6>
              </div>
            ) : (
              <DataTable
                customStyles={customStyles}
                columns={columns}
                data={data}
                dataAlign="center"
                fixedHeader
                // progressPending={pending}
                // progressComponent={<LoadingComponent />}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                fixedHeaderScrollHeight="70vh"
                sortServer
                responsive
                onSort={handleSort}
              />
            )}
          </Card>
        </Container>
      )}
    </Box>
  );
};

export default Supportlist;
