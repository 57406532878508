import React from 'react';
import logo from "../../images/Logo-with-text.png";

function Logo() {
  return (
    <div className="login-div">
      <img src={logo} alt="Pick'n'Treat" className="login_logo_image1" />
    </div>
  );
}

export default Logo;
