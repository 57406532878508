import React, { useEffect, useState } from "react";
import {
  Box,
  Collapse,
  CssBaseline,
  styled,
  Toolbar,
  List,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import KeyboardBackspaceSharpIcon from "@mui/icons-material/KeyboardBackspaceSharp";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { mandotarySetup } from "../utils/data";
import swal from "sweetalert";
import "./dashboardLayout.css";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../../src/images/Logo-icon.svg";
import logoText from "../../src/images/text-logo.png";
import {
  notificationaction,
  notificationcountAction,
  removeDataFromReducer,
} from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import {
  get_Notification_api,
  ImageUrl,
  unreadCount_api,
} from "../API/baseUrl";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NabNotification from "../pages/homepage/notificationNab.jsx";
import CloseIcon from "@mui/icons-material/Close";
import { Stack, Badge } from "@mui/material";
import Notification from "../pages/homepage/Notification.jsx";
import ToastMessage from "../utils/ToastMessage.jsx";
import Notification1 from "../pages/homepage/Notification1.jsx";

const drawerWidth = 280;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(10)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function DashboardLayout() {
  const { notificationData } = useSelector(
    (state) => state?.notificationReduser || {}
  );
  const { notificationcountData } = useSelector(
    (state) => state?.notificationcountReduser || {}
  );
  const restaurantName = localStorage.getItem("restaurantName");
  const restaurantLogo = localStorage.getItem("restaurantLogo");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [open, setOpen] = React.useState(true);
  const [draweropen, setDraweropen] = React.useState(false);

  const [pending, setPending] = useState(true);
  const [countval, setCountval] = useState("");

  const [notification, setNotification] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // **************** Notification API *************
  const vendorIdFk = localStorage.getItem("vendorIdFk");

  useEffect(() => {
    setPending(true);
    const parameter = {
      type: 0,
      offset: 0,
      userType: "vendor",
      limit: 0,
      search: " ",
      columnName: "",
      sortBy: "ACS",
      url: `${get_Notification_api}${vendorIdFk}`,
    };
    dispatch(notificationaction(parameter));
  }, []);

  useEffect(() => {
    const fetchUnreadCount = () => {
      const parameter = {
        url: `${unreadCount_api}${vendorIdFk}`,
      };
      dispatch(notificationcountAction(parameter));
    };
    fetchUnreadCount();
    const intervalId = setInterval(fetchUnreadCount, 2000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (notificationcountData) {
      if (notificationcountData.status) {
        setCountval(notificationcountData?.Count);
      } else {
        ToastMessage("error", notificationcountData.message);
      }
    }
  }, [notificationcountData]);

  useEffect(() => {
    if (notificationData) {
      if (notificationData.status) {
        setNotification(notificationData.data || []);
      } else {
        ToastMessage("error", notificationData.message);
      }
      setPending(false);
    }
  }, [notificationData]);

  const handleSubmenu = (text, id, arr, index, obj) => {
    // alert(text);
    setDraweropen(false);

    if (text === "Logout") {
      handlelogout();
      return;
    }

    navigate(`${obj.path}`);
    arr[index].open = !arr[index].open;
  };

  const handlelogout = () => {
    setDraweropen(false);
    swal({
      title: "Are you sure you want to logout?",
      text: "Once logged out, you will need to login!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        localStorage.setItem("auth", false);
        localStorage.clear();
        dispatch(removeDataFromReducer("VENDOR_LOGIN_RESET"));
        navigate("/");
      }
    });
  };

  const handleSubmenuNavigate = (text, path) => {
    navigate(path);
  };

  const handleDrawerOpen = () => {
    setDraweropen(!draweropen);
  };

  const handleDrawerclose = () => {
    setDraweropen(false);
  };

  const handleImageClick = () => {
    navigate("/dashboard/app");
  };

  const setActive = (path) => {
    const searchedpath = window.location.pathname.search(path);
    return searchedpath;
  };

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: "#264f0b",
      },
      children: `${name.restaurantName?.split(" ")[0][0]}`,
    };
  }

  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(true);
  };

  return (
    <Box className="main_layout_div">
      <CssBaseline />
      <div>
        <AppBar
          elevation={1}
          position="fixed"
          open={open}
          sx={{ backgroundColor: "#fff" }}
          className="appbar_container"
        >
          <Toolbar
            sx={{ display: "flex", justifyContent: "space-between" }}
            className={open ? "forwa" : "backwa"}
          >

            <IconButton className="focus_button" onClick={handleDrawerOpen}>
              {/* {/ {open ? <KeyboardBackspaceSharpIcon /> : <ArrowForwardIcon />} /} */}
              {draweropen ? "" :<MenuIcon sx={{ color: "#000" }} />}
              
              <p className="menuText">Menu</p>
            </IconButton>

            {!isDrawerOpen && (
            <Stack
              spacing={2}
              direction="row"
              onClick={toggleDrawer(true)}
              style={{ textAlign: "end", float: "right", cursor: "pointer" }}
            >
              {!countval || countval === 0 ? (
                <NotificationsIcon color="action" />
              ) : (
                <Badge badgeContent={countval} color="success">
                  <NotificationsIcon color="action" />
                </Badge>
              )}
            </Stack>
            )}
            {isDrawerOpen && (
              <div className="mobile_notification_tab">
                <Notification1 onClose={() => setIsDrawerOpen(false)} />
              </div>
            )}
          </Toolbar>
        </AppBar>
      </div>

      <Drawer variant="permanent" open={open} className="drawer_sidebar">
        <DrawerHeader
          position="relative"
          className="drawer_header"
          sx={{ p: 2 }}
        >
          {/* {/ <h3 style={{margin:"2px"}}>Pick'n'Treat</h3> /} */}

          <img src={logo} alt="Pick'n'Treat" className="logo_pickntreat" />
          <img src={logoText} alt="Pick'n'Treat" className="logo_pickntreat_withtext" />
          
        </DrawerHeader>
        {/* <div className="logo_text_container">
        
        </div> */}
        {/* {/ <Divider /> /}
        {/ restaurantLogo /} */}
        <div className="vendorname_sidebar_container">
          {/* {/ <Avatar {...stringAvatar({ restaurantName })} /> /} */}
          <Avatar alt="Vendor" src={`${ImageUrl}${restaurantLogo}`} />
          <h1 className="vender_name_text_sidebar">
            {localStorage.getItem("restaurantName")}
          </h1>
          {/* {/ <h1 className="vender_name_text_sidebar">hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh</h1> /} */}
        </div>

        <List className="sidebar_ul">
          {/* <h1 className="sidebar_heading_of_list">STORE</h1> */}
          {mandotarySetup.map((text, index) => (
            <React.Fragment key={index}>
              <ListItem
                key={text.id}
                sx={{ display: "block" }}
                className={
                  setActive(text.path) === 0
                    ? "active_class sidebar_list"
                    : "sidebar_list"
                }
              >
                <ListItemButton
                  className="list_button_hover"
                  sx={{
                    minHeight: 40,
                    fontWeight: 900,
                    minWidth: 77,
                    justifyContent: open ? "initial" : "center",
                    py: "3px",
                  }}
                  onClick={() =>
                    handleSubmenu(
                      text.name,
                      text.id,
                      mandotarySetup,
                      index,
                      text
                    )
                  }
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {text.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={text?.name}
                    // sx={{ opacity: open ? 1 : 0 }}
                  />
                  {text.data.length
                    ? text.open
                      ? text.icon2
                      : text.icon1
                    : ""}
                </ListItemButton>
              </ListItem>
              <Divider />
              {text.data.map((item, ind) => {
                return (
                  <Collapse
                    in={text.open}
                    timeout="auto"
                    unmountOnExit
                    key={ind}
                    className="collpase_nav_bar"
                  >
                    <List
                      component="div"
                      disablePadding
                      className={
                        pathname === item.subpath
                          ? "active_class_sub sidebar_list"
                          : "sidebar_list"
                      }
                    >
                      <ListItemButton
                        sx={{ pl: 3 }}
                        className="collapse_nav_icon"
                        onClick={() =>
                          handleSubmenuNavigate(item?.subMenu, item.subpath)
                        }
                      >
                        <ListItemIcon>{item.subIcon}</ListItemIcon>
                        <ListItemText
                          className={
                            pathname === text.subpath
                              ? "active_class sidebar_list"
                              : "sidebar_list"
                          }
                          primary={item?.subMenu}
                        />
                      </ListItemButton>
                    </List>
                  </Collapse>
                );
              })}
            </React.Fragment>
          ))}
        </List>

        {/* 
        <List className="sidebar_ul_logout">


          <ListItem
            sx={{ display: "block" }}

          >
            <ListItemButton
              sx={{
                minHeight: 40, fontWeight: 900, minWidth: 77,
                justifyContent: open ? "initial" : "center", py: "3px",
              }}
              onClick={handlelogout}
            >
              <ListItemIcon
                sx={{ minWidth: 0, mr: open ? 1 : "auto", justifyContent: "center" }}

              >
                <ExitToAppIcon sx={{ color: `var(--colorused)` }} />
              </ListItemIcon>
              <ListItemText
                primary="Logout"
                sx={{ opacity: open ? 1 : 0 }}
              />

            </ListItemButton>
          </ListItem>
        </List> */}
      </Drawer>

      {draweropen ? (
        <div open={draweropen} className="drawer_mobile_responsive">
          <IconButton
            edge="end"
            onClick={handleDrawerclose} // This function closes the drawer
            sx={{
              position: "absolute",
              top: 10,
              right: 10, // Position it to the top-right corner
              color: "black", // Color for the close button
            }}
          >
            <CloseIcon />
          </IconButton>
          <DrawerHeader
            position="relative"
            className="drawer_header"
            sx={{ p: 2 }}
          >
            {/* {/ <h3 style={{margin:"2px"}}>Pick'n'Treat</h3> /} */}

            <img src={logo} alt="Pick'n'Treat" className="logo_pickntreat" />
            
          <img src={logoText} alt="Pick'n'Treat" className="logo_pickntreat_withtext" />
          </DrawerHeader>

          <div className="vendorname_sidebar_container">
            {/* <Avatar {...stringAvatar({ restaurantName })} /> */}
            <Avatar alt="Vendor" src={`${ImageUrl}${restaurantLogo}`} />
            {/* {/ <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" /> /} */}
            <h1 className="vender_name_text_sidebar">{restaurantName}</h1>
          </div>

          <List className="sidebar_ul">
            <h1 className="sidebar_heading_of_list">STORE</h1>
            {mandotarySetup.map((text, index) => (
              <React.Fragment key={index}>
                <ListItem
                  key={text.id}
                  sx={{ display: "block" }}
                  className={
                    setActive(text.path) === 0
                      ? "active_class sidebar_list"
                      : "sidebar_list"
                  }
                >
                  <ListItemButton
                    className="list_button_hover"
                    sx={{
                      minHeight: 40,
                      fontWeight: 900,
                      minWidth: 77,
                      justifyContent: open ? "initial" : "center",
                      py: "3px",
                    }}
                    onClick={() =>
                      handleSubmenu(
                        text.name,
                        text.id,
                        mandotarySetup,
                        index,
                        text
                      )
                    }
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 1 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {text.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={text?.name}
                      // sx={{ opacity: open ? 1 : 0 }}
                    />
                    {text.data.length
                      ? text.open
                        ? text.icon2
                        : text.icon1
                      : ""}
                  </ListItemButton>
                </ListItem>
                <Divider />
                {text.data.map((item, ind) => {
                  return (
                    <Collapse
                      in={text.open}
                      timeout="auto"
                      unmountOnExit
                      key={ind}
                      className="collpase_nav_bar"
                    >
                      <List
                        component="div"
                        disablePadding
                        className={
                          pathname === item.subpath
                            ? "active_class_sub sidebar_list"
                            : "sidebar_list"
                        }
                      >
                        <ListItemButton
                          sx={{ pl: 3 }}
                          className="collapse_nav_icon"
                          onClick={() =>
                            handleSubmenuNavigate(item?.subMenu, item.subpath)
                          }
                        >
                          <ListItemIcon>{item.subIcon}</ListItemIcon>
                          <ListItemText
                            className={
                              pathname === text.subpath
                                ? "active_class sidebar_list"
                                : "sidebar_list"
                            }
                            primary={item?.subMenu}
                          />
                        </ListItemButton>
                      </List>
                    </Collapse>
                  );
                })}
              </React.Fragment>
            ))}
          </List>

          {/* <List className="sidebar_ul_logout">


            <ListItem
              sx={{ display: "block" }}

            >
              <ListItemButton
                sx={{
                  minHeight: 40, fontWeight: 900, minWidth: 77,
                  justifyContent: open ? "initial" : "center", py: "3px",
                }}
                onClick={handlelogout}
              >
                <ListItemIcon
                  sx={{ minWidth: 0, mr: open ? 1 : "auto", justifyContent: "center" }}

                >
                  <ExitToAppIcon sx={{ color: `var(--colorused)` }} />
                </ListItemIcon>
                <ListItemText
                  primary="Logout"
                  sx={{ opacity: open ? 1 : 0 }}
                />

              </ListItemButton>
            </ListItem>
          </List> */}
        </div>
      ) : (
        ""
      )}

      <Box
        className="scrollbar_hide outlet_container"
        onClick={handleDrawerclose}
        component="main"
        sx={{ flexGrow: 1, height: "100%", overflow: "auto" }}
      >
        <NabNotification />
        <Outlet />
      </Box>
    </Box>
  );
}
