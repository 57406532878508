import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import "./index.css";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Box, Container, Stack, TextField, Button, InputAdornment, IconButton } from "@mui/material";
import * as Yup from 'yup';
import { ChangePasswordShema } from '../../utils/schema';
import { useDispatch, useSelector } from 'react-redux';
import { ChangePasswordAction, removeDataFromReducer, vendorLoginaction } from '../../redux/actions';
import { Change_password_api } from '../../API/baseUrl';
import ToastMessage from "../../utils/ToastMessage";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const Settings = () => {
  useEffect(() => {
    document.title = "Settings";
  }, []);

  const documentStatus = localStorage.getItem('documentStatus');
  const [typeforlist, setTypeforlist] = useState("Pending");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const dispatch = useDispatch();
  const { ChangeData } = useSelector(
    (state) => state?.ChangePasswordReduser || {}
  );

  const initialValues = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const submitForm = async (values) => {
    console.log('Submitting form with values:', values);
    const parameter = {
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
      confirmNewPassword: values.confirmNewPassword,
      url: Change_password_api,
    };
    dispatch(ChangePasswordAction(parameter));
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema: ChangePasswordShema,
    onSubmit: submitForm,
  });

  useEffect(() => {
    if (ChangeData?.status) {
      ToastMessage("success", ChangeData?.message);
      dispatch(removeDataFromReducer("Change_Password_RESET"));
    } else {
      ToastMessage("error", ChangeData?.message);
      dispatch(removeDataFromReducer("Change_Password_RESET"));
    }
  }, [ChangeData]);

  return (
    <Box sx={{ overflow: "hidden" }}>
      {documentStatus == 2 || documentStatus == 0 ? (
        <div className="alert_message">
          <h1>Please upload your documents to move forward</h1>
        </div>
      ) : (
        <Container maxWidth="false" className="containerdivuser">
          <Stack direction="row" alignItems="center" justifyContent="space-between" className="page_heading_div">
            <h3 className="table_heading">Settings</h3>
          </Stack>

          <Tabs style={{ padding: "20px" }}>
            <TabList className="TabList">
              <Tab className="Tab" onClick={() => setTypeforlist("Change Password")}>Change Password</Tab>
            </TabList>

            <TabPanel>
              <form onSubmit={handleSubmit}
              style={{
                display:"flex",
                flexDirection:"column",
                 padding:"20px 0px"
              }}
              >
                <Container Container className="Gird" item xs={12} md={6} lg={6} 
                sx={{ display: "flex", 
                flexDirection: "column" ,
             
                }} >
                <TextField
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  label="Old Password"
                  name="oldPassword"
                  type={showOldPassword ? 'text' : 'password'} // Toggle for old password
                  value={values.oldPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.oldPassword && Boolean(errors.oldPassword)}
                  helperText={touched.oldPassword && errors.oldPassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle old password visibility"
                          onClick={() => setShowOldPassword((prev) => !prev)}
                          edge="end"
                        >
                          {showOldPassword ?<Visibility />  : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                
                />
                <TextField
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  label="New Password"
                  name="newPassword"
                  type={showNewPassword ? 'text' : 'password'} // Toggle for new password
                  value={values.newPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.newPassword && Boolean(errors.newPassword)}
                  helperText={touched.newPassword && errors.newPassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle new password visibility"
                          onClick={() => setShowNewPassword((prev) => !prev)}
                          edge="end"
                        >
                          {showNewPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
              
                />
                <TextField
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  label="Confirm Password"
                  name="confirmNewPassword"
                  type={showConfirmPassword ? 'text' : 'password'} // Toggle for confirm password
                  value={values.confirmNewPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.confirmNewPassword && Boolean(errors.confirmNewPassword)}
                  helperText={touched.confirmNewPassword && errors.confirmNewPassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle confirm password visibility"
                          onClick={() => setShowConfirmPassword((prev) => !prev)}
                          edge="end"
                        >
                          {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                 
                />
                <Button type="submit" variant="contained" className='change_password_btn'>
                  Change Password
                </Button>
              </Container>
              </form>
            </TabPanel>
          </Tabs>
        </Container>
      )}
    </Box>
  );
};

export default Settings;
