import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import {
  ImageUrl,
  bagListfordashboard_api,
  active_surprisebag_api,
  inActive_surprisebag_api,
  getDocument_status_api,
} from "../../API/baseUrl";
import {
  getBagList,
  activeSurprisebag,
  removeDataFromReducer,
  getStatusAction,
} from "../../redux/actions";
import { CircularProgress, FormControlLabel, Skeleton, Switch } from "@mui/material";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ToastMessage from "../../utils/ToastMessage";
import "./index.css";
import Help from "../createSurpriseBag/faq";
import SurpriseBagModal from "./surpricebagModal";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import EditSurpriseBagModal from "./EditsurprisebagModal";
import OwnerDocument from "../ownerdocument";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/joy";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import LoadingComponent from "../../components/loadingComponent";
import ReservationsTable from "./reservationtable";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import SkeletonGrid from "./skeletomcard";
import LoaderAnimation from "../../logo-2/loader/loaderanimation";

const DashboardHome = () => {
  const navigate = useNavigate();

  const documentupload = localStorage.getItem("documentupload");
  const restaurantIdFK = localStorage.getItem("restaurantIdFK");
  const restaurantName = localStorage.getItem("restaurantName");
  const isSingleSurpriseBagCreated = localStorage.getItem(
    "isSingleSurpriseBagCreated"
  );
  const dispatch = useDispatch();
  const { dashboardbaglistData } = useSelector(
    (state) => state?.dashboardbaglist || {}
  );
  const { activeSurpriseBagData } = useSelector(
    (state) => state?.activeSurpriseBagReduser || {}
  );
  const { getStatusData } = useSelector(
    (state) => state?.getStatusReduser || {}
  );
  const [data, setData] = useState([]);
  const [idfordetails, setIdfordetails] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [messageOpen, setMessageOpen] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [editModal, setEditModal] = React.useState(false);
  const [categoryName, setCategoryName] = React.useState(false);
  const [validDate, setValidDate] = React.useState("");
  const [checked, setChecked] = React.useState(true);
  const [documentstatus, setDocumentstatus] = React.useState("");
  const [isDocumentuploaded, setIsDocumentuploaded] = React.useState("");
  const [callApi, setCallApi] = React.useState(false);
  const [showskeleton, setShowskeleton] = React.useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [indexval, setIndexval] = useState("");
  const [option, setOption] = useState("Running");

  const restaurantFoodImage = localStorage.getItem("restaurantFoodImage");
  useEffect(() => {
    document.title = "Pick'n'Treat";
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500);

    return () => clearTimeout(timer); // Cleanup timer if component unmounts
  }, []);

  useEffect(() => {
    if (isSingleSurpriseBagCreated == 0 && documentstatus === 1) {
      navigate("/surpriceBag/createSurprisebag");
    }
  }, [documentstatus, isSingleSurpriseBagCreated]);

  const PinkSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#267887",
      "&:hover": {
        backgroundColor: "",
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#267887",
    },
  }));

  const handleOpen = (id) => {
    if (option === "Running") {
      setOpen(true);
      setIdfordetails(id);
    }
  };

  const handleEditBag = (id, name, date) => {
    if (option !== "Past") {
      setEditModal(true);
      setIdfordetails(id);
      setCategoryName(name);
      setValidDate(date);
    }

  };

  const handleEditBagModalClose = () => {
    if (option !== "Past") {
      setEditModal(false);
    }
  };

  const handleActivebag = (id, index) => {
    setIndexval(index);
    setLoading(true);
    const parameter = {
      url: `${active_surprisebag_api}${id}`,
    };
    dispatch(activeSurprisebag(parameter));
  };

  const handledeActivebag = (id) => {
    // setLoading(true)
    const parameter = {
      url: `${inActive_surprisebag_api}${id}`,
    };
    dispatch(activeSurprisebag(parameter));
  };

  const handleClose = () => setOpen(false);

  useEffect(() => {
    const parameter = {
      offset: "",
      limit: "",
      search: "",
      columnName: "",
      sortBy: "",
      option: option,
      url: `${bagListfordashboard_api}${restaurantIdFK}`,
    };
    if (activeSurpriseBagData?.status) {
      ToastMessage("success", activeSurpriseBagData?.message);
      setLoading(false);
      dispatch(getBagList(parameter));
      dispatch(removeDataFromReducer("ACTIVE_SURPRISEBAG_RESET"));
    } else {
      ToastMessage("error", activeSurpriseBagData?.message);
      setLoading(false);
    }
  }, [activeSurpriseBagData]);

  // useEffect(() => {
  //   if (dashboardbaglistData?.status) {
  //     setShowskeleton(false)
  //     setData(dashboardbaglistData?.data);
  //   } else {
  //     ToastMessage("error", dashboardbaglistData?.message);
  //     setShowskeleton(false)
  //   }
  // }, [dashboardbaglistData]);

  useEffect(() => {
    if (dashboardbaglistData) {
      setShowskeleton(true); // Set skeleton to true initially
  
      const timer = setTimeout(() => {
        setShowskeleton(false); // Set skeleton to false after 2 seconds
  
        if (dashboardbaglistData.status) {
          setData(dashboardbaglistData.data);
        } else {
          ToastMessage("error", dashboardbaglistData.message);
        }
      }, 1000); // 2 seconds delay
  
      // Clear timeout if the component is unmounted or data changes
      return () => clearTimeout(timer);
    }
  }, [dashboardbaglistData]);

  useEffect(() => {
    if (getStatusData?.status) {
      setDocumentstatus(getStatusData?.documentStatus);
      setIsDocumentuploaded(getStatusData?.isDocumentUpload);
      localStorage.setItem("documentStatus", getStatusData?.documentStatus);
      localStorage.setItem("documentupload", getStatusData?.isDocumentUpload);

      if (getStatusData?.isAccountActive == 0) {
        localStorage.setItem("auth", false);
        localStorage.clear();
        dispatch(removeDataFromReducer("VENDOR_LOGIN_RESET"));
        navigate("/");
      }
      if (getStatusData?.documentStatus == 1) {
        localStorage.setItem("restaurantLogo", getStatusData?.restaurantLogo);
        localStorage.setItem(
          "restaurantFoodImage",
          getStatusData?.restaurantFoodImage
        );
      }
    } else {
      ToastMessage("error", getStatusData?.message);
    }
  }, [getStatusData]);

  const getbaglist = () => {
    setShowskeleton(true)
    const parameter = {
      offset: "",
      limit: "",
      search: "",
      columnName: "",
      sortBy: "",
      option: option,
      url: `${bagListfordashboard_api}${restaurantIdFK}`,
    };
    dispatch(getBagList(parameter));
  };

  useEffect(() => {
    getbaglist();
  }, [option]);

  useEffect(() => {
    const parameter = {
      restaurantIdFk: restaurantIdFK,
      url: getDocument_status_api,
    };
    dispatch(getStatusAction(parameter));
  }, [callApi]);

  const handleAddnew = () => {
    navigate("/dashboard/addmore/bag");
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    maxWidth: "100%",
    borderRadius: "10px",
    textAlign: "center",
    bgcolor: "background.paper",
    border: "none",
    outline: "none",
    boxShadow: 24,
    p: 4,
  };

  const handleType = (type) => {
    setOption(type);
  };

  const handleDetailnavigation = (id, index) => {
    navigate(`/dashboard/store/${id}/${index}/${"Details"}`);
  };

  const today = new Date().toLocaleDateString('en-US', { weekday: 'long' });
  const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1)).toLocaleDateString('en-US', { weekday: 'long' });

  return (
    <div className="">
      {/* {isLoading ? (
        <LoadingComponent />
      ) : ( */}
      {isLoading ? (
        // <Box sx={{ padding: '20px' }}>
        //   <Skeleton variant="text" width="60%" height={40} />
        //   <Skeleton variant="rectangular" height={200} />

        //   {/* Three skeleton boxes */}
        //   <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
        //     <Skeleton variant="rectangular" height={100} width="30%" />
        //     <Skeleton variant="rectangular" height={100} width="30%" />
        //     <Skeleton variant="rectangular" height={100} width="30%" />
        //   </Box>

        //   <Skeleton variant="text" width="40%" height={30} />
        //   <Skeleton variant="rectangular" height={200} />
        //   <Skeleton variant="text" width="80%" height={30} />
        //   <Skeleton variant="rectangular" height={100} />
        // </Box>

<LoaderAnimation/>

      ) : (
        <>
          {documentstatus == 0 && isDocumentuploaded == 1 ? (
            <Modal
              open={messageOpen}
              // onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Your documents have been uploaded and Pick'n'Treat team is
                  reviewing your documents.
                </Typography>
              </Box>
            </Modal>
          ) : (
            <>
              {documentupload == 0 || documentstatus == 2 ? (
                <OwnerDocument {...{ setCallApi, callApi }} />
              ) : (
                <div style={{ padding: "10px 5px" }}>
                  <h2 className="restaurant_heading_main_greet">
                    Hi, {localStorage.getItem("restaurantName")}
                  </h2>

                  <div>
                    <div className="dashboard_home_main_container">
                      <div className="AddNew_container">
                        {/* <h3 className="surprisebag_list_heading">
                          Your Magic Bags
                        </h3> */}

                        <Tabs style={{ padding: "20px" }}>
                          <TabList className="TabList">
                            <Tab
                              className="Tab"
                              onClick={() => handleType("Running")}
                            >
                              Current Magic Bags
                            </Tab>
                            <Tab
                              className="Tab"
                              onClick={() => handleType("Past")}
                            >
                              Expired Magic Bags
                            </Tab>
                          </TabList>
                          {/* <TabPanel></TabPanel>
                    <TabPanel></TabPanel>
                    <TabPanel></TabPanel> */}
                        </Tabs>

                        <button
                          type="button"
                          onClick={handleAddnew}
                          className="addNew_btn"
                        >
                          Add New
                        </button>
                      </div>
                      <hr></hr>

                      {showskeleton ?  <LoaderAnimation/> :
                       <div className="surprise_bag_list_container">

                       {data.length === 0 ? (
                         <p className="no-data-message" style={{ textAlign: "center", display: "flex", width: '100%', height: '20vh', justifyContent: "center", alignItems: 'center' }}>No Magic bag found</p>
                       ) : (

                         data?.map((item, index) => (
                           <div
                             className="dashboard_surprise_bagcontainer"
                             key={index}
                           >
                             <div className="surprise_bag_image_dashboard_container_main">
                               <div
                                 style={{
                                   backgroundImage: `url(${ImageUrl}${item?.surpriseBagCoverImage ? item?.surpriseBagCoverImage : item?.restaurantFoodImage})`
                                   // backgroundImage: `url(${ImageUrl}${item?.restaurantFoodImage})`,
                                 }}
                                 className="surprise_bag_image_dashboard_container"
                                 onClick={() =>
                                   item?.isActive === 1
                                     ? handleEditBag(
                                       item?.surpriseBagIdFK,
                                       item?.surpriseBagCategory,
                                       item?.surpriseBagEndDate
                                     )
                                     : handleOpen(item?.surpriseBagIdFK)
                                 }
                               ></div>

                               {/* {item?.isActive == 1 ? <p onClick={() => handledeActivebag(item?.surpriseBagIdFK)} className='selling_btn'>Stop selling</p> : ""} */}
                               {item?.isActive == 1 && option !== "Past" ? (
                                 <FormControlLabel
                                   className="selling_btn"
                                   control={
                                     <PinkSwitch
                                       onChange={() =>
                                         handledeActivebag(item?.surpriseBagIdFK)
                                       }
                                       checked={checked}
                                       inputProps={{
                                         "aria-label": "controlled",
                                       }}
                                     />
                                   }
                                   label="Selling now"
                                 />
                               ) : (
                                 ""
                               )}
                             </div>
                             <div className="surprice_bag_name_dashboard_container">
                               <p
                                 onClick={() =>
                                   item?.isActive == 1
                                     ? handleEditBag(
                                       item?.surpriseBagIdFK,
                                       item?.surpriseBagCategory,
                                       item?.surpriseBagEndDate
                                     )
                                     : handleOpen(item?.surpriseBagIdFK)
                                 }
                                 className="surprice_bag_name_dashboard"
                               >
                                 {item?.surpriseBagName}
                               </p>
                               <p
                                 onClick={() =>
                                   item?.isActive == 1
                                     ? handleEditBag(
                                       item?.surpriseBagIdFK,
                                       item?.surpriseBagCategory,
                                       item?.surpriseBagEndDate
                                     )
                                     : handleOpen(item?.surpriseBagIdFK)
                                 }
                                 className="surprice_bag_category_dashboard"
                               >
                                 {item?.surpriseBagCategory}
                               </p>

                              

                               {item?.isActive == 1 && option !== "Past" ? (
                                 <div
                                   style={{ marginTop: "10px" }}
                                   onClick={() =>
                                     handleEditBag(
                                       item?.surpriseBagIdFK,
                                       item?.surpriseBagCategory,
                                       item?.surpriseBagEndDate
                                     )
                                   }
                                 >
                                  
                                   <p className="surprice_bag_category_dashboard">
                                     <span>
                                       <ShoppingBagOutlinedIcon sx={{ fontSize: "18px", color: "#8e8e8e" }} />
                                     </span>

                                     {item?.numberOfSurpriseBagsAvailable ==
                                       0 ? (
                                       <span
                                         className="ml-1"
                                         style={{ color: "red" }}
                                       >
                                         Sold Out
                                       </span>
                                     ) : (
                                       <div>
                                         <span className="ml-1">
                                           {item?.dailyNumberOfSurpriseBag -
                                             item?.numberOfSurpriseBagsAvailable}
                                         </span>
                                         <span className="ml-1"> Out Of </span>
                                         <span className="ml-1">
                                           {item?.dailyNumberOfSurpriseBag}
                                         </span>

                                         <span className="ml-1" color="red">
                                           {" "}
                                           Sold
                                         </span>
                                       </div>
                                     )}
                                   </p>

                                   {/* <p className='surprice_bag_category_dashboard'><AccessTimeIcon sx={{ color: "#a8b3b3" }} /> Today {item?.startTime} - {item?.endTime} </p> */}
                                   <p className="surprice_bag_category_dashboard">
                                     <span>
                                       <WatchLaterOutlinedIcon sx={{ fontSize: "18px", color: "#8e8e8e" }} />
                                     </span>
                                     <span className="ml-1">{item?.pickupDay === today
                                       ? 'Today'
                                       : item?.pickupDay === tomorrow
                                         ? 'Tomorrow'
                                         : item?.pickupDay}</span>
                                     <span className="ml-1">
                                       {item?.startTime} -{" "}
                                     </span>
                                     <span className="ml-1">
                                       {item?.endTime}
                                     </span>{" "}
                                   </p>


                                   <p className="surprice_bag_category_dashboard">
                                     <span>
                                       <CalendarMonthIcon sx={{ fontSize: "18px", color: "#8e8e8e" }} />
                                     </span>
                                     <span className="ml-1"> Ends On </span>
                                     <span className="ml-1">
                                       {item?.surpriseBagEndDate}
                                     </span>
                                   </p>
                                 </div>
                               ) :
                                 (


                                   <div
                                     style={{ marginTop: "5px" }}
                                   // onClick={() =>
                                   //   handleEditBag(
                                   //     item?.surpriseBagIdFK,
                                   //     item?.surpriseBagCategory,
                                   //     item?.surpriseBagEndDate
                                   //   )
                                   // }
                                   >
                                     {option === "Past" ? "" : <p className="surprice_bag_category_dashboard">
                                       <span>
                                         <CalendarMonthIcon sx={{ fontSize: "18px", color: "#8e8e8e" }} />
                                       </span>
                                       <span className="ml-1"> Ends On </span>
                                       <span className="ml-1">
                                         {item?.surpriseBagEndDate}
                                       </span>
                                     </p>

                                     }

                                   </div>
                                 )
                               }

                               {item?.isActive == 1 && option !== "Past" ? (
                                 ""
                               ) : (
                                 <>
                                   {option === "Past" ? (
                                     <button
                                       className="dashboard_activate_btn_modal"
                                       onClick={() =>
                                         handleDetailnavigation(
                                           item?.surpriseBagIdFK,
                                           index
                                         )
                                       }
                                     >
                                       {loading && indexval == index ? (
                                         <CircularProgress
                                           size={18}
                                           style={{ color: "white" }}
                                         />
                                       ) : (
                                         "View Details"
                                       )}
                                     </button>

                                   ) : (
                                     <button
                                       className="dashboard_activate_btn_modal"
                                       onClick={() =>
                                         handleActivebag(
                                           item?.surpriseBagIdFK,
                                           index
                                         )
                                       }
                                     >
                                       {loading && indexval == index ? (
                                         <CircularProgress
                                           size={18}
                                           style={{ color: "white" }}
                                         />
                                       ) : (
                                         "Start selling"
                                       )}
                                     </button>
                                   )}
                                 </>
                               )}
                             </div>
                           </div>
                         ))

                       )}

                     </div>
                      }
                     
                    </div>

                    {/* <Help restro = 'false'/> */}

                    <ReservationsTable />

                    {open ? (
                      <SurpriseBagModal
                        {...{ open, idfordetails, handleClose }}
                      />
                    ) : (
                      ""
                    )}

                    {editModal ? (
                      <EditSurpriseBagModal
                        {...{
                          editModal,
                          idfordetails,
                          categoryName,
                          handleEditBagModalClose,
                          getbaglist,
                          validDate,
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default DashboardHome;
