import { useState, useMemo } from 'react';
import { SxProps, useTheme } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { LineChart } from 'echarts/charts';
import { TitleComponent, TooltipComponent, GridComponent, LegendComponent } from 'echarts/components';
import EChartsReact from 'echarts-for-react';

// ECharts configuration
echarts.use([
  LineChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  CanvasRenderer,
]);

// BalanceChart Component
const BalanceChart = ({ data, ...rest }) => {
  const theme = useTheme();

  const option = useMemo(
    () => ({
      tooltip: {
        trigger: 'axis',
        formatter: '{b}: {c}',
      },
      grid: {
        top: 40,
        bottom: 70,
        left: 0,
        right: 0,
        containerLabel: true,
      },
      xAxis: {
        type: 'category',
        data: data?.X_Axis_Array,
        axisTick: { show: false },
        axisLine: { show: true },
        axisLabel: { show: true },
        boundaryGap: 0,
      },
      yAxis: {
        type: 'value',
        min: 10,
        minInterval: 1,
        axisLabel: { show: false },
        splitLine: { show: false },
      },
      series: [
        {
          data: data?.magic_bag_purchase_Array,
          type: 'line',
          smooth: true,
          showSymbol: false,
          symbol: 'none',
          lineStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
              { offset: 0, color: '#d4fff6' },
              { offset: 1, color: '#55AE9C' },
            ]),
            width: 3,
            type: 'solid',
            cap: 'round',
          },
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#d4fff6' },
              { offset: 1, color: '#55AE9C' },
            ]),
          },
        },
      ],
    }),
    [theme, data],
  );

  return <EChartsReact option={{ ...option, tooltip: { ...option.tooltip, confine: true } }} {...rest} className='balancegraph'/>;
};

// Balance Component
const Balance = ({data}) => {
  const [timeline, setTimeline] = useState('monthly');


  const balanceData = [
    {
      id: 1,
      type: 'Saves',
      value: '43.50%',
      progress: '+2.45%',
      isPositive: true,
    },
    {
      id: 2,
      type: 'Balance',
      value: '₹52,422',
      progress: '-4.75%',
      isPositive: false,
    },
  ];

  return (

   <div className='balancegraph'>
  <BalanceChart
       
       data={data}
      //  sx={{ width: '100%' , height: '220px !important' }}
     />
   </div>

    
  
  );
};

export default Balance;
