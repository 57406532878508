import React, { useRef, useState } from 'react';
import { getFirestore, collection, addDoc, query, orderBy, limit, serverTimestamp } from 'firebase/firestore';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router-dom'; 
import ChatMessage from './ChatMessage';
import './index.css'
const db = getFirestore();

function ChatRoom() {
  const { restaurantIdFK } = useParams(); 

  const dummy = useRef();
  const messagesRef = collection(db, 'messages');
  const q = query(messagesRef, orderBy('createdAt'), limit(25));
  const [messages] = useCollectionData(q, { idField: 'id' });
  const [formValue, setFormValue] = useState('');
  const resturantId = localStorage.getItem('restaurantIdFK');
  const sendMessage = async (e) => {
    e.preventDefault();

    if (!resturantId) {
      alert("Restaurant ID is required to send a message.");
      return;
    }

    await addDoc(messagesRef, {
      text: formValue,
      reply: "Hi, Welcome what's up",
      createdAt: serverTimestamp(),
      uid:resturantId, 
      photoURL: null
    });

    setFormValue('');
    dummy.current.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <>
      <main>
        {messages && messages.map(msg => <ChatMessage key={msg.id} message={msg} />)}
        <span ref={dummy}></span>
      </main>

      <form onSubmit={sendMessage}>
        <input
          value={formValue}
          onChange={(e) => setFormValue(e.target.value)}
          placeholder="Say something nice"
        />
        <button type="submit" disabled={!formValue}>🕊️</button>
      </form>
    </>
  );
}

export default ChatRoom;
