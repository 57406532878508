import { CircularProgress, Grid, Skeleton, Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Card, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  get_Notification_api,
  read_message_api,
  unreadCount_api,
} from "../../API/baseUrl";
import ToastMessage from "../../utils/ToastMessage";
import {
  notificationaction,
  notificationreadAction,
} from "../../redux/actions";
import CloseIcon from "@mui/icons-material/Close";
import { SupportTicketByid } from "../../redux/actions";
import { Fullscreen } from "@mui/icons-material";
import moment from "moment/moment";
import LoaderAnimation from "../../logo-2/loader/loaderanimation";

const Notification = ({ onClose }) => {
  const [selectedNotification, setSelectedNotification] = useState(null);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [sortBy, setSortBy] = useState("");
  const [sortByColumnName, setSortByColumnName] = useState("");
  const [searchText, setSearchText] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState([]);
  const [unreadNotification, setUnreadNotification] = useState([]);
  const [readNotification, setReadNotification] = useState([]);
  const { notificationData } = useSelector(
    (state) => state?.notificationReduser || {}
  );
  const { notificationreadData } = useSelector(
    (state) => state?.notificationreadReduser || {}
  );
  const dispatch = useDispatch();
  const [notification_id, setNotification_id] = useState("");
  const vendorIdFk = localStorage.getItem("vendorIdFk");
  const [notificationType, setNotificationType] = useState(0);

  const buttonStyle = (type) => ({
    backgroundColor: notificationType === type ? `var(--colorused)` : "white",
    color: notificationType == type ? "white" : `var(--colorused)`,
    backgroundColor: notificationType === type ? `var(--colorused)` : "white",
    border: "none",
    padding: "10px 20px",
    margin: "0 5px",
    cursor: "pointer",
    borderRadius: "4px",
    width: "100%",
  });

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const markAsRead = (id) => {
    const parameter = {
      url: `${read_message_api}${id}`,
    };
    dispatch(notificationreadAction(parameter));
  };

  const handleCardClick = async (id, isRead, link) => {
    setNotification_id(id);
    setSelectedNotification(notification);
    if (Array.isArray(notification)) {
      const updatedNotifications = notification.map((item) =>
        item.notification_id === id ? { ...item, isRead: 1 } : item
      );
      setNotification(updatedNotifications);
      setReadNotification(
        updatedNotifications.filter((item) => item.isRead === 1)
      );
      setUnreadNotification(
        updatedNotifications.filter((item) => item.isRead === 0)
      );
      markAsRead(id);
    } else {
      console.error("Notification data is not an array:", notification);
    }
    window.location.href = link;
  };

  useEffect(() => {
    setLoading(true);
    const parameter = {
      type: notificationType,
      offset: 0,
      userType: "vendor",
      limit: 0,
      search: searchText,
      columnName: sortByColumnName,
      sortBy: sortBy.toUpperCase(),
      url: `${get_Notification_api}${vendorIdFk}`,
    };
    dispatch(notificationaction(parameter));
  }, [searchText, sortByColumnName, sortBy, notificationType, vendorIdFk]);

  useEffect(() => {
    if (notificationData) {
      if (notificationData.status) {
        const allNotifications = notificationData.data || [];
        setNotification(allNotifications);
        setUnreadNotification(
          allNotifications.filter((item) => item.isRead === 0)
        );
        setReadNotification(
          allNotifications.filter((item) => item.isRead === 1)
        );
        setTotalRows(allNotifications.length);
      } else {
        ToastMessage("error", notificationData.message);
      }
      setLoading(false);
    }
  }, [notificationData]);

  useEffect(() => {
    if (notificationreadData) {
      if (notificationreadData.status) {
        setSearchText("");
        ToastMessage("error", notificationreadData.message);
      } else {
        ToastMessage("error", notificationreadData.message);
      }
      setLoading(false);
    }
  }, [notificationreadData]);

  // Get paginated notifications
  const paginatedNotifications = (
    notificationType === 0 ? unreadNotification : readNotification
  ).slice((page - 1) * perPage, page * perPage);

  function formatTimestamp(timestamp) {
    return moment(timestamp, "YYYY-MM-DD HH:mm:ss.SSSSSS").format(
      "MMMM Do YYYY, h:mm:ss a"
    );
  }

  return (
    <div
      style={{
        backgroundColor: "gary",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <div style={{ width: "500px", padding: "20px", color: "black" }}>
        <h3>Notifications</h3>
        <button
          onClick={onClose}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            minWidth: "auto",
            padding: "10px",
            border: "none",
            background: "none",
          }}
        >
          <CloseIcon />
        </button>
        {/* <Grid container spacing={2} rowGap={2} sx={{ marginTop: "10px" }}>
          <Grid item xs={12} sm={6} md={6} lg={12} style={{ display: "flex" }}>
            <button
              style={buttonStyle(0)}
              onClick={() => setNotificationType(0)}

            >
              Unread
            </button>
            <button
              style={buttonStyle(1)}
              onClick={() => setNotificationType(1)}
            >
              Read
            </button>
          </Grid>
        </Grid> */}
        <Grid container spacing={2} rowGap={2} sx={{ marginTop: "10px" }}>
          <Grid
            item
            // xs={4}
            // sm={6}
            md={6}
            lg={6}
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <button
              style={buttonStyle(0)}
              onClick={() => setNotificationType(0)}
            >
              Unread
            </button>
          </Grid>
          <Grid
            item
            // xs={4}
            // sm={6}
            md={6}
            lg={6}
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <button
              style={buttonStyle(1)}
              onClick={() => setNotificationType(1)}
            >
              Read
            </button>
          </Grid>
        </Grid>

        <div>
          {loading ? (
            // <div className="loaders">
            //   <Skeleton
            //     variant="rectangular"
            //     width={Fullscreen}
            //     height={80}
            //     style={{ marginTop: "10px" }}
            //   />
            //   <Skeleton
            //     variant="rectangular"
            //     width={Fullscreen}
            //     height={80}
            //     style={{ marginTop: "10px" }}
            //   />
            //   <Skeleton
            //     variant="rectangular"
            //     width={Fullscreen}
            //     height={80}
            //     style={{ marginTop: "10px" }}
            //   />
            //   <Skeleton
            //     variant="rectangular"
            //     width={Fullscreen}
            //     height={80}
            //     style={{ marginTop: "10px" }}
            //   />
            // </div>

            <LoaderAnimation/>
          ) : (
            <Stack
              spacing={2}
              style={{
                height: "80vh",
                overflowY:
                  paginatedNotifications.length > 0 ? "scroll" : "hidden",
              }}
            >
              {paginatedNotifications.length > 0 ? (
                paginatedNotifications.map((notification) => (
                  <details
                    key={notification.notification_id}
                    style={{ padding: "0px" }}
                  >
                    <summary
                      style={{ listStyleType: "none", cursor: "pointer" }}
                    >
                     <Card
                        style={{
                          width: "29rem",
                          backgroundColor:
                            notificationType === 0
                              ? "rgb(229, 235, 229)"
                              : "white",
                        }}
                        onClick={() =>
                          handleCardClick(
                            notification.notification_id,
                            notification.isRead,
                            notification.redirectLink
                          )
                        }
                      >
                        <Card.Body>
                          <Grid
                            container
                            rowGap={2}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Grid item xs={12} md={4} lg={7}>
                              <h5
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "bold",
                                  margin: "0px",
                                }}
                              >
                                {notification.notification_name}
                              </h5>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              lg={12}
                              style={{ fontSize: "13px" }}
                            >
                              <small>
                                {formatTimestamp(notification.created_at)}
                              </small>
                            </Grid>
                          </Grid>
                          <Card.Text>
                            <p style={{ fontSize: "15px", margin: "0px" }}>
                              {notification.notification_message}
                            </p>
                          </Card.Text>
                        </Card.Body>
                      </Card> 
                    </summary>
                  </details>
                ))
              ) : (
                <p style={{ textAlign: "center" }} className="mt-4">
                  No {notificationType === 0 ? "Unread" : "Read"} Notification
                </p>
              )}
              {paginatedNotifications.length > 0 ? (
                <Pagination
                  count={Math.ceil(totalRows / perPage)}
                  page={page}
                  onChange={handlePageChange}
                  // color="primary"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                />
              ) : (
                ""
              )}
            </Stack>
          )}
        </div>
      </div>
    </div>
  );
};

export default Notification;
