import * as echarts from 'echarts/core';
import React, { useMemo } from 'react';
import { Box, Paper, Stack, Typography, useTheme } from '@mui/material';
import { BarChart } from 'echarts/charts';
import { TooltipComponent, GridComponent, AxisPointerComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import EChartsReact from 'echarts-for-react';

echarts.use([BarChart, TooltipComponent, GridComponent, AxisPointerComponent, CanvasRenderer]);

const TotalSpentChart = ({ data, ...rest }) => {
  const theme = useTheme();
  
  const option = useMemo(() => {
    const baseColor = theme.palette.grey[400];
    const hoverColor = getComputedStyle(document.documentElement).getPropertyValue('--colorused').trim() || theme.palette.primary.main;
    const magicBagRevenue = data?.magic_bag_revenue_Array || [];
    const minValue = magicBagRevenue.length > 0 ? Math.min(...magicBagRevenue) : 0;
    const maxValue = magicBagRevenue.length > 0 ? Math.max(...magicBagRevenue) : 100;
    const interval = maxValue > minValue ? Math.ceil((maxValue - minValue) / 5) : 1;
    return {
      tooltip: {
        trigger: 'axis',
        formatter: 'Revenue: ₹{c}',
        axisPointer: {
          type: 'line',
          axis: 'y',
          label: {
            show: true,
            fontWeight: 500,
            color: getComputedStyle(document.documentElement).getPropertyValue('--colorused').trim() || theme.palette.primary.main,
            fontSize: theme.typography.caption.fontSize,
            backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--colorused1').trim() || theme.palette.primary.main,
            padding: [4, 4, 0, 4],
          },
          lineStyle: {
            type: 'dashed',
            color: getComputedStyle(document.documentElement).getPropertyValue('--colorused').trim() || theme.palette.primary.main,
            width: 1,
          },
        },
      },
      grid: {
        top: '10%',
        left: '5%',
        right: '5%',
        bottom: '15%',
        containLabel: true,
      },
      xAxis: [
        {
          type: 'category',
          data: data?.X_Axis_Array,
          name: ' Time ', // Label for x-axis
          nameLocation: 'center',
          // nameGap: 30,
          nameGap: 45, 
          nameTextStyle: {
            fontSize: theme.typography.caption.fontSize,
            color: theme.palette.text.primary,
            fontWeight: 500,
            // margin:"10px 2px",
            // margin: [14, 0, 0, 0],
          },
          axisTick: { show: false },
          axisLine: {
            show: true,
            lineStyle: {
              color: theme.palette.grey[500],
              width: 1,
            },
          },
          axisLabel: {
            margin: 15,
            fontWeight: 500,
            color: theme.palette.text.disabled,
            fontSize: theme.typography.caption.fontSize,
            fontFamily: theme.typography.fontFamily,
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          // min: 100,
          // minInterval: 1,
          min: minValue > 0 ? minValue : 0,  // Ensure min is non-negative
          max: maxValue,
          interval: interval,
          name: 'Revenue', // Label for y-axis
          nameLocation: 'middle',
          nameGap: 50,
          nameTextStyle: {
            fontSize: theme.typography.caption.fontSize,
            color: theme.palette.text.primary,
            fontWeight: 500,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: theme.palette.grey[500],
              width: 1,
            },
          },
          axisLabel: { show: true },
          splitLine: { show: true },
        },
      ],
      series: [
        {
          name: 'Revenue',
          type: 'bar',
          barWidth: '60%',
          itemStyle: {
            borderRadius: [10, 10, 10, 10],
            color: baseColor,
          },
          emphasis: {
            itemStyle: {
              color: hoverColor,
            },
          },
          data: data?.magic_bag_revenue_Array,
        },
      ],
    };
  }, [theme, data]);

  return <EChartsReact option={{ ...option, tooltip: { ...option.tooltip, confine: true } }} {...rest} className="balancegraph" />;
};

const Graph = ({ data }) => {
  return (
    <div>
      <TotalSpentChart
        data={data}
        sx={{ height: '200px !important' }}
      />
    </div>
  );
};

export default Graph;
