
import React, { useState } from 'react'
import mainlogocreatebag from "../../images/mainlogocreatebag.jpg"
import { Outlet } from 'react-router-dom';
import "./index.css"


const CreateSurpriceBag = () => {

  return (
    <div className="createsurprise_bag_mainContainer">
      <div className='main_registration_image_section'>
        <img src={mainlogocreatebag} alt='image' />
      </div>
      <div className="surprise_bag_outlet_container">
        <Outlet/>
      </div>
    </div>

  )
}

export default CreateSurpriceBag;