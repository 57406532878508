import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, Form, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import "./index.css";
import { useFormik } from "formik";
import Help from "./faq";
import BagSizeListComp from "./bagsizeList";
import BagSizeCategory from "./category";
import Section2 from "./section2";
import {
  createbag,
  getPrivacyAction,
  getStatusAction,
  removeDataFromReducer,
} from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { create_bag_api, get_setting_api, getDocument_status_api } from "../../API/baseUrl";
import moment from "moment";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ToastMessage from "../../utils/ToastMessage";
import { useNavigate } from "react-router-dom";
import StepsOperations from "./operationaldays";
import back from "../../images/back.png";
import {} from "../../API/baseUrl";
import { getbagsize } from "../../redux/actions";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import "./index.css";
import DietrySection from "./dietry";
import { Box, CircularProgress } from "@mui/material";
const initialValues = {
  restaurantIdFk: 0,
  surpriseBagCategory: "",
  surpriseBagName: "Magic Bag",
  surpriseBagDescription: "",
  surpriseBagSizeIdFK: "",
  dailyNumberOfSurpriseBag: "",
  dietaryType: "",
  surpriseBagEndDate: "",
  pickupSchedule: "",

  mondayProperty: 0,
  mondayStart: "",
  mondayEnd: "",

  tuesdayProperty: 0,
  tuesdayStart: "",
  tuesdayEnd: "",

  wednesdayProperty: 0,
  wednesdayStart: "",
  wednesdayEnd: "",

  thursdayProperty: 0,
  thursdayStart: "",
  thursdayEnd: "",

  fridayProperty: 0,
  fridayStart: "",
  fridayEnd: "",

  saturdayProperty: 0,
  saturdayStart: "",
  saturdayEnd: "",

  sundayProperty: 0,
  sundayStart: "",
  sundayEnd: "",
};

const StepstocreateSurprise = () => {
  const restaurantIdFK = localStorage.getItem("restaurantIdFK");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { createbagData } = useSelector((state) => state?.createbag || {});
  const [selectedCard, setSelectedCard] = useState(0);
  const [showbtn, setShowbtn] = useState("");

  const [startvalue, setStartvalue] = React.useState(null);
  const [dietvalue, setDietvalue] = useState("");
  const [continueactive, setContinueactive] = useState(false);
  const [showRecommendation, setShowRecommendation] = useState(false);
  const [descriptionval, setDescriptionval] = useState("");
  const [activeLines, setActiveLines] = useState(Array(1).fill(true));
  const [endDateLimit, setEndDateLimit] = useState("");
  const [validation, setvalidation] = useState(false);
  const { getPrivacyData } = useSelector(
    (state) => state?.getPrivacyReduser || {}
  );


  const { getStatusData } = useSelector(
    (state) => state?.getStatusReduser || {}
  );

  const [loading, setloading] = useState(false);

  useEffect(() => {
    const parameter = {
      restaurantIdFk: restaurantIdFK,
      url: getDocument_status_api,
    };
    dispatch(getStatusAction(parameter));
  }, []);

  

  useEffect(() => {
    if (getStatusData?.status) {
     if(getStatusData?.documentStatus !== 1){
      navigate("/dashboard/app");
     }

    } else {
      ToastMessage("error", getStatusData?.message);
    }
  }, [getStatusData]);


  useEffect(() => {
    if (getPrivacyData?.status) {
      setEndDateLimit(getPrivacyData.data?.data);
    } else {
      ToastMessage("error", getPrivacyData?.message);
    }
  }, [getPrivacyData]);

  useEffect(() => {
    const parameter = {
      type: "enddate",
      url: get_setting_api,
    };
    dispatch(getPrivacyAction(parameter));
  }, []);

  const handleLineClick = (cardIndex) => {
    if (cardIndex === 1) {
      if (!values.surpriseBagCategory) {
        ToastMessage("error", "Please select Category");
        setContinueactive(false);
      } else {
        setSelectedCard(cardIndex);
        const newActiveLines = Array(5).fill(false);
        newActiveLines[cardIndex] = true;
        setActiveLines(Array(2).fill(true));
      }
    } else if (cardIndex === 2) {
      if (!values.surpriseBagDescription) {
        ToastMessage("error", "Please enter Description");
        setContinueactive(false);
      } else {
        setSelectedCard(cardIndex);
        const newActiveLines = Array(5).fill(false);
        newActiveLines[cardIndex] = true;
        // setActiveLines(newActiveLines);
        setActiveLines(Array(3).fill(true));
      }
    } else if (cardIndex === 3) {
      if (!values.dietaryType) {
        ToastMessage("error", "Please select Dietry type");
        setContinueactive(false);
      } else {
        setSelectedCard(cardIndex);
        const newActiveLines = Array(5).fill(false);
        newActiveLines[cardIndex] = true;
        setActiveLines(Array(4).fill(true));
      }
    } else if (cardIndex === 4) {
      if (!values.surpriseBagSizeIdFK) {
        ToastMessage("error", "Please Choose Bagsize");
        setContinueactive(false);
      } else {
        setSelectedCard(cardIndex);
        const newActiveLines = Array(5).fill(false);
        newActiveLines[cardIndex] = true;
        setActiveLines(Array(5).fill(true));
      }
    } else if (cardIndex === 5) {
      if (!values.dailyNumberOfSurpriseBag) {
        ToastMessage("error", "Please select number of bags");
        setContinueactive(false);
      } else {
        setSelectedCard(cardIndex);
        const newActiveLines = Array(5).fill(false);
        newActiveLines[cardIndex] = true;
        setActiveLines(Array(6).fill(true));
      }
    } else {
      setSelectedCard(cardIndex);
      const newActiveLines = Array(5).fill(false);
      newActiveLines[cardIndex] = true;
      setActiveLines(newActiveLines);
    }
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: "",
    onSubmit: (value) => submitForm(value),
  });

  const handleformsubmit = (e) => {
    e.preventDefault();
    handleSubmit();
  };

  useEffect(() => {
    if (createbagData?.status) {
      ToastMessage("success", createbagData?.message);
      localStorage.setItem(
        "isSingleSurpriseBagCreated",
        createbagData?.isSingleSurpriseBagCreated
      );
      dispatch(removeDataFromReducer("CREATE_BAG_RESET"));
      navigate("/dashboard/app");
      setloading(false);
    } else {
      ToastMessage("error", createbagData?.message);
    }
  }, [createbagData]);

  const getData = () => {
    const operationArray = [];

    if (values.mondayStart) {
      operationArray.push({
        pickupDay: "Monday",
        isPickUp: values.mondayProperty ? 1 : 0,
        startTime: values.mondayStart.format("HH:mm"),
        endTime: values.mondayEnd.format("HH:mm"),
      });
    }
    if (!values.mondayStart) {
      operationArray.push({
        pickupDay: "Monday",
        isPickUp: values.mondayProperty ? 1 : 0,
        startTime: "",
        endTime: "",
      });
    }
    if (values.tuesdayStart) {
      operationArray.push({
        pickupDay: "Tuesday",
        isPickUp: values.tuesdayProperty ? 1 : 0,
        startTime: values.tuesdayStart.format("HH:mm"),
        endTime: values.tuesdayEnd.format("HH:mm"),
      });
    }
    if (!values.tuesdayStart) {
      operationArray.push({
        pickupDay: "Tuesday",
        isPickUp: values.tuesdayProperty ? 1 : 0,
        startTime: "",
        endTime: "",
      });
    }
    if (values.wednesdayStart) {
      operationArray.push({
        pickupDay: "Wednesday",
        isPickUp: values.wednesdayProperty ? 1 : 0,
        startTime: values.wednesdayStart.format("HH:mm"),
        endTime: values.wednesdayEnd.format("HH:mm"),
      });
    }
    if (!values.wednesdayStart) {
      operationArray.push({
        pickupDay: "Wednesday",
        isPickUp: values.wednesdayProperty ? 1 : 0,
        startTime: "",
        endTime: "",
      });
    }

    if (values.thursdayStart) {
      operationArray.push({
        pickupDay: "Thursday",
        isPickUp: values.thursdayProperty ? 1 : 0,
        startTime: values.thursdayStart.format("HH:mm"),
        endTime: values.thursdayEnd.format("HH:mm"),
      });
    }
    if (!values.thursdayStart) {
      operationArray.push({
        pickupDay: "Thursday",
        isPickUp: values.thursdayProperty ? 1 : 0,
        startTime: "",
        endTime: "",
      });
    }
    if (values.fridayStart) {
      operationArray.push({
        pickupDay: "Friday",
        isPickUp: values.fridayProperty ? 1 : 0,
        startTime: values.fridayStart.format("HH:mm"),
        endTime: values.fridayEnd.format("HH:mm"),
      });
    }

    if (!values.fridayStart) {
      operationArray.push({
        pickupDay: "Friday",
        isPickUp: values.fridayProperty ? 1 : 0,
        startTime: "",
        endTime: "",
      });
    }

    if (values.saturdayStart) {
      operationArray.push({
        pickupDay: "Saturday",
        isPickUp: values.saturdayProperty ? 1 : 0,
        startTime: values.saturdayStart.format("HH:mm"),
        endTime: values.saturdayEnd.format("HH:mm"),
      });
    }

    if (!values.saturdayStart) {
      operationArray.push({
        pickupDay: "Saturday",
        isPickUp: values.saturdayProperty ? 1 : 0,
        startTime: "",
        endTime: "",
      });
    }

    if (values.sundayStart) {
      operationArray.push({
        pickupDay: "Sunday",
        isPickUp: values.sundayProperty ? 1 : 0,
        startTime: values.sundayStart.format("HH:mm"),
        endTime: values.sundayEnd.format("HH:mm"),
      });
    }
    if (!values.sundayStart) {
      operationArray.push({
        pickupDay: "Sunday",
        isPickUp: values.sundayProperty ? 1 : 0,
        startTime: "",
        endTime: "",
      });
    }
    return operationArray;
  };



  const handlenumbtn = (value) => {
    values.dailyNumberOfSurpriseBag = value;
    setShowbtn(value);
    setContinueactive(true);
  };

  const submitForm = (value) => {
   
    const parameter = {
      restaurantIdFk: restaurantIdFK,
      surpriseBagCategory: values.surpriseBagCategory,
      surpriseBagName: "Magic Bag",
      surpriseBagDescription: descriptionval,
      surpriseBagSizeIdFk: values.surpriseBagSizeIdFK,
      dailyNumberOfSurpriseBag: values.dailyNumberOfSurpriseBag,
      dietaryType: values.dietaryType,
      surpriseBagEndDate: values.surpriseBagEndDate
        ? values.surpriseBagEndDate?.format("DD-MM-YYYY")
        : "",
      pickupSchedule: JSON.stringify(getData()),
      url: create_bag_api,
    };
    if (!startvalue) {
      ToastMessage("error", "Please select End Date");
    } else if (getData().length !== 7) {
      ToastMessage("error", "Please fill all days details");
    }
    else if(validation){
      ToastMessage("error","Please check error")
    }
    
    else {
      setloading(true);
      dispatch(createbag(parameter));
    }
  };

  const handleNewTimeSet = (e, val) => {
    const timeValue = e;

    setFieldValue(val, timeValue);
  };

  const handleCheckboxChange = (name, e) => {
    setContinueactive(true);
    if (name == "mondayProperty") {
      //   setCheckOperation(e.target.checked ? true : false);
      //   setMessageOperation(e.target.checked ? true : false);
      setFieldValue("mondayProperty", e.target.checked ? "1" : "0");

      if (!e.target.checked) {
        setFieldValue("mondayStart", "");
        setFieldValue("mondayEnd", "");
        setFieldValue("mondayLimit", 0);
      }
    }

    if (name == "tuesdayProperty") {
      setFieldValue("tuesdayProperty", e.target.checked ? "1" : "0");
      if (!e.target.checked) {
        setFieldValue("tuesdayStart", "");
        setFieldValue("tuesdayEnd", "");
        setFieldValue("tuesdayLimit", 0);
      }
    }
    if (name == "wednesdayProperty") {
      setFieldValue("wednesdayProperty", e.target.checked ? "1" : "0");
      if (!e.target.checked) {
        setFieldValue("wednesdayStart", "");
        setFieldValue("wednesdayEnd", "");
        setFieldValue("wednesdayLimit", 0);
      }
    }
    if (name == "thursdayProperty") {
      setFieldValue("thursdayProperty", e.target.checked ? "1" : "0");
      if (!e.target.checked) {
        setFieldValue("thursdayStart", "");
        setFieldValue("thursdayEnd", "");
        setFieldValue("thursdayLimit", 0);
      }
    }
    if (name == "fridayProperty") {
      setFieldValue("fridayProperty", e.target.checked ? "1" : "0");
      if (!e.target.checked) {
        setFieldValue("fridayStart", "");
        setFieldValue("fridayEnd", "");
        setFieldValue("fridayLimit", 0);
      }
    }
    if (name == "saturdayProperty") {
      setFieldValue("saturdayProperty", e.target.checked ? "1" : "0");
      if (!e.target.checked) {
        setFieldValue("saturdayStart", "");
        setFieldValue("saturdayEnd", "");
        setFieldValue("saturdayLimit", 0);
      }
    }
    if (name == "sundayProperty") {
      setFieldValue("sundayProperty", e.target.checked ? "1" : "0");

      if (!e.target.checked) {
        setFieldValue("sundayStart", "");
        setFieldValue("sundayEnd", "");
        setFieldValue("sundayLimit", 0);
      }
    }
  };

  const handleNext1 = () => {
    // setSelectedCard((prevStep) => Math.max(prevStep - 1, 0));

    if (selectedCard === 0) {
      navigate("/surpriceBag/createSurprisebag");
      setActiveLines(Array(1).fill(true));
      setContinueactive(true);
    } else if (selectedCard === 1) {
      setSelectedCard((prevStep) => Math.max(prevStep - 1, 0));
      setActiveLines(Array(1).fill(true));
      setContinueactive(true);
    } else if (selectedCard === 2) {
      setSelectedCard((prevStep) => Math.max(prevStep - 1, 0));
      setActiveLines(Array(2).fill(true));
      setContinueactive(true);
    } else if (selectedCard === 3) {
      setSelectedCard((prevStep) => Math.max(prevStep - 1, 0));
      setActiveLines(Array(3).fill(true));
      setContinueactive(true);
    } else if (selectedCard === 4) {
      setSelectedCard((prevStep) => Math.max(prevStep - 1, 0));
      setActiveLines(Array(4).fill(true));
      setContinueactive(true);
    } else if (selectedCard === 5) {
      setSelectedCard((prevStep) => Math.max(prevStep - 1, 0));
      setActiveLines(Array(5).fill(true));
      setContinueactive(true);
    }
  };

  const handleNext = () => {
    // if (selectedCard < 5) {
    //   setSelectedCard(selectedCard + 1);
    // }
    // else{
    //   handleformsubmit()
    // }
    if (selectedCard === 0) {
      if (!values.surpriseBagCategory) {
        ToastMessage("error", "Please select Category");
      } else {
        setSelectedCard(selectedCard + 1);
        setActiveLines(Array(2).fill(true));
        if (!values.surpriseBagDescription) {
          setContinueactive(false);
        } else {
          setContinueactive(true);
        }
      }
    } else if (selectedCard === 1) {
      if (!values.surpriseBagDescription) {
        ToastMessage("error", "Please enter Description");
      } else {
        setSelectedCard(selectedCard + 1);
        setActiveLines(Array(3).fill(true));
        if (!values.dietaryType) {
          setContinueactive(false);
        } else {
          setContinueactive(true);
        }
      }
    } else if (selectedCard === 2) {
      if (!values.dietaryType) {
        ToastMessage("error", "Please select Dietry type");
      } else {
        setSelectedCard(selectedCard + 1);
        setActiveLines(Array(4).fill(true));
        if (!values.surpriseBagSizeIdFK) {
          setContinueactive(false);
        } else {
          setContinueactive(true);
        }
      }
    } else if (selectedCard === 3) {
      if (!values.surpriseBagSizeIdFK) {
        ToastMessage("error", "Please Choose Bagsize");
      } else {
        setSelectedCard(selectedCard + 1);
        setActiveLines(Array(5).fill(true));
        if (!values.dailyNumberOfSurpriseBag) {
          setContinueactive(false);
        } else {
          setContinueactive(true);
        }
      }
    } else if (selectedCard === 4) {
      if (!values.dailyNumberOfSurpriseBag) {
        ToastMessage("error", "Please select number of bags");
      } else {
        setSelectedCard(selectedCard + 1);
        setActiveLines(Array(6).fill(true));
        if (!values.pickupSchedule) {
          setContinueactive(false);
        } else {
          setContinueactive(true);
        }
      }
    }
  };

  const today = dayjs();
  // const maxDate = today.add({endDateLimit}, 'day');
  const maxDate = today.add(31, "day");


  return (
    <div>
      {/* <form onSubmit={handleformsubmit}> */}
      <form>
        <Card style={{ maxWidth: "35rem", border: "none" }}>
          <div
            style={{
              height: "80vh",
              overflow: "auto",
              // scrollbarWidth: "20px",
              padding: "0px 50px",
            }}
            // className="card"
            className="cardcss"
          >
            <Card.Body
              style={{ display: selectedCard === 0 ? "block" : "none" }}
            >
              <Card.Title>
                {/* <img src={back} onClick={handleNext1} style={{ cursor: 'pointer' }} alt="Back" /> */}{" "}
                <h5 className="bag_step_heading m-b-10 m-t-0">
                  Select the category that best describes your surplus food
                </h5>
                <small>
                  Let customers know what they can expect in their Magic Bags.
                </small>
              </Card.Title>
              <Card.Text>
                <BagSizeCategory
                  {...{
                    values,
                    errors,
                    handleBlur,
                    handleChange,
                    continueactive,
                    setContinueactive,
                  }}
                />
              </Card.Text>
            </Card.Body>

            <Card.Body
              style={{ display: selectedCard === 1 ? "block" : "none" }}
            >
              <Card.Title>
                {/* <img
                  src={back} // Assuming 'back' is your image source
                  onClick={handleNext1} // Using the correct function for handling back navigation
                  style={{ cursor: 'pointer' }} // Optional: Adding a pointer cursor for better UX
                  alt="Back" // Optional: Adding an alt text for accessibility
                /> */}{" "}
                {/* <h5 className="bag_step_heading"> */}
                <h5 className="bag_step_heading m-t-0">
                  Add a name and a description
                </h5>
                {/* </h5> */}
                <small>
                  We've make it easy! Here's what we suggest. You can always
                  make changes.
                </small>
              </Card.Title>
              <Card.Text style={{ display: "block" }}>
                <Section2
                  {...{
                    descriptionval,
                    setDescriptionval,
                    dietvalue,
                    setDietvalue,
                    values,
                    errors,
                    handleBlur,
                    handleChange,
                    setContinueactive,
                  }}
                />
              </Card.Text>
            </Card.Body>

            <Card.Body
              style={{ display: selectedCard === 2 ? "block" : "none" }}
            >
              <Card.Title>
                {/* <img
                  src={back} // Assuming 'back' is your image source
                  onClick={handleNext1} // Using the correct function for handling back navigation
                  style={{ cursor: 'pointer' }} // Optional: Adding a pointer cursor for better UX
                  alt="Back" // Optional: Adding an alt text for accessibility
                /> */}{" "}
                {/* <h5 className="bag_step_heading"> */}
                <h5 className="bag_step_heading m-t-0">Select Dietry Type</h5>
                {/* </h5> */}
              </Card.Title>
              <Card.Text style={{ display: "block" }}>
                <DietrySection
                  {...{
                    dietvalue,
                    setContinueactive,
                    setDietvalue,
                    values,
                    errors,
                    handleBlur,
                    handleChange,
                  }}
                />
              </Card.Text>
            </Card.Body>

            <Card.Body
              style={{
                display: selectedCard === 3 ? "block" : "none",
              }}
            >
              <Card.Title>
                {/* <img
                  src={back} // Assuming 'back' is your image source
                  onClick={handleNext1} // Using the correct function for handling back navigation
                  style={{ cursor: 'pointer' }} // Optional: Adding a pointer cursor for better UX
                  alt="Back" // Optional: Adding an alt text for accessibility
                /> */}{" "}
                <h5 className="bag_step_heading m-t-0">
                  Choose your Magic Bag size{" "}
                </h5>
              </Card.Title>
              <Card.Text>
                <BagSizeListComp
                  {...{
                    values,
                    errors,
                    handleBlur,
                    handleChange,
                    setContinueactive,
                     setShowRecommendation
                  }}
                />
{
  showRecommendation ?<div
  style={{
    border: "1px solid #b8dbb3",
    borderRadius: "10px",
    margin: "3%",
    backgroundColor: "rgb(225 237 223)",
    padding: "10px",
    color: `var(--colorused)`,
  }}
>
  <b>Recommended for you</b>
  <p>
    Based on your Magic Bag type, we recommend starting with the
    Medium size. You can always change this later.
  </p>
</div> :""
}

                
              </Card.Text>
            </Card.Body>

            <Card.Body
              style={{ display: selectedCard === 4 ? "block" : "none" }}
            >
              <Card.Title>
                {/* <img
                  src={back} // Assuming 'back' is your image source
                  onClick={handleNext1} // Using the correct function for handling back navigation
                  style={{ cursor: 'pointer' }} // Optional: Adding a pointer cursor for better UX
                  alt="Back" // Optional: Adding an alt text for accessibility
                /> */}{" "}
                {/* <h5 className="bag_step_heading"> */}
                <h5 className="bag_step_heading m-t-0">
                  Set the daily number of Magic Bags
                </h5>
                {/* </h5> */}
              </Card.Title>
              <Card.Text>
                <div style={{ display: "flex" }}>
                  <button
                    type="button"
                    onClick={() => handlenumbtn("2")}
                    className={`bttn ${showbtn == 2 ? "btn_num_active" : ""} `}
                  >
                    2
                  </button>
                  <button
                    type="button"
                    onClick={() => handlenumbtn("3")}
                    className={`bttn ${showbtn == 3 ? "btn_num_active" : ""} `}
                  >
                    3
                  </button>
                  <button
                    type="button"
                    onClick={() => handlenumbtn("4")}
                    className={`bttn ${showbtn == 4 ? "btn_num_active" : ""} `}
                  >
                    4
                  </button>
                  <button
                    type="button"
                    onClick={() => handlenumbtn("5")}
                    className={`bttn ${showbtn == 5 ? "btn_num_active" : ""} `}
                  >
                    5
                  </button>
                </div>

                <div
                  style={{
                    border: "1px solid #b8dbb3",
                    borderRadius: "10px",
                    marginTop: "6%",
                    backgroundColor: "rgb(225 237 223)",
                    padding: "10px",
                    color: `var(--colorused)`,
                  }}
                >
                  <b>Recommended for you</b>
                  <p>
                    {" "}
                    We recommended starting with 2-3 Magic bags per day. You can
                    always change this later.
                  </p>
                </div>

                {/* <Help /> */}
              </Card.Text>
            </Card.Body>

            <Card.Body
              style={{ display: selectedCard === 5 ? "block" : "none" }}
            >
              <Card.Title>
                {/* <img
                  src={back} // Assuming 'back' is your image source
                  onClick={handleNext1} // Using the correct function for handling back navigation
                  style={{ cursor: 'pointer' }} // Optional: Adding a pointer cursor for better UX
                  alt="Back" // Optional: Adding an alt text for accessibility
                /> */}{" "}
                {/* <h5 className="bag_step_heading"> */}
                <h5 className="bag_step_heading m-t-0">
                  Set weekly pickup schedule
                </h5>
                {/* </h5> */}
                <small className="mt-2">
                  {" "}
                  This schedule will repeat weekly{" "}
                </small>
              </Card.Title>
              <Card.Text>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  sx={{ display: "flex" , flexDirection:"column"}}
                >
                  <div className="end-date_lable">
                  <label>End Date*</label>
                  <DatePicker
                    focused
                    className="datepicker_field_bag"
                    size="small"
                    label=""
                    placeholder="DD-MM-YYYY"
                    value={startvalue}
                    format="DD-MM-YYYY"
                    minDate={today} // Set minimum date to today
                    maxDate={maxDate} // Set maximum date to 30 days from today
                    onChange={(newValue) => {
                      setStartvalue(newValue);
                      setFieldValue("surpriseBagEndDate", newValue);
                    }}
                  />
                  </div>
                 
                </LocalizationProvider>

                <StepsOperations
                  {...{
                    values,
                    validation, setvalidation,
                    handleCheckboxChange,
                    handleNewTimeSet,
                    errors,
                    setContinueactive,
                  }}
                />

                <div
                  style={{
                    border: "1px solid #b8dbb3",
                    borderRadius: "10px",
                    margin: "3%",
                    backgroundColor: "rgb(225 237 223)",
                    padding: "10px",
                  }}
                >
                  <b>Recommended for you</b>
                  <p>
                    {" "}
                    We recommended to allow at least 30 minutes for pickup this
                    way your customers have a better chance of making it. You
                    can always change this later.
                  </p>
                </div>
              </Card.Text>
            </Card.Body>
          </div>

          <div style={{ display: "flex" }}>
            {[0, 1, 2, 3, 4, 5].map((index) => (
              <div
                key={index}
                className="line"
                onClick={() => handleLineClick(index)}
                style={{
                  cursor: "pointer",
                  backgroundColor: activeLines[index]
                    ? `var(--colorused)`
                    : "#c5c5c5",
                }}
              ></div>
            ))}
          </div>

          {/* {(selectedCard < 5) ? <button className={`continue_btn ${continueactive ? "btnactive_continue" : ""} `} type="button" onClick={handleNext}
          >Continue</button> :

            <button
              className={`continue_btn ${continueactive ? "btnactive_continue" : ""} `} type="button" onClick={handleformsubmit}
              disabled={loading}
            >

              {loading ? (
                <CircularProgress size={18} style={{ color: "white" }} />
              ) : (
                "Continue"
              )}
            </button>
          } */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 2,
            }}
          >
            {/* Back Button */}
            <button
              className={`continue_btn ${
                continueactive ? "btnactive_continue" : "btnactive_continue"
              } `}
              type="button"
              onClick={handleNext1}
              style={{ cursor: "pointer" }}
              variant="outlined"
            >
              Back
            </button>

            {/* Continue Button */}
            {(selectedCard < 5) ? <button className={`continue_btn ${continueactive ? "btnactive_continue" : ""} `} type="button" onClick={handleNext}
          >Continue</button> :

            <button
              className={`continue_btn ${continueactive ? "btnactive_continue" : ""} `} type="button" onClick={handleformsubmit}
              disabled={loading}
            >

              {loading ? (
                <CircularProgress size={18} style={{ color: "white" }} />
              ) : (
                "Continue"
              )}
            </button>
          }
          </Box>
        </Card>
      </form>
    </div>
  );
};

export default StepstocreateSurprise;
