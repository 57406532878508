import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import {
  Box,
  Typography,
  CircularProgress,
  Divider,
  Switch,
} from "@mui/material";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { useDispatch, useSelector } from "react-redux";
import ToastMessage from "../../utils/ToastMessage";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  editSurprisebag,
  getpickupwindowdata,
  removeDataFromReducer,
} from "../../redux/actions";
import {
  edit_surprise_bag_api,
  get_pickupwindow_data,
} from "../../API/baseUrl";
import { alpha, styled } from "@mui/material/styles";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { pink } from "@mui/material/colors";
import "react-datepicker/dist/react-datepicker.css";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import { editSurpriseBagSchema } from "../../utils/schema";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SpecialDayAfterActiveModal from "./specialdayAfteractivate";
import moment from "moment/moment";
import PickUpSkeleton from "./pickupskeleton";
import LoaderAnimation from "../../logo-2/loader/loaderanimation";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  maxWidth: "95%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  outline: "none",
  border: "none",
  p: 4,
};

const PinkSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#267887",
    "&:hover": {
      backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#267887",
  },
}));

export default function EditSurpriseBagModal({
  getbaglist,
  editModal,
  idfordetails,
  validDate,
  categoryName,
  handleEditBagModalClose,
}) {
  const dispatch = useDispatch();
  const { editSurpriseBagData } = useSelector(
    (state) => state?.editSurpriseBagReduser || {}
  );
  const { pickupwindowData } = useSelector(
    (state) => state?.pickupwindowDataReduser || {}
  );
  const [loading, setLoading] = useState(false);
  const [pending, setPending] = useState(true);
  const [radiovalue, setRadiovalue] = useState("Today");
  const [stock, setStock] = useState(1);
  const [endError, setEndError] = useState("");
  const [minEndTime, setMinEndTime] = useState(null);
  const [maxStartTime, setMaxStartTime] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [isdayvalid, setIsdayvalid] = useState(false);
  const [isPickUpval, setIsPickUpval] = useState(1);
  const [minquantity, setMinquantity] = useState("");

  const [specialDayModalOpen, setSpecialDayModalOpen] = useState(false);

  const [minTime, setMinTime] = useState(null); // Minimum time for today

  // Update the minTime based on the radio value
  useEffect(() => {
    if (radiovalue === "Today") {
      // Set minimum time to the current time if "today" is selected
      setMinTime(dayjs());
    } else {
      // Otherwise, clear minTime to allow all times
      setMinTime(null);
    }
  }, [radiovalue]);



  const initialValues = {
    surpriseBagIdFk: idfordetails,
    pickupDay: "Today",
    dailyNumberOfSurpriseBag: stock,
    startTime: "",
    endTime: "",
  };

  const { values, errors, touched, handleBlur, handleSubmit, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema: editSurpriseBagSchema,
      onSubmit: () => submitForm(),
    });

  const handleFormSubmit = (event) => {
    console.log("errors" , errors)
    console.log("values" , values)
    event.preventDefault();
    handleSubmit();
  };



  function checkIfToday(dateInState) {
    // Format both the input date and today's date to 'YYYY-MM-DD' for comparison
    const today = moment().format('YYYY-MM-DD');
    const inputDate = moment(dateInState, 'DD/MM/YYYY').format('YYYY-MM-DD');
    if (inputDate === today) {
      setIsdayvalid(true)
    }
  }



  useEffect(() => {
    checkIfToday(validDate)
  }, [validDate]);




  const submitForm = async () => {

    setLoading(true);
    const parameter = {
      surpriseBagIdFk: idfordetails,
      pickupDay: values.pickupDay,
      dailyNumberOfSurpriseBag: stock,
      startTime: values.startTime,
      endTime: values.endTime,
      isPickUp: isPickUpval,
      url: edit_surprise_bag_api,
    };

     if (values.startTime >= values.endTime) {
      ToastMessage("error", "End time must be greater than start time");
      setLoading(false);
    }
    else{
      dispatch(editSurprisebag(parameter));
    }
    
  };



  useEffect(() => {
    if (editSurpriseBagData?.status) {
      setLoading(false);
      ToastMessage("success", editSurpriseBagData?.message);
      dispatch(removeDataFromReducer("EDIT_SURPRISEBAG_RESET"));
      getbaglist();
      handleEditBagModalClose();
    } else {
      setLoading(false);
      ToastMessage("error", editSurpriseBagData?.message);
    }
  }, [editSurpriseBagData]);



  useEffect(() => {
    setPending(true);
    const parameter = {
      surpriseBagIdFk: idfordetails,
      pickupDay: radiovalue,
      url: get_pickupwindow_data,
    };
    dispatch(getpickupwindowdata(parameter));
  }, [radiovalue]);



  useEffect(() => {
    if (pickupwindowData) {
      setPending(true); // Set skeleton to true initially
  
      const timer = setTimeout(() => {
        setPending(false); // Set skeleton to false after 2 seconds
  
        if (pickupwindowData?.status) {
          setMinquantity(pickupwindowData?.totalOrderedQuantity)
          setPending(false);
    
          if(pickupwindowData?.data){
            const { start_time, end_time, daily_number_of_surprise_bag } =
            pickupwindowData?.data;
    
            
          setStock(daily_number_of_surprise_bag ? daily_number_of_surprise_bag : 1);
          setFieldValue("dailyNumberOfSurpriseBag", daily_number_of_surprise_bag ? daily_number_of_surprise_bag : 1);
    
          const startTimeParsed = dayjs(start_time, "HH:mm");
          const endTimeParsed = dayjs(end_time, "HH:mm");
    
          setFieldValue(
            "startTime",
            start_time ? startTimeParsed.format("HH:mm") : ""
          );
          setFieldValue("endTime", end_time ? endTimeParsed.format("HH:mm") : "");
          setStartTime(startTimeParsed);
          setEndTime(endTimeParsed);
          
          }
         
    
        } else {
    
          setPending(false);
          setFieldValue("startTime", "");
          setFieldValue("endTime", "");
          setStock(1);
          setStartTime(null);
          setEndTime(null);
        }
      }, 200); // 2 seconds delay
  
      return () => clearTimeout(timer);
    }
  }, [pickupwindowData]);








  useEffect(() => {
    if (values.startTime === values.endTime && values.startTime) {
      setEndError("Start Time and End time should not be same");
    } else {
      setEndError("");
    }
  }, [values.endTime, values.startTime]);


  const handleTimeChange = (newValue, type) => {
    if (newValue) {
      const formattedTime = newValue.format("HH:mm");

      sendTimeToAPI(formattedTime, type);

      if (type === "start") {
        setStartTime(newValue);
        setMinEndTime(newValue);
      } else {
        setEndTime(newValue);
        setMaxStartTime(newValue);
      }
    }
  };

  const sendTimeToAPI = (formattedTime, type) => {
    if (type === "start") {
      values.startTime = formattedTime;
    } else {
      values.endTime = formattedTime;
    }
  };

  const handleradioChange = (event) => {
    values.pickupDay = event.target.value;
    setRadiovalue(event.target.value);
  };

  const handleQuantityAdd = () => {
    const val = values.dailyNumberOfSurpriseBag + 1;
    values.dailyNumberOfSurpriseBag = val;
    setStock(stock < 5 ? stock + 1 : stock);
  };

  const handleQuantityminus = () => {
    const val = values.dailyNumberOfSurpriseBag - 1;
    values.dailyNumberOfSurpriseBag = val;
    setStock(stock == 1 ? stock : stock - 1);
  };

  const handleNavigate = () => {
    if (!isdayvalid) {
      setSpecialDayModalOpen(true);
    }
  };

  const handleSpecialDayClose = () => {
    setSpecialDayModalOpen(false);
  };

  return (
    <div>
      <Modal
        open={editModal}
        aria-labelledby="special-day-modal-title"
        aria-describedby="special-day-modal-description"
      >

          
          
          <Box sx={style}>
          <div className="edit_meal_heading_container">
            <h1 className="edit_meal_heading">{categoryName}</h1>

            <CloseIcon
              sx={{ cursor: "pointer" }}
              onClick={handleEditBagModalClose}
            />
          </div>
          <Divider />


          {pending?
          <LoaderAnimation/> : 


          <div className="scrollable-hidden">
            <FormControl>
              <FormLabel
                className="day_select"
                id="demo-controlled-radio-buttons-group"
              >
                PickUp day
              </FormLabel>
              <RadioGroup
                className="radio_button_align"
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={radiovalue}
                onChange={handleradioChange}
              >
                <FormControlLabel
                  value="Today"
                  control={<Radio sx={{ color: `var(--colorused)` }} />}
                  label="Today"
                />
                {isdayvalid ? "" : <FormControlLabel
                  disabled={isdayvalid}
                  value="Tomorrow"
                  control={<Radio disabled={isdayvalid} sx={{ color: `var(--colorused)` }} />}
                  label="Tomorrow"
                />}

              </RadioGroup>
            </FormControl>
            {errors.pickupDay && touched.pickupDay ? (
              <p className="form-error">{errors.pickupDay}</p>
            ) : null}

            <div className="editSurprise_bag_container">
              <div className="editSurprise_bag_container_inside">
                <h1 className="edit_bag_heading">Magic Bags for sale</h1>
                <div
                  className="container_fluid_button_edit"
                  style={{
                    width: "50%",
                    margin: "auto",
                  }}
                >
                  <button
                   disabled={stock==1 || stock == minquantity}
                    type="button"
                    onClick={handleQuantityminus}
                    className="button_inc_dec"
                  >
                    {" "}
                    -{" "}
                  </button>
                  <input
                  className="stock_input_box"
                    sx={{
                      padding: "8.5px 14px",
                    }}
                    label=""
                    type="text"
                    name="dailyNumberOfSurpriseBag"
                    value={stock}
                    onBlur={handleBlur}
                  />

                  <button
                    disabled={stock==5}
                    type="button"
                    onClick={handleQuantityAdd}
                    className="button_inc_dec"
                  >
                    {" "}
                    +{" "}
                  </button>
                </div>
              </div>
              <Divider />

              <div className="editSurprise_bag_container_inside">
                <h1 className="edit_bag_heading">Pick-up window</h1>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box
                    className="flex-wrap"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 2,
                    }}
                  >
                    <div>
                      <Typography
                        variant="body1"
                        sx={{ mr: 2, color: `var(--colorused)` }}
                      >
                        Pickup Starts
                      </Typography>
                      <DemoContainer
                        className="input_time_field"
                        components={["TimePicker", "TimePicker"]}
                      >

                        <TimePicker
                          className="input_time_field_input"
                          label=""
                          value={startTime}
                          name="startTime"
                          onBlur={handleBlur}
                          onChange={(newValue) =>
                            handleTimeChange(newValue, "start")
                          }
                          maxTime={maxStartTime}
                          minTime={minTime}
                          ampm={false}
                        />
                      </DemoContainer>
                      {errors.startTime && touched.startTime ? (
                        <p className="form-error">{errors.startTime}</p>
                      ) : null}
                    </div>

                    <div>
                      <Typography
                        variant="body1"
                        sx={{ mr: 2, color: `var(--colorused)` }}
                      >
                        Pickup Ends
                      </Typography>
                      <DemoContainer
                        className="input_time_field"
                        components={["TimePicker", "TimePicker"]}
                      >

                        <TimePicker
                          className="input_time_field_input"
                          label=""
                          value={endTime} // This should be a dayjs object
                          name="endTime"
                          onBlur={handleBlur}
                          onChange={(newValue) =>
                            handleTimeChange(newValue, "end")
                          }
                          minTime={minEndTime}
                          ampm={false}
                        />
                      </DemoContainer>
                      {errors.endTime && touched.endTime ? (
                        <p className="form-error">{errors.endTime}</p>
                      ) : null}
                    </div>
                  </Box>
                </LocalizationProvider>
                {endError ? <p className="form-error">{endError}</p> : ""}
              </div>

              <Divider />

              <div
              
                // className="modal_upper_part add_special_afteractivation"
                className={`modal_upper_part add_special_afteractivation ${isdayvalid ? 'backgroundgray' : ''}`}
                onClick={() => handleNavigate()}
              >
                <p className="modal_text">
                  <CalendarMonthOutlinedIcon sx={{ color: "#5e6064" }} />
                  Add special day
                </p>
                <ArrowForwardIosIcon
                  sx={{ fontSize: "16px", color: "#5e6064", cursor: "pointer" }}
                />
              </div>
            </div>

            {specialDayModalOpen && (
              <SpecialDayAfterActiveModal
                specialDayModalOpen={specialDayModalOpen}
                handleSpecialDayClose={handleSpecialDayClose}
                id={idfordetails}
                validDate={validDate}
              />
            )}

            <div className="display_align m-t-10">
              <button
                type="button"
                className="calender_btn_modal"
                onClick={handleFormSubmit}
              >
                {loading ? (
                  <CircularProgress size={18} style={{ color: "white" }} />
                ) : (
                  "Update"
                )}
              </button>
            </div>
          </div>

              }

        </Box>


       
      </Modal>
    </div>
  );
}
