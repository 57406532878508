import React, { useEffect, useState } from "react";
import "./index.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import InputField from "../../components/input";
import { useDispatch, useSelector } from "react-redux";
import {
  ForgetPasswordaction,
  removeDataFromReducer,
} from "../../redux/actions";
import { send_emailforReset_api, vendorLogin_api } from "../../API/baseUrl";
import {
  vendorLoginSchema,
  vendorSendEmailForResetPasswordSchema,
} from "../../utils/schema";
import ToastMessage from "../../utils/ToastMessage";
import logo from "../../images/Full-logo.svg";
import Logo from "./logo";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { forgetPasswordData } = useSelector(
    (state) => state?.forgetPasswordReduser || {}
  );

  const initialValues = {
    email: "",
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: vendorSendEmailForResetPasswordSchema,
    onSubmit: () => submitForm(),
  });

  useEffect(() => {
    if (forgetPasswordData?.status) {
      setLoading(false);
      ToastMessage("success", forgetPasswordData?.message);
      dispatch(removeDataFromReducer("FORGET_PASSWORD_RESET"));
      dispatch(removeDataFromReducer("LOGIN_USER_RESET"));
      navigate("/");
    } else {
      setLoading(false);
      ToastMessage("error", forgetPasswordData?.message);
    }
  }, [forgetPasswordData]);

  const submitForm = async () => {
    setLoading(true);
    const parameter = {
      email: values.email,
      url: send_emailforReset_api,
    };
    dispatch(ForgetPasswordaction(parameter));
  };

  const handleformSubmit = (e) => {
    e.preventDefault();
    console.log(errors, values);
    handleSubmit();
  };

  const handlesignup = () => {
    navigate("/signup");
  };

  return (
    <div className="login-container">
      <form onSubmit={handleformSubmit} className="login-container_fluid">
      <Logo/>
        {/* <div className="login-div">
          <h3>Forget Password</h3>
          <CloseIcon />
        </div> */}

        <div>
          <p className="Forget_password_lable">
            Enter your email to reset your password
          </p>
          <InputField
            label="Email"
            placeholder="email@gmail.com"
            name="email"
            value={values.email}
            type="email"
            onChange={handleChange}
            onBlur={handleBlur}
            
          />
          {errors.email && touched.email ? (
            <p className="form-error">{errors.email}</p>
          ) : null}
        </div>

        <button type="submit" disabled={loading} className="login-btn">
          {loading ? (
            <CircularProgress size={24} style={{ color: "white" }} />
          ) : (
            "Submit"
          )}
        </button>
      </form>
    </div>
  );
};

export default ForgetPassword;
