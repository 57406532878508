import { Box, Grid } from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './index.css';
import TimePickercomponent from '../resturantType/timepicker';
import Switch from '@mui/material/Switch';
import dayjs from 'dayjs';

const StepsOperations = ({
    values,
    handleCheckboxChange,
    handleNewTimeSet,
    errors,
    setContinueactive,
    validation, setvalidation
}) => {
    const params = useParams();

    const currentTime = new Date();
    const hours = currentTime.getHours().toString().padStart(2, '0');
    const minutes = currentTime.getMinutes().toString().padStart(2, '0');
    const formattedTime = `${hours}:${minutes}`;

    const [renderUI, setRenderUI] = useState(1);

    const [daysArray] = useState([
        { "name": "Monday", "formikKeyCheckbox": "mondayProperty", "formikKeyStartTime": "mondayStart", "formikKeyEndTime": "mondayEnd" },
        { "name": "Tuesday", "formikKeyCheckbox": "tuesdayProperty", "formikKeyStartTime": "tuesdayStart", "formikKeyEndTime": "tuesdayEnd" },
        { "name": "Wednesday", "formikKeyCheckbox": "wednesdayProperty", "formikKeyStartTime": "wednesdayStart", "formikKeyEndTime": "wednesdayEnd" },
        { "name": "Thursday", "formikKeyCheckbox": "thursdayProperty", "formikKeyStartTime": "thursdayStart", "formikKeyEndTime": "thursdayEnd" },
        { "name": "Friday", "formikKeyCheckbox": "fridayProperty", "formikKeyStartTime": "fridayStart", "formikKeyEndTime": "fridayEnd" },
        { "name": "Saturday", "formikKeyCheckbox": "saturdayProperty", "formikKeyStartTime": "saturdayStart", "formikKeyEndTime": "saturdayEnd" },
        { "name": "Sunday", "formikKeyCheckbox": "sundayProperty", "formikKeyStartTime": "sundayStart", "formikKeyEndTime": "sundayEnd" },
   
    ]);

    
  const ValidationMessagealert = (showAlert) => {
    if(showAlert == true){
      setvalidation(true)
    }else{
      setvalidation(false)
    }
  };
  
  const getValidationMessage = (checkbox, startTime, endTime, ) => {
    let validationMessage = "";
  
    if (values[checkbox] === "1") {
      // Check if required fields are missing
      if (!values[startTime] || !values[endTime]) {
        validationMessage = "Please fill all details";
        errors[checkbox] = validationMessage;
        ValidationMessagealert(true);  // Show alert with true
      } else if (values[startTime] >= values[endTime]) {
        // Additional check: startTime should not be greater than or equal to endTime
        validationMessage = "Start time should be less than end time";
        errors[checkbox] = validationMessage;
        ValidationMessagealert(true);  // Show alert with true
      } else {
        // No errors; clear any existing error for the checkbox
        delete errors[checkbox];
        ValidationMessagealert(false);  // Clear alert with false
      }
    } else {
      delete errors[checkbox];
    }
  
    return validationMessage;
  };

    const getMinTime = (item) => {
        return values[item.formikKeyStartTime]
            ? dayjs(values[item.formikKeyStartTime], 'HH:mm').add(1, 'minute')
            : null;
    };

    const getMaxTime = (item) => {
        return values[item.formikKeyEndTime] || null;
    };
    
    const timePickerUI = () => {
        return daysArray?.map((item) => {
            return (
                <Grid item xs={12} md={12} lg={12} sx={{ display: 'flex' }}>
                    <div className="operation_field_container">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values[item.formikKeyCheckbox] === '1'}
                                    onChange={(e) => handleCheckboxChange(item.formikKeyCheckbox, e)}
                                    sx={{
                                        color: `var(--colorused)`,
                                        '&.Mui-checked': {
                                            color: `var(--colorused)`,
                                        },
                                    }}

                                />
                            }
                            label={item.name}
                        />

                        <div className='timePicker_wrap'>
                            <TimePickercomponent
                                value={values[item.formikKeyStartTime]}
                                label=""
                                onChange={(newvalue) => handleNewTimeSet(newvalue, item.formikKeyStartTime)}
                                maxTime={getMaxTime(item)}
                            />
                            <TimePickercomponent
                                value={values[item.formikKeyEndTime]}
                                label=""
                                onChange={(newvalue) => handleNewTimeSet(newvalue, item.formikKeyEndTime)}
                                minTime={getMinTime(item)}
                            />
                        </div>

                    </div>
                    <p style={{ color: 'red', marginLeft: '15px' }}>{getValidationMessage(item.formikKeyCheckbox,
                        item.formikKeyStartTime,
                        item.formikKeyEndTime
                    )}</p>
                </Grid>)
        })
    }
    return (
        <div style={{ marginLeft: '5px', marginTop: "20px" }}>
            <div>
                <Grid container spacing={0} mt="10px" sx={{ display: 'flex' }}>
                    {timePickerUI()}
                </Grid>

            </div>
        </div>
    );
};

export default StepsOperations;
