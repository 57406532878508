import React, { useEffect, useState } from 'react'
import "./index.css"
import CloseIcon from '@mui/icons-material/Close';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { CircularProgress, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, TextField } from '@mui/material'

import { useFormik } from "formik";
import { useNavigate, useParams } from 'react-router-dom';
import InputField from '../../components/input';
import "./index.css"
import { SetPasswordSchema } from '../../utils/schema';
import { setpasswordAction } from '../../redux/actions';
import { resetPassword_api, setPassword_api } from '../../API/baseUrl';
import { useDispatch, useSelector } from 'react-redux';
import ToastMessage from '../../utils/ToastMessage';
import Logo from './logo';

const offset = 5;

const ReSetPassword = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  
    const { email } = useParams()
    const { id } = useParams()
    const dispatch = useDispatch();
    const { setPasswordData } = useSelector((state) => state?.setPasswordreduser || {});

    const decodedEmail = atob(email);




console.log("setPasswordData",setPasswordData)

    const initialValues = {
        email: decodedEmail,
        password: "",
        confirmPassword: "",
    }








    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } =
        useFormik({
            initialValues,
            validationSchema: SetPasswordSchema,
            onSubmit: () => submitForm()
        });

    const submitForm = async () => {
        setLoading(true)
        const parameter = {
            id: id,
            password: values.password,
            url: resetPassword_api,
          };
            dispatch(setpasswordAction(parameter));
    }

    const handleformSubmit = (e) => {   
    e.preventDefault()
    console.log(errors, values)
    handleSubmit()
    }
  

    
    useEffect(() => {
        if (setPasswordData?.status) {
            setLoading(false)
            ToastMessage('success', setPasswordData?.message);
            navigate('/');
        } else {
            ToastMessage('error', setPasswordData?.message);
            setLoading(false)
        }
    }, [setPasswordData]);




  useEffect(() => {
    values.email = decodedEmail
    setFieldValue("email" , decodedEmail)
  }, [decodedEmail,email]);



    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);




    return (
        <div className='login-container'>
            <form onSubmit={handleformSubmit} className='login-container_fluid'>
                <Logo/>
                {/* <div className='login-div'>
                    <h3>Reset Password</h3>
                    
                </div> */}

                <div>
                    <InputField label="Email" placeholder="Enter your email" name="email" value={values.email} type="email" disabled='true'
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {errors.email && touched.email ? (
                        <p className="form-error">{errors.email}</p>
                    ) : null}

                </div>

                <FormControl sx={{ width: '100%', mt: 2 ,}} variant="outlined">


                    <InputLabel className='label_sign_password' htmlFor="outlined-adornment-password">New Password</InputLabel>
                    <OutlinedInput
                     className='input_sign_password'
                        id="password"
                        size='small'
                        autoComplete="new-password"
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                >
                                    {!showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="New Password"
                    />
                    {touched.password && errors.password ? (
                        <div className="form-error">{errors.password}</div>
                    ) : null}
                </FormControl>



                <FormControl sx={{ width: '100%', mt: 2 }} variant="outlined">
                    <InputLabel className='label_sign_password' htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                    <OutlinedInput
                     className='input_sign_password'
                        id="confirmPassword"
                        size='small'
                        autoComplete="new-password"
                        type={showConfirmPassword ? 'text' : 'password'}
                        name="confirmPassword"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowConfirmPassword}
                                    edge="end"
                                >
                                    {!showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Confirm Password"
                    />
                    {touched.confirmPassword && errors.confirmPassword ? (
                        <div className="form-error">{errors.confirmPassword}</div>
                    ) : null}
                </FormControl>

                <button type='submit' className='login-btn'>
                    
                    {loading ? (
                        <CircularProgress size={24} style={{ color: "white" }} />
                      ) : (
                        "Reset Password"
                      )}
                </button>

            </form>
        </div>
    )
}

export default ReSetPassword;