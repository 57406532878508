import {combineReducers} from 'redux';
import * as type from '../../type';

const initialState = {};

const loginUser = (state = initialState, action) => {
  switch (action.type) {
    case type.LOGIN_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: true,
        loginInfo: action.data,
      };
    case type.LOGIN_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.LOGIN_USER_RESET:
        return {
          ...state,
          loading: false,
          loginInfo: null,
        };
    default:
      return state;
  }
};


const sendemail = (state = initialState, action) => {
  switch (action.type) {
    case type.SEND_EMAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.SEND_EMAIL_SUCCESS:
      return {
        ...state,
        loading: true,
        otpverify: action.data,
      };
    case type.SEND_EMAIL_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.SEND_EMAIL_RESET:
        return {
          ...state,
          loading: false,
          otpverify: null,
        };
    default:
      return state;
  }
};

const uploadimagereduser = (state = initialState, action) => {
 
  switch (action.type) {
    case type.UPLOAD_DOCUMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.UPLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: true,
        uploaddocumentdata: action.data,
      };
    case type.UPLOAD_DOCUMENT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    default:
      return state;
  }
};


const bankListreduser = (state = initialState, action) => {
 
  switch (action.type) {
    case type.BANK_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.BANK_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        bankListdata: action.data,
      };
    case type.BANK_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    default:
      return state;
  }
};

const resturantDetails = (state = initialState, action) => {
  switch (action.type) {
    case type.RESTURANT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.RESTURANT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: true,
        resturantDetailsInfo: action.data,
      };
    case type.RESTURANT_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.RESTURANT_DETAILS_RESET:
        return {
          ...state,
          loading: false,
          resturantDetailsInfo: null,
        };
    default:
      return state;
  }
};

const resturantType = (state = initialState, action) => {
  switch (action.type) {
    case type.RESTURANT_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.RESTURANT_TYPE_SUCCESS:
      return {
        ...state,
        loading: true,
        resturantTypeData: action.data,
      };
    case type.RESTURANT_TYPE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.RESTURANT_TYPE_RESET:
        return {
          ...state,
          loading: false,
          resturantTypeData: null,
        };
    default:
      return state;
  }
};

const cusinlist = (state = initialState, action) => {
  switch (action.type) {
    case type.CUSINS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.CUSINS_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        cusinlistdata: action.data,
      };
    case type.CUSINS_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.CUSINS_LIST_RESET:
        return {
          ...state,
          loading: false,
          cusinlistdata: null,
        };
    default:
      return state;
  }
};

const mobileotpreducer = (state = initialState, action) => {
  switch (action.type) {
    case type.MOBILE_OTP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.MOBILE_OTP_SUCCESS:
      return {
        ...state,
        loading: true,
        mobileotpdata: action.data,
      };
    case type.MOBILE_OTP_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.MOBILE_OTP_RESET:
        return {
          ...state,
          loading: false,
          mobileotpdata: null,
        };
    default:
      return state;
  }
};

const resturantotpreducer = (state = initialState, action) => {
  switch (action.type) {
    case type.RESTURANT_OTP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.RESTURANT_OTP_SUCCESS:
      return {
        ...state,
        loading: true,
        resturantotpdata: action.data,
      };
    case type.RESTURANT_OTP_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.RESTURANT_OTP_RESET:
        return {
          ...state,
          loading: false,
          resturantotpdata: null,
        };
    default:
      return state;
  }
};

const ownerotpverify = (state = initialState, action) => {
  switch (action.type) {
    case type.VERIFY_OWNEROTP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.VERIFY_OWNEROTP_SUCCESS:
      return {
        ...state,
        loading: true,
        ownerotpdata: action.data,
      };
    case type.VERIFY_OWNEROTP_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.VERIFY_OWNEROTP_RESET:
        return {
          ...state,
          loading: false,
          ownerotpdata: null,
        };
    default:
      return state;
  }
};

const resturantotpverify = (state = initialState, action) => {
  switch (action.type) {
    case type.VERIFY_RESTURANTOTP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.VERIFY_RESTURANTOTP_SUCCESS:
      return {
        ...state,
        loading: true,
        resturantotpverifydata: action.data,
      };
    case type.VERIFY_RESTURANTOTP_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.VERIFY_RESTURANTOTP_RESET:
        return {
          ...state,
          loading: false,
          resturantotpverifydata: null,
        };
    default:
      return state;
  }
};



const createResturant = (state = initialState, action) => {
  switch (action.type) {
    case type.CREATE_RESTURANT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.CREATE_RESTURANT_SUCCESS:
      return {
        ...state,
        loading: true,
        createresturantdata: action.data,
      };
    case type.CREATE_RESTURANT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.CREATE_RESTURANT_RESET:
        return {
          ...state,
          loading: false,
          createresturantdata: null,
        };
    default:
      return state;
  }
};


const setPasswordreduser = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.SET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: true,
        setPasswordData: action.data,
      };
    case type.SET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.SET_PASSWORD_RESET:
        return {
          ...state,
          loading: false,
          setPasswordData: null,
        };
    default:
      return state;
  }
};



const vendorLoginreduser = (state = initialState, action) => {
  switch (action.type) {
    case type.VENDOR_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.VENDOR_LOGIN_SUCCESS:
      return {
        ...state,
        loading: true,
        vendorloginData: action.data,
      };
    case type.VENDOR_LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.VENDOR_LOGIN_RESET:
        return {
          ...state,
          loading: false,
          vendorloginData: null,
        };
    default:
      return state;
  }
};


const bagsizelistreduser = (state = initialState, action) => {
  switch (action.type) {
    case type.BAGSIZE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.BAGSIZE_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        bagsizelistData: action.data,
      };
    case type.BAGSIZE_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.BAGSIZE_LIST_RESET:
        return {
          ...state,
          loading: false,
          bagsizelistData: null,
        };
    default:
      return state;
  }
};


const createbag = (state = initialState, action) => {
  switch (action.type) {
    case type.CREATE_BAG_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.CREATE_BAG_SUCCESS:
      return {
        ...state,
        loading: true,
        createbagData: action.data,
      };
    case type.CREATE_BAG_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.CREATE_BAG_RESET:
        return {
          ...state,
          loading: false,
          createbagData: null,
        };
    default:
      return state;
  }
};



const getDocumentReduser = (state = initialState, action) => {
  switch (action.type) {
    case type.DOCUMENTS_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.DOCUMENTS_DETAILS_SUCCESS:
      return {
        ...state,
        loading: true,
        getReduserData: action.data,
      };
    case type.DOCUMENTS_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.DOCUMENTS_DETAILS_RESET:
        return {
          ...state,
          loading: false,
          getReduserData: null,
        };
    default:
      return state;
  }
};





const dashboardbaglist = (state = initialState, action) => {
  switch (action.type) {
    case type.Bag_List_REQUEST:
      return {
        ...state,
        loading: true,
        dashboardbaglistData:null,
      };
    case type.Bag_List_SUCCESS:
      return {
        ...state,
        loading: true,
        dashboardbaglistData: action.data,
      };
    case type.Bag_List_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.Bag_List_RESET:
        return {
          ...state,
          loading: false,
          dashboardbaglistData: null,
        };
    default:
      return state;
  }
};


const surprisebagDetailsReduser = (state = initialState, action) => {
  switch (action.type) {
    case type.SURPRISEBAG_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.SURPRISEBAG_DETAILS_SUCCESS:
      return {
        ...state,
        loading: true,
        surprisebagDetailsData: action.data,
      };
    case type.SURPRISEBAG_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.SURPRISEBAG_DETAILS_RESET:
        return {
          ...state,
          loading: false,
          surprisebagDetailsData: null,
        };
    default:
      return state;
  }
};



const updateInstructionReduser = (state = initialState, action) => {
  switch (action.type) {
    case type.UPDATE_INSTRUCTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.UPDATE_INSTRUCTION_SUCCESS:
      return {
        ...state,
        loading: true,
        updateInstructionsData: action.data,
      };
    case type.UPDATE_INSTRUCTION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.UPDATE_INSTRUCTION_RESET:
        return {
          ...state,
          loading: false,
          updateInstructionsData: null,
        };
    default:
      return state;
  }
};



const updateoverviewReduser = (state = initialState, action) => {
  switch (action.type) {
    case type.UPDATE_OVERVIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.UPDATE_OVERVIEW_SUCCESS:
      return {
        ...state,
        loading: true,
        updateoverviewData: action.data,
      };
    case type.UPDATE_OVERVIEW_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.UPDATE_OVERVIEW_RESET:
        return {
          ...state,
          loading: false,
          updateoverviewData: null,
        };
    default:
      return state;
  }
};


const  updatesheduleDataReduser = (state = initialState, action) => {
  switch (action.type) {
    case type.UPDATE_SHEDULE_REQUEST:
      return {
        ...state,
        loading: true,
        updatesheduleData: null,
      };
    case type.UPDATE_SHEDULE_SUCCESS:
      return {
        ...state,
        loading: true,
        updatesheduleData: action.data,
      };
    case type.UPDATE_SHEDULE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.UPDATE_SHEDULE_RESET:
        return {
          ...state,
          loading: false,
          updatesheduleData: null,
        };
    default:
      return state;
  }
};

const  updatecalenderReduser = (state = initialState, action) => {
  switch (action.type) {
    case type.UPDATE_CALENDER_REQUEST:
      return {
        ...state,
        loading: true,
        updatecalenderData: null,
      };
    case type.UPDATE_CALENDER_SUCCESS:
      return {
        ...state,
        loading: true,
        updatecalenderData: action.data,
      };
    case type.UPDATE_CALENDER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.UPDATE_CALENDER_RESET:
        return {
          ...state,
          loading: false,
          updatecalenderData: null,
        };
    default:
      return state;
  }
};



const  pickupwindowDataReduser = (state = initialState, action) => {
  switch (action.type) {
    case type.GET_PICKUPWINDOWDATA_REQUEST:
      return {
        ...state,
        loading: true,
        pickupwindowData: null,
      };
    case type.GET_PICKUPWINDOWDATA_SUCCESS:
      return {
        ...state,
        loading: true,
        pickupwindowData: action.data,
      };
    case type.GET_PICKUPWINDOWDATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.GET_PICKUPWINDOWDATA_RESET:
        return {
          ...state,
          loading: false,
          pickupwindowData: null,
        };
    default:
      return state;
  }
};

const  orderListReduser = (state = initialState, action) => {
  switch (action.type) {
    case type.ORDER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        orderListData: null,
      };
    case type.ORDER_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        orderListData: action.data,
      };
    case type.ORDER_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.ORDER_LIST_RESET:
        return {
          ...state,
          loading: false,
          orderListData: null,
        };
    default:
      return state;
  }
};



const  dashboardReduser = (state = initialState, action) => {
  switch (action.type) {
    case type.DASHBOARD_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        dashboardrevenueData: null,
      };
    case type.DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        loading: true,
        dashboardrevenueData: action.data,
      };
    case type.DASHBOARD_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.DASHBOARD_DATA_RESET:
        return {
          ...state,
          loading: false,
          dashboardrevenueData: null,
        };
    default:
      return state;
  }
};



const  notificationReduser = (state = initialState, action) => {
  switch (action.type) {
    case type.NOTIFICATION_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        notificationData: null,
      };
    case type.NOTIFICATION_DATA_SUCCESS:
      return {
        ...state,
        loading: true,
        notificationData: action.data,
      };
    case type.NOTIFICATION_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.NOTIFICATION_DATA_RESET:
        return {
          ...state,
          loading: false,
          notificationData: null,
        };
    default:
      return state;
  }
};



const  orderDetailsReduser = (state = initialState, action) => {
  switch (action.type) {
    case type.ORDER_DEATILS_REQUEST:
      return {
        ...state,
        loading: true,
        orderdetailsData: null,
      };
    case type.ORDER_DEATILS_SUCCESS:
      return {
        ...state,
        loading: true,
        orderdetailsData: action.data,
      };
    case type.ORDER_DEATILS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.ORDER_DEATILS_RESET:
        return {
          ...state,
          loading: false,
          orderdetailsData: null,
        };
    default:
      return state;
  }
};

const  activeSurpriseBagReduser = (state = initialState, action) => {
  switch (action.type) {
    case type.ACTIVE_SURPRISEBAG_REQUEST:
      return {
        ...state,
        loading: true,
        activeSurpriseBagData: null,
      };
    case type.ACTIVE_SURPRISEBAG_SUCCESS:
      return {
        ...state,
        loading: true,
        activeSurpriseBagData: action.data,
      };
    case type.ACTIVE_SURPRISEBAG_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.ACTIVE_SURPRISEBAG_RESET:
        return {
          ...state,
          loading: false,
          activeSurpriseBagData: null,
        };
    default:
      return state;
  }
};




const  changeOrderstatusReduser = (state = initialState, action) => {
  switch (action.type) {
    case type.CHANGE_ORDERSTATUS_REQUEST:
      return {
        ...state,
        loading: true,
        changeOrderstatusData: null,
      };
    case type.CHANGE_ORDERSTATUS_SUCCESS:
      return {
        ...state,
        loading: true,
        changeOrderstatusData: action.data,
      };
    case type.CHANGE_ORDERSTATUS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.CHANGE_ORDERSTATUS_RESET:
        return {
          ...state,
          loading: false,
          changeOrderstatusData: null,
        };
    default:
      return state;
  }
};


const  editSurpriseBagReduser = (state = initialState, action) => {
  switch (action.type) {
    case type.EDIT_SURPRISEBAG_REQUEST:
      return {
        ...state,
        loading: true,
        editSurpriseBagData: null,
      };
    case type.EDIT_SURPRISEBAG_SUCCESS:
      return {
        ...state,
        loading: true,
        editSurpriseBagData: action.data,
      };
    case type.EDIT_SURPRISEBAG_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.EDIT_SURPRISEBAG_RESET:
        return {
          ...state,
          loading: false,
        editSurpriseBagData: null,
        };
    default:
      return state;
  }
};



const  editcoverPhotoReduser = (state = initialState, action) => {
  switch (action.type) {
    case type.EDIT_COVERPHOTO_REQUEST:
      return {
        ...state,
        loading: true,
        editcoverPhotoData: null,
      };
    case type.EDIT_COVERPHOTO_SUCCESS:
      return {
        ...state,
        loading: true,
        editcoverPhotoData: action.data,
      };
    case type.EDIT_COVERPHOTO_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.EDIT_COVERPHOTO_RESET:
        return {
          ...state,
          loading: false,
          editcoverPhotoData: null,
        };
    default:
      return state;
  }
};


const  editLogoReduser = (state = initialState, action) => {
  switch (action.type) {
    case type.EDIT_LOGO_REQUEST:
      return {
        ...state,
        loading: true,
        editlogoData: null,
      };
    case type.EDIT_LOGO_SUCCESS:
      return {
        ...state,
        loading: true,
        editlogoData: action.data,
      };
    case type.EDIT_LOGO_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.EDIT_LOGO_RESET:
        return {
          ...state,
          loading: false,
          editlogoData: null,
        };
    default:
      return state;
  }
};


const  forgetPasswordReduser = (state = initialState, action) => {
  switch (action.type) {
    case type.FORGET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        forgetPasswordData: null,
      };
    case type.FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: true,
        forgetPasswordData: action.data,
      };
    case type.FORGET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.FORGET_PASSWORD_RESET:
        return {
          ...state,
          loading: false,
          forgetPasswordData: null,
        };
    default:
      return state;
  }
};

const  getStatusReduser = (state = initialState, action) => {
  switch (action.type) {
    case type.GET_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
        getStatusData: null,
      };
    case type.GET_STATUS_SUCCESS:
      return {
        ...state,
        loading: true,
        getStatusData: action.data,
      };
    case type.GET_STATUS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.GET_STATUS_RESET:
        return {
          ...state,
          loading: false,
          getStatusData: null,
        };
    default:
      return state;
  }
};


const  addMoreReduser = (state = initialState, action) => {
  switch (action.type) {
    case type.ADDMORE_SURPRISEBAG_REQUEST:
      return {
        ...state,
        loading: true,
        addMoreData: null,
      };
    case type.ADDMORE_SURPRISEBAG_SUCCESS:
      return {
        ...state,
        loading: true,
        addMoreData: action.data,
      };
    case type.ADDMORE_SURPRISEBAG_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.ADDMORE_SURPRISEBAG_RESET:
        return {
          ...state,
          loading: false,
          addMoreData: null,
        };
    default:
      return state;
  }
};


const  createTicketReduser = (state = initialState, action) => {
  switch (action.type) {
    case type.CREATE_TICKET_REQUEST:
      return {
        ...state,
        loading: true,
        createTicketData: null,
      };
    case type.CREATE_TICKET_SUCCESS:
      return {
        ...state,
        loading: true,
        createTicketData: action.data,
      };
    case type.CREATE_TICKET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.CREATE_TICKET_RESET:
        return {
          ...state,
          loading: false,
          createTicketData: null,
        };
    default:
      return state;
  }
};



const  getPrivacyReduser = (state = initialState, action) => {
  switch (action.type) {
    case type.GET_PRIVACY_REQUEST:
      return {
        ...state,
        loading: true,
        getPrivacyData: null,
      };
    case type.GET_PRIVACY_SUCCESS:
      return {
        ...state,
        loading: true,
        getPrivacyData: action.data,
      };
    case type.GET_PRIVACY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.GET_PRIVACY_RESET:
        return {
          ...state,
          loading: false,
          getPrivacyData: null,
        };
    default:
      return state;
  }
};


const  getfaqReduser = (state = initialState, action) => {
  switch (action.type) {
    case type.GET_FAQ_REQUEST:
      return {
        ...state,
        loading: true,
        getfaqData: null,
      };
    case type.GET_FAQ_SUCCESS:
      return {
        ...state,
        loading: true,
        getfaqData: action.data,
      };
    case type.GET_FAQ_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.GET_FAQ_RESET:
        return {
          ...state,
          loading: false,
          getfaqData: null,
        };
    default:
      return state;
  }
};




const  verifyCodeReduser = (state = initialState, action) => {
  switch (action.type) {
    case type.VERIFY_CODE_REQUEST:
      return {
        ...state,
        loading: true,
        verifyCodeData: null,
      };
    case type.VERIFY_CODE_SUCCESS:
      return {
        ...state,
        loading: true,
        verifyCodeData: action.data,
      };
    case type.VERIFY_CODE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.VERIFY_CODE_RESET:
        return {
          ...state,
          loading: false,
          verifyCodeData: null,
        };
    default:
      return state;
  }
};


const  reservationListDataReduser = (state = initialState, action) => {
  switch (action.type) {
    case type.RESERVATION_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        reservationListData: null,
      };
    case type.RESERVATION_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        reservationListData: action.data,
      };
    case type.RESERVATION_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.RESERVATION_LIST_RESET:
        return {
          ...state,
          loading: false,
          reservationListData: null,
        };
    default:
      return state;
  }
};


const  getSpecialdaysDataReduser = (state = initialState, action) => {
  switch (action.type) {
    case type.SPECIALDAYS_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        getSpecialdaysData: null,
      };
    case type.SPECIALDAYS_DATA_SUCCESS:
      return {
        ...state,
        loading: true,
        getSpecialdaysData: action.data,
      };
    case type.SPECIALDAYS_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.SPECIALDAYS_DATA_RESET:
        return {
          ...state,
          loading: false,
          getSpecialdaysData: null,
        };
    default:
      return state;
  }
};



const  ExcleDownloadOrder = (state = initialState, action) => {

  switch (action.type) {
    case type.Excle_Download_REQUEST:
      return {
        ...state,
        loading: true,
        ExcleDownloadOrderInfo: null,
      };
    case type.Excle_Download_SUCCESS:
      return {
        ...state,
        loading: true,
        ExcleDownloadOrderInfo: action.data,
      };
    case type.Excle_Download_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.Excle_Download_RESET:
        return {
          ...state,
          loading: false,
          ExcleDownloadOrderInfo: null,
        };
    default:
      return state;
  }
};

const  SupportTicketByid = (state = initialState, action) => {
 
  switch (action.type) {
    case type.Support_Ticket_By_id_REQUEST:
      return {
        ...state,
        loading: true,
        SupportTicketByiddata: null,
      };
    case type.Support_Ticket_By_id_SUCCESS:
      return {
        ...state,
        loading: true,
        SupportTicketByiddata: action.data,
      };
    case type.Support_Ticket_By_id_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.Support_Ticket_By_id_RESET:
        return {
          ...state,
          loading: false,
          SupportTicketByiddata: null,
        };
    default:
      return state;
  }
};


const  submitTicketChatData = (state = initialState, action) => {
 
  switch (action.type) {
    case type.submitTicketChatData_REQUEST:
      return {
        ...state,
        loading: true,
        submitTicketChatData: null,
      };
    case type.submitTicketChatData_SUCCESS:
      return {
        ...state,
        loading: true,
        submitTicketChatData: action.data,
      };
    case type.submitTicketChatData_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.submitTicketChatData_RESET:
        return {
          ...state,
          loading: false,
          submitTicketChatData: null,
        };
    default:
      return state;
  }
};

const  GetTicketChatData = (state = initialState, action) => {
 
  switch (action.type) {
    case type.GetTicketChatData_REQUEST:
      return {
        ...state,
        loading: true,
        GetTicketChatData: null,
      };
    case type.GetTicketChatData_SUCCESS:
      return {
        ...state,
        loading: true,
        GetTicketChatData: action.data,
      };
    case type.GetTicketChatData_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.GetTicketChatData_RESET:
        return {
          ...state,
          loading: false,
          GetTicketChatData: null,
        };
    default:
      return state;
  }
};



const  notificationreadReduser = (state = initialState, action) => {
 
  switch (action.type) {
    case type.NOTIFICATION_READ_REQUEST:
      return {
        ...state,
        loading: true,
        notificationreadData: null,
      };
    case type.NOTIFICATION_READ_SUCCESS:
      return {
        ...state,
        loading: true,
        notificationreadData: action.data,
      };
    case type.NOTIFICATION_READ_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.NOTIFICATION_READ_RESET:
        return {
          ...state,
          loading: false,
          notificationreadData: null,
        };
    default:
      return state;
  }
};


const  notificationcountReduser = (state = initialState, action) => {
 
  switch (action.type) {
    case type.NOTIFICATION_COUNT_REQUEST:
      return {
        ...state,
        loading: true,
        notificationcountData: null,
      };
    case type.NOTIFICATION_COUNT_SUCCESS:
      return {
        ...state,
        loading: true,
        notificationcountData: action.data,
      };
    case type.NOTIFICATION_COUNT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.NOTIFICATION_COUNT_RESET:
        return {
          ...state,
          loading: false,
          notificationcountData: null,
        };
    default:
      return state;
  }
};



const ChangePasswordReduser = (state = initialState, action) => {
  console.log(action,"??**")
  switch (action.type) {
    case type.Change_Password_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.Change_Password_SUCCESS:
      return {
        ...state,
        loading: true,
        ChangeData: action.data,
      };
    case type.Change_Password_ERROR:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
      case type.Change_Password_RESET:
        return {
          ...state,
          loading: false,
          ChangeData: null,
        };
    default:
      return state;
  }
};
export {
  loginUser,
  notificationreadReduser,
  pickupwindowDataReduser,
  ExcleDownloadOrder,
  getSpecialdaysDataReduser,
  reservationListDataReduser,
  verifyCodeReduser,
  notificationReduser,
  getDocumentReduser,
  getfaqReduser,
  getPrivacyReduser,
  createTicketReduser,
  addMoreReduser,
  getStatusReduser,
  forgetPasswordReduser,
  editLogoReduser,
  editcoverPhotoReduser,
  editSurpriseBagReduser,
  changeOrderstatusReduser,
  activeSurpriseBagReduser,
  orderListReduser,
  orderDetailsReduser,
  updatecalenderReduser,
  updatesheduleDataReduser,
  updateoverviewReduser,
  surprisebagDetailsReduser,
  updateInstructionReduser,
  dashboardbaglist,
  sendemail,
  createbag,
  bagsizelistreduser,
  uploadimagereduser,
  resturantDetails,
  resturantType,
  cusinlist,
  mobileotpreducer,
  resturantotpreducer,
  ownerotpverify,
  resturantotpverify,
  createResturant,
  setPasswordreduser,
  vendorLoginreduser,
  bankListreduser,
  SupportTicketByid,
  submitTicketChatData,
  GetTicketChatData,
  ChangePasswordReduser,
  dashboardReduser,
  notificationcountReduser,
};
