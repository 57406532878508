import { Box, Grid } from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './index.css';
import Switch from '@mui/material/Switch';
import TimePickercomponent from '../../resturantType/timepicker';
import { alpha, styled } from '@mui/material/styles';
import { pink } from '@mui/material/colors';
import { Padding } from '@mui/icons-material';
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/x-date-pickers';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const ScheduleOperations = ({
    shobtn,
    values,
    handleCheckboxChange,
    handleNewTimeSet,
    errors,
    handleinputChange,
    setContinueactive
}) => {
    const params = useParams();

    const currentTime = new Date();
    const hours = currentTime.getHours().toString().padStart(2, '0');
    const minutes = currentTime.getMinutes().toString().padStart(2, '0');
    const formattedTime = `${hours}:${minutes}`;

    const [renderUI, setRenderUI] = useState(1);

    const [daysArray] = useState([
        { "name": "Monday", "dailyLimit": "mondayLimit", "formikKeyCheckbox": "mondayProperty", "formikKeyStartTime": "mondayStart", "formikKeyEndTime": "mondayEnd" },
        { "name": "Tuesday", "dailyLimit": "tuesdayLimit", "formikKeyCheckbox": "tuesdayProperty", "formikKeyStartTime": "tuesdayStart", "formikKeyEndTime": "tuesdayEnd" },
        { "name": "Wednesday", "dailyLimit": "wednesdayLimit", "formikKeyCheckbox": "wednesdayProperty", "formikKeyStartTime": "wednesdayStart", "formikKeyEndTime": "wednesdayEnd" },
        { "name": "Thursday", "dailyLimit": "thursdayLimit", "formikKeyCheckbox": "thursdayProperty", "formikKeyStartTime": "thursdayStart", "formikKeyEndTime": "thursdayEnd" },
        { "name": "Friday", "dailyLimit": "fridayLimit", "formikKeyCheckbox": "fridayProperty", "formikKeyStartTime": "fridayStart", "formikKeyEndTime": "fridayEnd" },
        { "name": "Saturday", "dailyLimit": "saturdayLimit", "formikKeyCheckbox": "saturdayProperty", "formikKeyStartTime": "saturdayStart", "formikKeyEndTime": "saturdayEnd" },
        { "name": "Sunday", "dailyLimit": "sundayLimit", "formikKeyCheckbox": "sundayProperty", "formikKeyStartTime": "sundayStart", "formikKeyEndTime": "sundayEnd" },


    ]);

   


    const getValidationMessage = (checkbox, startTime, endTime) => {
        let valildationMessage = "";
        if (values[checkbox] === '1') {
            if (values[startTime] === "" || values[endTime] === "") {
                valildationMessage = "Please enter details"
                errors[checkbox] = "Please enter details"
            } else {
                delete errors[checkbox];
            }
        } else {
            delete errors[checkbox];
        }
        return valildationMessage
    }

    const PinkSwitch = styled(Switch)(({ theme }) => ({
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: "#267887",
            '&:hover': {
                backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
            },
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: "#267887",
        },
    }));


    const timePickerUI = () => {
        return daysArray?.map((item) => {
            console.log("9876", item)
           
            const getMinTime = (item) => {
                return values[item.formikKeyStartTime]
                ? dayjs(values[item.formikKeyStartTime], 'HH:mm').add(1, 'minute')
                : null;;
            };
        
            const getMaxTime = (item) => {
                console.log("values[item.formikKeyEndTime]", values[item.formikKeyEndTime])
                return values[item.formikKeyEndTime] || null;
            };


            return (
                <Grid item xs={12} md={12} lg={12} sx={{ display: 'flex' }} className='' >
                    <div className="operation_field_container shedule_grid">
                        {/* <FormControlLabel
              control={
                <Checkbox
                  checked={values[item.formikKeyCheckbox] === '1'}
                  onChange={(e) => handleCheckboxChange(item.formikKeyCheckbox, e)}
                  sx={{
                    color: "#2a666b",
                    '&.Mui-checked': {
                      color: "#2a666b",
                    },
                  }}

                />
                // <Switch
                //             checked={values[item.formikKeyCheckbox] === '1'}
                //             onChange={(e) => handleCheckboxChange(item.formikKeyCheckbox, e)}
                //             inputProps={{ 'aria-label': 'controlled' }}
                //         />
              }
              label={item.name}
            /> */}
                        {/* <Switch
                            checked={values[item.formikKeyCheckbox] === '1'}
                            onChange={(e) => handleCheckboxChange(item.formikKeyCheckbox, e)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        /> */}

                        <h3 className='schedule_lable'>{item.name}</h3>


                        <FormControlLabel
                            className='form_control_lable'
                            control={
                                <PinkSwitch
                                  disabled={!shobtn}
                                    defaultChecked={item.formikKeyCheckbox}
                                    checked={values[item.formikKeyCheckbox] == '1'}
                                    onChange={(e) => handleCheckboxChange(item.formikKeyCheckbox, e)}
                                // sx={{
                                //     color: "#2a666b",
                                //     '&.Mui-checked': {
                                //         color: "#2a666b",
                                //     },
                                // }}

                                />

                            }
                            label={values[item.formikKeyCheckbox] == '1' ? <p className='pick_up'>Pick up</p> : <p className='no_pick_up'>No pickup</p>}
                        />
                        <div className='schedule_limit_container'>
                            <input type='number' disabled={values[item.formikKeyCheckbox] == '0' ||  !shobtn} value={values[item.formikKeyCheckbox] == "0" ? "" : values[item.dailyLimit]} onChange={(e) => handleinputChange(item.dailyLimit, e)} />
                            <lable>Daily limit: 5</lable>
                        </div>



                        <div className='d-flex g-5'>
                            <TimePickercomponent
                                value={values[item.formikKeyStartTime]}
                                disabled={values[item.formikKeyCheckbox] == '0' ||  !shobtn}
                                label=""
                                onChange={(newvalue) => handleNewTimeSet(newvalue, item.formikKeyStartTime)}
                                defaultValue={values[item.formikKeyStartTime]}
                                maxTime={getMaxTime(item)}
                            />
                            <TimePickercomponent
                                value={values[item.formikKeyEndTime]}
                                disabled={values[item.formikKeyCheckbox] == '0' || !shobtn}
                                label=""
                                onChange={(newvalue) => handleNewTimeSet(newvalue, item.formikKeyEndTime)}
                                defaultValue={values[item.formikKeyEndTime]}
                                minTime={getMinTime(item)}
                            />


                            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Box className="flex-wrap" sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                <label htmlFor="time"></label>
                                    <DemoContainer className="input_time_field" components={['TimePicker', 'TimePicker']}>
                                        <TimePicker
                                            className="input_time_field_input"
                                            label=""
                                            placeholder=""
                                            // onBlur={handleBlur}
                                            value={values[item.formikKeyStartTime]}
                                            onChange={(newValue) => handleNewTimeSet(newValue, item.formikKeyStartTime)}
                                            // maxTime={maxStartTime}
                                            ampm={false}
                                        />

                                    </DemoContainer>
                                   

                                </Box>
                            </LocalizationProvider>  
                            
                             <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Box className="flex-wrap" sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>

                                <label htmlFor="time"></label>
                                    <DemoContainer className="input_time_field" components={['TimePicker', 'TimePicker']}>
                                        <TimePicker
                                            className="input_time_field_input"
                                            label=""
                                            placeholder=""
                                            // onBlur={handleBlur}
                                            value={values[item.formikKeyEndTime]}
                                            onChange={(newValue) => handleNewTimeSet(newValue, item.formikKeyEndTime)}
                                            // maxTime={maxStartTime}
                                            ampm={false}
                                        />

                                    </DemoContainer>
                                  
                                </Box>
                            </LocalizationProvider> */}
                        </div>
                    </div>
                    <p style={{ color: 'red', marginLeft: '15px' }}>{getValidationMessage(item.formikKeyCheckbox,
                        item.formikKeyStartTime,
                        item.formikKeyEndTime
                    )}</p>

                    <hr></hr>
                </Grid>)
        })
    }
    return (
        <div style={{ marginLeft: '5px', marginTop: "20px" }}>
            <div>
                <Grid container spacing={0} mt="10px" sx={{ display: 'flex' }}>
                    {timePickerUI()}
                </Grid>

            </div>
        </div>
    );
};

export default ScheduleOperations;
