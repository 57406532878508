import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Skeleton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import SearchToolbar from "../../components/Search/Search";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import TimeOperations from "./time";
import { useFormik } from "formik";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { get_cuisin, resturant_type_and_timing } from "../../API/baseUrl";
import { cusinslist, resturantType } from "../../redux/actions";
import ToastMessage from "../../utils/ToastMessage";
import CustomizedHook from "../../components/multiselect";
import { Autocomplete } from "@mui/joy";
import UseScrollToTop from "../../components/topOpen";
import ScheduleForm from "./time";
import DatePickerCom from "./Operation";
const vendorIdFk = localStorage.getItem("vendorIdFk");

const establismentType = [
  { title: "Restaurant", year: 1 },
  { title: "Cafe", year: 2 },
  { title: "Buffet Restaurant", year: 3 },
  { title: "Takeaway Restaurant", year: 4 },
  { title: "Sushi Restaurant", year: 5 },
  { title: "Hotel", year: 6 },
  { title: "Bakery", year: 7 },
  { title: "Pastry", year: 8 },
  { title: "Shop", year: 9 },
  { title: "Supermarket", year: 10 },
  { title: "Beverage", year: 11 },
  { title: "Butcher Shop", year: 12 },
  { title: "Fruit & Vegetable Store", year: 13 },
  { title: "Others", year: 14 },
];

const Timing = ({
  allendval,
  setAllendval,
  allstartval,
  setAllstartval,
  cusinlist,
  setCusinlist,
  radiovalue,
  showselectedValues,
  selectedOptions,
  setSelectedOptions,
  setShowselectedValues,
  setRadiovalue,
  errors,
  values,
  handleChange,
  touched,
  handleBlur,
  setFieldValue,
  selectedValues,
  setSelectedValues,
  days,
  setDays,
}) => {
  const dispatch = useDispatch();

  const { resturantTypeData } = useSelector(
    (state) => state?.resturantType || {}
  );
  const { cusinlistdata } = useSelector((state) => state?.cusinlist || {});
  const [searchtext, setSearchtext] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const parameter = {
      search: searchtext ? searchtext : "",
      url: get_cuisin,
    };
    dispatch(cusinslist(parameter));
  }, [searchtext]);

  useEffect(() => {
    if (cusinlistdata?.status) {
      setCusinlist(cusinlistdata?.data);
      setLoading(false);
      // ToastMessage("success", cusinlistdata?.message);
    } else {
      ToastMessage("error", cusinlistdata?.message);
      setLoading(false);
    }
  }, [cusinlistdata]);

  useEffect(() => {
    if (resturantTypeData?.status) {
      ToastMessage("success", resturantTypeData?.message);
    } else {
      ToastMessage("error", resturantTypeData?.message);
    }
  }, [resturantTypeData]);

  const handleNewTimeSet = (e, val) => {
    const timeValue = e;
    setFieldValue(val, timeValue);
  };

  const handleCheckboxChange = (name, e) => {
    if (name == "mondayProperty") {
      //   setCheckOperation(e.target.checked ? true : false);
      //   setMessageOperation(e.target.checked ? true : false);
      setFieldValue("mondayProperty", e.target.checked ? "1" : "0");

      if (!e.target.checked) {
        setFieldValue("mondayStart", "");
        setFieldValue("mondayEnd", "");
        setFieldValue("mondayLimit", 0);
      }
    }

    if (name == "tuesdayProperty") {
      setFieldValue("tuesdayProperty", e.target.checked ? "1" : "0");
      if (!e.target.checked) {
        setFieldValue("tuesdayStart", "");
        setFieldValue("tuesdayEnd", "");
        setFieldValue("tuesdayLimit", 0);
      }
    }
    if (name == "wednesdayProperty") {
      setFieldValue("wednesdayProperty", e.target.checked ? "1" : "0");
      if (!e.target.checked) {
        setFieldValue("wednesdayStart", "");
        setFieldValue("wednesdayEnd", "");
        setFieldValue("wednesdayLimit", 0);
      }
    }
    if (name == "thursdayProperty") {
      setFieldValue("thursdayProperty", e.target.checked ? "1" : "0");
      if (!e.target.checked) {
        setFieldValue("thursdayStart", "");
        setFieldValue("thursdayEnd", "");
        setFieldValue("thursdayLimit", 0);
      }
    }
    if (name == "fridayProperty") {
      setFieldValue("fridayProperty", e.target.checked ? "1" : "0");
      if (!e.target.checked) {
        setFieldValue("fridayStart", "");
        setFieldValue("fridayEnd", "");
        setFieldValue("fridayLimit", 0);
      }
    }
    if (name == "saturdayProperty") {
      setFieldValue("saturdayProperty", e.target.checked ? "1" : "0");
      if (!e.target.checked) {
        setFieldValue("saturdayStart", "");
        setFieldValue("saturdayEnd", "");
        setFieldValue("saturdayLimit", 0);
      }
    }
    if (name == "sundayProperty") {
      setFieldValue("sundayProperty", e.target.checked ? "1" : "0");

      if (!e.target.checked) {
        setFieldValue("sundayStart", "");
        setFieldValue("sundayEnd", "");
        setFieldValue("sundayLimit", 0);
      }
    }
  };

  const handleRemoveCuisine = (item) => {
    setSelectedValues(selectedValues.filter((value) => value !== item));
    setShowselectedValues(selectedValues.filter((value) => value !== item));
  };

  const handleCheckboxChangecuisin = (event) => {
    const value = event.target.value;
    setSelectedValues((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value]
    );
    setShowselectedValues((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value]
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="resturent_container">
      <UseScrollToTop />

      <Accordion defaultExpanded className="restaurant_acordian">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <div className="acordian_heading_container">
            <h3 className="acordian_heading_text">Type of Cuisines *</h3>
            <p>Select options which best describe food you serve</p>
          </div>
        </AccordionSummary>

        <AccordionDetails className="accordion_gap1">
          <div className="selected_value_container">
            {selectedValues.length > 0 && (
              <>
                <span>Selected:</span>
                <div className="cuising_display">
                  {selectedValues.map((item, index) => (
                    <div key={index} className="cusinscontainer">
                      <p className="mt-3">{item}</p>
                      <CloseIcon
                        sx={{
                          fontSize: "18px",
                          fontWeight: 500,
                          cursor: "pointer",
                        }}
                        onClick={() => handleRemoveCuisine(item)}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>

          <SearchToolbar {...{ searchtext, setSearchtext }} />

          {/* <div className="formcontrol-div">
            {cusinlist?.map((item, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    sx={{
                      color: `var(--colorused)`,
                      "&.Mui-checked": {
                        color: `var(--colorused)`,
                      },
                    }}
                    value={item.specialty}
                    checked={selectedValues.includes(item.specialty)}
                    onChange={handleCheckboxChangecuisin}
                  />
                }
                label={item.specialty}
              />
            ))}
          </div> */}

          {/* <div className="formcontrol-div">
{loading ? (
    // Display skeleton loaders in 3 columns
    Array.from(new Array(9)).map((_, index) => (
      <div key={index} style={{ flex: '1 0 30%', marginBottom: '10px', display: 'flex',  }}>
        <Skeleton variant="text" width={150} height={30} />
      </div>
    ))
  ) : (
        cusinlist?.map((item, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                sx={{
                  color: `var(--colorused)`,
                  "&.Mui-checked": {
                    color: `var(--colorused)`,
                  },
                }}
                value={item.specialty}
                checked={selectedValues.includes(item.specialty)}
                onChange={handleCheckboxChangecuisin}
              />
            }
            label={item.specialty}
          />
        ))
      )}
    </div> */}
          <div
            className="formcontrol-div"
            style={{
              // display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            {loading
              ? // Display skeleton loaders in a row
                Array.from(new Array(12)).map((_, index) => (
                  <div
                    key={index}
                    style={{
                      flex: "1 0 30%",
                      marginBottom: "10px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Skeleton variant="text" width={150} height={30} />
                  </div>
                ))
              : cusinlist?.map((item, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        sx={{
                          color: `var(--colorused)`,
                          "&.Mui-checked": {
                            color: `var(--colorused)`,
                          },
                        }}
                        value={item.specialty}
                        checked={selectedValues.includes(item.specialty)}
                        onChange={handleCheckboxChangecuisin}
                      />
                    }
                    label={item.specialty}
                  />
                ))}
          </div>
        </AccordionDetails>
      </Accordion>
      {errors.cuisinesSpeacility && touched.cuisinesSpeacility ? (
        <p className="form-error">{errors.cuisinesSpeacility}</p>
      ) : null}

      <Accordion defaultExpanded className="resturant_acordian">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <div className="acordian_heading_container">
            <h3 className="acordian_heading_text">Establishment Type *</h3>
            <p>Select most relevant category for your restaurant type</p>
          </div>
        </AccordionSummary>
        <AccordionDetails className="accordion_gap">
          <CustomizedHook
            {...{ setFieldValue, values, selectedOptions, setSelectedOptions }}
          />
        </AccordionDetails>
      </Accordion>
      {errors.establishmentType && touched.establishmentType ? (
        <p className="form-error">{errors.establishmentType}</p>
      ) : null}

      <Accordion defaultExpanded className="resturant_acordian">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <div className="acordian_heading_container">
            <h3 className="acordian_heading_text">Operation Days</h3>
          </div>
        </AccordionSummary>
        <AccordionDetails className="accordion_gap">
          {/* <TimeOperations {...{
                        values,
                        handleCheckboxChange,
                        handleNewTimeSet,
                        errors,
                        setFieldValue,
                        allendval,
                        allstartval
                    }} /> */}
          <DatePickerCom
            {...{
              values,
              handleCheckboxChange,
              handleNewTimeSet,
              errors,
              setFieldValue,
              allendval,
              allstartval,
              days,
              setDays,
            }}
          />
        </AccordionDetails>
      </Accordion>
      {errors.operationDay && touched.operationDay ? (
        <p className="form-error">{errors.operationDay}</p>
      ) : null}

      {/* <button className='imageupload_btn' type='submit'>Submit</button> */}
    </div>
  );
};

export default Timing;
