import {call, put, takeEvery} from 'redux-saga/effects';
import * as type from '../../type';
import {
  getNetworkCall,
  postexcelNetworkCall,
  postNetworkCall,
  postNetworkcreateCall,
} from '../../../networkCall';

function* loginUsers(action) {
  try {
    const loginUser = yield call(postNetworkCall, action.payload);
    yield put({type: type.LOGIN_USER_SUCCESS, data: loginUser});
  } catch (e) {
    yield put({type: type.LOGIN_USER_ERROR, message: e.message});
  }
}

function* sendemailS(action) {
  try {
    const sendemail = yield call(postNetworkCall, action.payload);
    yield put({type: type.SEND_EMAIL_SUCCESS, data: sendemail});
  } catch (e) {
    yield put({type: type.SEND_EMAIL_ERROR, message: e.message});
  }
}

function* uploadimageS(action) {
  try {
    const data = yield call(postNetworkcreateCall, action.payload);
    yield put({type: type.UPLOAD_DOCUMENT_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.UPLOAD_DOCUMENT_ERROR, message: e.message});
  }
}

function* createResturantS(action) {
 console.log("actionaction",action)
  try {
    const data = yield call(postNetworkcreateCall, action.payload);
    yield put({type: type.CREATE_RESTURANT_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.CREATE_RESTURANT_ERROR, message: e.message});
  }
}

function* resturantS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({type: type.RESTURANT_DETAILS_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.RESTURANT_DETAILS_ERROR, message: e.message});
  }
}

function* resturantTypeS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({type: type.RESTURANT_TYPE_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.RESTURANT_TYPE_ERROR, message: e.message});
  }
}

function* CUSINSLISTS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({type: type.CUSINS_LIST_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.CUSINS_LIST_ERROR, message: e.message});
  }
}

function* mobileotpS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({type: type.MOBILE_OTP_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.MOBILE_OTP_ERROR, message: e.message});
  }
}
function* resturantotpS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({type: type.RESTURANT_OTP_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.RESTURANT_OTP_ERROR, message: e.message});
  }
}

function* ownerotpverifyS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({type: type.VERIFY_OWNEROTP_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.VERIFY_OWNEROTP_ERROR, message: e.message});
  }
}

function* resturantotpverifyS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({type: type.VERIFY_RESTURANTOTP_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.VERIFY_RESTURANTOTP_ERROR, message: e.message});
  }
}



function* setPasswordS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({type: type.SET_PASSWORD_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.SET_PASSWORD_ERROR, message: e.message});
  }
}




function* vendorloginS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({type: type.VENDOR_LOGIN_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.VENDOR_LOGIN_ERROR, message: e.message});
  }
}



function* banklistS(action) {
  try {
    const data = yield call(getNetworkCall, action.payload);
    yield put({type: type.BANK_LIST_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.BANK_LIST_ERROR, message: e.message});
  }
}




function* createBagS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({type: type.CREATE_BAG_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.CREATE_BAG_ERROR, message: e.message});
  }
}


function* dashboardbaglistS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({type: type.Bag_List_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.Bag_List_ERROR, message: e.message});
  }
}



function* bagsizelistS(action) {
  try {
    const data = yield call(getNetworkCall, action.payload);
    yield put({type: type.BAGSIZE_LIST_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.BAGSIZE_LIST_ERROR, message: e.message});
  }
}

function* surprisebagDetailsS(action) {
  try {
    const data = yield call(getNetworkCall, action.payload);
    yield put({type: type.SURPRISEBAG_DETAILS_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.SURPRISEBAG_DETAILS_ERROR, message: e.message});
  }
}


function* updateInstructionS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({type: type.UPDATE_INSTRUCTION_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.UPDATE_INSTRUCTION_ERROR, message: e.message});
  }
}


function* updateoverviewS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({type: type.UPDATE_OVERVIEW_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.UPDATE_OVERVIEW_ERROR, message: e.message});
  }
}


function* updatesheduleS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({type: type.UPDATE_SHEDULE_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.UPDATE_SHEDULE_ERROR, message: e.message});
  }
}

function* updatecalenderS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({type: type.UPDATE_CALENDER_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.UPDATE_CALENDER_ERROR, message: e.message});
  }
}



function* pickupwindowDataS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({type: type.GET_PICKUPWINDOWDATA_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.GET_PICKUPWINDOWDATA_ERROR, message: e.message});
  }
}

function* orderListS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({type: type.ORDER_LIST_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.ORDER_LIST_ERROR, message: e.message});
  }
}

function* dashboardS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({type: type.DASHBOARD_DATA_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.DASHBOARD_DATA_ERROR, message: e.message});
  }
}

function* notificationS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({type: type.NOTIFICATION_DATA_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.NOTIFICATION_DATA_ERROR, message: e.message});
  }
}

function* orderstatuschangeS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({type: type.CHANGE_ORDERSTATUS_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.CHANGE_ORDERSTATUS_ERROR, message: e.message});
  }
}

function* activeSurpriseBagS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({type: type.ACTIVE_SURPRISEBAG_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.ACTIVE_SURPRISEBAG_ERROR, message: e.message});
  }
}



function* editSurpriseBagS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({type: type.EDIT_SURPRISEBAG_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.EDIT_SURPRISEBAG_ERROR, message: e.message});
  }
}


function* SendEmailS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({type: type.FORGET_PASSWORD_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.FORGET_PASSWORD_ERROR, message: e.message});
  }
}


function* getStatusS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({type: type.GET_STATUS_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.GET_STATUS_ERROR, message: e.message});
  }
}


function* getPrivacyS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({type: type.GET_PRIVACY_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.GET_PRIVACY_ERROR, message: e.message});
  }
}

function* getfaqS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({type: type.GET_FAQ_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.GET_FAQ_ERROR, message: e.message});
  }
}



function* editcoverPhotoS(action) {
  try {
    const data = yield call(postNetworkcreateCall, action.payload);
    yield put({type: type.EDIT_COVERPHOTO_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.EDIT_COVERPHOTO_ERROR, message: e.message});
  }
}

function* editlogoS(action) {
  try {
    const data = yield call(postNetworkcreateCall, action.payload);
    yield put({type: type.EDIT_LOGO_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.EDIT_LOGO_ERROR, message: e.message});
  }
}


function* AddmoreS(action) {
  
  try {
    const data = yield call(postNetworkcreateCall, action.payload);
    yield put({type: type.ADDMORE_SURPRISEBAG_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.ADDMORE_SURPRISEBAG_ERROR, message: e.message});
  }
}


function* CreateTicketS(action) {
  
  try {
    const data = yield call(postNetworkcreateCall, action.payload);
    yield put({type: type.CREATE_TICKET_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.CREATE_TICKET_ERROR, message: e.message});
  }
}




  function* OrderDetailsS(action) {
  
    try {
      const data = yield call(postNetworkCall, action.payload);
      yield put({type: type.ORDER_DEATILS_SUCCESS, data: data});
    } catch (e) {
      yield put({type: type.ORDER_DEATILS_ERROR, message: e.message});
    }
  }
  



    
function* documentgetS(action) {
  try {
    const data = yield call(getNetworkCall, action.payload);
    yield put({ type: type.DOCUMENTS_DETAILS_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.DOCUMENTS_DETAILS_ERROR, message: e.message });
  }
}


    
function* verifyCodeS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({ type: type.VERIFY_CODE_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.VERIFY_CODE_ERROR, message: e.message });
  }
}



    
function* reservationListS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({ type: type.RESERVATION_LIST_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.RESERVATION_LIST_ERROR, message: e.message });
  }
}


    
function* getSpecialDaysS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({ type: type.SPECIALDAYS_DATA_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.SPECIALDAYS_DATA_ERROR, message: e.message });
  }
}


function* ExcleDownloadOrderS(action) {
  try {
    const data = yield call(postexcelNetworkCall, action.payload);
    yield put({type: type.Excle_Download_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.Excle_Download_ERROR, message: e.message});
  }
}


function* SupportTicketByidS(action) {
  try {
    const data = yield call(getNetworkCall, action.payload);
    yield put({type: type.Support_Ticket_By_id_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.Support_Ticket_By_id_ERROR, message: e.message});
  }
}

function* notificationreadS(action) {
  try {
    const data = yield call(getNetworkCall, action.payload);
    yield put({type: type.NOTIFICATION_READ_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.NOTIFICATION_READ_ERROR, message: e.message});
  }
}

function* notificationcountS(action) {
  try {
    const data = yield call(getNetworkCall, action.payload);
    yield put({type: type.NOTIFICATION_COUNT_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.NOTIFICATION_COUNT_ERROR, message: e.message});
  }
}

function* submitTicketChatDataS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({type: type.submitTicketChatData_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.submitTicketChatData_ERROR, message: e.message});
  }
}

function* GetTicketChatDataS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({type: type.GetTicketChatData_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.GetTicketChatData_ERROR, message: e.message});
  }
}


function* changePasswordS(action) {
  try {
    const data = yield call(postNetworkCall, action.payload);
    yield put({type: type.Change_Password_SUCCESS, data: data});
  } catch (e) {
    yield put({type: type.Change_Password_ERROR, message: e.message});
  }
}

function* ExcleDownloadOrderSaga() {
  yield takeEvery(type.Excle_Download_REQUEST, ExcleDownloadOrderS);
}


function* getSpecialDaysSaga() {
  
  yield takeEvery(type.SPECIALDAYS_DATA_REQUEST, getSpecialDaysS);
}

function* notificationreadSaga() {
  
  yield takeEvery(type.NOTIFICATION_READ_REQUEST, notificationreadS);
}

function* notificationcountSaga() {
  
  yield takeEvery(type.NOTIFICATION_COUNT_REQUEST, notificationcountS);
}

function* reservationListSaga() {
  
  yield takeEvery(type.RESERVATION_LIST_REQUEST, reservationListS);
}

function* verifyCodeSaga() {
  
  yield takeEvery(type.VERIFY_CODE_REQUEST, verifyCodeS);
}


function* documentgetSaga() {
  
  yield takeEvery(type.DOCUMENTS_DETAILS_REQUEST, documentgetS);
}

function* getPrivacySaga() {
  
  yield takeEvery(type.GET_PRIVACY_REQUEST, getPrivacyS);
}
function* getfaqSaga() {
  
  yield takeEvery(type.GET_FAQ_REQUEST, getfaqS);
}


function* CreateTicketSaga() {
  
  yield takeEvery(type.CREATE_TICKET_REQUEST, CreateTicketS);
}

function* AddmoreSaga() {
  
  yield takeEvery(type.ADDMORE_SURPRISEBAG_REQUEST, AddmoreS);
}

function* SendEmailSaga() {
  yield takeEvery(type.FORGET_PASSWORD_REQUEST, SendEmailS);
}

function* getStatusSaga() {
  yield takeEvery(type.GET_STATUS_REQUEST, getStatusS);
}

function* editlogoSaga() {
  yield takeEvery(type.EDIT_LOGO_REQUEST, editlogoS);
}

function* editcoverPhotoSaga() {
  yield takeEvery(type.EDIT_COVERPHOTO_REQUEST, editcoverPhotoS);
}

function* editSurpriseBagSaga() {
  yield takeEvery(type.EDIT_SURPRISEBAG_REQUEST, editSurpriseBagS);
}

function* orderstatusChangeSaga() {
  yield takeEvery(type.CHANGE_ORDERSTATUS_REQUEST, orderstatuschangeS);
}

function* activeSurpriseBagSaga() {
  yield takeEvery(type.ACTIVE_SURPRISEBAG_REQUEST, activeSurpriseBagS);
}

function* orderListSaga() {
  yield takeEvery(type.ORDER_LIST_REQUEST, orderListS);
}


function* dashboardSaga() {
  yield takeEvery(type.DASHBOARD_DATA_REQUEST, dashboardS);
}

function* notificationSaga() {
  yield takeEvery(type.NOTIFICATION_DATA_REQUEST, notificationS);
}

function* orderDetailsSaga() {
  yield takeEvery(type.ORDER_DEATILS_REQUEST, OrderDetailsS);
}

function* updatecalenderSaga() {
  yield takeEvery(type.UPDATE_CALENDER_REQUEST, updatecalenderS);
}

function* pickupwindowDataSaga() {
  yield takeEvery(type.GET_PICKUPWINDOWDATA_REQUEST, pickupwindowDataS);
}

function* updatesheduleSaga() {
  yield takeEvery(type.UPDATE_SHEDULE_REQUEST, updatesheduleS);
}


function* updateoverviewSaga() {
  yield takeEvery(type.UPDATE_OVERVIEW_REQUEST, updateoverviewS);
}

function* updateInstructionSaga() {
  yield takeEvery(type.UPDATE_INSTRUCTION_REQUEST, updateInstructionS);
}

function* surprisebagDetailsSaga() {
  yield takeEvery(type.SURPRISEBAG_DETAILS_REQUEST, surprisebagDetailsS);
}

function* bagsizelistSaga() {
  yield takeEvery(type.BAGSIZE_LIST_REQUEST, bagsizelistS);
}

function* dashboardbaglistSaga() {
  yield takeEvery(type.Bag_List_REQUEST, dashboardbaglistS);
}


function* createBagSaga() {
  yield takeEvery(type.CREATE_BAG_REQUEST, createBagS);
}


function* banklistSaga() {
  yield takeEvery(type.BANK_LIST_REQUEST, banklistS);
}


function* vendorloginSaga() {
  yield takeEvery(type.VENDOR_LOGIN_REQUEST, vendorloginS);
}


function* setPasswordSaga() {
  yield takeEvery(type.SET_PASSWORD_REQUEST, setPasswordS);
}


function* loginSaga() {
  yield takeEvery(type.LOGIN_USER_REQUEST, loginUsers);
}

function* sendemailSaga() {
  yield takeEvery(type.SEND_EMAIL_REQUEST, sendemailS);
}

function* uploadimageSaga() {
  yield takeEvery(type.UPLOAD_DOCUMENT_REQUEST, uploadimageS);
}

function* createResturantSaga() {
  yield takeEvery(type.CREATE_RESTURANT_REQUEST, createResturantS);
}


function* returantSaga() {
  yield takeEvery(type.RESTURANT_DETAILS_REQUEST, resturantS);
}

function* resturantTypeSaga() {
  yield takeEvery(type.RESTURANT_TYPE_REQUEST, resturantTypeS);
}

function* CUSINSLISTSSaga() {
  yield takeEvery(type.CUSINS_LIST_REQUEST, CUSINSLISTS);
}

function* mobileotpSaga() {
  yield takeEvery(type.MOBILE_OTP_REQUEST, mobileotpS);
}
function* resturantotpSaga() {
  yield takeEvery(type.RESTURANT_OTP_REQUEST, resturantotpS);
}
function* ownerotpverifySaga() {
  yield takeEvery(type.VERIFY_OWNEROTP_REQUEST, ownerotpverifyS);
}
function* resturantotpverifySaga() {
  yield takeEvery(type.VERIFY_RESTURANTOTP_REQUEST, resturantotpverifyS);
}

function* SupportTicketByidSaga() {
  yield takeEvery(type.Support_Ticket_By_id_REQUEST, SupportTicketByidS);
}

function* submitTicketChatDataSaga() {
  yield takeEvery(type.submitTicketChatData_REQUEST, submitTicketChatDataS);
}
function* GetTicketChatDataSaga() {
  yield takeEvery(type.GetTicketChatData_REQUEST, GetTicketChatDataS);
}

function* ChangePasswordSaga() {
  yield takeEvery(type.Change_Password_REQUEST, changePasswordS);
}

export {
  loginSaga,
  pickupwindowDataSaga,
  getSpecialDaysSaga,
  verifyCodeSaga,
  documentgetSaga,
  getfaqSaga,
  getPrivacySaga,
  CreateTicketSaga,
  AddmoreSaga,
  getStatusSaga,
  editlogoSaga,
  orderstatusChangeSaga,
  updatecalenderSaga,
  updateoverviewSaga,
  activeSurpriseBagSaga,
  sendemailSaga,
  SendEmailSaga,
  uploadimageSaga,
  returantSaga,
  editcoverPhotoSaga,
  resturantTypeSaga,
  CUSINSLISTSSaga,
  mobileotpSaga,
  resturantotpSaga,
  ownerotpverifySaga,
  resturantotpverifySaga,
  updateInstructionSaga,
  createResturantSaga,
  setPasswordSaga,
  vendorloginSaga,
  banklistSaga,
  bagsizelistSaga,
  createBagSaga,
  dashboardbaglistSaga,
  notificationSaga,
  surprisebagDetailsSaga,
  updatesheduleSaga,
  orderListSaga,
  dashboardSaga,
  orderDetailsSaga,
  editSurpriseBagSaga,
  reservationListSaga,
  ExcleDownloadOrderSaga,
  SupportTicketByidSaga,
  submitTicketChatDataSaga,
  GetTicketChatDataSaga,
  ChangePasswordSaga,
  notificationreadSaga,
  notificationcountSaga,
};


