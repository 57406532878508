import React, { useState } from "react";
import InputField from "../../components/input";
import { TextareaAutosize } from "@mui/material";
import { Autocomplete } from "@mui/joy";
import "./index.css";

const Section2 = ({
  dietvalue,
  setDietvalue,
  descriptionval,
  setDescriptionval,
  values,
  handleChange,
  handleBlur,
  setContinueactive,
}) => {
  const ondescriptionchange = (e) => {
    if (e.target.value.length <= 200) {
      setDescriptionval(e.target.value);
      values.surpriseBagDescription = e.target.value;
      setContinueactive(true);
    }
  };

  return (
    <div className="name_description_container">
      <div>
        <lable className="lable_name">Name</lable>
        <InputField
          size="small"
          label=""
          placeholder=""
          name="email"
          value="Magic Bag"
          type="text"
          disabled="true"
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>

      <div>
        <lable className="lable_name">Description</lable>
        <TextareaAutosize
          className="textarea_field"
          minRows={6}
          size="small"
          label=""
          placeholder=""
          name="surpriseBagDescription"
          value={descriptionval}
          type="text"
          onChange={(e) => ondescriptionchange(e)}
          onBlur={handleBlur}
        />
        <p className="descriptionlimit">
          {descriptionval.length ? descriptionval.length : 0}/200
        </p>
      </div>
    </div>
  );
};

export default Section2;
