/**
 * Login Action
 */

import * as type from '../type';

/**
 *
 * @param {*} data
 * @returns
 */
export const loginUser = data => {
  return {
    type: type.LOGIN_USER_REQUEST,
    payload: {data},
  };
};

export const sendemail = data => {
  return {
    type: type.SEND_EMAIL_REQUEST,
    payload: {data},
  };
};

export const uploaddocuments = data => {
 
  return {
    type: type.UPLOAD_DOCUMENT_REQUEST,
    payload: {data},
  };
};

export const restrurentdetails = data => {
  return {
    type: type.RESTURANT_DETAILS_REQUEST,
    payload: {data},
  };
};

export const resturantType = data => {
  return {
    type: type.RESTURANT_TYPE_REQUEST,
    payload: {data},
  };
};

export const cusinslist = data => {
  return {
    type: type.CUSINS_LIST_REQUEST,
    payload: {data},
  };
};

export const mobileotpaction = data => {
  return {
    type: type.MOBILE_OTP_REQUEST,
    payload: {data},
  };
};

export const resturentotpaction = data => {
  return {
    type: type.RESTURANT_OTP_REQUEST,
    payload: {data},
  };
};

export const verifyOwnerOtp = data => {
  return {
    type: type.VERIFY_OWNEROTP_REQUEST,
    payload: {data},
  };
};

export const verifyresturantOtp = data => {
  return {
    type: type.VERIFY_RESTURANTOTP_REQUEST,
    payload: {data},
  };
};


export const createresturant = data => {
  return {
    type: type.CREATE_RESTURANT_REQUEST,
    payload: {data},
  };
};


export const setpasswordAction = data => {
  return {
    type: type.SET_PASSWORD_REQUEST,
    payload: {data},
  };
};

export const vendorLoginaction = data => {
  return {
    type: type.VENDOR_LOGIN_REQUEST,
    payload: {data},
  };
};

export const getBanklist = data => {
  return {
    type: type.BANK_LIST_REQUEST,
    payload: {data},
  };
};

export const getbagsize = data => {
  return {
    type: type.BAGSIZE_LIST_REQUEST,
    payload: {data},
  };
};


export const createbag = data => {
  return {
    type: type.CREATE_BAG_REQUEST,
    payload: {data},
  };
};

export const getBagList = data => {
  return {
    type: type.Bag_List_REQUEST,
    payload: {data},
  };
};

export const surprisebagDetails = data => {
  return {
    type: type.SURPRISEBAG_DETAILS_REQUEST,
    payload: {data},
  };
};

export const updateInstruction = data => {
  return {
    type: type.UPDATE_INSTRUCTION_REQUEST,
    payload: {data},
  };
};


export const updateoverview = data => {
  return {
    type: type.UPDATE_OVERVIEW_REQUEST,
    payload: {data},
  };
};


export const updateshedule = data => {
  return {
    type: type.UPDATE_SHEDULE_REQUEST,
    payload: {data},
  };
};


export const getpickupwindowdata = data => {
  return {
    type: type.GET_PICKUPWINDOWDATA_REQUEST,
    payload: {data},
  };
};


export const updatecalender = data => {
  return {
    type: type.UPDATE_CALENDER_REQUEST,
    payload: {data},
  };
};

export const orderlistaction = data => {
  return {
    type: type.ORDER_LIST_REQUEST,
    payload: {data},
  };
};


export const dashboardaction = data => {
  return {
    type: type.DASHBOARD_DATA_REQUEST,
    payload: {data},
  };
};


export const notificationaction = data => {
  return {
    type: type.NOTIFICATION_DATA_REQUEST,
    payload: {data},
  };
};


export const orderdeatilsaction = data => {
  return {
    type: type.ORDER_DEATILS_REQUEST,
    payload: {data},
  };
};


export const activeSurprisebag = data => {
  return {
    type: type.ACTIVE_SURPRISEBAG_REQUEST,
    payload: {data},
  };
};


export const changeOrderStatus = data => {
  return {
    type: type.CHANGE_ORDERSTATUS_REQUEST,
    payload: {data},
  };
};


export const editSurprisebag = data => {
  return {
    type: type.EDIT_SURPRISEBAG_REQUEST,
    payload: {data},
  };
};


export const editcoverPhoto = data => {
  return {
    type: type.EDIT_COVERPHOTO_REQUEST,
    payload: {data},
  };
};

export const editlogoPhoto = data => {
  return {
    type: type.EDIT_LOGO_REQUEST,
    payload: {data},
  };
};


export const resendmobileOtp = data => {
  return {
    type: type.RESEND_MOBILEOTP_REQUEST,
    payload: {data},
  };
};


export const ForgetPasswordaction = data => {
  return {
    type: type.FORGET_PASSWORD_REQUEST,
    payload: {data},
  };
};

export const getStatusAction = data => {
  return {
    type: type.GET_STATUS_REQUEST,
    payload: {data},
  };
};


export const notificationreadAction = data => {
  return {
    type: type.NOTIFICATION_READ_REQUEST,
    payload: {data},
  };
};

export const notificationcountAction = data => {
  return {
    type: type.NOTIFICATION_COUNT_REQUEST,
    payload: {data},
  };
};


export const ResetPasswordaction = data => {
  return {
    type: type.RESET_PASSWORD_REQUEST,
    payload: {data},
  };
};


export const addmoreaction = data => {
  
  return {
    type: type.ADDMORE_SURPRISEBAG_REQUEST,
    payload: {data},
  };
};


export const createTicketAction = data => {
  
  return {
    type: type.CREATE_TICKET_REQUEST,
    payload: {data},
  };
};


export const getPrivacyAction = data => {
  
  return {
    type: type.GET_PRIVACY_REQUEST,
    payload: {data},
  };
};


export const getfaqAction = data => {
  
  return {
    type: type.GET_FAQ_REQUEST,
    payload: {data},
  };
};

export const documentViewRequest = data => {
  
  return {
    type: type.DOCUMENTS_DETAILS_REQUEST,
    payload: {data},
  };
};

export const verifycodeaction = data => {
  
  return {
    type: type.VERIFY_CODE_REQUEST,
    payload: {data},
  };
};



export const reservationListaction = data => {
  
  return {
    type: type.RESERVATION_LIST_REQUEST,
    payload: {data},
  };
};


export const getSpecialDayData = data => {
  return {
    type: type.SPECIALDAYS_DATA_REQUEST,
    payload: {data},
  };
};



export const  ExcleDownloadOrder  = (data) => {

  return {
    type: type.Excle_Download_REQUEST ,
    payload: {data},
  };
};

export const  SupportTicketByid  = (data) => {

  return {
    type: type.Support_Ticket_By_id_REQUEST ,
    payload: {data},
  };
};

export const removeDataFromReducer = (data) => ({
  type: data,
});

export const  submitTicketChatDataAction  = (data) => {

  return {
    type: type.submitTicketChatData_REQUEST,
    payload: {data},
  };
};

export const  GetTicketChatDataAction  = (data) => {

  return {
    type: type.GetTicketChatData_REQUEST,
    payload: {data},
  };
};

export const ChangePasswordAction = data => {
  
  return {
    type: type.Change_Password_REQUEST,
    payload: {data},
  };
};