import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import OverviewSurpriseBag from './overview';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { SlCalender } from "react-icons/sl";
import { MdSchedule } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";
import "./index.css"
import Schedule from './schedule';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Calender from './Calender';
import Information from './infomation';
import { addmoreaction, getPrivacyAction, removeDataFromReducer } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { addMore_surprisebag_api, get_setting_api, ImageUrl } from '../../API/baseUrl';
import moment from 'moment';
import { addmoreShema } from '../../utils/schema';
import ToastMessage from '../../utils/ToastMessage';
const resturantId = localStorage.getItem('restaurantIdFK');

const initialValues = {

  restaurantIdFk : resturantId,
  surpriseBagCoverImage: "",
  surpriseBagCategory: "",
  surpriseBagName: "Magic Bag",
  surpriseBagDescription: "",
  surpriseBagSizeIdFk: "",
  dietaryType: "",
  surpriseBagEndDate : "",
  pickupSchedule  : "",
  specialDay  : "",
  surpriseBagPackaging  : "",
  surpriseBagPickupInstruction  : "",
  surpriseBagBuffetFoodSafety  : 0,
  surpriseBagStoringAndAllergens  : "",


  
  mondayProperty: '0',
  mondayStart: '',
  mondayEnd: '',

  tuesdayProperty: '0',
  tuesdayStart: '',
  tuesdayEnd: '',

  wednesdayProperty: '0',
  wednesdayStart: '',
  wednesdayEnd: '',

  thursdayProperty: '0',
  thursdayStart: '',
  thursdayEnd: '',

  fridayProperty: '0',
  fridayStart: '',
  fridayEnd: '',

  saturdayProperty: '0',
  saturdayStart: '',
  saturdayEnd: '',

  sundayProperty: '0',
  sundayStart: '',
  sundayEnd: '',
};



const AddMoreSurpriseBag = () => {
  const restaurantLogo = localStorage.getItem('restaurantLogo');
  const restaurantFoodImage = localStorage.getItem('restaurantFoodImage');
  const dispatch = useDispatch();
  const [categoryval, setCategoryval] = useState("");
  const [dietryval, setDietryval] = useState("");
  const [packingval, setPackingval] = useState();
  const { id } = useParams();
  const [tabIndex, setTabIndex] = useState(0);
  const [enddate, setEnddate] = useState(null);   
  const [selectedImage, setSelectedImage] = useState(`${ImageUrl}${restaurantFoodImage}`);
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [calenderValue, setCalenderValue] = useState();
  const [loading, setLoading] = useState(false);
  const [validation, setvalidation] = useState(false);
  const navigate = useNavigate();
  const auth = localStorage.getItem("auth");

  const { addMoreData } = useSelector(
    (state) => state?.addMoreReduser || {}
  );

  const [endDateLimit, setEndDateLimit] = useState("");
  const { getPrivacyData } = useSelector((state) => state?.getPrivacyReduser || {});


  useEffect(() => {
    if (getPrivacyData?.status) {
      setEndDateLimit(getPrivacyData.data?.data)
    } else {
      ToastMessage("error", getPrivacyData?.message);
    }
  }, [getPrivacyData]);

    


  useEffect(() => {
    const parameter = {
        type: "enddate",
        url:get_setting_api,
    };
    dispatch(getPrivacyAction(parameter));
  }, []);


  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: addmoreShema,
    onSubmit: (value) => submitForm(value),
  });

  const handleformSubmit = (e) => {
    const resturantId = localStorage.getItem('restaurantIdFK');
    e.preventDefault()
    const fd = new FormData();
    fd.append("surpriseBagCoverImage", selectedImage);
    fd.append("restaurantIdFk", resturantId);
    handleSubmit(fd)
  }


  useEffect(() => {
    if (addMoreData?.status) {
        setLoading(false)
        ToastMessage('success', addMoreData?.message);
        dispatch(removeDataFromReducer("ADDMORE_SURPRISEBAG_RESET"));
        navigate("/dashboard/app")
    } else {
        ToastMessage('error', addMoreData?.message);
    }
}, [addMoreData]);






  const getDaysData = () => {
    const operationArray = [];
    if (values.mondayStart && values.mondayEnd) {
        operationArray.push({
            pickupDay: 'Monday',
            isPickup: values.mondayProperty,
            dailyNumberOfSurpriseBag: values.mondayLimit ? values.mondayLimit : 0,
            startTime: values.mondayStart?.format('HH:mm'),
            endTime: values.mondayEnd?.format('HH:mm'),
        });
    }
    if (!values.mondayStart && !values.mondayEnd) {
        operationArray.push({
            pickupDay: 'Monday',
            isPickup: values.mondayProperty,
            dailyNumberOfSurpriseBag: values.mondayLimit ? values.mondayLimit : 0,
            startTime: '',
            endTime: '',
        });
    }
    if (values.tuesdayStart && values.tuesdayEnd) {
        operationArray.push({
            pickupDay: 'Tuesday',
            isPickup: values.tuesdayProperty,
            dailyNumberOfSurpriseBag:  values.tuesdayLimit ? values.tuesdayLimit : 0,
            startTime: values.tuesdayStart.format('HH:mm'),
            endTime: values.tuesdayEnd.format('HH:mm'),
        });
    }
    if (!values.tuesdayStart && values.tuesdayEnd) {
        operationArray.push({
            pickupDay: 'Tuesday',
            isPickup: values.tuesdayProperty,
            dailyNumberOfSurpriseBag:  values.tuesdayLimit ? values.tuesdayLimit : 0,
            startTime: '',
            endTime: '',
        });
    }
    if (values.wednesdayStart && values.wednesdayEnd) {
        operationArray.push({
            pickupDay: 'Wednesday',
            isPickup: values.wednesdayProperty,
            dailyNumberOfSurpriseBag:  values.wednesdayLimit ? values.wednesdayLimit : 0,
            startTime: values.wednesdayStart.format('HH:mm'),
            endTime: values.wednesdayEnd.format('HH:mm'),
        });
    }
    if (!values.wednesdayStart && !values.wednesdayEnd) {
        operationArray.push({
            pickupDay: 'Wednesday',
            isPickup: values.wednesdayProperty,
            dailyNumberOfSurpriseBag:  values.wednesdayLimit ? values.wednesdayLimit : 0,
            startTime: '',
            endTime: '',
        });
    }

    if (values.thursdayStart && values.thursdayEnd) {
        operationArray.push({
            pickupDay: 'Thursday',
            isPickup: values.thursdayProperty,
            dailyNumberOfSurpriseBag:  values.thursdayLimit ? values.thursdayLimit : 0,
            startTime: values.thursdayStart.format('HH:mm'),
            endTime: values.thursdayEnd.format('HH:mm'),
        });
    }
    if (!values.thursdayStart && !values.thursdayEnd) {
        operationArray.push({
            pickupDay: 'Thursday',
            isPickup: values.thursdayProperty,
            dailyNumberOfSurpriseBag:  values.thursdayLimit ? values.thursdayLimit : 0,
            startTime: '',
            endTime: '',
        });
    }
    if (values.fridayStart && values.fridayEnd) {
        operationArray.push({
            pickupDay: 'Friday',
            isPickup: values.fridayProperty,
            dailyNumberOfSurpriseBag:  values.fridayLimit ? values.fridayLimit : 0,
            startTime: values.fridayStart.format('HH:mm'),
            endTime: values.fridayEnd.format('HH:mm'),
        });
    }

    if (!values.fridayStart && !values.fridayEnd) {
        operationArray.push({
            pickupDay: 'Friday',
            isPickup: values.fridayProperty,
            dailyNumberOfSurpriseBag:  values.fridayLimit ? values.fridayLimit : 0,
            startTime: '',
            endTime: '',
        });
    }

    if (values.saturdayStart && values.saturdayEnd) {
        operationArray.push({
            pickupDay: 'Saturday',
            isPickup: values.saturdayProperty,
            dailyNumberOfSurpriseBag: values.saturdayLimit ? values.saturdayLimit : 0,
            startTime: values.saturdayStart.format('HH:mm'),
            endTime: values.saturdayEnd.format('HH:mm'),
        });
    }

    if (!values.saturdayStart && !values.saturdayEnd) {
        operationArray.push({
            pickupDay: 'Saturday',
            isPickup: values.saturdayProperty,
            dailyNumberOfSurpriseBag: values.saturdayLimit ? values.saturdayLimit : 0,
            startTime: '',
            endTime: '',
        });
    }

    if (values.sundayStart && values.sundayEnd) {
        operationArray.push({
            pickupDay: 'Sunday',
            isPickup: values.sundayProperty,
            dailyNumberOfSurpriseBag: values.sundayLimit ? values.sundayLimit : 0,
            startTime: values.sundayStart.format('HH:mm'),
            endTime: values.sundayEnd.format('HH:mm'),
        });
    }
    if (!values.sundayStart && !values.sundayEnd) {
        operationArray.push({
            pickupDay: 'Sunday',
            isPickup: values.sundayProperty,
            dailyNumberOfSurpriseBag: values.sundayLimit ? values.sundayLimit : 0,
            startTime: '',
            endTime: '',
        });
    }
    return operationArray;
};



  const getDaysDatavalidation = () => {
    const operationArray = [];
    if (values.mondayStart && values.mondayEnd) {
        operationArray.push({
            pickupDay: 'Monday',
            isPickup: values.mondayProperty,
            dailyNumberOfSurpriseBag: values.mondayLimit ? values.mondayLimit : 0,
            startTime: values.mondayStart?.format('HH:mm'),
            endTime: values.mondayEnd?.format('HH:mm'),
        });
    }
   
    if (values.tuesdayStart && values.tuesdayEnd) {
        operationArray.push({
            pickupDay: 'Tuesday',
            isPickup: values.tuesdayProperty,
            dailyNumberOfSurpriseBag:  values.tuesdayLimit ? values.tuesdayLimit : 0,
            startTime: values.tuesdayStart.format('HH:mm'),
            endTime: values.tuesdayEnd.format('HH:mm'),
        });
    }
  
    if (values.wednesdayStart && values.wednesdayEnd) {
        operationArray.push({
            pickupDay: 'Wednesday',
            isPickup: values.wednesdayProperty,
            dailyNumberOfSurpriseBag:  values.wednesdayLimit ? values.wednesdayLimit : 0,
            startTime: values.wednesdayStart.format('HH:mm'),
            endTime: values.wednesdayEnd.format('HH:mm'),
        });
    }
   

    if (values.thursdayStart && values.thursdayEnd) {
        operationArray.push({
            pickupDay: 'Thursday',
            isPickup: values.thursdayProperty,
            dailyNumberOfSurpriseBag:  values.thursdayLimit ? values.thursdayLimit : 0,
            startTime: values.thursdayStart.format('HH:mm'),
            endTime: values.thursdayEnd.format('HH:mm'),
        });
    }
  
    if (values.fridayStart && values.fridayEnd) {
        operationArray.push({
            pickupDay: 'Friday',
            isPickup: values.fridayProperty,
            dailyNumberOfSurpriseBag:  values.fridayLimit ? values.fridayLimit : 0,
            startTime: values.fridayStart.format('HH:mm'),
            endTime: values.fridayEnd.format('HH:mm'),
        });
    }

  
    if (values.saturdayStart && values.saturdayEnd) {
        operationArray.push({
            pickupDay: 'Saturday',
            isPickup: values.saturdayProperty,
            dailyNumberOfSurpriseBag: values.saturdayLimit ? values.saturdayLimit : 0,
            startTime: values.saturdayStart.format('HH:mm'),
            endTime: values.saturdayEnd.format('HH:mm'),
        });
    }


    if (values.sundayStart && values.sundayEnd) {
        operationArray.push({
            pickupDay: 'Sunday',
            isPickup: values.sundayProperty,
            dailyNumberOfSurpriseBag: values.sundayLimit ? values.sundayLimit : 0,
            startTime: values.sundayStart.format('HH:mm'),
            endTime: values.sundayEnd.format('HH:mm'),
        });
    }
   
    return operationArray;
};





const handleCheckboxChange = (name, e) => {
 
  if (name == 'mondayProperty') {
      //   setCheckOperation(e.target.checked ? true : false);
      //   setMessageOperation(e.target.checked ? true : false);
      setFieldValue('mondayProperty', e.target.checked ? '1' : '0');

      if (!e.target.checked) {
          setFieldValue('mondayStart', '');
          setFieldValue('mondayEnd', '');
          setFieldValue('mondayLimit', 0);
      }
  }

  if (name == 'tuesdayProperty') {
      setFieldValue('tuesdayProperty', e.target.checked ? '1' : '0');
      if (!e.target.checked) {
          setFieldValue('tuesdayStart', '');
          setFieldValue('tuesdayEnd', '');
          setFieldValue('tuesdayLimit', 0);
      }
  }
  if (name == 'wednesdayProperty') {
      setFieldValue('wednesdayProperty', e.target.checked ? '1' : '0');
      if (!e.target.checked) {
          setFieldValue('wednesdayStart', '');
          setFieldValue('wednesdayEnd', '');
          setFieldValue('wednesdayLimit', 0);
      }
  }
  if (name == 'thursdayProperty') {
      setFieldValue('thursdayProperty', e.target.checked ? '1' : '0');
      if (!e.target.checked) {
          setFieldValue('thursdayStart', '');
          setFieldValue('thursdayEnd', '');
          setFieldValue('thursdayLimit', 0);
      }
  }
  if (name == 'fridayProperty') {
      setFieldValue('fridayProperty', e.target.checked ? '1' : '0');
      if (!e.target.checked) {
          setFieldValue('fridayStart', '');
          setFieldValue('fridayEnd', '');
          setFieldValue('fridayLimit', 0);
      }
  }
  if (name == 'saturdayProperty') {
      setFieldValue('saturdayProperty', e.target.checked ? '1' : '0');
      if (!e.target.checked) {
          setFieldValue('saturdayStart', '');
          setFieldValue('saturdayEnd', '');
          setFieldValue('saturdayLimit', 0);
      }
  }
  if (name == 'sundayProperty') {
      setFieldValue('sundayProperty', e.target.checked ? '1' : '0');

      if (!e.target.checked) {
          setFieldValue('sundayStart', '');
          setFieldValue('sundayEnd', '');
          setFieldValue('sundayLimit', 0);
      }
  }
};


// const handleNewTimeSet = (e, val) => {
//   if (val == 'mondayStart') {
//       setFieldValue('mondayStart', e.target.value);
//   }
//   if (val == 'mondayEnd') {
//       setFieldValue('mondayEnd', e.target.value);
//   }
//   if (val == 'tuesdayStart') {
//       setFieldValue('tuesdayStart', e.target.value);
//   }
//   if (val == 'tuesdayEnd') {
//       setFieldValue('tuesdayEnd', e.target.value);
//   }
//   if (val == 'wednesdayStart') {
//       setFieldValue('wednesdayStart', e.target.value);
//   }
//   if (val == 'wednesdayEnd') {
//       setFieldValue('wednesdayEnd', e.target.value);
//   }
//   if (val == 'thursdayStart') {
//       setFieldValue('thursdayStart', e.target.value);
//   }
//   if (val == 'thursdayEnd') {
//       setFieldValue('thursdayEnd', e.target.value);
//   }
//   if (val == 'fridayStart') {
//       setFieldValue('fridayStart', e.target.value);
//   }
//   if (val == 'fridayEnd') {
//       setFieldValue('fridayEnd', e.target.value);
//   }
//   if (val == 'saturdayStart') {
//       setFieldValue('saturdayStart', e.target.value);
//   }
//   if (val == 'saturdayEnd') {
//       setFieldValue('saturdayEnd', e.target.value);
//   }
//   if (val == 'sundayStart') {
//       setFieldValue('sundayStart', e.target.value);
//   }
//   if (val == 'sundayEnd') {
//       setFieldValue('sundayEnd', e.target.value);
//   }
// };

const handleNewTimeSet = (e, val) => {
    const timeValue = e;
    setFieldValue(val, timeValue);
  };


const handleinputChange = (name, e) => {

  if (name == 'mondayLimit') {
      if(e.target.value < 0){
          setFieldValue('mondayLimit', 0);
      }
      else{
          setFieldValue('mondayLimit', e.target.value < 6 ? e.target.value : '5');
      }
      
  }

  if (name == 'tuesdayLimit') {
     
      if(e.target.value < 0){
          setFieldValue('tuesdayLimit', 0);
      }
      else{
          setFieldValue('tuesdayLimit', e.target.value < 6 ? e.target.value : '5');
      }
  }
  if (name == 'wednesdayLimit') {
      
      if(e.target.value < 0){
          setFieldValue('wednesdayLimit', 0);
      }
      else{
          setFieldValue('wednesdayLimit', e.target.value < 6 ? e.target.value : '5');
      }
  }
  if (name == 'thursdayLimit') {
      
      if(e.target.value < 0){
          setFieldValue('thursdayLimit', 0);
      }
      else{
          setFieldValue('thursdayLimit', e.target.value < 6 ? e.target.value : '5');
      }
  }
  if (name == 'fridayLimit') {
      
      if(e.target.value < 0){
          setFieldValue('fridayLimit', 0);
      }
      else{
          setFieldValue('fridayLimit', e.target.value < 6 ? e.target.value : '5');
      }
  }
  if (name == 'saturdayLimit') {
     
      if(e.target.value < 0){
          setFieldValue('saturdayLimit', 0);
      }
      else{
          setFieldValue('saturdayLimit', e.target.value < 6 ? e.target.value : '5');
      }
  }
  if (name == 'sundayLimit') {
     
      if(e.target.value < 0){
          setFieldValue('sundayLimit', 0);
      }
      else{
          setFieldValue('sundayLimit', e.target.value < 6 ? e.target.value : '5');
      }
  }
};


  const handlebackIcon = () => {
    navigate("/dashboard/app");
  }

  useEffect(() => {
    values.pickupSchedule= JSON.stringify(getDaysData())
}, [getDaysData()]);


  useEffect(() => {
    values.specialDay = JSON.stringify(calenderValue)
  }, [calenderValue]);


  const submitForm = (value) => {
    setLoading(true)
    const parameter = {
      data: value,
      url: addMore_surprisebag_api,
    };
    dispatch(addmoreaction(parameter));
  };


  const handleIndexChane = (index) =>{
       if(tabIndex === 0){
        if(values.surpriseBagCategory && values.surpriseBagDescription && values.surpriseBagSizeIdFk && values.dietaryType){
            setTabIndex(index)
        }
        else{
            ToastMessage('error', "Please fill all the required field");
        }
       }
      
       else if((tabIndex === 1)){
         if(getDaysDatavalidation().length === 0 ){
            ToastMessage("error","Please fill atleast one day fields")
          }
         if(validation){
            ToastMessage("error","Please check error")
          }
          else if(!values.surpriseBagEndDate || !enddate){
            ToastMessage("error","Please select End Date")
          }
        else{
            setTabIndex(index)
           }
       }
       else{
        setTabIndex(index)
       }
  }

  return (

    <div className='detail_surprise_container'>
      <h3 className='surprise_bag_details_heading'>
        <ArrowBackIcon sx={{ cursor: "pointer" }} onClick={handlebackIcon} />
        <b>Add Magic Bag</b>
      </h3>
      <Tabs style={{ padding: "20px" }} selectedIndex={tabIndex} onSelect={(index) => handleIndexChane(index)}>
        <TabList className="TabList">
          <Tab className="Tab">
            <MdSchedule />
            Overview
          </Tab>
         
          <Tab className="Tab">Schedule</Tab>
          <Tab className="Tab">
            <SlCalender />
            Calendar
          </Tab>
          <Tab className="Tab">
            <IoSettingsOutline />
            Settings
          </Tab>
        </TabList>
        <TabPanel >
          <OverviewSurpriseBag {...{ dietryval, setDietryval,categoryval, setCategoryval, values,handleChange,handleBlur,touched, errors,selectedImage, setSelectedImage,selectedImageFile, setSelectedImageFile }} />
        </TabPanel>
      
        <TabPanel >
          {/* <h2>Schedule</h2> */}
          <Schedule {...{validation, setvalidation ,endDateLimit,enddate, setEnddate, values,handleCheckboxChange, handleNewTimeSet,errors,handleinputChange,setFieldValue }} />
        </TabPanel>
        <TabPanel >
          {/* <h2>Calendar</h2> */}
          {/* <Calender/> */}
          <Calender {...{ values ,setCalenderValue}} />
        </TabPanel>
        <TabPanel >
          <Information {...{loading, packingval, setPackingval, handleformSubmit , values,handleChange,handleBlur,touched, errors}} />
        </TabPanel>
      </Tabs>
    </div>



  )
}

export default AddMoreSurpriseBag;