

import { useEffect, useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getTokenfun, onMessageListener } from './firebase';
import { Button, Toast } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setNotification } from './redux/actions/notificationActions';

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notification = useSelector(state => state.notification);
  
  const [show, setShow] = useState(false);
  
   const [isTokenFound, setTokenFound] = useState(false);
  getTokenfun(setTokenFound);

  useEffect(() => {
    onMessageListener()
      .then(payload => {
        dispatch(setNotification({ title: payload.notification.title, body: payload.notification.body }));
        setShow(true);
        console.log(payload);
      })
      .catch(err => console.log('failed: ', err));
  }, [dispatch]);

  return (
    <div className="App">
      <header className="App-header">
        <Toast
          onClose={() => setShow(false)}
          show={show}
          delay={3000}
          autohide
          animation
          style={{
            position: 'absolute',
            top: 20,
            right: 20,
          }}
        >
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded mr-2"
              alt=""
            />
            <strong className="mr-auto">Notification</strong>
            <small>12 mins ago</small>
          </Toast.Header>
          <Toast.Body>{notification.body || 'There are some new updates that you might love!'}</Toast.Body>
        </Toast>
        <Button onClick={() => setShow(true)}>Show Toast</Button>
      </header>
    </div>
  );
}

export default App;
