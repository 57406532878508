import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { BiSolidShoppingBags } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';
import { surprisebagDetails } from '../../redux/actions';
import { ImageUrl, surprisebagDetails_api } from '../../API/baseUrl';
import ToastMessage from '../../utils/ToastMessage';
import "./index.css"
import OverviewSurpriseBag from './overview';
import Information from './infomation';


const SettingSurpriseBag = ({ id,shobtn }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { surprisebagDetailsData } = useSelector((state) => state?.surprisebagDetailsReduser || {});
  const auth = localStorage.getItem("auth");
  const [edit, setEdit] = useState(false)
  const [data, setData] = useState([])
  const options = ['one', 'two', 'three'];
  const defaultOption = options[0];

  const [editing, setEditing] = useState(false);

  const handleEdit = () => {
    setEditing(true);
  };

  const handleCancel = () => {
    setEditing(false);
  };

  const handleSave = () => {

    setEditing(false);
  };

  const handleDropdownChange = (selectedOption) => {
    console.log("Selected option:", selectedOption);

  };


  const handleEditBtn = () => {
    setEdit(!edit)
  }



  useEffect(() => {
    if (surprisebagDetailsData?.status) {
      setData(surprisebagDetailsData?.data)
    } else {
      ToastMessage('error', surprisebagDetailsData?.message);
    }
  }, [surprisebagDetailsData]);


  useEffect(() => {
    const parameter = {
      url: `${surprisebagDetails_api}${id}`,

    };
    dispatch(surprisebagDetails(parameter));
  }, []);



  return (
    <div className='dashboardoverview_container_fluid'>

      <OverviewSurpriseBag {...{ id }} />

      <Information shobtn={shobtn}  {...{ id }} />

    </div>
  )
}

export default SettingSurpriseBag;