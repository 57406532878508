
import React, { useState } from 'react'
import Dropdown from "react-bootstrap/Dropdown";
import Help from './faq';
import { useNavigate } from 'react-router-dom';
import Assets4 from "../../images/Asset4.jpg"
import imgwelcome from "../../images/createbagimg.png"
const restaurantName = localStorage.getItem('restaurantName');


const SurpriseWelcome = () => {
  const navigate = useNavigate()


  const handlecreate = () => {
    navigate("surpriceBag/create");
  }


  return (
    <div className='create_bag_main_screen_container'>
      <h3 style={{width:"92%"}}>Hi, {localStorage.getItem('restaurantName')}</h3>
      <div className='container_btn_img'>
     <div>
     <img src={Assets4} alt='pickntreat' height={150}/>
     </div>
        <p>To start selling your surplus food create your first Magic Bag</p>
        <button onClick={handlecreate} className='welcome_screen_btn'>Create Magic Bag</button>
      </div>

      <Help restro = 'true'/>
    </div>
  )
}

export default SurpriseWelcome;