import React, { useEffect, useState } from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import './index.css';
import { edit_coverPhoto_api, surprisebagDetails_api } from '../../../API/baseUrl';
import { editcoverPhoto, removeDataFromReducer, surprisebagDetails } from '../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import ToastMessage from '../../../utils/ToastMessage';
import { useFormik } from 'formik';

const ModalOverview = ({ handleClose, open1,updatedDetails, heading, description, coverimg, surpriseBagIdFk }) => {
    const [selectedImage, setSelectedImage] = useState('');
    const [selectedImageFile, setSelectedImageFile] = useState(null);
    const [isImageSelected, setIsImageSelected] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { editcoverPhotoData } = useSelector((state) => state?.editcoverPhotoReduser || {});

  
    const onImageSelect = (event) => {
        const file = event.target.files[0];
        setSelectedImageFile(file); // Set the file for FormData
        
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setSelectedImage(e.target.result);
                setIsImageSelected(true);
            };
            reader.readAsDataURL(file);
        }
    };

    const initialValues = {
        surpriseBagIdFk: surpriseBagIdFk,
        surpriseBagCoverImage: "", // Will be handled by FormData
    };

    const { values, errors, handleSubmit, setFieldValue } = useFormik({
        initialValues,
        validationSchema: "", // Add validation schema if needed
        onSubmit: async (values, { setSubmitting }) => {
            const fd = new FormData();
            fd.append("surpriseBagCoverImage", selectedImageFile); // Add the file to FormData
            fd.append("surpriseBagIdFk", values.surpriseBagIdFk); // Append other form data
            
            setLoading(true);
            try {
                const parameter = {
                    data: fd, // Send FormData
                    url: edit_coverPhoto_api,
                };

                dispatch(editcoverPhoto(parameter));

                // You might want to wait for dispatch to complete or add a callback
            } catch (error) {
                console.error("Error:", error);
            } finally {
                setLoading(false);
                setSubmitting(false);
            }
        },
    });

    useEffect(() => {
        const parameter = {
            url: `${surprisebagDetails_api}${surpriseBagIdFk}`,
          };
      
        if (editcoverPhotoData?.status) {
            ToastMessage('success', editcoverPhotoData?.message);
            dispatch(removeDataFromReducer('EDIT_COVERPHOTO_RESET'));
            dispatch(surprisebagDetails(parameter));
            // updatedDetails()
            handleClose();
            setSelectedImage('');
            setIsImageSelected(false);
        } else {
            ToastMessage('error', editcoverPhotoData?.message);
        }
    }, [editcoverPhotoData]);

    return (
        <Modal
            open={open1}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    width: '60vw',
                    height: '90vh',
                    background: 'white',
                    p: 2,
                    outline: 'none',
                    borderRadius: '8px',
                    boxShadow: 24,
                    overflowY: 'auto',
                }}
            >
                <Typography variant="h6" id="modal-modal-title" className="heading">
                    {heading}
                </Typography>
                <Typography className="description">
                    <p>{description}</p>
                </Typography>
                <div className="centerpart">
                    {selectedImage ? (
                        <img src={selectedImage} alt="Selected Image" className='cover_image_logo' />
                    ) : (
                        <img src={coverimg} alt="Placeholder Image" className='cover_image_logo' />
                    )}
                    <label htmlFor="imageUpload" className="Browser_btn">
                        Browse
                        <input
                            id="imageUpload"
                            type="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={onImageSelect}
                        />
                    </label>
                    <div className="uploading_msg">
                        <p>
                            By uploading this image, you, on behalf of your store, warrant that you have all the necessary legal rights to use and display this image in relation to your store, and you authorize Pick'n'Treat to use this image.
                        </p>
                    </div>
                </div>
                <hr className="divider" />
                <div className="lastbtn">
                    <div className="cancel" type="button" onClick={handleClose}>Cancel</div>
                    <button
                        type='button'
                        onClick={handleSubmit}
                        className={`Apply ${isImageSelected ? '' : 'disabled'}`}
                        disabled={!isImageSelected}
                    >
                        Apply
                    </button>
                </div>
            </Box>
        </Modal>
    );
};

export default ModalOverview;