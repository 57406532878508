import React from 'react';
import './index.css';
import { DayCalendarSkeleton, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { format } from 'date-fns';
import { Col, Row } from 'react-bootstrap';
import dayjs from 'dayjs';
import { Checkbox, FormControlLabel } from '@mui/material';

const DatePickerCom = ({ days, setDays }) => {
    const handleCheckboxChange = (id, checked) => {
        setDays((prevDays) =>
            prevDays.map((day) =>
                day.id === id
                    ? { ...day, checked }
                    : id === 1 && checked
                        ? {
                            ...day, 
                            checked: true,
                            startTime: days[0].startTime,
                            endTime: days[0].endTime
                        }
                        : day
            )
        );
    };

    const handleTimeChange = (value, type, id) => {
        const formattedTime = value ? format(value.$d, 'HH:mm') : null; // Adjusted to handle Dayjs object
        setDays((prevDays) =>
            prevDays.map((day) =>
                day.id === id
                    ? { ...day, [type]: formattedTime }
                    : id === 1
                        ? { ...day, [type]: formattedTime }
                        : day
            )
        );
        
   
    };

    const getMinTime = (day) => {
        return day.startTime ? dayjs(day.startTime, 'HH:mm').add(1, 'minute') : null;
    };

    const getMaxTime = (day) => {
        return day.endTime ? dayjs(day.endTime, 'HH:mm') : null;
    };

 

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="date-picker-container">
                {days.map((day) => (
                    <div className="operation_field_container">
                        
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={day.checked}
                                    onChange={(e) => handleCheckboxChange(day.id, e.target.checked)}
                                    sx={{
                                        color: `var(--colorused)`,
                                        '&.Mui-checked': {
                                            color: `var(--colorused)`,
                                        },
                                    }}

                                />
                            }
                            label={day.name}
                        />

                        <div className='timePicker_wrap'>
                            <TimePicker
                             className="input_time_field_input"
                                ampm={false}
                                label="Start Time"
                                value={day.startTime ? dayjs(day.startTime, 'HH:mm') : null}
                                onChange={(value) => handleTimeChange(value, 'startTime', day.id)}
                                disabled={!day.checked}
                                maxTime={getMaxTime(day)}
                            />
                        </div>
                        <div className='timePicker_wrap'>
                            <TimePicker
                             className="input_time_field_input"
                                ampm={false}
                                label="End Time"
                                value={day.endTime ? dayjs(day.endTime, 'HH:mm') : null}
                                onChange={(value) => handleTimeChange(value, 'endTime', day.id)}
                                disabled={!day.checked}
                                minTime={getMinTime(day)}
                            />
                        </div>
                    </div>
                ))}


            </div>
        </LocalizationProvider>
    );
};

export default DatePickerCom;
