import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import {
  TextField,
  Switch,
  Button,
  Box,
  Typography,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import {
  LocalizationProvider,
  DatePicker,
  TimePicker,
} from "@mui/x-date-pickers";
import { useDispatch, useSelector } from "react-redux";
import ToastMessage from "../../../utils/ToastMessage";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { removeDataFromReducer, updatecalender } from "../../../redux/actions";
import { update_calender_api } from "../../../API/baseUrl";
import { alpha, styled } from "@mui/material/styles";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { pink } from "@mui/material/colors";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useFormik } from "formik";
import {
  calenderSchemaRequired,
  calenderUpdateSchema,
} from "../../../utils/schema";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  maxWidth: "95%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "6px",
  p: 4,
};

const PinkSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#267887",
    "&:hover": {
      backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#267887",
  },
}));

export default function SpecialDayModal({
  specialDayModalOpen,
  handleSpecialDayClose,
  values,
  setCalenderValue,
  validdate
}) {
  const [firstDayval, setFirstDayval] = useState(null);
  const [lastDayval, setLastDayval] = useState(null);
  const [storeOpen, setStoreOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [minEndTime, setMinEndTime] = useState(null);
  const [maxStartTime, setMaxStartTime] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [minLastDay, setMinLastDay] = useState(null);
  const [maxFirstDay, setMaxFirstDay] = useState(null);
  const [storageval, setStorageval] = useState(1);
  const [endError, setEndError] = useState("");

  const handleTimeChange = (newValue, type) => {
    if (newValue) {
      const formattedTime = dayjs(newValue).format("HH:mm");
      // sendTimeToAPI(formattedTime, type);

      if (type === "start") {
        setStartTime(newValue);
        setMinEndTime(newValue);
      } else {
        setEndTime(newValue);
        setMaxStartTime(newValue);
      }
    }
  };

  const handleDateChange = (date) => {
    setFirstDayval(date);

    setMinLastDay(date); // Set minimum date for last day based on the selected first day
  };

  const handlelastDateChange = (date) => {
    setLastDayval(date);
    // values.lastDay = dayjs(date).format('DD/MM/YYYY');
    setMaxFirstDay(date); // Set maximum date for first day based on the selected last day
  };

  // const handleinputChange = (e) => {
  //   if (e.target.value < 0) {
  //     setStorageval(0);
  //   } else {
  //     setStorageval(e.target.value < 6 ? e.target.value : "5");
  //   }
  // };

  const handleinputChange = (e) => {
    const value = parseInt(e.target.value, 10); 
    if (isNaN(value) || value < 1) {
      setStorageval(1); 
    } else if (value > 5) {
      setStorageval(5); 
    } else {
      setStorageval(value); 
    }
  };

  const today = new Date();

  useEffect(() => {
    if (startTime === endTime && values.startTime) {
      ToastMessage("error", "Start Time and End time should not be same");
    } else {
      setEndError("");
    }
  }, [endTime, startTime]);

  const handleAddDates = () => {
    const data = {
      firstDay: firstDayval ? dayjs(firstDayval).format("DD/MM/YYYY") : "",
      lastDay: lastDayval ? dayjs(lastDayval).format("DD/MM/YYYY") : "",
      isPickUp: storeOpen == true ? 1 : 0,
      startTime: startTime ? dayjs(startTime).format("HH:mm") : "",
      endTime: endTime ? dayjs(endTime).format("HH:mm") : "",
      dailyNumberOfSurpriseBag: storageval ? storageval : "0",
    };
    if (storeOpen == true) {
      if (startTime && endTime && storageval) {
        if (startTime >= endTime) {
          ToastMessage("error", "End time must be greater than start time");
         
        }else{
          setCalenderValue(data);
          handleSpecialDayClose();
        }
      } else {
        ToastMessage("error", "Please fill all the field");
      }
    }

    if (firstDayval && lastDayval) {
      setCalenderValue(data);
      handleSpecialDayClose();
    } else {
      ToastMessage("error", "Please fill all the field");
    }

    // Close the modal after collecting the data
  };


  

  function convertDateToFullFormat(dateInState) {
    // Parse the input date in 'DD/MM/YYYY' format and convert it to a JavaScript Date object
    const date = moment(dateInState, 'DD/MM/YYYY').toDate();
  
    // Get the full format date string
    const fullFormatDate = date.toString();
  
    return fullFormatDate;
  }

  return (
    <div>
      <Modal
        open={specialDayModalOpen}
        onClose={handleSpecialDayClose}
        aria-labelledby="special-day-modal-title"
        aria-describedby="special-day-modal-description"
      >
        <Box sx={style}>
          <Typography className="add_special_heading" variant="h5" mb={3}>
            Add special days{" "}
            <CloseIcon
              onClick={handleSpecialDayClose}
              sx={{ cursor: "pointer" }}
            />
          </Typography>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="display_align flex-wrap">
              <div>
                <Typography
                  variant="body1"
                  sx={{ mr: 2, color: `var(--colorused)` }}
                >
                  First Day
                </Typography>
                <ReactDatePicker
                  className="date_input_calender"
                  selected={firstDayval}
                  onChange={handleDateChange}
                  name="firstDay"
                  // onBlur={handleBlur}
                  dateFormat="dd/MM/yyyy"
                  maxDate={maxFirstDay || convertDateToFullFormat(validdate)} // Restrict max date for first day
                  minDate={today}
                />
              </div>

              <div>
                <Typography
                  variant="body1"
                  sx={{ mr: 2, color: `var(--colorused)` }}
                >
                  Last Day
                </Typography>
                <ReactDatePicker
                  className="date_input_calender"
                  selected={lastDayval}
                  onChange={handlelastDateChange}
                  name="lastDay"
                  // onBlur={handleBlur}
                  dateFormat="dd/MM/yyyy"
                  maxDate={convertDateToFullFormat(validdate)}
                  minDate={minLastDay} // Restrict min date for last day
                />
              </div>

              <div>
                <Typography
                  variant="body1"
                  sx={{ mr: 2, color: `var(--colorused)` }}
                >
                  Store will be
                </Typography>
                <FormControlLabel
                  control={
                    <PinkSwitch
                      checked={storeOpen}
                      onChange={(e) => setStoreOpen(e.target.checked)}
                    />
                  }
                  label={storeOpen === true ? <p>Open</p> : <p>Closed</p>}
                />
              </div>
            </div>
          </LocalizationProvider>

          {storeOpen === true ? (
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box
                  className="flex-wrap"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 2,
                  }}
                >
                  <div>
                    <Typography
                      variant="body1"
                      sx={{ mr: 2, color: `var(--colorused)` }}
                    >
                      Pickup Starts
                    </Typography>
                    <DemoContainer
                      className="input_time_field"
                      components={["TimePicker", "TimePicker"]}
                    >
                      <TimePicker
                        className="input_time_field_input"
                        label=""
                        placeholder=""
                        name="startTime"
                        // onBlur={handleBlur}
                        value={startTime}
                        onChange={(newValue) =>
                          handleTimeChange(newValue, "start")
                        }
                        maxTime={maxStartTime}
                        ampm={false}
                        format="HH:mm" // 24-hour format
                        showNow={false}
                      />
                    </DemoContainer>
                  </div>

                  <div>
                    <Typography
                      variant="body1"
                      sx={{ mr: 2, color: `var(--colorused)` }}
                    >
                      Pickup Ends
                    </Typography>
                    <DemoContainer
                      className="input_time_field"
                      components={["TimePicker", "TimePicker"]}
                    >
                      <TimePicker
                        className="input_time_field_input"
                        label=""
                        value={endTime}
                        name="endTime"
                        // onBlur={handleBlur}
                        onChange={(newValue) =>
                          handleTimeChange(newValue, "end")
                        }
                        minTime={minEndTime}
                        ampm={false}
                      />
                    </DemoContainer>
                  </div>

                  <div>
                    <Typography
                      variant="body1"
                      sx={{ mr: 2, color: `var(--colorused)` }}
                    >
                      Stock
                    </Typography>
                    <TextField
                      sx={{ width: "110px" }}
                      label=""
                      type="number"
                      name="dailyNumberOfSurpriseBag"
                      value={storageval}
                      onChange={handleinputChange}
                      // onBlur={handleBlur}
                    />
                  </div>
                </Box>
              </LocalizationProvider>
            </div>
          ) : (
            ""
          )}

          <div className="display_align m-t-10">
            <div>
              <Typography variant="body2" mt={2}>
                Daily limit: <strong>5</strong>
              </Typography>
              <Typography variant="caption">
                To change your daily limit, contact us
              </Typography>
            </div>

            <button
              type="button"
              className="calender_btn_modal"
              onClick={handleAddDates}
            >
              {loading ? (
                <CircularProgress size={18} style={{ color: "white" }} />
              ) : (
                "Add dates"
              )}
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
