import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import OverviewSurpriseBag from './overview';

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { SlCalender } from "react-icons/sl";
import { MdSchedule } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";

import "./index.css"
import SettingSurpriseBag from './setting';
import Schedule from './schedule';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Calender from './Calender';
// import Calender from './Calender';



const DetailSurpriseBag = () => {

  const { id } = useParams();
  const { name } = useParams();
  const { selectindex } = useParams();
  const navigate = useNavigate();
  const auth = localStorage.getItem("auth");

  const handlebackIcon = () => {
    navigate("/dashboard/app");
  }


  return (

    <div className='detail_surprise_container'>
      <h3 className='surprise_bag_details_heading'>
        <ArrowBackIcon sx={{ cursor: "pointer" }} onClick={handlebackIcon} />
        <b>{name == "Details" ? "Magic Bag Details" :"Magic Bag"}</b>
      </h3>
      <Tabs style={{ padding: "20px" }} defaultIndex={selectindex}>
        <TabList className="TabList">
          <Tab className="Tab">
            <MdSchedule />
            Overview
          </Tab>
          <Tab className="Tab">
            <SlCalender />
            Calendar
          </Tab>
          <Tab className="Tab">Schedule</Tab>
          <Tab className="Tab">
            <IoSettingsOutline />
            Settings
          </Tab>
        </TabList>
        <TabPanel >
          <OverviewSurpriseBag shobtn={name == "Details" ? false : true} {...{ id }} />
        </TabPanel>
        <TabPanel >
          {/* <h2>Calendar</h2> */}
          {/* <Calender/> */}
          <Calender shobtn={name == "Details" ? false : true}  {...{ id }} />
        </TabPanel>
        <TabPanel >
          {/* <h2>Schedule</h2> */}
          <Schedule shobtn={name == "Details" ? false : true}  {...{ id }} />
        </TabPanel>
        <TabPanel >
          <SettingSurpriseBag  shobtn={name == "Details" ? false : true} {...{ id }} />
        </TabPanel>
      </Tabs>
    </div>



  )
}

export default DetailSurpriseBag;