import React, { useState } from "react";
import PropTypes from "prop-types";
import { useAutocomplete } from "@mui/base/useAutocomplete";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { autocompleteClasses } from "@mui/material/Autocomplete";

const Root = styled("div")(
  ({ theme }) => `
  color: ${
    theme.palette.mode === "dark" ? "rgba(255,255,255,0.65)" : "rgba(0,0,0,.85)"
  };
  font-size: 14px;
`
);

const Label = styled("label")`
  padding: 0 0 4px;
  line-height: 1.5;
  display: block;
`;

const InputWrapper = styled("div")(
  ({ theme }) => `
  border: 1px solid ${theme.palette.mode === "dark" ? "#434343" : "#d9d9d9"};
  background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  &:hover {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
  }

  &.focused {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
    color: ${
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,0.65)"
        : "rgba(0,0,0,.85)"
    };
    height: 40px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`
);

function Tag(props) {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <CloseIcon onClick={onDelete} style={{fontSize:"25px"}}/>
    </div>
  );
}

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const StyledTag = styled(Tag)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: ${
    theme.palette.mode === "dark" ? "rgba(255,255,255,0.08)" : "#fafafa"
  };
  border: 1px solid ${theme.palette.mode === "dark" ? "#303030" : "#e8e8e8"};
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
    background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`
);

const Listbox = styled("ul")(
  ({ theme }) => `
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 3;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: ${theme.palette.mode === "dark" ? "#2b2b2b" : "#fafafa"};
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`
);

export default function CustomizedHook({
  setFieldValue,
  values,
  selectedOptions,
  setSelectedOptions,
}) {
  const [othersSelected, setOthersSelected] = useState(false);
  const [othersValue, setOthersValue] = useState("");

  const handleChange = (event, newValue) => {
    // If "Others" is selected, show the text field
    if (newValue.some((option) => option.title === "Others")) {
      setOthersSelected(true);
    } else {
      setOthersSelected(false);
      setOthersValue("");
    }

    // Filter out "Others" from the selected options to prevent it from being treated as a regular option
    const filteredValue = newValue.filter(
      (option) => option.title !== "Others"
    );

    setSelectedOptions(filteredValue);

    const titles = filteredValue.map((option) => option.title);
    const titlesString = titles.join(", ");

    setFieldValue("establishmentType", titlesString);
    values.establishmentType = titlesString;
  };

  const handleOthersChange = (event) => {
    const newValue = event.target.value;
    setOthersValue(newValue);

    // Update the selected options with the new "Others" value
    const updatedSelectedOptions = [
      ...selectedOptions.filter((option) => option.title !== othersValue),
      { title: newValue },
    ];
    setSelectedOptions(updatedSelectedOptions);

    const titles = updatedSelectedOptions.map((option) => option.title);
    const titlesString = titles.join(", ");

    setFieldValue("establishmentType", titlesString);
    values.establishmentType = titlesString;
  };

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: "customized-hook-demo",
    multiple: true,
    options: establismentType,
    getOptionLabel: (option) => option.title,
    value: selectedOptions,
    onChange: handleChange,
  });

  return (
    <Root>
      <div {...getRootProps()}>
        <Label {...getInputLabelProps()}>
          Select the type of establishment
        </Label>
        <InputWrapper ref={setAnchorEl} className={focused ? "focused" : ""}
        style={{
          // backgroundColor:"black",
          display:"flex",
          alignItems:"center"
        }}
        >
          {selectedOptions?.map((option, index) => {
            const { key, ...tagProps } = getTagProps({ index });
            return <StyledTag key={key} {...tagProps} label={option.title}
            
            />;
          })}
          <input {...getInputProps()} />
        </InputWrapper>
      </div>
      {groupedOptions.length > 0 ? (
        <Listbox {...getListboxProps()} style={{width:"90%"}}>
          {groupedOptions.map((option, index) => {
            const { key, ...optionProps } = getOptionProps({ option, index });
            return (
              <li key={key} {...optionProps}>
                <span>{option.title}</span>
                <CheckIcon fontSize="small" />
              </li>
            );
          })}
        </Listbox>
      ) : null}
      {othersSelected && (
        <div style={{ marginTop: "10px" }}>
          <input
            type="text"
            placeholder="Please specify..."
            value={othersValue}
            onChange={handleOthersChange}
            style={{
              width: "100%",
              padding: "12px",
              boxSizing: "border-box",
              border: "1px solid #d9d9d9",
              borderRadius: "6px",
            }}
          />
        </div>
      )}
    </Root>
  );
}

const establismentType = [
  { title: "Restaurant", year: 1 },
  { title: "Cafe", year: 2 },
  { title: "Buffet Restaurant", year: 3 },
  { title: "Takeaway Restaurant", year: 4 },
  { title: "Sushi Restaurant", year: 5 },
  { title: "Hotel", year: 6 },
  { title: "Bakery", year: 7 },
  { title: "Pastry", year: 8 },
  { title: "Shop", year: 9 },
  { title: "Supermarket", year: 10 },
  { title: "Beverage", year: 11 },
  { title: "Butcher Shop", year: 12 },
  { title: "Fruit & Vegetable Store", year: 13 },
  { title: "Others", year: 14 },
];

// import * as React from 'react';
// import PropTypes from 'prop-types';
// import { useAutocomplete } from '@mui/base/useAutocomplete';
// import CheckIcon from '@mui/icons-material/Check';
// import CloseIcon from '@mui/icons-material/Close';
// import { styled } from '@mui/material/styles';
// import { autocompleteClasses } from '@mui/material/Autocomplete';

// const Root = styled('div')(
//     ({ theme }) => `
//   color: ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.65)' : 'rgba(0,0,0,.85)'
//         };
//   font-size: 14px;
// `,
// );

// const Label = styled('label')`
//   padding: 0 0 4px;
//   line-height: 1.5;
//   display: block;
// `;

// const InputWrapper = styled('div')(
//     ({ theme }) => `
//   border: 1px solid ${theme.palette.mode === 'dark' ? '#434343' : '#d9d9d9'};
//   background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
//   border-radius: 4px;
//   padding: 1px;
//   display: flex;
//   flex-wrap: wrap;

//   &:hover {
//     border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
//   }

//   &.focused {
//     border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
//     box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
//   }

//   & input {
//     background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
//     color: ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.65)' : 'rgba(0,0,0,.85)'
//         };
//     height: 40px;
//     box-sizing: border-box;
//     padding: 4px 6px;
//     width: 0;
//     min-width: 30px;
//     flex-grow: 1;
//     border: 0;
//     margin: 0;
//     outline: 0;
//   }
// `,
// );

// function Tag(props) {
//     const { label, onDelete, ...other } = props;
//     return (
//         <div {...other}>
//             <span>{label}</span>
//             <CloseIcon onClick={onDelete} />
//         </div>
//     );
// }

// Tag.propTypes = {
//     label: PropTypes.string.isRequired,
//     onDelete: PropTypes.func.isRequired,
// };

// const StyledTag = styled(Tag)(
//     ({ theme }) => `
//   display: flex;
//   align-items: center;
//   height: 24px;
//   margin: 2px;
//   line-height: 22px;
//   background-color: ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.08)' : '#fafafa'
//         };
//   border: 1px solid ${theme.palette.mode === 'dark' ? '#303030' : '#e8e8e8'};
//   border-radius: 2px;
//   box-sizing: content-box;
//   padding: 0 4px 0 10px;
//   outline: 0;
//   overflow: hidden;

//   &:focus {
//     border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
//     background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
//   }

//   & span {
//     overflow: hidden;
//     white-space: nowrap;
//     text-overflow: ellipsis;
//   }

//   & svg {
//     font-size: 12px;
//     cursor: pointer;
//     padding: 4px;
//   }
// `,
// );

// const Listbox = styled('ul')(
//     ({ theme }) => `
//   margin: 2px 0 0;
//   padding: 0;
//   position: absolute;
//   list-style: none;
//   background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
//   overflow: auto;
//   max-height: 250px;
//   border-radius: 4px;
//   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
//   z-index: 1;

//   & li {
//     padding: 5px 12px;
//     display: flex;

//     & span {
//       flex-grow: 1;
//     }

//     & svg {
//       color: transparent;
//     }
//   }

//   & li[aria-selected='true'] {
//     background-color: ${theme.palette.mode === 'dark' ? '#2b2b2b' : '#fafafa'};
//     font-weight: 600;

//     & svg {
//       color: #1890ff;
//     }
//   }

//   & li.${autocompleteClasses.focused} {
//     background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
//     cursor: pointer;

//     & svg {
//       color: currentColor;
//     }
//   }
// `,
// );

// export default function CustomizedHook({ setFieldValue, values ,selectedOptions, setSelectedOptions}) {

//     const handleChange = (event, newValue) => {
//         setSelectedOptions(newValue);

//         const titles = newValue.map(option => option.title);
//         const titlesString = titles.join(', ');

//         setFieldValue("establishmentType", titlesString);
//         values.establishmentType = titlesString;
//     };

//     const {
//         getRootProps,
//         getInputLabelProps,
//         getInputProps,
//         getTagProps,
//         getListboxProps,
//         getOptionProps,
//         groupedOptions,
//         value,
//         focused,
//         setAnchorEl,
//     } = useAutocomplete({
//         id: 'customized-hook-demo',
//         multiple: true,
//         options: establismentType,
//         getOptionLabel: (option) => option.title,
//         value: selectedOptions,
//         onChange: handleChange,
//     });

//     return (
//         <Root>
//             <div {...getRootProps()}>
//                 <Label {...getInputLabelProps()}>Select the type of establishment</Label>
//                 <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''}>
//                     {selectedOptions?.map((option, index) => {
//                         const { key, ...tagProps } = getTagProps({ index });
//                         return <StyledTag key={key} {...tagProps} label={option.title} />;
//                     })}
//                     <input {...getInputProps()} />
//                 </InputWrapper>
//             </div>
//             {groupedOptions.length > 0 ? (
//                 <Listbox {...getListboxProps()}>
//                     {groupedOptions.map((option, index) => {
//                         const { key, ...optionProps } = getOptionProps({ option, index });
//                         return (
//                             <li key={key} {...optionProps}>
//                                 <span>{option.title}</span>
//                                 <CheckIcon fontSize="small" />
//                             </li>
//                         );
//                     })}
//                 </Listbox>
//             ) : null}
//         </Root>
//     );
// }

// const establismentType = [
//     { title: 'Restaurant', year: 1 },
//     { title: 'Cafe', year: 2 },
//     { title: 'Buffet Restaurant', year: 3 },
//     { title: 'Takeaway Restaurant', year: 4 },
//     { title: 'Sushi Restaurant', year: 5 },
//     { title: "Hotel", year: 6 },
//     { title: 'Bakery', year: 7 },
//     { title: 'Pastry', year: 8 },
//     { title: 'Shop', year: 9 },
//     { title: 'Supermarket', year: 10 },
//     { title: 'Beverage', year: 11 },
//     { title: 'Butcher Shop', year: 12 },
//     { title: 'Fruit & Vegetable Store', year: 13 },
//     { title: 'Others', year: 14 },
// ];
