import React from 'react';
import { Skeleton, Box, Grid, Typography, RadioGroup, FormControlLabel, Radio, IconButton, Divider } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

function PickUpSkeleton() {
  return (
    <Box sx={{ p: 2, border: '1px solid #e0e0e0', borderRadius: '8px', width: '100%', }}>
      
      {/* PickUp Day */}
      <Typography variant="subtitle1" sx={{ mb: 2 }}>
        PickUp day
      </Typography>
      <RadioGroup row>
        <FormControlLabel
          value="today"
          control={<Skeleton variant="circular" width={20} height={20} />}
          label={<Skeleton variant="text" width={50} />}
        />
        <FormControlLabel
          value="tomorrow"
          control={<Skeleton variant="circular" width={20} height={20} />}
          label={<Skeleton variant="text" width={80} />}
        />
      </RadioGroup>

      <Divider sx={{ my: 2 }} />

      {/* Magic Bags for Sale */}
      <Typography variant="h6" sx={{ mb: 2 }}>
        Magic Bags for sale
      </Typography>
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <IconButton>
            <Skeleton variant="circular" width={40} height={40} />
          </IconButton>
        </Grid>
        <Grid item>
          <Skeleton variant="rectangular" width={50} height={40} />
        </Grid>
        <Grid item>
          <IconButton>
            <Skeleton variant="circular" width={40} height={40} />
          </IconButton>
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} />

      {/* Pick-up Window */}
      <Typography variant="h6" sx={{ mb: 2 }}>
        Pick-up window
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Pickup Starts
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Skeleton variant="rectangular" width="100%" height={40} />
            <Skeleton variant="circular" width={20} height={20} sx={{ ml: -3 }} />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Pickup Ends
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Skeleton variant="rectangular" width="100%" height={40} />
            <Skeleton variant="circular" width={20} height={20} sx={{ ml: -3 }} />
          </Box>
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} />

      {/* Add Special Day */}
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
        <Skeleton variant="circular" width={24} height={24} />
        <Skeleton variant="text" width={120} sx={{ ml: 1 }} />
      </Box>
    </Box>
  );
}

export default PickUpSkeleton;
