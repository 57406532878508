import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getbagsize,
  removeDataFromReducer,
  surprisebagDetails,
  updateoverview,
} from "../../redux/actions";

import {
  ImageUrl,
  bagsize_list_api,
  surprisebagDetails_api,
  update_overview_api,
  update_cover_page,
} from "../../API/baseUrl";
import Textarea from "@mui/joy/Textarea";
import ToastMessage from "../../utils/ToastMessage";
import { Autocomplete, Avatar, ModalOverflow } from "@mui/joy";
import ListGroup from "react-bootstrap/ListGroup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useFormik } from "formik";
import { overviewUpdateSchema } from "../../utils/schema";
import { IconButton } from "@mui/material";
import { Unstable_Popup as BasePopup } from "@mui/base/Unstable_Popup";
import { styled } from "@mui/system";
import { PopupBody, Button } from "react-bootstrap";
import "./index.css";
import { MdOutlineEdit } from "react-icons/md";
import { IoCameraOutline } from "react-icons/io5";
import SpaIcon from "@mui/icons-material/Spa";
import ModalOverview from "./coverPhotoModal";
import ModalOverviewlogo from "./coverPhotoModal/logoModal";

const OverviewSurpriseBag = ({
  dietryval,
  setDietryval,
  categoryval,
  setCategoryval,
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
  selectedImage,
  setSelectedImage,
  selectedImageFile,
  setSelectedImageFile,
}) => {
  const restaurantLogo = localStorage.getItem("restaurantLogo");
  const restaurantFoodImage = localStorage.getItem("restaurantFoodImage");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { surprisebagDetailsData } = useSelector(
    (state) => state?.surprisebagDetailsReduser || {}
  );
  const { bagsizelistData } = useSelector(
    (state) => state?.bagsizelistreduser || {}
  );
  const { updateoverviewData } = useSelector(
    (state) => state?.updateoverviewReduser || {}
  );
  const auth = localStorage.getItem("auth");

  const [data, setData] = useState([]);
  const [bagsizelist, setBagsizelist] = useState([]);

  // *********popup*********
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen1 = (event) => {
    setOpen1(true);
    setAnchorEl(event.currentTarget);
  };

  const handleOpen2 = (event) => {
    setOpen2(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose2 = () => {
    setOpen2(false);
    setOpen1(false);
    setAnchorEl(null);
  };

  const Closemodal = () => {
    setOpen2(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const vendorIdFk = localStorage.getItem("restaurantTypeIdFK");
    const parameter = {
      url: `${bagsize_list_api}${vendorIdFk}`,
    };
    dispatch(getbagsize(parameter));
  }, []);

  useEffect(() => {
    if (bagsizelistData?.status) {
      setBagsizelist(bagsizelistData?.data);
    } else {
      ToastMessage("error", bagsizelistData?.message);
    }
  }, [bagsizelistData]);

  const category = [
    { label: "Meals", id: 1 },
    { label: "Bread & Pastries", id: 2 },
    { label: "Groceries", id: 3 },
    { label: "Other", id: 4 },
  ];

  const dietaryArray = [
    { label: "Non specified", id: 1 },
    { label: "Vegan", id: 2 },
    { label: "Vegetarian", id: 3 },
    { label: "Non-Vegetarian", id: 4 },
  ];

  const handleradiochange = (e) => {
    values.surpriseBagSizeIdFk = e.target.value;
  };

  const handledropdownChange = (event, newValue) => {
    values.surpriseBagCategory = newValue.label;
    setCategoryval(newValue);
  };

  const handledietryChange = (event, newValue) => {
    values.dietaryType = newValue.label;
    setDietryval(newValue);
  };

  const open = Boolean(anchorEl);
  const _id = open ? "simple-popup" : undefined;

  return (
    <div className="dashboardoverview_container_fluid">
      <form className="dashboard_home_main_container">
        <div className="d-flex">
          <h3 className="surprisebag_list_heading">Magic Bag Details</h3>
        </div>

        <hr></hr>
        <div className="surprise_bag_list_container">
          <div className="overview_img_container">
            <div className="edit"></div>

            {open1 ? (
              <ModalOverview
                open1={open1}
                handleClose={handleClose2}
                heading="Change cover image"
                description="Image requirements: PNG or JPG, 100 × 100 pixels, max. size 10 MB."
                // coverimg={`${ImageUrl}${data?.surpriseBagCoverImage ? data?.surpriseBagCoverImage : data?.restaurantFoodImage}`}
                coverimg={`${ImageUrl}${restaurantFoodImage}`}
                {...{
                  selectedImage,
                  setSelectedImage,
                  selectedImageFile,
                  setSelectedImageFile,
                }}
              />
            ) : (
              ""
            )}
            {/* <Button
              aria-describedby={_id}
              type="button"
              onClick={handleClick}
              className="logo_EditButton"
            >
              <MdOutlineEdit sx={{ fontSize: "12px" }} />
            </Button> */}
            <BasePopup
              id={_id}
              open={open}
              anchor={anchorEl}
              // open={open1 || open2}
              anchorEl={anchorEl}
              onClose={handleClose}
            >
              <div className="poupdiv" style={{ fontSize: "16px" }}>
                <div
                  onClick={handleOpen1}
                  style={{ cursor: "pointer" }}
                  className="change_profile"
                >
                  <IoCameraOutline
                    style={{ fontSize: "1.5rem", verticalAlign: "bottom" }}
                  />{" "}
                  Upload cover image
                </div>{" "}
                <hr />
                {/* <div
                  onClick={handleOpen2}
                  style={{ cursor: "pointer" }}
                  className="change_profile"
                >
                  <SpaIcon
                    style={{ fontSize: "1.5rem", verticalAlign: "bottom" }}
                  />{" "}
                  Change store logo
                </div> */}
                <ModalOverviewlogo
                  open1={open2}
                  handleClose={handleClose2}
                  heading="Change store image"
                  description="Image requirements: PNG or JPG, 100 × 100 pixels, max. size 10 MB."
                  coverimg={`${ImageUrl}${data?.restaurantFoodImage}`}
                />
              </div>
            </BasePopup>

            <img src={`${ImageUrl}${restaurantFoodImage}`} alt="" />

            {/* <img
              src={`${ImageUrl}${data?.restaurantLogo}`}
              alt=""
              className="logo_img"
            /> */}
            <Avatar
              className="logo_img"
              alt="logo_img"
              src={`${ImageUrl}${restaurantLogo}`}
            />
          </div>

          <div className="overview_details_inside_contain">
            <div className="m-b-10">
              <h5 className="overview_details_h">Name</h5>
              <p className="overview_details_p">Magic Bag</p>
            </div>

            <div className="m-b-10">
              <h5 className="overview_details_h">Description *</h5>

              <>
                {" "}
                <Textarea
                  minRows={4}
                  name="surpriseBagDescription"
                  placeholder="Description"
                  variant="outlined"
                  value={values.surpriseBagDescription}
                  // onChange={handleChange}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.length <= 200) {
                      handleChange(e); // Call the original handleChange if within limit
                    }
                  }}
                  onBlur={handleBlur}
                />
                   <p className="descriptionlimit">
          {values.surpriseBagDescription.length}/200
        </p>
                {errors.surpriseBagDescription &&
                touched.surpriseBagDescription ? (
                  <p className="form-error">{errors.surpriseBagDescription}</p>
                ) : null}
              </>
            </div>

            <div className="m-b-10">
              <h5 className="overview_details_h">Category *</h5>

              <>
                <Autocomplete
                  placeholder=""
                  name="surpriseBagCategory"
                  options={category}
                  defaultValue={categoryval}
                  onChange={(event, newValue) =>
                    handledropdownChange(event, newValue)
                  }
                  sx={{}}
                />
                {errors.surpriseBagCategory && touched.surpriseBagCategory ? (
                  <p className="form-error">{errors.surpriseBagCategory}</p>
                ) : null}
              </>
            </div>

            <div className="m-b-10">
              <h5 className="overview_details_h">Dietary type *</h5>

              <>
                <Autocomplete
                  placeholder=""
                  name="dietaryType"
                  options={dietaryArray}
                  defaultValue={dietryval}
                  onChange={(event, newValue) =>
                    handledietryChange(event, newValue)
                  }
                  sx={{}}
                />
                {errors.dietaryType && touched.dietaryType ? (
                  <p className="form-error">{errors.dietaryType}</p>
                ) : null}
              </>
            </div>

            <>
              <h5 className="overview_details_h">Pricing *</h5>

              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={values.surpriseBagSizeIdFk}
                name="surpriseBagSizeIdFk"
                onChange={handleradiochange}
              >
                {bagsizelist?.map((item, index) => (
                  <div className="size_container bigsize">
                    <FormControlLabel
                      className=""
                      value={item.surpriseBagSizeIdFK}
                      control={
                        <Radio
                          sx={{
                            color: `var(--colorused)`,
                            "&.Mui-checked": {
                              color: `var(--colorused)`,
                            },
                          }}
                        />
                      }
                      label={
                        <div className="bag_size_lable">
                          <p className="margin-0">{item.surpriseBagSize}</p>

                          <div className="text-align-end">
                            <h5 className="margin-0 align">
                           
                            ₹{item.surpriseBagRetailPrice} minimum price
                            </h5>

                            <p className="margin-0 align">
                           
                            ₹{item.surpriseBagSellingPrice} price in app
                            </p>
                          </div>
                        </div>
                      }
                    />
                  </div>
                ))}
              </RadioGroup>

              {errors.surpriseBagSizeIdFk && touched.surpriseBagSizeIdFk ? (
                <p className="form-error">{errors.surpriseBagSizeIdFk}</p>
              ) : null}
            </>
          </div>
        </div>
      </form>
    </div>
  );
};

export default OverviewSurpriseBag;
