import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ToastMessage from '../../utils/ToastMessage';
import { GetTicketChatData_api, TicketChatData_api } from '../../API/baseUrl';
import { GetTicketChatDataAction, submitTicketChatDataAction } from '../../redux/actions';
import './index.css';
import { Avatar, Box, IconButton, Paper, TextField, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
const ChatBox = ({ supportTicketIdFk, userIdFk, ticketId, from ,onClose,fromtype  }) => {
  const [ChatGetData, setGetChatData] = useState([]);
  const [messageContent, setMessageContent] = useState('');
  const dispatch = useDispatch();
  const chatMessagesEndRef = useRef(null);
  const submitTicketChatData = useSelector((state) => state?.submitTicketChatData?.submitTicketChatData);
  const getTicketChatData = useSelector((state) => state?.GetTicketChatData?.GetTicketChatData);

  useEffect(() => {
    chatMessagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [ChatGetData]);

  const GetChatData = () => {
    const parameter = {
      ticketId: ticketId,
      url: GetTicketChatData_api,
    };

    dispatch(GetTicketChatDataAction(parameter));
  };

  const ChatData = async () => {
    GetChatData();
    if (messageContent.trim() === '') {
      ToastMessage('error', 'Message cannot be empty');
      return;
    }

    const parameter = {
      supportTicketIdFk: supportTicketIdFk,
      from: userIdFk,
      fromType: fromtype ? fromtype : "vendor",
      ticketId: ticketId,
      content: messageContent,
      type: "text",
      url: TicketChatData_api,
    };

    dispatch(submitTicketChatDataAction(parameter));
    setMessageContent('');
  };

  const handleInputChange = (e) => {
    setMessageContent(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      ChatData();
    }
  };

  useEffect(() => {
    GetChatData();
  }, [ticketId]);

  useEffect(() => {
    if (getTicketChatData?.status) {
      setGetChatData(getTicketChatData?.chatData);
    } else if (getTicketChatData) {
      ToastMessage('error', getTicketChatData?.message);
    }
  }, [getTicketChatData]);

  useEffect(() => {
    if (submitTicketChatData?.status) {
      GetChatData();
    } else if (submitTicketChatData) {
      ToastMessage('error', submitTicketChatData?.message);
    }
  }, [submitTicketChatData]);

  return (
    // <div className='chat-room'>
    //   <div className="chat-messages">
    //     {ChatGetData?.map((TicketChatData, index) => {
    //       const messageClass =
    //         TicketChatData?.chatFromType === 'admin' ? 'admin-message' : 'vendor-message';

    //       return (
    //         <div
    //           key={index}
    //           className={`message-container ${messageClass}`}
    //           style={{
    //             display: 'flex',
    //             justifyContent: TicketChatData?.chatFromType === 'vendor' ? 'flex-end' : 'flex-start',
    //             marginBottom: '8px',
    //           }}
    //         >
    //           <div className='d-flex gap-2' style={{ alignItems: 'center' }}>
    //             <Avatar alt={TicketChatData?.chatFromType} src="/static/images/avatar/1.jpg" />
    //             <span style={{
    //               backgroundColor: TicketChatData?.chatFromType === 'admin' ? '#f1f0f0' : '#d1e7dd',
    //               padding: '10px',
    //               borderRadius: '10px',
    //               color: TicketChatData?.chatFromType === 'admin' ? '#000' : '#fff',
    //             }}>
    //               {TicketChatData?.chatContent}
    //             </span>
    //           </div>
    //         </div>
    //       );
    //     })}
    //     <div ref={chatMessagesEndRef} />
    //   </div>

    //   <div className="chatinput">
    //     <input
    //       type="text"
    //       value={messageContent}
    //       onChange={handleInputChange}
    //       onKeyPress={handleKeyPress}
    //       className='message_box_input'
    //     />
    //     <button type="submit" onClick={ChatData} className="send-button"><SendIcon /></button>
    //   </div>
    // </div>
<Paper elevation={3} sx={{ width: "100%", height: "100%", display: 'flex', flexDirection: 'column' }}>
  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, borderBottom: '1px solid #eee' }}>
    <Typography variant="h6">Chat Support</Typography>
    <IconButton
     onClick={onClose}
     >
      <CloseIcon />
    </IconButton>
  </Box>

  <Box sx={{ flex: 1, overflowY: 'auto', p: 2 }}>
    {ChatGetData?.map((chat, index) => {
      const isAdmin = chat?.chatFromType === "admin";

      return (
        <Box
          key={index}
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 2,
            flexDirection: isAdmin ? 'row' : 'row-reverse',  
          }}
        >
          <Avatar alt={chat?.chatFromType} src="/static/images/avatar/1.jpg" />
          <Paper
            elevation={1}
            sx={{
              p: 1,
              ml: isAdmin ? 2 : 0, 
              mr: isAdmin ? 0 : 2,  
              backgroundColor: isAdmin ? '#f5f5f5' : 'var(--colorused)', 
             
              borderRadius: 2,
              maxWidth: '70%',
              
              overflow: 'hidden',        
              whiteSpace: 'normal',     
              wordWrap: 'break-word',   
              overflowWrap: 'break-word',
            }}
          >
            <Typography variant="body2"
            style={{color: isAdmin ? 'black' : 'white'}}
            >{chat?.chatContent}</Typography>
          </Paper>
        </Box>
      );
    })}
    <div ref={chatMessagesEndRef} />
  </Box>

  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      p: 2,
      borderTop: '1px solid #eee',
    }}
  >
    <TextField
      fullWidth
      value={messageContent}
      onChange={handleInputChange}
      onKeyPress={handleKeyPress}
      placeholder="Type a message"
      variant="outlined"
      size="small"
    />
    <IconButton onClick={ChatData} style={{
      color:"var(--colorused)"
      }} >
      <SendIcon />
    </IconButton>
  </Box>
</Paper>

  );
};

export default ChatBox;
