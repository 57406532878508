
import { useState } from "react";
import ScheduleOperations from "./scheduleOpera";
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { TextField, Switch, Button, Box, Typography, FormControlLabel, CircularProgress } from '@mui/material';
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/x-date-pickers';
import "./index.css"




function Schedule({endDateLimit,validation, setvalidation, enddate, setEnddate, values, handleCheckboxChange, touched, handleBlur, handleNewTimeSet, errors, handleinputChange, setFieldValue }) {
    const [continueactive, setContinueactive] = useState(false);
   
 

    const today = new Date();
    const maxDate = dayjs(today).add(endDateLimit, 'day').toDate();



    const handleDateChange = (date) => {
        setEnddate(date);
        values.surpriseBagEndDate = dayjs(date).format('DD-MM-YYYY');
        setFieldValue("surpriseBagEndDate",dayjs(date).format('DD-MM-YYYY'))
    };
    const getMinTime = (item) => {
        return values[item.formikKeyStartTime]
            ? dayjs(values[item.formikKeyStartTime], 'HH:mm').add(1, 'minute')
            : null;
    };

    const getMaxTime = (item) => {
        return values[item.formikKeyEndTime] || null;
    };

    return (
        <div className="schedule_container">
            <div>
                <h3>Weekly schedule</h3>

                <p>Here you can specify how many Magic Bags will be available to buy in the Pick'n'Treat app per day of the week. You can also change the pick-up window in which customers can come and pick up their Magic Bags in your store.</p>
                <p>If you want to adjust the number of bags offered during the day because you've realised you have more or less food in surplus than anticipated, you can do this in the Dashboard.</p>

            </div>

            <hr></hr>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div>
                    <Typography variant="body1" sx={{ mr: 2, color: `var(--colorused)` }}>End date</Typography>
                    <ReactDatePicker
                        className="date_input_calender"
                        selected={enddate}
                        onChange={handleDateChange}
                        name="surpriseBagEndDate"
                        onBlur={handleBlur}
                        dateFormat="dd-MM-yyyy"
                        minDate={today}
                        maxDate={maxDate}
                    />
                    {/* {errors.surpriseBagEndDate && touched.surpriseBagEndDate ? (
                        <p className="form-error">{errors.surpriseBagEndDate}</p>
                    ) : null} */}
                </div>

            </LocalizationProvider>

        
            <ScheduleOperations {...{
                values,
                handleCheckboxChange,
                handleNewTimeSet,
                errors,
                setContinueactive,
                handleinputChange,
                validation, setvalidation
            }} />


        </div>
    );
}

export default Schedule;
