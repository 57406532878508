import React, { useEffect, useState } from "react";
import { Grid, InputAdornment, TextField } from "@mui/material";
import Graph from "./graph";
import AvatarCard from "./avatarcard";
import Balance from "./balance";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { dashboard_revenue_api, get_Notification_api } from "../../API/baseUrl";
import { dashboardaction, orderlistaction } from "../../redux/actions";
import { FormControl, MenuItem, Select } from "@mui/material";
import ToastMessage from "../../utils/ToastMessage";
import DashboardSkeleton from "./dashboardskeleton";

import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  Drawer,
  Stack,
  Badge,
  Pagination,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Button, Card, Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import Notification from "./Notification";
import LoaderAnimation from "../../logo-2/loader/loaderanimation";

function Homedashboard() {
  const dispatch = useDispatch();
  const { orderListData } = useSelector(
    (state) => state?.orderListReduser || {}
  );
  const { dashboardrevenueData } = useSelector(
    (state) => state?.dashboardReduser || {}
  );
  const [data, setData] = useState([]);
  const [pending, setPending] = useState(true);
  const [filter, setFilter] = useState("Today");
  const [fromDate, setFromDate] = useState(""); // State to hold 'From' date
  const [toDate, setToDate] = useState(""); // State to hold 'To' date

  useEffect(() => {
    document.title = "Dashboard";
  }, []);

  useEffect(() => {
    if (dashboardrevenueData) {
      if (dashboardrevenueData?.status) {
        setData(dashboardrevenueData);
        setPending(false);
      } else {
        ToastMessage("error", dashboardrevenueData?.message);
        setPending(false);
      }
    }
  }, [dashboardrevenueData]);

  useEffect(() => {
    const parameter = {
      filter_option: filter,
      url: dashboard_revenue_api,
      start_date: formatDate(fromDate), // Format the 'From' date
      end_date: formatDate(toDate), // Format the 'To' date
    };
    dispatch(dashboardaction(parameter));
  }, [filter, fromDate, toDate]);

  const handleChangePeriod = (event) => {
    const value = event.target.value;
    setFilter(value);

    if (value !== "Custom") {
      setFromDate(""); // Clear the dates when not 'Custom'
      setToDate("");
    }
  };

  const handleDateChange = (event, type) => {
    const date = event.target.value;
    if (type === "from") {
      setFromDate(date);
    } else {
      setToDate(date);
    }
  };

  // Function to format date in dd/mm/yyyy format
  // const formatDate = (dateString) => {
  //   if (!dateString) return "";
  //   const [year, month, day] = dateString.split("-"); // Split the yyyy-mm-dd format
  //   return `${day}/${month}/${year}`; // Reformat to dd/mm/yyyy
  // };

  // const handleDateChange = (event, type) => {
  //   const date = event.target.value;
  //   if (type === "from") {
  //     setFromDate(date);
  //   } else {
  //     setToDate(date);
  //   }
  // };

  // Function to format date in dd/mm/yyyy format
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const [year, month, day] = dateString.split("-"); // Split the yyyy-mm-dd format
    return `${day}-${month}-${year}`; // Reformat to dd/mm/yyyy
  };

  const getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  return (
    <div style={{ margin: "20px" }}>
      {pending ? (
        <LoaderAnimation/>
      ) : <>
        
          <div className="typeAndPeriodClass">
            <h4 className="dashboard_Heading">Dashboard</h4>

            <div className="typeAndPeriodClassContainer">
              <div className="periodDivClass periodDivClassPeriodtext">
                <p>Period :</p>
                <div className="periodDivClassDropdown">
                  <FormControl sx={{ m: 1, width: 300 }}>
                    <Select
                      labelId="demo-simple-select-label"
                      value={filter}
                      onChange={handleChangePeriod}
                    >
                      <MenuItem value="Today">Today</MenuItem>
                      <MenuItem value="Past 7 Days">This Week</MenuItem>
                      <MenuItem value="This Month">This Month</MenuItem>
                      <MenuItem value="Past Month">Last Month </MenuItem>
                      <MenuItem value="This Year">This Year </MenuItem>
                      <MenuItem value="Previous Year">Last Year </MenuItem>
                      <MenuItem value="Till Date">Till Date </MenuItem>
                      <MenuItem value="Custom">Custom</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

              {filter == "Custom" ? (
                <div className="dhasboard_date_container_main">
                  <div className="periodDivClass">
                    <p className="date_picker_label_dashboard">From</p>
                    <input
                      className="dhasboard_date_container"
                      type="date"
                      value={fromDate}
                      onChange={(e) => handleDateChange(e, "from")}
                      format="dd-mm-yyyy"
                      max={toDate || getTodayDate()}
                    />
                  </div>

                  <div className="periodDivClass">
                    <p className="date_picker_label_dashboard">To</p>
                    <input
                      className="dhasboard_date_container"
                      type="date"
                      value={toDate}
                      onChange={(e) => handleDateChange(e, "to")}
                      format="dd-mm-yyyy"
                      max={getTodayDate()} // Limit end date to today
                      min={fromDate} // Limit end date to selected start date
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>


<Grid container spacing={2}>
          <Grid
            container
            spacing={2}
            mt={2}
            mx={2}
            sx={{ display: "flex", gap: "25px" }}
          >



            <Grid item xs={12} sm={5.6} md={3.6} lg={3.6} className="graphs1">
                Total Sales
                <h2>₹ {data?.magic_bag_details?.Total_Revenue}</h2>
           </Grid>












            <Grid item  xs={12} sm={5.6} md={3.6} lg={3.6} className="graphs1">
              Magic Bags Sold
              <h2>{data?.magic_bag_details?.Total_Purchase}</h2>
            </Grid>

            <Grid item  xs={12} sm={5.6} md={3.6} lg={3.6} className="graphs1">
              Total Earnings
              <h2>
                ₹{" "}
                {(
                  data?.magic_bag_details?.Total_Revenue -
                  data?.magic_bag_details?.Total_Commision
                ).toFixed(2)}
              </h2>
            </Grid>

            <Grid item  xs={12} sm={5.6} md={3.6} lg={3.6} className="graphs1">
              Total Users
              <h2>{data?.magic_bag_details?.Total_Users}</h2>
            </Grid>


          </Grid>

          <Grid
            container
            spacing={2}
            mt={2}
            mx={2}
            sx={{ display: "flex", gap: "25px" }}
          ></Grid>

          <Grid
            container
            spacing={2}
            mt={2}
            mx={2}
            sx={{ display: "flex", gap: "25px" }}
          >
            <Grid
              item
              xs={12}
              md={12}
              lg={7.8}
              className="graphs1"
              style={{ height: "54vh" }}
            >
              Total Revenue
              <h2 style={{ color: "" }}>
                ₹ {data?.magic_bag_details?.Total_Revenue}
              </h2>
              <Graph data={data} sx={{ height: "10%", width: "100%" }} />
            </Grid>
              <Grid
              item
              xs={12}
              md={12}
              lg={3.8}
              className="graphs1"
              style={{ height: "54vh" }}
            >
              <AvatarCard />
            </Grid>
          </Grid>
        </Grid>
      </>}
    </div>
  );
}

export default Homedashboard;
