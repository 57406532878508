import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { ImageUrl } from "../../API/baseUrl";

const profile = {
  name: "Charles Robbie",
  location: "New York, USA",
  details: [
    {
      id: 1,
      type: "Projects",
      count: 28,
    },
    {
      id: 2,
      type: "Followers",
      count: 643,
    },
    {
      id: 3,
      type: "Following",
      count: 76,
    },
  ],
};

const AvatarCard = () => {
  const [restaurantLogo, setRestaurantLogo] = useState("");
  const [restaurantName, setRestaurantName] = useState("");

  useEffect(() => {
    // Retrieve the logo from local storage when the component mounts
    const logo = localStorage.getItem("restaurantLogo");
    if (logo) {
      setRestaurantLogo(logo);
    }
    const nameresturant = localStorage.getItem("restaurantName");
    if (nameresturant) {
      setRestaurantName(nameresturant);
    }
  }, []);
  return (
    <Stack direction="column" alignItems="center">
      {/* <Avatar
        //   src={AvatarImg}
          sx={{
            height: 130,
            width: 130,
            bgcolor: 'info.main',
          }}
        /> */}
      {restaurantLogo ? (
        <img
        src={`${ImageUrl}${restaurantLogo}`}
          alt="Restaurant Logo"
          // sx={{
          //   height: 30,
          //   width: 30,
          //   bgcolor: "info.main",
          // }}
          style={{borderRadius:"50%" ,height:"200px",width:"200px"}}
        />
      ) : (
        <p>No logo found in local storage.</p>
      )}
      <Typography variant="h5">
      {restaurantName ? (
        <h6 style={{
          textAlign:"center",
          marginTop:"20px"
        }}>{restaurantName}</h6>
      ) : (
        <p>No restaurant name found in local storage.</p>
      )}
      </Typography>
      {/* <Stack mt={0.25} alignItems="center" spacing={0.5}>
        <Typography variant="body1">{profile.location}</Typography>
      </Stack> */}

      {/* <Stack direction="row" spacing={4} mt={3}>
        {profile.details.map((item) => (
          <Stack key={item.id} direction="column" alignItems="center">
            <Typography fontSize="body2.fontSize">{item.type}</Typography>
            <h4>{item.count}</h4>
          </Stack>
        ))}
      </Stack> */}
    </Stack>
  );
};

export default AvatarCard;
