


import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import './index.css';
import moment from 'moment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const TimePickercomponent = ({ value, defaultValue, label, onChange, disabled,minTime, maxTime  }) => {

  function convertTimeToMoment(timeString) {
    const timeMoment = moment(timeString, "HH:mm");
    return timeMoment.toDate();
  }
  const convertTimeToDayjs = (timeString) => {
    return timeString ? dayjs(timeString, "HH:mm") : null;
  };


  const timeValue = value ? dayjs(value, "HH:mm") : null;
    const timeDefaultValue = defaultValue ? dayjs(defaultValue, "HH:mm") : null;
    const minTimeValue = convertTimeToDayjs(minTime);
    const maxTimeValue = convertTimeToDayjs(maxTime);


  function convertTimeToSpecificFormat(defaultValue, dateString = '2022-04-17') {
    const combinedDateTime = `${dateString}T${defaultValue}`;
    const formattedTime = moment(combinedDateTime, "YYYY-MM-DDTHH:mm");
    return formattedTime.format('YYYY-MM-DDTHH:mm');
  }
  

  return (
    <div className="time_container">
      <label htmlFor="time">{label}</label>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          className="input_time_field_input"
          label=""
          placeholder=""
          value={timeValue || timeDefaultValue}
          onChange={onChange}
          ampm={false}
          disabled={disabled}
          minTime={minTimeValue}
          maxTime={maxTimeValue}
          // defaultValue={dayjs(convertTimeToSpecificFormat(defaultValue))}
          // format="HH:mm"
        />
      </LocalizationProvider>
    </div>
  );
};

export default TimePickercomponent;

