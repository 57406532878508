import CryptoJS from 'crypto-js';

// Update function to handle promises correctly
export async function getJsonDecryptData(encryptedData) {

    try {
        // Define the same secret key and IV used for encryption
        const secretKey = CryptoJS.enc.Utf8.parse('ABCDEFGHIJKLMNOPQRSTUVWXYZabcdef');
        const iv = CryptoJS.enc.Utf8.parse('ABCDEFGHIJKLMNOP');

        // Decode the base64-encoded encrypted data
        const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, secretKey, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        // Convert the decrypted data back to a string
        const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);

        // Check if decrypted data is valid
        if (!decryptedData) {
            throw new Error('Decryption failed: No data returned.');
        }

        // Parse the decrypted string back to a JSON object
        // const jsonData = JSON?.parse(decryptedData);
        // const jsonData = JSON.parse(JSON.stringify(decryptedData, null, 2))
        const jsonData = decryptedData

        // Return the decrypted JSON object
        return jsonData;
    } catch (error) {
        throw error; // Re-throw error to handle it outside the function
    }
}

