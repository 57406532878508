import {
  Button,
  Container,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography
} from "@mui/material";
import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  get_mobile_otp,
  get_resturant_otp, restaurant_registration, verify_owner_otp,
  verify_resturant_otp
} from "../API/baseUrl";
import ResturantImage from "../pages/resturantImage";
import Timing from "../pages/resturantType";
import RestaurantInfo from "../pages/resturentinformation";
import {
  createresturant,
  mobileotpaction,
  resturentotpaction,
  verifyOwnerOtp,
  verifyresturantOtp,
} from "../redux/actions";
import { restaurantCreateshema } from "../utils/schema";
import ToastMessage from "../utils/ToastMessage";
import "./index.css";
const vendorIdFk = localStorage.getItem("vendorIdFk");

const initialValues = {
  isRestaurantMobileVerified: 0,
  vendorIdFk: vendorIdFk,
  isRestaurantOwnerMobileVerified: 0,
  restaurantName: "",
  restaurantAddress: "",
  restaurantLandlineNumber: "",
  restaurantLandlineNumberCountryCode: "",
  restaurantOwnerName: "",
  restaurantOwnerEmail: "",
  establishmentType: "",
  cuisinesSpeacility: "",
  operationDay: "",
  foodImage: "",
  restaurantLogo: "",
  restaurantMobileNumber: "",
  restaurantCountryCode: "",
  restaurantOwnerMobileNumber: "",
  restaurantOwnerCountryCode: "",
  restaurantAddressLatitude: "",
  restaurantAddressLongitude: "",

  mondayProperty: "",
  mondayStart: "",
  mondayEnd: "",

  tuesdayProperty: "",
  tuesdayStart: "",
  tuesdayEnd: "",

  wednesdayProperty: "",
  wednesdayStart: "",
  wednesdayEnd: "",

  thursdayProperty: "",
  thursdayStart: "",
  thursdayEnd: "",

  fridayProperty: "",
  fridayStart: "",
  fridayEnd: "",

  saturdayProperty: "",
  saturdayStart: "",
  saturdayEnd: "",

  sundayProperty: "",
  sundayStart: "",
  sundayEnd: "",
};

const NestedStepper = ({ steps, onClick, onsubmit, values }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [checked, setChecked] = React.useState(false);

  const handleNext = () => {
    if (activeStep === 0) {
      if (
        !values.restaurantName ||
        !values.restaurantAddress ||
        !values.restaurantOwnerName ||
        !values.restaurantOwnerEmail
      ) {
        ToastMessage("error", "Please fill required fields");
      }
      
      
      else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else if (activeStep === 1) {
      if (
        !values.establishmentType ||
        !values.cuisinesSpeacility ||
        !values.operationDay
      ) {
        ToastMessage("error", "Please fill required fields");
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handlecheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <>
      <Stepper
        className="stepperclasss"
        activeStep={activeStep}
        orientation="vertical"
      >
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>{step.label}</StepLabel>

            <StepContent>
              <form onSubmit={onsubmit} className="stepcontent_container">
                {step.content}
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>

                  {activeStep === steps.length - 1 ? (
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      type="button"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Next
                    </Button>
                  )}
                </div>
              </form>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {/* <FormControlLabel control={<Checkbox  checked={checked} onChange={handlecheckboxChange}/>} label="I agree to Pick’N’Treat’s Terms of Service, Privacy Policy, and Contract Policies" /> */}
    </>
  );
};

const MultiStepForm = () => {
  const [activeMainStep, setActiveMainStep] = useState(0);
  const [image, setImage] = useState("");
  const [logoimage, setLogoimage] = useState("");
  const [radiovalue, setRadiovalue] = useState("");
  const [selectedValues, setSelectedValues] = useState("");

  const [activeStep, setActiveStep] = useState(0);
  const [operationArray, setOperationArray] = useState([]);
  const [mobilevalue, setMobilevalue] = useState("");
  const [resturantmobilevalue, setResturantmobilevalue] = useState("");
  const [countrycode, setCountrycode] = useState("");
  const [resturantcountrycode, setResturantcountrycode] = useState("");
  const [mobileotp, setMobileotp] = useState("");
  const [resturantotp, setResturantotp] = useState("");
  const [isverified, setIsverified] = useState(false);
  const [isresturantnumberverified, setIsresturantnumberverified] =
    useState(false);

  const digitsArray = mobileotp?.toString().split("").map(Number);
  const resturantdigitsArray = resturantotp?.toString().split("").map(Number);

  const dispatch = useDispatch();
  const { createresturantdata } = useSelector(
    (state) => state?.createResturant || {}
  );
  const { mobileotpdata } = useSelector(
    (state) => state?.mobileotpreducer || {}
  );
  const { resturantotpdata } = useSelector(
    (state) => state?.resturantotpreducer || {}
  );
  const { ownerotpdata } = useSelector((state) => state?.ownerotpverify || {});
  const { resturantotpverifydata } = useSelector(
    (state) => state?.resturantotpverify || {}
  );
  const [showselectedValues, setShowselectedValues] = useState([]);

  

  const getData = () => {
    const operationArray = [];

    if (values.mondayProperty == 1) {
      operationArray.push({
        operationDay: "Monday",
        startTime: moment(values.mondayStart, ["HH:mm"]).format("hh:mm A"),
        endTime: moment(values.mondayEnd, ["HH:mm"]).format("hh:mm A"),
      });
    }
    if (values.tuesdayProperty == 1) {
      operationArray.push({
        operationDay: "Tuesday",
        startTime: moment(values.tuesdayStart, ["HH:mm"]).format("hh:mm A"),
        endTime: moment(values.tuesdayEnd, ["HH:mm"]).format("hh:mm A"),
      });
    }
    if (values.wednesdayProperty == 1) {
      operationArray.push({
        operationDay: "Wednesday",
        startTime: moment(values.wednesdayStart, ["HH:mm"]).format("hh:mm A"),
        endTime: moment(values.wednesdayEnd, ["HH:mm"]).format("hh:mm A"),
      });
    }

    if (values.thursdayProperty == 1) {
      operationArray.push({
        operationDay: "Thursday",
        startTime: moment(values.thursdayStart, ["HH:mm"]).format("hh:mm A"),
        endTime: moment(values.thursdayEnd, ["HH:mm"]).format("hh:mm A"),
      });
    }
    if (values.fridayProperty == 1) {
      operationArray.push({
        operationDay: "Friday",
        startTime: moment(values.fridayStart, ["HH:mm"]).format("hh:mm A"),
        endTime: moment(values.fridayEnd, ["HH:mm"]).format("hh:mm A"),
      });
    }

    if (values.saturdayProperty == 1) {
      operationArray.push({
        operationDay: "Saturday",
        startTime: moment(values.saturdayStart, ["HH:mm"]).format("hh:mm A"),
        endTime: moment(values.saturdayEnd, ["HH:mm"]).format("hh:mm A"),
      });
    }

    if (values.sundayProperty == 1) {
      operationArray.push({
        operationDay: "Sunday",
        startTime: moment(values.sundayStart, ["HH:mm"]).format("hh:mm A"),
        endTime: moment(values.sundayEnd, ["HH:mm"]).format("hh:mm A"),
      });
    }
    return operationArray;
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: restaurantCreateshema,
    onSubmit: (value) => submitForm(value),
  });

  useEffect(() => {
    const vendorIdFk = localStorage.getItem("vendorIdFk");
    if (digitsArray?.[3]) {
      const parameter = {
        vendorIdFk: vendorIdFk,
        restaurantOwnerMobileOtp: mobileotp,
        url: verify_owner_otp,
      };
      dispatch(verifyOwnerOtp(parameter));
    }
  }, [digitsArray?.[3]]);

  useEffect(() => {
    const vendorIdFk = localStorage.getItem("vendorIdFk");
    if (digitsArray?.[3]) {
      const parameter = {
        vendorIdFk: vendorIdFk,
        restaurantMobileOtp: resturantotp,
        url: verify_resturant_otp,
      };
      dispatch(verifyresturantOtp(parameter));
    }
  }, [resturantdigitsArray?.[3]]);

  useEffect(() => {
    if (mobileotpdata?.status) {
      setMobileotp(mobileotpdata?.otp);
      ToastMessage("success", mobileotpdata?.message);
    } else {
      ToastMessage("error", mobileotpdata?.message);
    }
  }, [mobileotpdata]);

  useEffect(() => {
    if (createresturantdata?.status) {
      ToastMessage("success", createresturantdata?.message);
    } else {
      ToastMessage("error", createresturantdata?.message);
    }
  }, [createresturantdata]);

  useEffect(() => {
    if (resturantotpdata?.status) {
      setResturantotp(resturantotpdata?.otp);
      ToastMessage("success", resturantotpdata?.message);
    } else {
      ToastMessage("error", resturantotpdata?.message);
    }
  }, [resturantotpdata]);

  useEffect(() => {
    if (resturantotpverifydata?.status) {
      ToastMessage("success", resturantotpverifydata?.message);
      setIsresturantnumberverified(true);
      values.isRestaurantMobileVerified = 1;
    } else {
      ToastMessage("error", resturantotpverifydata?.message);
    }
  }, [resturantotpverifydata]);

  useEffect(() => {
    if (ownerotpdata?.status) {
      ToastMessage("success", ownerotpdata?.message);
      setIsverified(true);
      values.isRestaurantOwnerMobileVerified = 1;
    } else {
      ToastMessage("error", ownerotpdata?.message);
    }
  }, [ownerotpdata]);

  const handlemobileverify = async () => {
    const vendorIdFk = localStorage.getItem("vendorIdFk");
    const parameter = {
      vendorIdFk: vendorIdFk,
      restaurantOwnerMobileNumber: mobilevalue,
      restaurantOwnerCountryCode: countrycode,
      url: get_mobile_otp,
    };
    dispatch(mobileotpaction(parameter));
  };

  const handleresturantverify = async () => {
    const vendorIdFk = localStorage.getItem("vendorIdFk");
    const parameter = {
      vendorIdFk: vendorIdFk,
      restaurantMobileNumber: resturantmobilevalue,
      restaurantCountryCode: resturantcountrycode,
      url: get_resturant_otp,
    };
    dispatch(resturentotpaction(parameter));
  };

  useEffect(() => {
    values.operationDay = JSON.stringify(getData());
  }, [getData()]);

  useEffect(() => {
    setFieldValue("cuisinesSpeacility", showselectedValues?.join(","));
    values.cuisinesSpeacility = showselectedValues?.join(",");
  }, [showselectedValues]);

  const handleformSubmit = (e) => {
    e.preventDefault();

    const fd = new FormData();
    fd.append("foodImage", values.foodImage);
    fd.append("restaurantLogo", values.restaurantLogo);
    handleSubmit(fd);
  };

  const submitForm = async (value) => {
    const parameter = {
      data: value,
      url: restaurant_registration,
    };
    if (logoimage) {
      dispatch(createresturant(parameter));
    }
  };

  const nestedSteps = [
    {
      mainLabel: "1. Create your resturant page",
      subSteps: [
        {
          label: "Restaurant Information",
          content: (
            <RestaurantInfo
              {...{
                digitsArray,
                resturantdigitsArray,
                errors,
                resturantotp,
                mobileotp,
                isverified,
                isresturantnumberverified,
                values,
                handleChange,
                touched,
                handleBlur,
                setFieldValue,
                setMobilevalue,
                setCountrycode,
                setResturantmobilevalue,
                handleresturantverify,
                setResturantcountrycode,
                handlemobileverify,
              }}
            />
          ),
        },
        {
          label: "Restaurant Type & Timings",
          content: (
            <Timing
              {...{
                radiovalue,
                showselectedValues,
                setShowselectedValues,
                setRadiovalue,
                errors,
                values,
                handleChange,
                touched,
                handleBlur,
                setFieldValue,
                selectedValues,
                setSelectedValues,
              }}
            />
          ),
        },
        {
          label: "Upload Images",
          content: (
            <ResturantImage
              {...{
                errors,
                values,
                handleChange,
                touched,
                handleBlur,
                setFieldValue,
                setLogoimage,
                setImage,
              }}
            />
          ),
        },
      ],
    },
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveMainStep(0);
  };

  return (
    <Container className="container_main_layout">
      <h2 className="restaurant_imnformation_text">Restaurant Information</h2>
      <Stepper
        className="main_layout_insider"
        activeStep={activeMainStep}
        orientation="vertical"
      >
        {nestedSteps.map((mainStep, index) => (
          <Step key={mainStep.mainLabel}>
            <StepLabel>{mainStep.mainLabel}</StepLabel>
            <StepContent>
              <NestedStepper
                steps={mainStep.subSteps}
                onClick={handleNext}
                onsubmit={handleformSubmit}
                values={values}
              />
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeMainStep === nestedSteps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you're finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}
      Upload images
    </Container>
  );
};

export default MultiStepForm;
