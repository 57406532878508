import React, { useState } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import 'react-dropdown/style.css';
import { Autocomplete, Switch, TextField } from '@mui/joy';
import './index.css';
import { CircularProgress, FormControlLabel } from "@mui/material";
import InputField from "../../../components/input";
import { useDispatch, useSelector } from "react-redux";
import LocalMallIcon from '@mui/icons-material/LocalMall';
import { alpha, styled } from '@mui/material/styles';
import { pink } from '@mui/material/colors';


const PinkSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: "#267887",
        '&:hover': {
            backgroundColor: "",
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: "#267887",
    },
}));



function Information({loading, packingval, setPackingval, handleformSubmit, values, handleChange, handleBlur, touched, errors }) {
    const options = ['one', 'two', 'three'];
    const [activebtn, setActivebtn] = useState(false);
    

    const dispatch = useDispatch();

    const [checked, setChecked] = React.useState(true);

    const handleswitchChange = (event) => {
        setChecked(event.target.checked);
        values.surpriseBagBuffetFoodSafety = event.target.checked ==true ? 1: 0
    };



    const handledropdownChange = (event, newValue) => {
        values.surpriseBagPackaging = newValue.label
        setPackingval(newValue)
        setActivebtn(true)
    }




    const packinglist = [
        { label: 'Food container and bag', id: 1 },
        { label: 'Food container and bag , customer may bring their own bag', id: 2 },
        { label: 'Food container, no bag', id: 3 },
    ]


    return (

        <div className='dashboardoverview_container_fluid'>
            <div className='dashboard_home_main_container'>
                <div className='d-flex'>
                    <h3 className='surprisebag_list_heading'>Instructions</h3>
                </div>

                <hr></hr>
                <div className='surprise_bag_list_container'>

                    <div className='overview_details_inside_contain'>

                        <div className='m-b-10'>
                            <h5 className='instructions_details_h'><LocalMallIcon sx={{ fontSize: "20px" }} /> Packaging *</h5>
                            <p className='instruction_details_p'>To ensure a smooth pickup, select the packaging type you will offer.</p>


                            <Autocomplete
                                className="autocomplete_instruction"
                                placeholder=""
                                name="surpriseBagPackaging"
                                options={packinglist}
                                sx={{}}
                                defaultValue={packingval}
                                onChange={(event, newValue) => handledropdownChange(event, newValue)}
                            />
                            {values.surpriseBagPackaging ? "" : 
                            <> {errors.surpriseBagPackaging &&
                                touched.surpriseBagPackaging ? (
                                <p className="form-error">
                                    {errors.surpriseBagPackaging}
                                </p>
                            ) : null}</>
                            }
                           

                        </div>


                        <div className='m-b-10 m-t-10'>
                            <h5 className='instructions_details_h'><LocalMallIcon sx={{ fontSize: "20px" }} /> Pick-up instructions *</h5>
                            <p className='instruction_details_p'>You can add additional instructions regarding pickup here, and they will be shown in the app.</p>



                            <InputField label="" placeholder="For example: 'The store is located inside the train station'"
                                name="surpriseBagPickupInstruction" value={values.surpriseBagPickupInstruction} type="text"
                                // onChange={(e) => handlevalueChange(e, "pickup")}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {errors.surpriseBagPickupInstruction &&
                                touched.surpriseBagPickupInstruction ? (
                                <p className="form-error">
                                    {errors.surpriseBagPickupInstruction}
                                </p>
                            ) : null}





                        </div>

                        <div className='m-b-10  m-t-10'>
                            <h5 className='instructions_details_h'><LocalMallIcon sx={{ fontSize: "20px" }} /> Buffet food safety</h5>
                            <p className='instruction_details_p'>Let us know if this Magic Bag contains food from a buffet. Users will see buffet-specific food safety recommendations in the app.</p>


                            <p className="instruction_value_switch">This Magic Bag contains food from a buffet

                                <FormControlLabel className="buffet_switch" control={
                                    <PinkSwitch onChange={handleswitchChange} checked={checked} inputProps={{ 'aria-label': 'controlled' }} />}
                                    label="" />

                            </p>


                        </div>

                        <div className='m-b-10 m-t-10'>
                            <h5 className='instructions_details_h'><LocalMallIcon sx={{ fontSize: "20px" }} /> Storing and allergens *</h5>
                            <p className='instruction_details_p'>You can add recommendations for storing and handling food, including warnings about allergens, here, and they will be shown in the app.</p>



                            <InputField label="" placeholder="For example: 'Store thr products refrigerated for a maximum of 2 days'" name="surpriseBagStoringAndAllergens" value={values.surpriseBagStoringAndAllergens} type="text"
                                // onChange={(e) => handlevalueChange(e, "storing")}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {errors.surpriseBagStoringAndAllergens &&
                                touched.surpriseBagStoringAndAllergens ? (
                                <p className="form-error">
                                    {errors.surpriseBagStoringAndAllergens}
                                </p>
                            ) : null}

                        </div>






                    </div>



                </div>




            </div>

            <ListGroup.Item>
                <div style={{ textAlign: "end", margin: "10px" }}>
                    <button
                        // disabled={!activebtn} 
                        type='button' disabled={loading} onClick={handleformSubmit} className="bttnnn1">{loading ? (
                            <CircularProgress size={18} style={{ color: "white" }} />
                          ) : (
                            "Submit"
                          )}</button>
                </div>
            </ListGroup.Item>
        </div>


    );
}

export default Information;
