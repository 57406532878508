import React from 'react';
import { Skeleton, Box, Card, CardContent, Typography } from '@mui/material';

const CardSkeleton = () => (
  <Card variant="outlined" sx={{ width: 300, m: 2, borderRadius: 2 }}>
    <Skeleton variant="rectangular" height={140} />
    <CardContent>
      <Box display="flex" alignItems="center" gap={1}>
        <Skeleton variant="circular" width={20} height={20} />
        <Skeleton variant="text" width="30%" />
      </Box>
      <Skeleton variant="text" width="60%" />
      <Box mt={1}>
        <Skeleton variant="rectangular" width="100%" height={40} />
      </Box>
    </CardContent>
  </Card>
);

const SkeletonGrid = () => (
  <Box display="flex" flexWrap="wrap" justifyContent="center">
    {[...Array(6)].map((_, index) => (
      <CardSkeleton key={index} />
    ))}
  </Box>
);

export default SkeletonGrid;
