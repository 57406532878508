import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import QuizIcon from '@mui/icons-material/Quiz';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';


export const mandotarySetup = [


  {
    id: 5,
    name: "Dashboard",
    path: "/dashboard/homepage",
    icon: <SpaceDashboardIcon sx={{ color: `var(--colorused)` }} />,
    data: [],
    icon1: "",
    icon2: "",
  }, 
  {
    id: 1,
    name: "Home",
    path: "/dashboard/app",
    icon: <HomeIcon sx={{ color: `var(--colorused)` }} />,
    data: [],
    icon1: "",
    icon2: "",
  }, 
  {
    id: 2,
    name: "Orders",
    path: "/dashboard/orderlist",
    icon: <BorderColorIcon sx={{ color: `var(--colorused)`}} />,
    data: [],
    icon1: "",
    icon2: "",
  },
  {
    id: 3,
    name: "Support",
    path: "/dashboard/createTicket",
    icon: <QuizIcon sx={{ color: `var(--colorused)` }} />,
    data: [],
    icon1: "",
    icon2: "",
  },
  {
    id: 4,
    name: "Settings",
    path: "/dashboard/settings",
    icon: <SettingsIcon sx={{ color: `var(--colorused)` }} />,
    data: [],
    icon1: "",
    icon2: "",
  },
  {
    id: 6,
    name: "Logout",
    // path: "/dashboard",
    icon: <ExitToAppIcon sx={{ color: `var(--colorused)` }} />,
    data: [],
    icon1: "",
    icon2: "",
  }, 
];


