// import React, { useEffect, useState } from 'react';
// import NotificationsIcon from "@mui/icons-material/Notifications";
// import {
//   Drawer,
//   Stack,
//   Badge,
//   Pagination,
//   CircularProgress,
// } from "@mui/material";
// import { useNavigate } from "react-router-dom";
// import { Button, Card, Modal } from "react-bootstrap";
// import CloseIcon from '@mui/icons-material/Close';
// import Notification from "./Notification";
// import { get_Notification_api } from '../../API/baseUrl';
// import { orderlistaction } from '../../redux/actions';
// import { useDispatch, useSelector } from 'react-redux';
// import ToastMessage from '../../utils/ToastMessage';

// const NabNotification = () => {
//     const dispatch = useDispatch();
//     const { notificationData } = useSelector((state) => state?.notificationReduser || {});
//     const [notification, setNotification] = useState([]);
//     const [isDrawerOpen, setIsDrawerOpen] = useState(false);
//     const [pending, setPending] = useState(true);
    

//   const toggleDrawer = (open) => () => {
//     setIsDrawerOpen(open);
//   };


//     // ***************** Notification API **************
//     const vendorIdFk = localStorage.getItem("vendorIdFk");

//     useEffect(() => {
//       setPending(true);
//       const parameter = {
//         type: 0,
  
//         offset: 0,
//         userType: "vendor",
//         limit: 0,
//         search: " ",
//         columnName: "",
//         sortBy: "ACS",
//         url: `${get_Notification_api}${vendorIdFk}`,
//       };
//       dispatch(orderlistaction(parameter));
//     }, []);
  
  
  
//     useEffect(() => {
//       if (notificationData) {
//         if (notificationData.status) {
//           setNotification(notificationData.data || []);
  
//         } else {
//           ToastMessage("error", notificationData.message);
//         }
//         setPending(false);
//       }
//     }, [notificationData]);
  
//     console.log(notification, "notificationnotification")
 
//   return (
//     <div>
      
          
// <div className="hidenotification" style={{ textAlign: "end", marginRight: "20px", width: "100%" }}>
//               <Stack
//                 spacing={2}
//                 direction="row"
//                 onClick={toggleDrawer(true)}
//                 style={{ textAlign: "end", float: "right", marginBottom: "20px", cursor: "pointer" }}
//               >
//                 {notification.length == 0 ? (
//                   <NotificationsIcon color="action" />
//                 ) : (
//                   <Badge badgeContent={notification.length} color="success">
//                     <NotificationsIcon color="action" />
//                   </Badge>
//                 )}
//               </Stack>
//               <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
             
//                 <Notification onClose={toggleDrawer(false)}/>
//               </Drawer>
//             </div>
//     </div>
//   );
// };

// export default NabNotification;
import React, { useEffect, useState, useRef } from "react";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Drawer, Stack, Badge, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Notification from "./Notification";
import { get_Notification_api } from "../../API/baseUrl";
import { notificationaction, orderlistaction } from "../../redux/actions";
import ToastMessage from "../../utils/ToastMessage";
import logo from "../../images/Logo-with-text.png"

const NabNotification = () => {
  const dispatch = useDispatch();
  const { notificationData } = useSelector((state) => state?.notificationReduser || {});
  
  const { notificationcountData } = useSelector((state) => state?.notificationcountReduser || {});
  const [notification, setNotification] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [countval, setCountval] = useState("");
  const [pending, setPending] = useState(true);
  const [prevNotificationCount, setPrevNotificationCount] = useState(0);
  // const audioRef = useRef(new Audio("src/images/notification.mp3")); // path to your sound file
  const audioRef = useRef(new Audio("../../images/notification.mp3")); 

  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };

  // Notification API call
  const vendorIdFk = localStorage.getItem("vendorIdFk");

  useEffect(() => {
    setPending(true);
    const parameter = {
      type: 0,
      offset: 0,
      userType: "vendor",
      limit: 0,
      search: " ",
      columnName: "",
      sortBy: "ACS",
      url: `${get_Notification_api}${vendorIdFk}`,
    };
    dispatch(notificationaction(parameter));
  }, []);

  useEffect(() => {
    if (notificationData) {
      if (notificationData.status) {
        const newNotificationCount = notificationData.data?.length || 0;
        if (newNotificationCount > prevNotificationCount) {
          // Play sound only when notification count increases
          audioRef.current.play().catch((error) => console.log("Audio play error:", error));
        }
        setPrevNotificationCount(newNotificationCount); // Update previous notification count
        setNotification(notificationData.data || []);
      } else {
        ToastMessage("error", notificationData.message);
      }
      setPending(false);
    }
  }, [notificationData]); // Trigger effect when orderListData changes


  


  useEffect(() => {
    if (notificationcountData) {
      if (notificationcountData.status) {
        setCountval(notificationcountData?.Count);
      } else {
        ToastMessage("error", notificationcountData.message);
      }
    }
  }, [notificationcountData]);


  return (
    <div>
      <div className="hidenotification" style={{ textAlign: "end", marginRight: "20px", width: "100%" }}>
        {/* <div className="logo_text_img_not">  <img src={logo} height={35}/></div> */}

        <Stack
          spacing={2}
          direction="row"
          onClick={toggleDrawer(true)}
          style={{
            textAlign: "end",
            float: "right",
            marginBottom: "20px",
            cursor: "pointer",
          }}
        >
          {!countval || countval == 0 ? (
            <NotificationsIcon color="action" />
          ) : (
            <Badge badgeContent={countval} color="success">
              <NotificationsIcon color="action" />
            </Badge>
          )}
        </Stack>
        <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}

        >
          <Notification onClose={toggleDrawer(false)} />
        </Drawer>
      </div>
    </div>
  );
};

export default NabNotification;
