import React, { useEffect, useState } from 'react'
import "./index.scss";
import "./index.css";
import data from './data';
import FAQItem from './faqitems';
import { getfaqAction } from '../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { faqList_api, faqList_api_vender } from '../../../API/baseUrl';
import ToastMessage from '../../../utils/ToastMessage';

const Help = ({restro}) => {

  const { getfaqData } = useSelector((state) => state?.getfaqReduser || {});
  const dispatch = useDispatch();

  const [faqdata, setFaqdata] = useState([]);

  useEffect(() => {
    if (getfaqData?.status) {
      setFaqdata(getfaqData.data)
    } else {
      ToastMessage("error", getfaqData?.message);
    }
  }, [getfaqData]);

    


  useEffect(() => {
    const parameter = {
      offset: "",
      limit: "",
      type: "Magic Bag",
      search: "",
      columnName: "",
      sortBy: "",
      url: faqList_api_vender,
    };
    dispatch(getfaqAction(parameter));
  }, []);

  

  const handelClick = (id) => {
    const details = document.getElementsByTagName("details");
    for (let i = 0; i < details.length; i++) {
      let element = details[i];
      let Elementid = Number(element.getAttribute("data-id"));

      if (Elementid !== id && element.hasAttribute("open")) {
        element.removeAttribute("open");
      }
    }
  };

  return (
    <>
    <div className='faq_wrapper_container_fluid '>
       <div className="faq_container_second_block">
          <h3>Similar Questions/Answers</h3>
             <div className="">
                 {faqdata?.map((item, index) => (
                    <FAQItem
                      key={index}
                      id={index}
                      handelClick={handelClick}
                      question={item.question}
                      answer={item.answer}
                    />
                  ))}
              </div>

      </div>
    </div>
   
  </>
  )
}

export default Help;
