import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { FaRegCalendarAlt } from "react-icons/fa";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import "./index.css";
import SpecialDayModal from "./modal/modal2";

function Calender({ values, setCalenderValue }) {
  const [specialDayModalOpen, setSpecialDayModalOpen] = useState(false);

  const today = new Date();

  // Convert values.surpriseBagEndDate from "DD-MM-YYYY" to a Date object
  const [day, month, year] = values.surpriseBagEndDate.split("-");
  const endDate = new Date(year, month - 1, day);

  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December",
  ];

  const daysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  const [currentDate, setCurrentDate] = useState(new Date());

  const handlePrevMonth = () => {
    setCurrentDate((prevDate) => new Date(prevDate.getFullYear(), prevDate.getMonth() - 1));
  };

  const handleNextMonth = () => {
    setCurrentDate((prevDate) => new Date(prevDate.getFullYear(), prevDate.getMonth() + 1));
  };

  const getDaysInMonth = (year, month) => {
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    const daysInMonth = lastDay.getDate();
    const startDay = firstDay.getDay();
    const endDay = lastDay.getDay();
    const days = [];

    for (let i = 0; i < startDay; i++) days.push(null);
    for (let i = 1; i <= daysInMonth; i++) days.push(new Date(year, month, i));
    for (let i = endDay + 1; i < 7; i++) days.push(null);

    return days;
  };

  const days = getDaysInMonth(currentDate.getFullYear(), currentDate.getMonth());

  const handledateClick = (day) => {
    if (day >= today.setHours(0, 0, 0, 0) && day <= endDate) {
      setSpecialDayModalOpen(true);
    }
  };

  return (
    <div className="calender_container">
      <Container className="calender_container_fluid">
        <h3>Calendar</h3>
        <p>
          The Calendar gives you an overview of the days customers can come by
          your store and pick up a Magic Bag. The days shown in the Calendar
          match your weekly schedule, but if there are temporary changes to your
          schedule, you can add these as Special Days here.
        </p>
        <div
          style={{ backgroundColor: "#d7f1ff", maxWidth: "90%" }}
          className="mx-3"
        >
          <FaRegCalendarAlt />
          Click on a date to see details about the day or to make changes.
        </div>
        <div>
          <div className="calendar">
            <div className="month-controls">
              <button
                onClick={handlePrevMonth}
                style={{
                  border: "none",
                  color: currentDate.getMonth() === today.getMonth() && currentDate.getFullYear() === today.getFullYear() ? 'grey' : 'black',
                  cursor: currentDate.getMonth() === today.getMonth() && currentDate.getFullYear() === today.getFullYear() ? 'not-allowed' : 'pointer'
                }}
                disabled={currentDate.getMonth() === today.getMonth() && currentDate.getFullYear() === today.getFullYear()}
              >
                <IoIosArrowBack />
              </button>
              <h2>
                {monthNames[currentDate.getMonth()]} {currentDate.getFullYear()}
              </h2>
              <button onClick={handleNextMonth} style={{ border: "none" }}>
                <IoIosArrowForward />
              </button>
            </div>
            <div className="days-of-week">
              {daysOfWeek.map((day) => (
                <div key={day} className="day-of-week">{day}</div>
              ))}
            </div>
            <div className="days">
              {days.map((day, index) => (
                <div key={index} className={`day ${day ? "" : "empty"}`}>
                  {day && (
                    <button
                      onClick={() => handledateClick(day)}
                      className="day-button"
                      disabled={day < today.setHours(0, 0, 0, 0) || day > endDate}
                      style={
                        day < today.setHours(0, 0, 0, 0) || day > endDate
                          ? { color: 'grey', cursor: 'not-allowed' }
                          : {}
                      }
                    >
                      {day.getDate()}
                    </button>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>

      {specialDayModalOpen && (
        <SpecialDayModal
          specialDayModalOpen={specialDayModalOpen}
          handleSpecialDayClose={() => setSpecialDayModalOpen(false)}
          values={values}
          setCalenderValue={setCalenderValue}
          validdate={values.surpriseBagEndDate}
        />
      )}
    </div>
  );
}

export default Calender;
