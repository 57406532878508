import React, { useState } from 'react'
import { Autocomplete } from '@mui/joy';
import "./index.css"
import Help from './faq';


const DietrySection = ({dietvalue,setContinueactive, setDietvalue, values, handleChange, handleBlur }) => {
    const dietryArray = [
        { label: 'Non specified', id: 1 },
        { label: 'Vegan', id: 2 },
        { label: 'Vegetarian', id: 3 },
        { label: 'Non-Vegetarian', id: 4 },
      ]
    
    
      const handledietryChange = (event, newValue) => {
        values.dietaryType = newValue.label
        setDietvalue(newValue)
        setContinueactive(true)
      }


    return (
        <div className='name_description_container'>
            <div>
                <p>Dietry Type</p>
                <Autocomplete
                    placeholder="Please select dietry type"
                    options={dietryArray}
                    sx={{}}
                    defaultValue={dietvalue}
                    // value={dietvalue ? dietvalue : ""}
                    label="Dietry Type"
                    onChange={(event, newValue) => handledietryChange(event, newValue)}
                />
            </div>
            <Help restro = 'true'/>
        </div>
    )
}

export default DietrySection;