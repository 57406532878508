const data = [
  {
    question: "What is a 5 Bag ?",
    answer: "On the Pick’n’Treat app, customers purchase a product called a Magic Bag from your store.Since it's difficult for most stores to anticipate what will remain at the end of the day, weintentionally refrain from detailing the specific contents of the bags. Therefore, the contents willdiffer each day, ensuring customers are always surprised by what they receive."
  },
  {
    question: "Your earnings and payouts",
    answer:
      "For each Standard Magic Bag sold through our app, we deduct a nominal fee of 55 INR, and the remainder is yours to keep. Once your earnings reach the minimum payout threshold, wetransfer the funds to your bank account on a monthly basis."
  },
  {
    question: "How do we sell the surplus food on the app ?",
    answer:
      "Once your account is established, your store will appear to customers within our app. On the designated days, a predetermined quantity of Magic Bags will be made available for purchase automatically. During the final 30 minutes of your store's operating hours, or at a time specified by you, customers who have reserved a Magic Bag will arrive to collect it. Payment is seamlessly processed through the app. Simply verify the receipt displayed on each customer'sphone and provide them with their Magic Bag."
  },
  {
    question: "What does Pick’n’Treat Cost ?",
    answer: "When you sell your Surplus food on our platform, we charge a small fee of 55 INR for each sale you complete, along with an annual service fee of 1999 INR. These fees are taken directly from your earnings, so you won't receive separate bills from us. You're free to cancel your account anytime without any obligations or additional charges."
  },
  
];
export default data;

