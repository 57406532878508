import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Divider from '@mui/material/Divider';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import "./index.css"
import { useNavigate } from 'react-router-dom';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    minWidth: '300px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export default function SurpriseBagModal({ open,idfordetails, handleClose }) {
    const navigate = useNavigate()


    const handleNavigate = (name, index) =>{
        navigate(`/dashboard/store/${idfordetails}/${index}/${name}`);
    }


    return (
        <div>
            <Modal
                className='sur_bag_modal_container'
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className='modal_upper_part'>
                        <h3>Magic Bag</h3>
                        <CloseIcon onClick={handleClose} />
                    </div>

                    <Divider/>

                    <div>
                        <div className='modal_upper_part' onClick={()=>handleNavigate("Overview", 0)} >
                            <p className='modal_text'><HomeOutlinedIcon sx={{color:"#5e6064"}} />  Overview</p>
                            <ArrowForwardIosIcon sx={{fontSize:"16px" , color:"#5e6064"}} />
                        </div>
                        <Divider/>
                        <div className='modal_upper_part' onClick={()=>handleNavigate("Calendar", 1)} >
                            <p  className='modal_text'><CalendarMonthOutlinedIcon sx={{color:"#5e6064"}} /> Calendar</p>
                            <ArrowForwardIosIcon sx={{fontSize:"16px",color:"#5e6064"}} />
                        </div>
                        <Divider/>
                        <div className='modal_upper_part' onClick={()=>handleNavigate("Schedule",2)} >
                            <p  className='modal_text'><HourglassEmptyOutlinedIcon sx={{color:"#5e6064"}} /> Schedule</p>
                            <ArrowForwardIosIcon sx={{fontSize:"16px",color:"#5e6064"}} />
                        </div>
                        <Divider/>
                        <div className='modal_upper_part'onClick={()=>handleNavigate("Setting",3)} >
                            <p className='modal_text'><SettingsOutlinedIcon sx={{color:"#5e6064"}} /> Setting</p>
                            <ArrowForwardIosIcon sx={{fontSize:"16px",color:"#5e6064"}} />

                        </div>
                    </div>

                </Box>
            </Modal>
        </div>
    );
}