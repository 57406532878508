import { Box, Grid } from "@material-ui/core";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./index.css";
import Switch from "@mui/material/Switch";
import TimePickercomponent from "../../resturantType/timepicker";
import { alpha, styled } from "@mui/material/styles";
import { pink } from "@mui/material/colors";
import { Padding } from "@mui/icons-material";
import dayjs from "dayjs";

const ScheduleOperations = ({
  values,
  handleCheckboxChange,
  handleNewTimeSet,
  errors,
  handleinputChange,validation, setvalidation
}) => {
  const params = useParams();
  const currentTime = new Date();
  const hours = currentTime.getHours().toString().padStart(2, "0");
  const minutes = currentTime.getMinutes().toString().padStart(2, "0");
  const formattedTime = `${hours}:${minutes}`;

  const [renderUI, setRenderUI] = useState(1);


  const [daysArray] = useState([
    {
      name: "Monday",
      dailyLimit: "mondayLimit",
      formikKeyCheckbox: "mondayProperty",
      formikKeyStartTime: "mondayStart",
      formikKeyEndTime: "mondayEnd",
    },
    {
      name: "Tuesday",
      dailyLimit: "tuesdayLimit",
      formikKeyCheckbox: "tuesdayProperty",
      formikKeyStartTime: "tuesdayStart",
      formikKeyEndTime: "tuesdayEnd",
    },
    {
      name: "Wednesday",
      dailyLimit: "wednesdayLimit",
      formikKeyCheckbox: "wednesdayProperty",
      formikKeyStartTime: "wednesdayStart",
      formikKeyEndTime: "wednesdayEnd",
    },
    {
      name: "Thursday",
      dailyLimit: "thursdayLimit",
      formikKeyCheckbox: "thursdayProperty",
      formikKeyStartTime: "thursdayStart",
      formikKeyEndTime: "thursdayEnd",
    },
    {
      name: "Friday",
      dailyLimit: "fridayLimit",
      formikKeyCheckbox: "fridayProperty",
      formikKeyStartTime: "fridayStart",
      formikKeyEndTime: "fridayEnd",
    },
    {
      name: "Saturday",
      dailyLimit: "saturdayLimit",
      formikKeyCheckbox: "saturdayProperty",
      formikKeyStartTime: "saturdayStart",
      formikKeyEndTime: "saturdayEnd",
    },
    {
      name: "Sunday",
      dailyLimit: "sundayLimit",
      formikKeyCheckbox: "sundayProperty",
      formikKeyStartTime: "sundayStart",
      formikKeyEndTime: "sundayEnd",
    },
  ]);


  const handleInputChangeForAllDays = (key, e) => {
    handleinputChange(key, e);
    // if (key === "mondayLimit") {
    //   daysArray.forEach((day) => {
    //     if (day.dailyLimit !== "mondayLimit") {
    //       handleinputChange(day.dailyLimit, e);
    //     }
    //   });
    // }
  };

  // useEffect(() => {
  //   daysArray.forEach((day) => {
  //     if (day.formikKeyCheckbox == "0") {
  //       handleinputChange(day.dailyLimit, e);
  //     }
  //   });
  // }, [values]);

  const ValidationMessagealert = (showAlert) => {
    if(showAlert == true){
      setvalidation(true)
    }else{
      setvalidation(false)
    }
  };
  
  const getValidationMessage = (checkbox, startTime, endTime, limit) => {
    let validationMessage = "";
  
    if (values[checkbox] === "1") {
      // Check if required fields are missing
      if (!values[startTime] || !values[endTime] || !values[limit]) {
        validationMessage = "Please fill all details";
        errors[checkbox] = validationMessage;
        ValidationMessagealert(true);  // Show alert with true
      } else if (values[startTime] >= values[endTime]) {
        // Additional check: startTime should not be greater than or equal to endTime
        validationMessage = "Start time should be less than end time";
        errors[checkbox] = validationMessage;
        ValidationMessagealert(true);  // Show alert with true
      } else {
        // No errors; clear any existing error for the checkbox
        delete errors[checkbox];
        ValidationMessagealert(false);  // Clear alert with false
      }
    } else {
      delete errors[checkbox];
    }
  
    return validationMessage;
  };
  

  const PinkSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#267887",
      "&:hover": {
        backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#267887",
    },
  }));


  const getMinTime = (item) => {
    return values[item.formikKeyStartTime]
      ? dayjs(values[item.formikKeyStartTime], 'HH:mm').add(1, 'minute')
      : null;
  };
  const getMaxTime = (item) => {
    return values[item.formikKeyEndTime] || null;
  };
  const timePickerUI = () => {
    return daysArray?.map((item) => {
      return (
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{ display: "flex" }}
          className=""
          key={item.name}
        >
          <div className="operation_field_container shedule_grid">
           
            <h3 className="schedule_lable">{item.name}</h3>

            <FormControlLabel
              className="form_control_lable"
              control={
                <PinkSwitch
                  defaultChecked={item.formikKeyCheckbox}
                  checked={values[item.formikKeyCheckbox] == "1"}
                  onChange={(e) =>
                    handleCheckboxChange(item.formikKeyCheckbox, e)
                  }
                />
              }
              label={
                values[item.formikKeyCheckbox] == "1" ? (
                  <p className="pick_up">Pick up</p>
                ) : (
                  <p className="no_pick_up">No pickup</p>
                )
              }
            />
            <div className="schedule_limit_container">
             
              <input
              disabled={values[item.formikKeyCheckbox] == "0"}
                type="number"
                min="1"
                value={values[item.formikKeyCheckbox] == "0" ? "" : values[item.dailyLimit]}
                onChange={(e) => {
                  const value = e.target.value;

                  if (value === "0" || value < 1) {
                    handleInputChangeForAllDays(item.dailyLimit, { target: { value: '1' } });
                  } else {

                    handleInputChangeForAllDays(item.dailyLimit, e);
                  }
                }}
              />
              <lable>Daily limit: 5</lable>
            </div>

            <div className="d-flex g-5">
              <TimePickercomponent
              disabled={values[item.formikKeyCheckbox] == "0"}
                value={values[item.formikKeyStartTime]}
                label=""
                onChange={(newValue) =>
                  handleNewTimeSet(newValue, item.formikKeyStartTime)
                }
                ampm={false}
                maxTime={getMaxTime(item)}
              />

              <TimePickercomponent
              disabled={values[item.formikKeyCheckbox] == "0"}
                value={values[item.formikKeyEndTime]}
                label=""
                onChange={(newValue) =>
                  handleNewTimeSet(newValue, item.formikKeyEndTime)
                }
                ampm={false}
                minTime={getMinTime(item)}
              />
            </div>
          </div>
          <p style={{ color: "red", marginLeft: "15px" }}>
            {getValidationMessage(
              item.formikKeyCheckbox,
              item.formikKeyStartTime,
              item.formikKeyEndTime,
              item.dailyLimit
            )}
          </p>

          <hr></hr>
        </Grid>
      );
    });
  };
  return (
    <div style={{ marginLeft: "5px", marginTop: "20px" }}>
      <div>
        <Grid container spacing={0} mt="10px" sx={{ display: "flex" }}>
          {timePickerUI()}
        </Grid>
      </div>
    </div>
  );
};

export default ScheduleOperations;
