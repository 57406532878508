import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { FaRegCalendarAlt } from "react-icons/fa";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import "./index.css";
import SpecialDayModal from "./modal/modal2";
import { getSpecialday_api } from "../../API/baseUrl";
import { getSpecialDayData } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";

function Calender({ id, shobtn }) {
  const [open, setOpen] = React.useState(false);
  const [specialDayModalOpen, setSpecialDayModalOpen] = useState(false);
  const [firstDay, setFirstDay] = useState("");
  const [specialDaysRange, setSpecialDaysRange] = useState({ start: null, end: null });
  const [specialDays, setSpecialDays] = useState([]);
  const [dates, setDates] = useState(null);
  const [validdate, setValiddate] = useState(null);

  const dispatch = useDispatch();
  const { getSpecialdaysData } = useSelector((state) => state?.getSpecialdaysDataReduser || {});
  
  const handleClose = () => setOpen(false);

  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December",
  ];

  const daysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

  const [currentDate, setCurrentDate] = useState(new Date());

  const handlePrevMonth = () => {
    setCurrentDate((prevDate) => {
      const prevMonthDate = new Date(
        prevDate.getFullYear(),
        prevDate.getMonth() - 1
      );
      return prevMonthDate;
    });
  };

  const handleNextMonth = () => {
    setCurrentDate((prevDate) => {
      const nextMonthDate = new Date(
        prevDate.getFullYear(),
        prevDate.getMonth() + 1
      );
      return nextMonthDate;
    });
  };

  useEffect(() => {
    const parameter = {
      url: `${getSpecialday_api}${id}`,
    };
    dispatch(getSpecialDayData(parameter));
  }, [dispatch, id]);

  useEffect(() => {
    if (getSpecialdaysData && getSpecialdaysData.data) {
      const { first_day, last_day } = getSpecialdaysData.data;

      setValiddate(getSpecialdaysData?.magicBagEndDate)

      if (first_day && last_day) {
        const formatDate = (dateStr) => {
          const parts = dateStr.split('/');
          if (parts.length === 3) {
            return new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
          }
          return null;
        };

        const start = formatDate(first_day);
        setFirstDay(formatDate(first_day));
        const end = formatDate(last_day);

        if (start && end) {
          setSpecialDaysRange({ start, end });
        } else {
          console.error('Invalid date format:', { first_day, last_day });
        }
      }

      const datesArray = getSpecialdaysData.data.map(item => {
        return new Date(item.date.split('/').reverse().join('-'));
      });
      setSpecialDays(datesArray);
    }
  }, [getSpecialdaysData]);

  const getDaysInMonth = (year, month) => {
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    const daysInMonth = lastDay.getDate();
    const startDay = firstDay.getDay();
    const endDay = lastDay.getDay();
    const days = [];

    for (let i = 0; i < startDay; i++) {
      days.push(null);
    }

    for (let i = 1; i <= daysInMonth; i++) {
      days.push(new Date(year, month, i));
    }

    for (let i = endDay + 1; i < 7; i++) {
      days.push(null);
    }

    return days;
  };

  const days = getDaysInMonth(
    currentDate.getFullYear(),
    currentDate.getMonth()
  );

  const isDateInRange = (date) => {
    if (!specialDaysRange.start || !specialDaysRange.end) return false;

    const start = new Date(specialDaysRange.start).setHours(0, 0, 0, 0);
    const end = new Date(specialDaysRange.end).setHours(0, 0, 0, 0);
    const current = date.setHours(0, 0, 0, 0);

    return current >= start && current <= end;
  };

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handledateClick = (day) => {
    if (day >= new Date().setHours(0, 0, 0, 0)) {
      setDates(formatDate(day));
      setSpecialDayModalOpen(true);
    }
  };

  const today = new Date();

  const isSpecialDay = (date) => {
    return specialDays.filter(specialDay => 
      specialDay.setHours(0, 0, 0, 0) === date.setHours(0, 0, 0, 0)
    ).length > 0;
  };

  const isValidDate = (date) => {
    if (!validdate) return true;
    const validDate = new Date(validdate.split('/').reverse().join('-'));
    return date <= validDate;
  };

  return (
    <div className="calender_container">
      <Container className="calender_container_fluid">
        <h3>Calendar</h3>
        <p>
          The Calendar gives you an overview of the days customers can come by
          your store and pick up a Magic Bag. The days shown in the Calendar
          match your weekly schedule, but if there are temporary changes to your
          schedule, you can add these as Special Days here.
        </p>
        <div
          style={{ backgroundColor: "#d7f1ff", maxWidth: "90%" }}
          className="mx-3"
        >
          <FaRegCalendarAlt />
          Click on a date to see details about the day or to make changes.
        </div>
        <div>
          <div className="calendar">
            <div className="month-controls">
              <button
                onClick={handlePrevMonth}
                style={{
                  border: "none",
                  color: currentDate.getMonth() === today.getMonth() && currentDate.getFullYear() === today.getFullYear() ? 'grey' : 'black',
                  cursor: currentDate.getMonth() === today.getMonth() && currentDate.getFullYear() === today.getFullYear() ? 'not-allowed' : 'pointer'
                }}
                disabled={currentDate.getMonth() === today.getMonth() && currentDate.getFullYear() === today.getFullYear()}
              >
                <IoIosArrowBack />
              </button>
              <h2>
                {monthNames[currentDate.getMonth()]} {currentDate.getFullYear()}
              </h2>
              <button onClick={handleNextMonth} style={{ border: "none" }}>
                <IoIosArrowForward />
              </button>
            </div>
            <div className="days-of-week">
              {daysOfWeek.map((day) => (
                <div key={day} className="day-of-week">
                  {day}
                </div>
              ))}
            </div>
            <div className="days">
              {days.map((day, index) => (
                <div key={index} className={`day ${day ? "" : "empty"}`}>
                  {day && (
                    <button
                      onClick={() => handledateClick(day)}
                      className="day-button"
                      disabled={day < new Date().setHours(0, 0, 0, 0) || !isValidDate(day)}
                      style={day < new Date().setHours(0, 0, 0, 0) || !isValidDate(day) ? { color: 'grey', cursor: 'not-allowed' } : {}}
                    >
                      {day.getDate()}
                      {isSpecialDay(day) ? <span className="red-dot"></span> : ""}
                    </button>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>

      {specialDayModalOpen && (
        <SpecialDayModal
          specialDayModalOpen={specialDayModalOpen}
          handleSpecialDayClose={() => setSpecialDayModalOpen(false)}
          selectedDate={dates}
          dates={dates}
          shobtn={shobtn}
          id={id}
          validdate={validdate}
        />
      )}
    </div>
  );
}

export default Calender;
