
import React, { useRef, useState, useEffect } from "react";
import { GoogleMap, useLoadScript, Marker, Autocomplete } from "@react-google-maps/api";

const libraries = ["places"];
const mapContainerStyle = {
  width: "100%",
  height: "50vh",
};

const center = {
  lat: 20.5937, 
  lng: 78.9629, 
};

const bounds = {
  north: 37.0902, 
  south: 8.0883,  
  east: 97.4026,  
  west: 68.1766,  
};

const MapCom = ({ lat, setLat, values, setFieldValue, selected, setSelected, addressValue, setAddressValue }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDxquUakcHcW4LsUwM32K0e8oWE9gzJBeM",
    libraries,
  });

  const [lng, setLng] = useState(null);
  const mapRef = useRef();
  const autocompleteRef = useRef();
  const inputRef = useRef();

  const onMapLoad = (map) => {
    mapRef.current = map;
  };

  const onPlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    if (place.geometry) {
      setFieldValue("restaurantAddress", place?.formatted_address);
      setAddressValue(place?.formatted_address);
      
      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();
      
      setSelected({ lat: latitude, lng: longitude });
      setLat(latitude);
      setLng(longitude);
      setFieldValue("restaurantAddressLatitude", latitude);
      setFieldValue("restaurantAddressLongitude", longitude);
      values.restaurantAddressLatitude = latitude;
      values.restaurantAddressLongitude = longitude;

      mapRef.current.panTo({ lat: latitude, lng: longitude });
      mapRef.current.setZoom(15);
      console.log("Selected location:", { latitude, longitude });
    }
  };

  const handleInputChange = (e) => {
    setAddressValue(e.target.value);
  };

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  return (
    <div className="mapContainer container">
      <p className="image_lable">Restaurant Address *</p>
      <Autocomplete
        onLoad={(autocomplete) => {
          autocompleteRef.current = autocomplete;
        }}
        onPlaceChanged={onPlaceChanged}
        options={{
          componentRestrictions: { country: "in" },
          strictBounds: true, 
        }}
      >
        <input
          id="autocomplete"
          name="address"
          label="Restaurant Address"
          placeholder="Enter your restaurant's locality, e.g., Sector 43, Indore..."
          type="text"
          value={addressValue}
          className="address_searchbox"
          onChange={handleInputChange}
          ref={inputRef} 
        />
      </Autocomplete>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={5} 
        center={selected || center} 
        onLoad={onMapLoad}
        bounds={bounds} 
      >
        {selected && <Marker position={selected} />} 
      </GoogleMap>
      
    </div>
  );
};

export default MapCom;
