// import React, { useEffect, useState } from "react";
// import Modal from "@mui/material/Modal";
// import "react-datepicker/dist/react-datepicker.css";
// import "./index.css";
// import { TextField, Switch, Button, Box, Typography, FormControlLabel, CircularProgress } from '@mui/material';
// import { LocalizationProvider, DatePicker, TimePicker } from '@mui/x-date-pickers';
// import { useDispatch, useSelector } from "react-redux";
// import ToastMessage from "../../../utils/ToastMessage";
// import dayjs from 'dayjs';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { getSpecialDayData, removeDataFromReducer, updatecalender } from "../../../redux/actions";
// import { getSpecialday_api, update_calender_api } from "../../../API/baseUrl";
// import { alpha, styled } from '@mui/material/styles';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { pink } from '@mui/material/colors';
// import ReactDatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import moment from "moment";
// import { useFormik } from "formik";
// import { calenderSchemaRequired, calenderUpdateSchema } from "../../../utils/schema";
// import CloseIcon from '@mui/icons-material/Close';

// const style = {
//     position: "absolute",
//     top: "50%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     width: 600,
//     maxWidth: '95%',
//     bgcolor: "background.paper",
//     boxShadow: 24,
//     borderRadius: "6px",
//     p: 4,
// };

// const PinkSwitch = styled(Switch)(({ theme }) => ({
//     '& .MuiSwitch-switchBase.Mui-checked': {
//         color: "#267887",
//         '&:hover': {
//             backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
//         },
//     },
//     '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
//         backgroundColor: "#267887",
//     },
// }));

// export default function SpecialDayModal({ specialDayModalOpen, handleSpecialDayClose, id, shobtn }) {
//     const dispatch = useDispatch();
//     const { updatecalenderData } = useSelector((state) => state?.updatecalenderReduser || {});
//     const { getSpecialdaysData } = useSelector((state) => state?.getSpecialdaysDataReduser || {});
//     const [firstDayval, setFirstDayval] = useState(null);
//     const [lastDayval, setLastDayval] = useState(null);
//     const [storeOpen, setStoreOpen] = useState(true);
//     const [loading, setLoading] = useState(false);
//     const [minEndTime, setMinEndTime] = useState(null);
//     const [maxStartTime, setMaxStartTime] = useState(null);
//     const [startTime, setStartTime] = useState(null);
//     const [endTime, setEndTime] = useState(null);
//     const [minLastDay, setMinLastDay] = useState(null);
//     const [maxFirstDay, setMaxFirstDay] = useState(null);

//     const initialValues = {
//         surpriseBagIdFk: id,
//         firstDay: new Date(),
//         lastDay: new Date(),
//         isPickUp: "",
//         startTime: "",
//         endTime: "",
//         dailyNumberOfSurpriseBag: "",
//     }

//     const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } =
//         useFormik({
//             initialValues,
//             validationSchema: storeOpen === true ? calenderSchemaRequired : calenderUpdateSchema,
//             onSubmit: () => submitForm(),
//         });

//     const handleFormSubmit = (event) => {
//         event.preventDefault();
//         handleSubmit()
//         console.log("errors", errors)

//     };

//     const submitForm = async () => {
//         setLoading(true)
//         const parameter = {
//             surpriseBagIdFk: id,
//             firstDay: values.firstDay,
//             lastDay: values.lastDay,
//             isPickUp: storeOpen === true ? 1 : 0,
//             startTime: values.startTime,
//             endTime: values.endTime,
//             dailyNumberOfSurpriseBag: values.dailyNumberOfSurpriseBag,
//             url: update_calender_api,
//         };
//         dispatch(updatecalender(parameter));
//     }

//     const parseTimeString = (timeString) => {
//         if (timeString) {
//             const [hours, minutes] = timeString?.split(':');

//             const date = new Date();
//             date?.setHours(parseInt(hours, 10));
//             date?.setMinutes(parseInt(minutes, 10));
//             date?.setSeconds(0);
//             date?.setMilliseconds(0);
//             console.log("Date object:", date);
//             return date;
//         }
//         return null;
//     };

//     useEffect(() => {
//         if (getSpecialdaysData && getSpecialdaysData.data) {
//           setFieldValue("firstDay" , getSpecialdaysData.data.first_day)
//           setFieldValue("lastDay" , getSpecialdaysData.data.last_day )
//           setFieldValue("dailyNumberOfSurpriseBag" , getSpecialdaysData.data.daily_number_of_surprise_bag )
//           setFieldValue("startTime" , getSpecialdaysData.data.start_time)
//           setFieldValue("endTime" , getSpecialdaysData.data.end_time)
//           setStoreOpen(getSpecialdaysData.data.is_pickup == 1 ? true : false)
//         //   setStartTime(parseTimeString(getSpecialdaysData?.data?.start_time));
//         //   setMinEndTime(parseTimeString(getSpecialdaysData?.data?.start_time));
//         //   setEndTime(parseTimeString(getSpecialdaysData?.data?.end_time));
//         //   setMaxStartTime(parseTimeString(getSpecialdaysData?.data?.end_time));

//         }
//       }, [getSpecialdaysData]);

//     useEffect(() => {
//         if (updatecalenderData?.status) {
//             setLoading(false)
//             ToastMessage('success', updatecalenderData?.message);
//             dispatch(removeDataFromReducer("UPDATE_CALENDER_RESET"));
//             handleSpecialDayClose()
//         } else {
//             setLoading(false)
//             ToastMessage('error', updatecalenderData?.message);
//         }
//     }, [updatecalenderData]);

//     const handleTimeChange = (newValue, type) => {
//         console.log("newValue",newValue)
//         if (newValue) {
//             const formattedTime = dayjs(newValue).format('HH:mm');
//             sendTimeToAPI(formattedTime, type);

//             if (type === "start") {
//                 setStartTime(newValue);
//                 setMinEndTime(newValue);
//             } else {
//                 setEndTime(newValue);
//                 setMaxStartTime(newValue);
//             }
//         }
//     };

//     const sendTimeToAPI = (formattedTime, type) => {
//         if (type === "start") {
//             values.startTime = formattedTime;
//             setFieldValue("startTime", formattedTime);
//         } else {
//             values.endTime = formattedTime;
//             setFieldValue("endTime", formattedTime);
//         }
//     };

//     // const handleDateChange = (date) => {
//     //     setFirstDayval(date);
//     //     values.firstDay = dayjs(date).format('DD/MM/YYYY')
//     // };

//     // const handlelastDateChange = (date) => {
//     //     setLastDayval(date);
//     //     values.lastDay = dayjs(date).format('DD/MM/YYYY')
//     // };

//     const handleDateChange = (date) => {
//         setFirstDayval(date);
//         values.firstDay = dayjs(date).format('DD/MM/YYYY');
//         setFieldValue("firstDay", dayjs(date).format('DD/MM/YYYY'));
//         setMinLastDay(date); // Set minimum date for last day based on the selected first day
//     };

//     const handlelastDateChange = (date) => {
//         setLastDayval(date);
//         values.lastDay = dayjs(date).format('DD/MM/YYYY');
//         setFieldValue("lastDay", dayjs(date).format('DD/MM/YYYY'));
//         setMaxFirstDay(date); // Set maximum date for first day based on the selected last day
//     };

//     const handleinputChange = (e) => {
//         if (e.target.value < 0) {
//             setFieldValue('dailyNumberOfSurpriseBag', 0);
//         }
//         else {
//             setFieldValue('dailyNumberOfSurpriseBag', e.target.value < 6 ? e.target.value : '5');
//         }
//     };

//     const today = new Date();

//     return (
//         <div>
//             <Modal
//                 open={specialDayModalOpen}
//                 onClose={handleSpecialDayClose}
//                 aria-labelledby="special-day-modal-title"
//                 aria-describedby="special-day-modal-description"

//             >
//                 <Box sx={style}
//                     component="form"
//                     onSubmit={handleFormSubmit}

//                 >
//                     <Typography className="add_special_heading" variant="h5" mb={3}>{shobtn ? "Add special days" : "special days details"} <CloseIcon onClick={handleSpecialDayClose} sx={{ cursor: "pointer" }} /></Typography>

//                     <LocalizationProvider dateAdapter={AdapterDayjs}>
//                         <div className="display_align flex-wrap">
//                             <div>
//                                 <Typography variant="body1" sx={{ mr: 2, color: `var(--colorused)` }}>First Day</Typography>
//                                 <ReactDatePicker
//                                     className="date_input_calender"
//                                     selected={firstDayval}
//                                     onChange={handleDateChange}
//                                     name="firstDay"
//                                     onBlur={handleBlur}
//                                     dateFormat="dd/MM/yyyy"
//                                     maxDate={maxFirstDay} // Restrict max date for first day
//                                     minDate={today}
//                                 />
//                                 {errors.firstDay && touched.firstDay ? (
//                                     <p className="form-error">{errors.firstDay}</p>
//                                 ) : null}
//                             </div>

//                             <div>
//                                 <Typography variant="body1" sx={{ mr: 2, color: `var(--colorused)` }}>Last Day</Typography>
//                                 <ReactDatePicker
//                                     className="date_input_calender"
//                                     selected={lastDayval}
//                                     onChange={handlelastDateChange}
//                                     name="lastDay"
//                                     onBlur={handleBlur}
//                                     dateFormat="dd/MM/yyyy"
//                                     minDate={minLastDay} // Restrict min date for last day
//                                 />
//                                 {errors.lastDay && touched.lastDay ? (
//                                     <p className="form-error">{errors.lastDay}</p>
//                                 ) : null}
//                             </div>

//                             <div>
//                                 <Typography variant="body1" sx={{ mr: 2, color: `var(--colorused)` }}>Store will be</Typography>
//                                 <FormControlLabel
//                                     control={
//                                         <PinkSwitch
//                                             checked={storeOpen}
//                                             onChange={(e) => setStoreOpen(e.target.checked)}
//                                         />

//                                     }
//                                     label={storeOpen === true ? <p>Open</p> : <p>Closed</p>}
//                                 />

//                             </div>
//                         </div>
//                     </LocalizationProvider>

//                     {storeOpen === true ?
//                         <div>
//                             <LocalizationProvider dateAdapter={AdapterDayjs}>
//                                 <Box className="flex-wrap" sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
//                                     <div>
//                                         <Typography variant="body1" sx={{ mr: 2, color: `var(--colorused)` }}>Pickup Starts</Typography>
//                                         <DemoContainer className="input_time_field" components={['TimePicker', 'TimePicker']}>
//                                             <TimePicker
//                                                 className="input_time_field_input"
//                                                 label=""
//                                                 placeholder=""
//                                                 name="startTime"
//                                                 onBlur={handleBlur}
//                                                 value={startTime}
//                                                 defaultValue={"10:45"}
//                                                 onChange={(newValue) => handleTimeChange(newValue, "start")}
//                                                 maxTime={maxStartTime}
//                                                 ampm={false}
//                                             />

//                                         </DemoContainer>
//                                         {errors.startTime && touched.startTime ? (
//                                             <p className="form-error">{errors.startTime}</p>
//                                         ) : null}
//                                     </div>

//                                     <div>
//                                         <Typography variant="body1" sx={{ mr: 2, color: `var(--colorused)` }}>Pickup Ends</Typography>
//                                         <DemoContainer className="input_time_field" components={['TimePicker', 'TimePicker']}>
//                                             <TimePicker
//                                                 className="input_time_field_input"
//                                                 label=""
//                                                 value={endTime}
//                                                 name="endTime"
//                                                 onBlur={handleBlur}
//                                                 onChange={(newValue) => handleTimeChange(newValue, "end")}
//                                                 minTime={minEndTime}
//                                                 ampm={false}
//                                             />

//                                         </DemoContainer>
//                                         {errors.endTime && touched.endTime ? (
//                                             <p className="form-error">{errors.endTime}</p>
//                                         ) : null}
//                                     </div>

//                                     <div>
//                                         <Typography variant="body1" sx={{ mr: 2, color: `var(--colorused)` }}>Stock</Typography>
//                                         <TextField
//                                             sx={{ width: "110px" }}
//                                             label=""
//                                             type="number"
//                                             name="dailyNumberOfSurpriseBag"
//                                             value={values.dailyNumberOfSurpriseBag}
//                                             onChange={handleinputChange}
//                                             onBlur={handleBlur}
//                                         />
//                                         {errors.dailyNumberOfSurpriseBag && touched.dailyNumberOfSurpriseBag ? (
//                                             <p className="form-error">{errors.dailyNumberOfSurpriseBag}</p>
//                                         ) : null}
//                                     </div>

//                                 </Box>
//                             </LocalizationProvider>

//                         </div>
//                         : ""
//                     }

//                     <div className="display_align m-t-10">
//                         <div>
//                             <Typography variant="body2" mt={2}>
//                                 Daily limit: <strong>5</strong>
//                             </Typography>
//                             <Typography variant="caption">
//                                 To change your daily limit, contact us
//                             </Typography>
//                         </div>

//                         {shobtn ?
//                             <button
//                                 type="submit"
//                                 className="calender_btn_modal"
//                             >
//                                 {loading ? (
//                                     <CircularProgress size={18} style={{ color: "white" }} />
//                                 ) : (
//                                     "Add dates"
//                                 )}

//                             </button> : ""}

//                     </div>
//                 </Box>
//             </Modal>
//         </div>
//     );
// }

// import React, { useEffect, useState } from "react";
// import Modal from "@mui/material/Modal";
// import "react-datepicker/dist/react-datepicker.css";
// import "./index.css";
// import {
//   TextField,
//   Switch,
//   Button,
//   Box,
//   Typography,
//   FormControlLabel,
//   CircularProgress,
// } from "@mui/material";
// import {
//   LocalizationProvider,
//   DatePicker,
//   TimePicker,
// } from "@mui/x-date-pickers";
// import { useDispatch, useSelector } from "react-redux";
// import ToastMessage from "../../../utils/ToastMessage";
// import dayjs from "dayjs";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import {
//   getSpecialDayData,
//   removeDataFromReducer,
//   updatecalender,
// } from "../../../redux/actions";
// import { getSpecialday_api, update_calender_api } from "../../../API/baseUrl";
// import { alpha, styled } from "@mui/material/styles";
// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
// import { pink } from "@mui/material/colors";
// import ReactDatePicker from "react-datepicker";
// import moment from "moment";
// import { useFormik } from "formik";
// import {
//   calenderSchemaRequired,
//   calenderUpdateSchema,
// } from "../../../utils/schema";
// import CloseIcon from "@mui/icons-material/Close";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 600,
//   maxWidth: "95%",
//   bgcolor: "background.paper",
//   boxShadow: 24,
//   borderRadius: "6px",
//   p: 4,
// };

// const PinkSwitch = styled(Switch)(({ theme }) => ({
//   "& .MuiSwitch-switchBase.Mui-checked": {
//     color: "#267887",
//     "&:hover": {
//       backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
//     },
//   },
//   "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
//     backgroundColor: "#267887",
//   },
// }));

// export default function SpecialDayModal({
//   specialDayModalOpen,
//   handleSpecialDayClose,
//   id,
//   shobtn,
// }) {
//   const dispatch = useDispatch();
//   const { updatecalenderData } = useSelector(
//     (state) => state?.updatecalenderReduser || {}
//   );
//   const { getSpecialdaysData } = useSelector(
//     (state) => state?.getSpecialdaysDataReduser || {}
//   );
//   const [firstDayval, setFirstDayval] = useState(null);
//   const [lastDayval, setLastDayval] = useState(null);
//   const [storeOpen, setStoreOpen] = useState(true);
//   const [loading, setLoading] = useState(false);
//   const [minEndTime, setMinEndTime] = useState(null);
//   const [maxStartTime, setMaxStartTime] = useState(null);
//   const [startTime, setStartTime] = useState(null);
//   const [endTime, setEndTime] = useState(null);
//   const [minLastDay, setMinLastDay] = useState(null);
//   const [maxFirstDay, setMaxFirstDay] = useState(null);

//   const initialValues = {
//     surpriseBagIdFk: id,
//     firstDay: new Date(),
//     lastDay: new Date(),
//     isPickUp: "",
//     startTime: "",
//     endTime: "",
//     dailyNumberOfSurpriseBag: "",
//   };

//   const {
//     values,
//     errors,
//     touched,
//     handleBlur,
//     handleChange,
//     handleSubmit,
//     setFieldValue,
//   } = useFormik({
//     initialValues,
//     validationSchema:
//       storeOpen === true ? calenderSchemaRequired : calenderUpdateSchema,
//     onSubmit: () => submitForm(),
//   });

//   const handleFormSubmit = (event) => {
//     event.preventDefault();
//     handleSubmit();
//   };

//   const submitForm = async () => {
//     setLoading(true);
//     const parameter = {
//       surpriseBagIdFk: id,
//       firstDay: values.firstDay,
//       lastDay: values.lastDay,
//       isPickUp: storeOpen ? 1 : 0,
//       startTime: values.startTime,
//       endTime: values.endTime,
//       dailyNumberOfSurpriseBag: values.dailyNumberOfSurpriseBag,
//       url: update_calender_api,
//     };
//     dispatch(updatecalender(parameter));
//   };

//   const parseTimeString = (timeString) => {
//     if (timeString) {
//       const [hours, minutes] = timeString.split(":");
//       return dayjs()
//         .hour(parseInt(hours, 10))
//         .minute(parseInt(minutes, 10))
//         .second(0);
//     }
//     return null;
//   };

//   useEffect(() => {
//     if (getSpecialdaysData && getSpecialdaysData.data) {
//       const firstDay = dayjs(getSpecialdaysData.data.first_day);
//       const lastDay = dayjs(getSpecialdaysData.data.last_day);
//       const startTimeValue = parseTimeString(getSpecialdaysData.data.start_time);
//       const endTimeValue = parseTimeString(getSpecialdaysData.data.end_time);

//       // Prefill the form fields with incoming data
//       setFieldValue("firstDay", firstDay.format("DD/MM/YYYY"));
//       setFieldValue("lastDay", lastDay.format("DD/MM/YYYY"));
//       setFieldValue("dailyNumberOfSurpriseBag", getSpecialdaysData.data.daily_number_of_surprise_bag);
//       setFieldValue("startTime", getSpecialdaysData.data.start_time);
//       setFieldValue("endTime", getSpecialdaysData.data.end_time);
//       setStoreOpen(getSpecialdaysData.data.is_pickup === 1);

//       // Set startTime and endTime as Day.js objects
//       setStartTime(startTimeValue);
//       setEndTime(endTimeValue);
//       setMinEndTime(startTimeValue);
//       setMaxStartTime(endTimeValue);
//       setFirstDayval(firstDay.toDate()); // Set state for date pickers
//       setLastDayval(lastDay.toDate()); // Set state for date pickers
//     }
//   }, [getSpecialdaysData]);

//   useEffect(() => {
//     if (updatecalenderData?.status) {
//       setLoading(false);
//       ToastMessage("success", updatecalenderData?.message);
//       dispatch(removeDataFromReducer("UPDATE_CALENDER_RESET"));
//       handleSpecialDayClose();
//     } else {
//       setLoading(false);
//       ToastMessage("error", updatecalenderData?.message);
//     }
//   }, [updatecalenderData]);

//   const handleTimeChange = (newValue, type) => {
//     if (newValue) {
//       const formattedTime = dayjs(newValue).format("HH:mm");
//       sendTimeToAPI(formattedTime, type);

//       if (type === "start") {
//         setStartTime(newValue);
//         setMinEndTime(newValue);
//       } else {
//         setEndTime(newValue);
//         setMaxStartTime(newValue);
//       }
//     }
//   };

//   const sendTimeToAPI = (formattedTime, type) => {
//     if (type === "start") {
//       values.startTime = formattedTime;
//       setFieldValue("startTime", formattedTime);
//     } else {
//       values.endTime = formattedTime;
//       setFieldValue("endTime", formattedTime);
//     }
//   };

//   const handleDateChange = (date) => {
//     setFirstDayval(date);
//     setFieldValue("firstDay", dayjs(date).format("DD/MM/YYYY"));
//     setMinLastDay(date);
//   };

//   const handlelastDateChange = (date) => {
//     setLastDayval(date);
//     setFieldValue("lastDay", dayjs(date).format("DD/MM/YYYY"));
//     setMaxFirstDay(date);
//   };

//   const handleinputChange = (e) => {
//     if (e.target.value < 0) {
//       setFieldValue("dailyNumberOfSurpriseBag", 0);
//     } else {
//       setFieldValue(
//         "dailyNumberOfSurpriseBag",
//         e.target.value < 6 ? e.target.value : "5"
//       );
//     }
//   };

//   const today = new Date();

//   return (
//     <div>
//       <Modal
//         open={specialDayModalOpen}
//         onClose={handleSpecialDayClose}
//         aria-labelledby="special-day-modal-title"
//         aria-describedby="special-day-modal-description"
//       >
//         <Box sx={style} component="form" onSubmit={handleFormSubmit}>
//           <Typography className="add_special_heading" variant="h5" mb={3}>
//             {shobtn ? "Add special days" : "Special days details"}{" "}
//             <CloseIcon
//               onClick={handleSpecialDayClose}
//               sx={{ cursor: "pointer" }}
//             />
//           </Typography>

//           <LocalizationProvider dateAdapter={AdapterDayjs}>
//             <div className="display_align flex-wrap">
//               <div>
//                 <Typography
//                   variant="body1"
//                   sx={{ mr: 2, color: `var(--colorused)` }}
//                 >
//                   First Day
//                 </Typography>
//                 <ReactDatePicker
//                   className="date_input_calender"
//                   selected={firstDayval}
//                   onChange={handleDateChange}
//                   name="firstDay"
//                   onBlur={handleBlur}
//                   dateFormat="dd/MM/yyyy"
//                   maxDate={maxFirstDay}
//                   minDate={today}
//                 />
//                 {errors.firstDay && touched.firstDay ? (
//                   <p className="form-error">{errors.firstDay}</p>
//                 ) : null}
//               </div>

//               <div>
//                 <Typography
//                   variant="body1"
//                   sx={{ mr: 2, color: `var(--colorused)` }}
//                 >
//                   Last Day
//                 </Typography>
//                 <ReactDatePicker
//                   className="date_input_calender"
//                   selected={lastDayval}
//                   onChange={handlelastDateChange}
//                   name="lastDay"
//                   onBlur={handleBlur}
//                   dateFormat="dd/MM/yyyy"
//                   minDate={minLastDay}
//                 />
//                 {errors.lastDay && touched.lastDay ? (
//                   <p className="form-error">{errors.lastDay}</p>
//                 ) : null}
//               </div>

//               <div>
//                 <Typography
//                   variant="body1"
//                   sx={{ mr: 2, color: `var(--colorused)` }}
//                 >
//                   Store will be
//                 </Typography>
//                 <FormControlLabel
//                   control={
//                     <PinkSwitch
//                       checked={storeOpen}
//                       onChange={(e) => setStoreOpen(e.target.checked)}
//                     />
//                   }
//                   label={storeOpen ? <p>Open</p> : <p>Closed</p>}
//                 />
//               </div>
//             </div>
//           </LocalizationProvider>

//           {storeOpen && (
//             <div>
//               <LocalizationProvider dateAdapter={AdapterDayjs}>
//                 <Box
//                   className="flex-wrap"
//                   sx={{
//                     display: "flex",
//                     justifyContent: "space-between",
//                     mt: 2,
//                   }}
//                 >
//                   <div>
//                     <Typography
//                       variant="body1"
//                       sx={{ mr: 2, color: `var(--colorused)` }}
//                     >
//                       Pickup Starts
//                     </Typography>
//                     <DemoContainer
//                       className="input_time_field"
//                       components={["TimePicker", "TimePicker"]}
//                     >
//                       <TimePicker
//                         className="input_time_field_input"
//                         label=""
//                         value={startTime}
//                         onBlur={handleBlur}
//                         onChange={(newValue) =>
//                           handleTimeChange(newValue, "start")
//                         }
//                         maxTime={maxStartTime}
//                         ampm={false}
//                       />
//                     </DemoContainer>
//                     {errors.startTime && touched.startTime ? (
//                       <p className="form-error">{errors.startTime}</p>
//                     ) : null}
//                   </div>

//                   <div>
//                     <Typography
//                       variant="body1"
//                       sx={{ mr: 2, color: `var(--colorused)` }}
//                     >
//                       Pickup Ends
//                     </Typography>
//                     <DemoContainer
//                       className="input_time_field"
//                       components={["TimePicker", "TimePicker"]}
//                     >
//                       <TimePicker
//                         className="input_time_field_input"
//                         label=""
//                         value={endTime}
//                         onBlur={handleBlur}
//                         onChange={(newValue) =>
//                           handleTimeChange(newValue, "end")
//                         }
//                         minTime={minEndTime}
//                         ampm={false}
//                       />
//                     </DemoContainer>
//                     {errors.endTime && touched.endTime ? (
//                       <p className="form-error">{errors.endTime}</p>
//                     ) : null}
//                   </div>

//                   <div>
//                     <Typography
//                       variant="body1"
//                       sx={{ mr: 2, color: `var(--colorused)` }}
//                     >
//                       Stock
//                     </Typography>
//                     <TextField
//                       sx={{ width: "110px" }}
//                       label=""
//                       type="number"
//                       name="dailyNumberOfSurpriseBag"
//                       value={values.dailyNumberOfSurpriseBag}
//                       onChange={handleinputChange}
//                       onBlur={handleBlur}
//                     />
//                     {errors.dailyNumberOfSurpriseBag &&
//                     touched.dailyNumberOfSurpriseBag ? (
//                       <p className="form-error">
//                         {errors.dailyNumberOfSurpriseBag}
//                       </p>
//                     ) : null}
//                   </div>
//                 </Box>
//               </LocalizationProvider>
//             </div>
//           )}

//           <div className="display_align m-t-10">
//             <div>
//               <Typography variant="body2" mt={2}>
//                 Daily limit: <strong>5</strong>
//               </Typography>
//               <Typography variant="caption">
//                 To change your daily limit, contact us
//               </Typography>
//             </div>

//             {shobtn && (
//               <button type="submit" className="calender_btn_modal">
//                 {loading ? (
//                   <CircularProgress size={18} style={{ color: "white" }} />
//                 ) : (
//                   "Add dates"
//                 )}
//               </button>
//             )}
//           </div>
//         </Box>
//       </Modal>
//     </div>
//   );
// }

import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import {
  TextField,
  Switch,
  Button,
  Box,
  Typography,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import {
  LocalizationProvider,
  DatePicker,
  TimePicker,
} from "@mui/x-date-pickers";
import { useDispatch, useSelector } from "react-redux";
import ToastMessage from "../../../utils/ToastMessage";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  getSpecialDayData,
  removeDataFromReducer,
  updatecalender,
} from "../../../redux/actions";
import { getSpecialday_api, update_calender_api } from "../../../API/baseUrl";
import { alpha, styled } from "@mui/material/styles";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { pink } from "@mui/material/colors";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { useFormik } from "formik";
import {
  calenderSchemaRequired,
  calenderUpdateSchema,
} from "../../../utils/schema";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  maxWidth: "95%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "6px",
  p: 4,
};

const PinkSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#267887",
    "&:hover": {
      backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#267887",
  },
}));

export default function SpecialDayModal({
  specialDayModalOpen,
  handleSpecialDayClose,
  id,
  shobtn,
  dates,
  validdate
}) {
  const dispatch = useDispatch();
  const { updatecalenderData } = useSelector(
    (state) => state?.updatecalenderReduser || {}
  );
  const { getSpecialdaysData } = useSelector(
    (state) => state?.getSpecialdaysDataReduser || {}
  );
  const [firstDayval, setFirstDayval] = useState(null);
  const [lastDayval, setLastDayval] = useState(null);
  const [storeOpen, setStoreOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [minEndTime, setMinEndTime] = useState(null);
  const [maxStartTime, setMaxStartTime] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [minLastDay, setMinLastDay] = useState(null);
  const [maxFirstDay, setMaxFirstDay] = useState(null);

  const initialValues = {
    surpriseBagIdFk: id,
    firstDay: new Date(),
    lastDay: new Date(),
    isPickUp: "",
    startTime: "",
    endTime: "",
    dailyNumberOfSurpriseBag: "",
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema:
      storeOpen === true ? calenderSchemaRequired : calenderUpdateSchema,
    onSubmit: () => submitForm(),
  });

  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleSubmit();
  };

  
  useEffect(() => {
    if(!storeOpen){
      values.dailyNumberOfSurpriseBag = 1
      values.startTime =""
      values.endTime =""
    }
  }, [storeOpen]);

  const submitForm = async () => {
    setLoading(true);
    const parameter = {
      surpriseBagIdFk: id,
      firstDay: values.firstDay,
      lastDay: values.lastDay,
      isPickUp: storeOpen ? 1 : 0,
      startTime: values.startTime,
      endTime: values.endTime,
      dailyNumberOfSurpriseBag: values.dailyNumberOfSurpriseBag,
      url: update_calender_api,
    };
    if (values.startTime >= values.endTime && storeOpen) {
      ToastMessage("error", "End time must be greater than start time");
      setLoading(false);
    }else{
    dispatch(updatecalender(parameter));
    }
  };

  const parseTimeString = (timeString) => {
    if (timeString) {
      const [hours, minutes] = timeString.split(":");
      return dayjs()
        .hour(parseInt(hours, 10))
        .minute(parseInt(minutes, 10))
        .second(0);
    }
    return null;
  };


  
  function convertDateToFullFormat(dateInState) {
    // Parse the input date in 'DD/MM/YYYY' format and convert it to a JavaScript Date object
    const date = moment(dateInState, 'DD/MM/YYYY').toDate();
  
    // Get the full format date string
    const fullFormatDate = date.toString();
  
    console.log(fullFormatDate); // Example: "Fri Nov 22 2024 00:00:00 GMT+0530 (India Standard Time)"
    return fullFormatDate;
  }

  // useEffect(() => {
  //   if (getSpecialdaysData && getSpecialdaysData.data) {
  //     const aadata = getSpecialdaysData.data?.filter((item) => {
  //       return item.first_day === dates;
  //     });

  //     console.log("Filtered data:", aadata);

  //     const { first_day, last_day } = aadata[0];
  //     const startTimeValue = parseTimeString(aadata[0]?.start_time);
  //     const endTimeValue = parseTimeString(aadata[0]?.end_time);

  //     setFieldValue("firstDay", first_day); // Set firstDay directly from API
  //     setFieldValue("lastDay", last_day); // Set lastDay directly from API
  //     setFieldValue(
  //       "dailyNumberOfSurpriseBag",
  //       aadata[0]?.daily_number_of_surprise_bag
  //     );
  //     setFieldValue("startTime", aadata[0]?.start_time);
  //     setFieldValue("endTime", aadata[0]?.end_time);
  //     setStoreOpen(aadata[0]?.is_pickup === 1);

  //     // Set startTime and endTime as Day.js objects
  //     setStartTime(startTimeValue);
  //     setEndTime(endTimeValue);
  //     setMinEndTime(startTimeValue);
  //     setMaxStartTime(endTimeValue);
  //     // setFirstDayval(firstDay.toDate()); // Set state for date pickers
  //     // setLastDayval(lastDay.toDate()); // Set state for date pickers
  //     setFirstDayval(
  //       first_day ? new Date(first_day?.split("/").reverse().join("-")) : null
  //     ); // Convert "DD/MM/YYYY" to "YYYY-MM-DD" for Date object
  //     setLastDayval(
  //       last_day ? new Date(last_day?.split("/").reverse().join("-")) : null
  //     ); // Convert "DD/MM/YYYY" to "YYYY-MM-DD" for Date object
  //   }
  // }, [getSpecialdaysData]);



  useEffect(() => {
    if (getSpecialdaysData && getSpecialdaysData.data) {
      const aadata = getSpecialdaysData.data.filter((item) => {
        return item.first_day === dates;
      });
  
      console.log("Filtered data:", aadata);
      if (aadata.length > 0) {
        const { first_day, last_day } = aadata[0];
        const startTimeValue = parseTimeString(aadata[0]?.start_time) || null;
        const endTimeValue = parseTimeString(aadata[0]?.end_time) || null;
  
        setFieldValue("firstDay", first_day || null); 
        setFieldValue("lastDay", last_day || null); 
        setFieldValue("dailyNumberOfSurpriseBag", aadata[0]?.daily_number_of_surprise_bag || 0);
        setFieldValue("startTime", aadata[0]?.start_time || null);
        setFieldValue("endTime", aadata[0]?.end_time || null);
        setStoreOpen(aadata[0]?.is_pickup === 1);
        setStartTime(startTimeValue);
        setEndTime(endTimeValue);
        setMinEndTime(startTimeValue);
        setMaxStartTime(endTimeValue);
        setFirstDayval(
          first_day ? new Date(first_day.split("/").reverse().join("-")) : null
        );
        setLastDayval(
          last_day ? new Date(last_day.split("/").reverse().join("-")) : null
        ); 
      } else {
        console.log("No data found for the specified date.");
        setFieldValue("firstDay", null);
        setFieldValue("lastDay", null);
        setFieldValue("dailyNumberOfSurpriseBag", 1);
        setFieldValue("startTime", null);
        setFieldValue("endTime", null);
        setStoreOpen(false);
        setStartTime(null);
        setEndTime(null);
        setMinEndTime(null);
        setMaxStartTime(null);
        setFirstDayval(null);
        setLastDayval(null);
      }
    }
  }, [getSpecialdaysData, dates]); 
  


  useEffect(() => {
    if (updatecalenderData?.status) {
      setLoading(false);
      ToastMessage("success", updatecalenderData?.message);
      dispatch(removeDataFromReducer("UPDATE_CALENDER_RESET"));
      handleSpecialDayClose();
    } else {
      setLoading(false);
      ToastMessage("error", updatecalenderData?.message);
    }
  }, [updatecalenderData]);

  const handleTimeChange = (newValue, type) => {
    if (newValue) {
      const formattedTime = dayjs(newValue).format("HH:mm");
      sendTimeToAPI(formattedTime, type);

      if (type === "start") {
        setStartTime(newValue);
        setMinEndTime(newValue);
      } else {
        setEndTime(newValue);
        setMaxStartTime(newValue);
      }
    }
  };

  const sendTimeToAPI = (formattedTime, type) => {
    if (type === "start") {
      values.startTime = formattedTime;
      setFieldValue("startTime", formattedTime);
    } else {
      values.endTime = formattedTime;
      setFieldValue("endTime", formattedTime);
    }
  };

  // const handleDateChange = (date) => {
  //   setFirstDayval(date);
  //   setFieldValue("firstDay", dayjs(date).format("DD/MM/YYYY"));
  //   setMinLastDay(date);
  // };

  // const handlelastDateChange = (date) => {
  //   setLastDayval(date);
  //   setFieldValue("lastDay", dayjs(date).format("DD/MM/YYYY"));
  //   setMaxFirstDay(date);
  // };
  const handleDateChange = (date) => {
    setFirstDayval(date);
    setMinLastDay(date); 
    setFieldValue("firstDay", date?.toLocaleDateString("en-GB")); // Store as "DD/MM/YYYY"
  };

  const handleLastDateChange = (date) => {
    setLastDayval(date);
    setMaxFirstDay(date); 
    setFieldValue("lastDay", date?.toLocaleDateString("en-GB")); // Store as "DD/MM/YYYY"
  };

  // const handleinputChange = (e) => {
  //   if (e.target.value < 0) {
  //     setFieldValue("dailyNumberOfSurpriseBag", 0);
  //   } else {
  //     setFieldValue(
  //       "dailyNumberOfSurpriseBag",
  //       e.target.value < 6 ? e.target.value : "5"
  //     );
  //   }
  // };
  const handleinputChange = (e) => {
    const value = parseInt(e.target.value);
  
    if (value < 1) {
      setFieldValue("dailyNumberOfSurpriseBag", 1);
    } else if (value > 5) {
      setFieldValue("dailyNumberOfSurpriseBag", 5);
    } else {
      setFieldValue("dailyNumberOfSurpriseBag", value);
    }
  };
  
  const today = new Date();

  return (
    <div>
      <Modal
        open={specialDayModalOpen}
        onClose={handleSpecialDayClose}
        aria-labelledby="special-day-modal-title"
        aria-describedby="special-day-modal-description"
      >
        <Box sx={style} component="form" onSubmit={handleFormSubmit}>
          <Typography className="add_special_heading" variant="h5" mb={3}>
            {shobtn ? "Add special days" : "Special days details"}{" "}
            <CloseIcon
              onClick={handleSpecialDayClose}
              sx={{ cursor: "pointer" }}
            />
          </Typography>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="display_align flex-wrap">
              <div>
                <Typography
                  variant="body1"
                  sx={{ mr: 2, color: `var(--colorused)` }}
                >
                  First Day
                </Typography>
              
                <ReactDatePicker
                  className="date_input_calender"
                  selected={firstDayval}
                  onChange={handleDateChange}
                  name="firstDay"
                  onBlur={handleBlur}
                  dateFormat="dd/MM/yyyy" // Display as received from the API
                  maxDate={maxFirstDay || convertDateToFullFormat(validdate)} // Restrict max date for first day
                  minDate={today}
                />

                {errors.firstDay && touched.firstDay ? (
                  <p className="form-error">{errors.firstDay}</p>
                ) : null}
              </div>

              <div>
                <Typography
                  variant="body1"
                  sx={{ mr: 2, color: `var(--colorused)` }}
                >
                  Last Day
                </Typography>
             
                <ReactDatePicker
                  className="date_input_calender"
                  selected={lastDayval}
                  onChange={handleLastDateChange}
                  name="lastDay"
                  onBlur={handleBlur}
                  dateFormat="dd/MM/yyyy" // Display as received from the API
                  maxDate={convertDateToFullFormat(validdate)}
                  minDate={minLastDay} // Restrict min date for last day
                />
                {errors.lastDay && touched.lastDay ? (
                  <p className="form-error">{errors.lastDay}</p>
                ) : null}
              </div>

              <div>
                <Typography
                  variant="body1"
                  sx={{ mr: 2, color: `var(--colorused)` }}
                >
                  Store will be
                </Typography>
                <FormControlLabel
                  control={
                    <PinkSwitch
                      checked={storeOpen}
                      onChange={(e) => setStoreOpen(e.target.checked)}
                    />
                  }
                  label={storeOpen ? <p>Open</p> : <p>Closed</p>}
                />
              </div>
            </div>
          </LocalizationProvider>

          {storeOpen && (
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box
                  className="flex-wrap"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 2,
                  }}
                >
                  <div>
                    <Typography
                      variant="body1"
                      sx={{ mr: 2, color: `var(--colorused)` }}
                    >
                      Pickup Starts
                    </Typography>
                    <DemoContainer
                      className="input_time_field"
                      components={["TimePicker", "TimePicker"]}
                    >
                      <TimePicker
                        className="input_time_field_input"
                        label=""
                        value={startTime}
                        onBlur={handleBlur}
                        onChange={(newValue) =>
                          handleTimeChange(newValue, "start")
                        }
                        maxTime={maxStartTime}
                        ampm={false}
                      />
                    </DemoContainer>
                    {errors.startTime && touched.startTime ? (
                      <p className="form-error">{errors.startTime}</p>
                    ) : null}
                  </div>

                  <div>
                    <Typography
                      variant="body1"
                      sx={{ mr: 2, color: `var(--colorused)` }}
                    >
                      Pickup Ends
                    </Typography>
                    <DemoContainer
                      className="input_time_field"
                      components={["TimePicker", "TimePicker"]}
                    >
                      <TimePicker
                        className="input_time_field_input"
                        label=""
                        value={endTime}
                        onBlur={handleBlur}
                        onChange={(newValue) =>
                          handleTimeChange(newValue, "end")
                        }
                        minTime={minEndTime}
                        ampm={false}
                      />
                    </DemoContainer>
                    {errors.endTime && touched.endTime ? (
                      <p className="form-error">{errors.endTime}</p>
                    ) : null}
                  </div>

                  <div>
                    <Typography
                      variant="body1"
                      sx={{ mr: 2, color: `var(--colorused)` }}
                    >
                      Stock
                    </Typography>
                    <TextField
                      sx={{ width: "110px" }}
                      label=""
                      type="number"
                      name="dailyNumberOfSurpriseBag"
                      value={values.dailyNumberOfSurpriseBag}
                      onChange={handleinputChange}
                      onBlur={handleBlur}
            
                    />
                    {errors.dailyNumberOfSurpriseBag &&
                    touched.dailyNumberOfSurpriseBag ? (
                      <p className="form-error">
                        {errors.dailyNumberOfSurpriseBag}
                      </p>
                    ) : null}
                  </div>
                </Box>
              </LocalizationProvider>
            </div>
          )}

          <div className="display_align m-t-10">
            <div>
              <Typography variant="body2" mt={2}>
                Daily limit: <strong>5</strong>
              </Typography>
              <Typography variant="caption">
                To change your daily limit, contact us
              </Typography>
            </div>

            {shobtn && (
              <button type="submit" className="calender_btn_modal">
                {loading ? (
                  <CircularProgress size={18} style={{ color: "white" }} />
                ) : (
                  "Add dates"
                )}
              </button>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
}
