

import { useDispatch, useSelector } from 'react-redux';
import RemoveTag from '../../components/removeHtml';
import './index.css'
import { useEffect, useState } from "react";
import { get_setting_api } from '../../API/baseUrl';
import { getPrivacyAction } from '../../redux/actions';
import ToastMessage from '../../utils/ToastMessage';
import AppNabvar from '../../components/nabvar';


const Terms = () => {
    const [getTermsData, setGetTermsData] = useState('')
    const { getPrivacyData } = useSelector((state) => state?.getPrivacyReduser || {});
    const dispatch = useDispatch();

    useEffect(() => {
      if (getPrivacyData?.status) {
        setGetTermsData(getPrivacyData.data?.data)
      } else {
        // ToastMessage("error", getPrivacyData?.message);
      }
    }, [getPrivacyData]);
  
      
  
  
    useEffect(() => {
      const parameter = {
          type: "vendorTerm&Condition",
          url:get_setting_api,
      };
      dispatch(getPrivacyAction(parameter));
    }, []);
  
    
    useEffect(() => {
      document.title = "Terms & Conditions";
    }, []);

    return (
        <div className="termsOutsideClass">
          <AppNabvar/>
        <div className="termsMainClass">
            {/* <p className="headTermsClass">Privacy Policy</p> */}
            <p className='terms_content'>
            <RemoveTag ParserText={getTermsData} />
            </p>
        </div>
        </div>
    )
}
export default Terms;