

import React, { useEffect, useState } from 'react';
import { Box, Modal, Typography, Divider } from '@mui/material';
import { AiFillMessage } from 'react-icons/ai';
import { useSelector, useDispatch } from 'react-redux';
import ToastMessage from '../../utils/ToastMessage';
import { GetDetailById } from '../../API/baseUrl';
import { SupportTicketByid } from '../../redux/actions';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import ChatBox from '../../components/ChatByAPI/Index';
import CryptoJS from 'crypto-js';
import { getJsonDecryptData } from './decript';

const style = {
  position: 'absolute',
  top: '50%',
  right: '10px',
  transform: 'translateY(-50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
  width: '80vw',
  height: '80vh',
  maxWidth: '500px',
  maxHeight: '90vh',
  borderRadius: '8px',
  overflowY: 'auto',
};

const TicketDetails = ({ details }) => (
  <Container
    maxWidth="lg"
    sx={{
      mt: 4,
      mb: 4,
      p: 2,
      bgcolor: '#f9f9f9',
      borderRadius: '8px',
      boxShadow: 3,
    }}
  >
    <Box
      sx={{
        p: 3,
        borderRadius: '8px',
        bgcolor: 'white',
        boxShadow: 2,
        border: '1px solid #ddd',
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontWeight: 540,
          fontSize: '22px',
          mb: 2,
          color: '#333',
        }}
      >
        Ticket Details
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Typography sx={{ mb: 1 }}>
        <strong>Ticket ID:</strong> {details?.ticketId}
      </Typography>
      <Typography sx={{ mb: 1 }}>
        <strong>Status:</strong> {details?.supportTicketStatus}
      </Typography>
      <Typography sx={{ mb: 1 }}>
        <strong>Creation Date:</strong> {details?.ticketCreationDate}
      </Typography>
      <Typography sx={{ mb: 1 }}>
        <strong>Mobile Number:</strong> {details?.mobileNumber}
      </Typography>
      <Typography sx={{ mb: 1 }}>
        <strong>Country Code:</strong> {details?.countryCode}

      </Typography>
      <Typography sx={{ mb: 1 }}>
        <strong>User Name:</strong> {details?.userName}
      </Typography>
      <Typography sx={{ mb: 1 }}>
        <strong>Email:</strong> {details?.email}
      </Typography>
      <Typography sx={{ mb: 1 }}>
        <strong>Purpose:</strong> {details?.purpose}
      </Typography>
      <Typography>
        <strong>Message:</strong> {details?.message}
      </Typography>
    </Box>
  </Container>
);

const TicketDetailEmail = () => {
  const [open, setOpen] = useState(false);
  const [ticketDetail, setTicketDetail] = useState({});
  const [decryptedObject, setDecryptedObject] = useState(null);

  const dispatch = useDispatch();
  const { SupportTicketByiddata } = useSelector((state) => state.SupportTicketByid || {});

  const { ticketId, restaurantIdFK } = useParams();

  const [supportTicketIdFk, setSupportTicketIdFk] = useState('');
  const [fromType, setfromtype] = useState('');
  const [ticketId1, setticketId1] = useState('');
  const [from, setfrom] = useState('');


  useEffect(() => {
       const extractAndDecrypt = async () => {
      try {
        const decryptedData = await getJsonDecryptData(ticketId);
        const parsedResult = JSON.parse(decryptedData);
        setDecryptedObject(parsedResult);
        setSupportTicketIdFk(parsedResult.supportTicketIdFk);
        setfromtype(parsedResult.fromType);
        setticketId1(parsedResult.ticketId);
        setfrom(parsedResult.from);

      } catch (error) {
        ToastMessage('error', 'Decryption failed.');
      }
    };

    extractAndDecrypt();
  }, [ticketId]);

  useEffect(() => {
    if (SupportTicketByiddata?.status) {
      setTicketDetail(SupportTicketByiddata?.data);
    } else {
      ToastMessage('error', SupportTicketByiddata?.message);
    }
  }, [SupportTicketByiddata]);




  const fetchTicketData = () => {

    const parameter = {
      ticketIdFk: supportTicketIdFk,
      // supportTicketIdFk,
      // from,
      // fromType,
      // ticketId1,
      url: `${GetDetailById}${supportTicketIdFk}`,
    };
    dispatch(SupportTicketByid(parameter));
  };

  // Fetch ticket data when the component mounts
  useEffect(() => {
    fetchTicketData();
  }, [supportTicketIdFk]);

  return (
    <>
      <TicketDetails details={ticketDetail} />
      <div className='chat-body'>
        <AiFillMessage
          variant="contained"
          style={{ fontSize: '50px', color: '#264f0b' }}
          onClick={() => setOpen(true)}
          className='chatBtn'
        />
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box sx={style}>
            <div className='chat-room'>
              <ChatBox
              onClose={() => setOpen(false)}
                supportTicketIdFk={supportTicketIdFk}
                ticketId={ticketId1}
                userIdFk={ticketDetail?.userIdFk}
                from={from}
                fromtype={fromType}
              />
             
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default TicketDetailEmail;
