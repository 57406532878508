
import React, { useState } from 'react'
import OwnerDocument from '../ownerdocument';
import DashboardHome from '../dashboardHome/dashboardHome';
import DashboardLayout from '../../layout/dashboardlayout';
import CreateSurpriceBag from '../../pages/createSurpriseBag/index';


const MainPage = () => {
    const documentStatus = localStorage.getItem('documentStatus');
    const documentupload = localStorage.getItem('documentupload');
    const isSingleSurpriseBagCreated = localStorage.getItem('isSingleSurpriseBagCreated');

  
  return (
    <div>
    {documentupload === 0 ? <DashboardLayout/> : <CreateSurpriceBag/> }
  {/* // isSingleSurpriseBagCreated === 0 ?  <CreateSurpriceBag/> : <>{documentupload === 0 ? <OwnerDocument/> : <DashboardLayout/>}</> */}
{/* } */}

        {/* <CreateSurpriceBag/> */}

    </div>
  )
}

export default MainPage;