import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TextField } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import InputAdornment from "@mui/material/InputAdornment";
import React, { useEffect, useState } from "react";
import InputField from "../../components/input";
import "./index.css";
import MapCom from "./locationMap";
import UseScrollToTop from "../../components/topOpen";
import OtpInput from 'react-otp-input';

const RestaurantInfo = ({
  lat, setLat,
  addressValue,
  setAddressValue,
  selected,
  setSelected,
  setFieldValue,
  showownerOtpResendbtn,
  showResOtpResendbtn,
  errors,
  digitsArray,
  resturantdigitsArray,
  setDigitsArray, setResturantdigitsArray,
  resturantotp,
  mobileotp,
  isverified,
  isresturantnumberverified,
  values,
  handleChange,
  touched,
  handleBlur,
  setMobilevalue,
  setCountrycode,
  setResturantmobilevalue,
  handleresturantverify,
  setResturantcountrycode,
  handlemobileverify,
}) => {
  ;
  const phoneChange = (e) => {
    setMobilevalue(e.target.value);
    values.restaurantOwnerMobileNumber = e.target.value;
    setCountrycode("+91");
    values.restaurantOwnerCountryCode = "+91";
  };

  const resturantphoneChange = (e) => {
    setResturantmobilevalue(e.target.value);
    values.restaurantMobileNumber = e.target.value;
    setResturantcountrycode("+91");
    values.restaurantCountryCode = "+91";
  };

  function Boxchange1(e) {
    var target = e.srcElement || e.target;
    if (
      target.value.length === parseInt(target.attributes["maxLength"].value)
    ) {
      document.getElementById("input2").focus();
    } else if (target.value.length === 0) {
      var previous = target;
      while ((previous = previous.previousElementSibling)) {
        if (previous == null) break;
        if (previous.tagName.toLowerCase() === "input") {
          previous.focus();
          break;
        }
      }
    }
  }

  function Boxchange2(e) {
    var target = e.srcElement || e.target;
    if (
      target.value.length === parseInt(target.attributes["maxLength"].value)
    ) {
      document.getElementById("input3").focus();
    } else if (target.value.length === 0) {
      var previous = target;
      while ((previous = previous.previousElementSibling)) {
        if (previous == null) break;
        if (previous.tagName.toLowerCase() === "input") {
          previous.focus();
          break;
        }
      }
    }
  }

  function Boxchange3(e) {
    var target = e.srcElement || e.target;
    if (
      target.value.length === parseInt(target.attributes["maxLength"].value)
    ) {
      document.getElementById("input4").focus();
    } else if (target.value.length === 0) {
      var previous = target;
      while ((previous = previous.previousElementSibling)) {
        if (previous == null) break;
        if (previous.tagName.toLowerCase() === "input") {
          previous.focus();
          break;
        }
      }
    }
  }

  function Boxchange4(e) {
    var target = e.srcElement || e.target;
    if (
      target.value.length === parseInt(target.attributes["maxLength"].value)
    ) {
      document.getElementById("input5").focus();
    } else if (target.value.length === 0) {
      var previous = target;
      while ((previous = previous.previousElementSibling)) {
        if (previous == null) break;
        if (previous.tagName.toLowerCase() === "input") {
          previous.focus();
          break;
        }
      }
    }
  }

  const handleKeyPress = (e) => {
    // Prevent non-numeric characters
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="resturent_container">
      <UseScrollToTop />
      <Accordion defaultExpanded className="resturant_acordian">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <div className="acordian_heading_container">
            <h3 className="acordian_heading_text">Restaurant Details</h3>
            <p>Name, address and location</p>
          </div>
        </AccordionSummary>
        <AccordionDetails className="accordion_gap">
          <div>
            <InputField
              label="Restaurant Name *"
              placeholder="Restaurant Name"
              name="restaurantName"
              value={values.restaurantName}
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.restaurantName && touched.restaurantName ? (
              <p className="form-error">{errors.restaurantName}</p>
            ) : null}
          </div>


          <MapCom
            {...{
              addressValue,
              setAddressValue,
              values,
              setFieldValue,
              selected,
              setSelected,
              lat, setLat
            }}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded className="resturant_acordian">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <div className="acordian_heading_container">
            <h3 className="acordian_heading_text">Restaurant Owner Details</h3>
            <p>These will be used to share revenue related communications</p>
          </div>
        </AccordionSummary>
        <AccordionDetails className="accordion_gap">
          <div>
            <InputField
              label="Restaurant Owner Name *"
              placeholder="Restaurant Owner Name"
              name="restaurantOwnerName"
              value={values.restaurantOwnerName}
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.restaurantOwnerName && touched.restaurantOwnerName ? (
              <p className="form-error">{errors.restaurantOwnerName}</p>
            ) : null}
          </div>

          <>
            {/* <p className='image_lable'>Owner Mobile Number</p> */}

            <div className="mobile_container">
              <div className="mobile">

                <TextField
                  fullWidth
                  type="tel"
                  label="Owner Mobile Number *"
                  value={values.restaurantOwnerMobileNumber}
                  onChange={(e) => phoneChange(e)}
                  disabled={isverified}
                  onKeyPress={handleKeyPress}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+91</InputAdornment>
                    ),
                    inputProps: {
                      maxLength: 10, // Set max length to 10 digits
                      pattern: "\\d*", // Only allow digits
                    },
                  }}
                  inputProps={{
                    onInput: (e) => {
                      // Prevent input if length is greater than 10
                      if (e.target.value.length > 10) {
                        e.target.value = e.target.value.slice(0, 10);
                      }
                    },
                  }}
                />

                {errors.restaurantOwnerMobileNumber &&
                  touched.restaurantOwnerMobileNumber ? (
                  <p className="Login_form-error">
                    {errors.restaurantOwnerMobileNumber}
                  </p>
                ) : null}
              </div>

              {showownerOtpResendbtn ? (
                <button
                  type="button"
                  className="verify_btn"
                  onClick={handlemobileverify}
                >
                  Resend OTP
                </button>
              ) : (
                <>
                  {isverified ? (
                    <button className="verified_btn" type="button">
                      Verified
                    </button>
                  ) : (
                    <button
                      className={`verify_btn ${!values.restaurantOwnerMobileNumber
                        ? "cursornodrop"
                        : ""
                        } `}
                      disabled={!values.restaurantOwnerMobileNumber}
                      type="button"
                      onClick={handlemobileverify}
                    >
                      Verify
                    </button>
                  )}
                </>
              )}
            </div>
          </>

          {isverified ?
            ""
            :
            <div className="otp_input_container">
            <OtpInput
              skipDefaultStyles="true"
              inputStyle='otp_input_type'
              value={digitsArray}
              onChange={setDigitsArray}
              numInputs={4}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
            />
            </div>


            // (
            //   <div>
            //     <input
            //       id="input1"
            //       onKeyUp={Boxchange1}
            //       maxLength="1"
            //       className="box"
            //       type="text"
            //       value={digitsArray?.[0]}
            //     />
            //     <input
            //       id="input2"
            //       onKeyUp={Boxchange2}
            //       maxLength="1"
            //       className="box"
            //       type="text"
            //       value={digitsArray?.[1]}
            //     />
            //     <input
            //       id="input3"
            //       onKeyUp={Boxchange3}
            //       maxLength="1"
            //       className="box"
            //       type="text"
            //       value={digitsArray?.[2]}
            //     />
            //     <input
            //       id="input4"
            //       onKeyUp={Boxchange4}
            //       maxLength="1"
            //       className="box"
            //       type="text"
            //       value={digitsArray?.[3]}
            //     />
            //   </div>
            // )


          }

          <div>
            <InputField
              label="Restaurant Owner Email *"
              placeholder="Restaurant Owner Email"
              name="restaurantOwnerEmail"
              value={values.restaurantOwnerEmail}
              type="email"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.restaurantOwnerEmail && touched.restaurantOwnerEmail ? (
              <p className="form-error">{errors.restaurantOwnerEmail}</p>
            ) : null}
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded className="resturant_acordian">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <div className="acordian_heading_container">
            <h3 className="acordian_heading_text">
              Contact Number of Restaurant
            </h3>
            <p>Your customers will call on this number for general enquiries</p>
          </div>
        </AccordionSummary>
        <AccordionDetails className="accordion_gap">
          <>
            {/* <p className='image_lable'>Restaurant Mobile Number</p> */}

            <div className="mobile_container">
              <div className="mobile">
                {/* <MuiPhoneNumber
                                    className='mobile_phone_field'
                                    name='restaurantMobileNumber'
                                    disableAreaCodes
                                    disabled={isresturantnumberverified}
                                    defaultCountry={'in'}
                                    value={values.restaurantMobileNumber}
                                    onChange={resturantphoneChange}
                                /> */}

                <TextField
                  fullWidth
                  type="tel"
                  label="Restaurant Mobile Number *"
                  value={values.restaurantMobileNumber}
                  onChange={(e) => resturantphoneChange(e)}
                  disabled={isresturantnumberverified}
                  onKeyPress={handleKeyPress}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+91</InputAdornment>
                    ),
                    inputProps: {
                      maxLength: 10, // Set max length to 10 digits
                      pattern: "\\d*", // Only allow digits
                    },
                  }}
                  inputProps={{
                    onInput: (e) => {
                      // Prevent input if length is greater than 10
                      if (e.target.value.length > 10) {
                        e.target.value = e.target.value.slice(0, 10);
                      }
                    },
                  }}
                />
                {errors.restaurantMobileNumber &&
                  touched.restaurantMobileNumber ? (
                  <p className="Login_form-error">
                    {errors.restaurantMobileNumber}
                  </p>
                ) : null}
              </div>

              {showResOtpResendbtn ? (
                <button
                  type="button"
                  className="verify_btn"
                  onClick={handleresturantverify}
                >
                  Resend OTP
                </button>
              ) : (
                <>
                  {isresturantnumberverified ? (
                    <button className="verified_btn" type="button">
                      Verified
                    </button>
                  ) : (
                    <button
                      className={`verify_btn ${!values.restaurantMobileNumber ? "cursornodrop" : ""
                        } `}
                      type="button"
                      disabled={!values.restaurantMobileNumber}
                      onClick={handleresturantverify}
                    >
                      Verify
                    </button>
                  )}
                </>
              )}
            </div>
          </>

          {isresturantnumberverified ? (
            ""
          ) : (

            <div className="otp_input_container">
              <OtpInput
                skipDefaultStyles="true"
                inputStyle='otp_input_type'
                value={resturantdigitsArray}
                onChange={setResturantdigitsArray}
                numInputs={4}
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
              />
            </div>



            // <div>
            //   <input
            //     id="input1"
            //     onKeyUp={Boxchange1}
            //     maxLength="1"
            //     className="box"
            //     type="text"
            //     value={resturantdigitsArray?.[0]}
            //   />
            //   <input
            //     id="input2"
            //     onKeyUp={Boxchange2}
            //     maxLength="1"
            //     className="box"
            //     type="text"
            //     value={resturantdigitsArray?.[1]}
            //   />
            //   <input
            //     id="input3"
            //     onKeyUp={Boxchange3}
            //     maxLength="1"
            //     className="box"
            //     type="text"
            //     value={resturantdigitsArray?.[2]}
            //   />
            //   <input
            //     id="input4"
            //     onKeyUp={Boxchange4}
            //     maxLength="1"
            //     className="box"
            //     type="text"
            //     value={resturantdigitsArray?.[3]}
            //   />
            // </div>
          )}

          <div className="mobile_container">
            {/* <TextField
                            className='countrycode_input'
                            variant="outlined"
                            id="restaurantLandlineNumberCountryCode"
                            label="Country Code"
                            name="restaurantLandlineNumberCountryCode"
                            value={values.restaurantLandlineNumberCountryCode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                                touched.restaurantLandlineNumberCountryCode && Boolean(errors.restaurantLandlineNumberCountryCode)
                            }
                            helperText={
                                touched.restaurantLandlineNumberCountryCode && errors.restaurantLandlineNumberCountryCode
                            }
                        /> */}

            <TextField
              variant="outlined"
              id="stdCode"
              label="STD Code"
              name="restaurantLandlineNumberCountryCode"
              value={values.restaurantLandlineNumberCountryCode}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.restaurantLandlineNumberCountryCode &&
                Boolean(errors.restaurantLandlineNumberCountryCode)
              }
              helperText={
                touched.restaurantLandlineNumberCountryCode &&
                errors.restaurantLandlineNumberCountryCode
              }
            />
            <TextField
              variant="outlined"
              fullWidth
              disabled={!values.restaurantLandlineNumberCountryCode}
              id="restaurantLandlineNumber"
              label="Landline Number"
              name="restaurantLandlineNumber"
              value={values.restaurantLandlineNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.restaurantLandlineNumber &&
                Boolean(errors.restaurantLandlineNumber)
              }
              helperText={
                touched.restaurantLandlineNumber &&
                errors.restaurantLandlineNumber
              }
            />
          </div>
        </AccordionDetails>
      </Accordion>

      {/* <button className='imageupload_btn' onClick={submitForm}>Submit</button> */}
    </div>
  );
};

export default RestaurantInfo;
