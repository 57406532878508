import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";
import CloseIcon from "@mui/icons-material/Close";
import InputField from "../../components/input";
import { useFormik } from "formik";
import { Checkbox, CircularProgress, FormControlLabel } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ToastMessage from "../../utils/ToastMessage";
import { signUp_api } from "../../API/baseUrl";
import { loginUser } from "../../redux/actions";
import { signUpshema } from "../../utils/schema";
import logo from "../../images/Full-logo.svg";
import Logo from "./logo";

const initialValues = {
  email: "",
  fullname: "",
};

const SignUp = () => {
  const [pending, setPending] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginInfo } = useSelector((state) => state?.loginUser || {});
  const [checked, setChecked] = React.useState(false);

  const handlecheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  console.log("loginInfo", loginInfo);

  const handlesignup = () => {
    navigate("/");
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: signUpshema,
      onSubmit: () => submitForm(),
    });

  const handleSubmit2 = (e) => {
    e.preventDefault();
    handleSubmit();
    console.log("error", errors);
    console.log("values", values);
  };

  const submitForm = async () => {
    setPending(true);
    const parameter = {
      email: values.email,
      name: values.fullname,
      url: signUp_api,
    };
    dispatch(loginUser(parameter));
  };

  useEffect(() => {
    if (loginInfo?.status) {
      setPending(false);
      localStorage.setItem("otp", loginInfo?.otp);
      localStorage.setItem("vendorIdFk", loginInfo?.vendorIdFk);
      localStorage.setItem("email", values.email);
      localStorage.setItem("emailName", values.fullname);
      if (loginInfo?.isVendorCreated === 1) {
        ToastMessage("error", "vendor already created");
      } else {
        // ToastMessage('success', loginInfo?.message);
        navigate("/verifyotp");
      }
    } else {
      ToastMessage("error", loginInfo?.message);
      setPending(false);
    }
  }, [loginInfo]);

  const gototerms = () => {
    window.open("/Pick'n'Treate/terms", "_blank");
  };

  const gotopolicy = () => {
    window.open("/Pick'n'Treate/policy", "_blank");
  };

  return (
    <div className="login-container">
      <div className="login-container_fluid" style={{ gap: "5px !important" }}>
      <Logo/>
        {/* <div className="login-div">
          <h3>Sign Up</h3>
          <CloseIcon />
        </div> */}

        <form className="signupcontainer" onSubmit={handleSubmit2}>
          <div>
            <InputField
              label="Full Name"
              placeholder="Enter your full name"
              name="fullname"
              value={values.fullname}
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.fullname && touched.fullname ? (
              <p className="form-error">{errors.fullname}</p>
            ) : null}
          </div>

          <div>
            <InputField
              label="Email"
              placeholder="Enter your email"
              name="email"
              value={values.email}
              type="email"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.email && touched.email ? (
              <p className="form-error">{errors.email}</p>
            ) : null}
          </div>
          <div className="checkbox_agreetext_container">
            {" "}
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    color: `var(--colorused)`,
                    "&.Mui-checked": {
                      color: `var(--colorused)`,
                    },
                  }}
                  checked={checked}
                  onChange={handlecheckboxChange}
                />
              }
              label=""
            />
            <p className="agree_text">
              I agree to Pick’n’Treat’s{" "}
              <span onClick={gototerms}>Terms & Service </span>and{" "}
              <span onClick={gotopolicy}>Privacy Policy.</span> {" "}
            </p>
          </div>

          <button
            disabled={pending || !checked}
            className={`login-btn ${!checked ? "cursornodrop" : ""} `}
            type="submit"
          >
            {pending ? (
              <CircularProgress size={24} style={{ color: "white" }} />
            ) : (
              "Sign Up"
            )}
          </button>
        </form>

        {/* <p className="class-or">Or</p>

                <p className="social-media-btn">Continue with Google</p> */}

        <p className="creatperatext">
          Already have an account?
          <button className="createaccount_btn" onClick={handlesignup}>
            Login
          </button>
        </p>
      </div>
    </div>
  );
};

export default SignUp;
