import React, { useEffect, useState } from "react";
import { Button, TextField, Box, Stack } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "./index.css";

const ExcelDownloader = ({
  showdatefilter,
  callBack,
  value,
  margin,
  setSearchtext,
  startvalue,
  endvalue,
  setStartvalue,
  setEndvalue,
  handleExcel,
}) => {
  // Initialize start and end dates
  const [startDate, setStartDate] = useState(
    startvalue ? dayjs(startvalue) : null
  );
  const [endDate, setEndDate] = useState(
    endvalue ? dayjs(endvalue) : null
  );

  // Handle download
  const handleDownload = () => {
    setStartvalue(startDate ? startDate.format("DD-MM-YYYY") : "");
    setEndvalue(endDate ? endDate.format("DD-MM-YYYY") : "");
    handleExcel();
    callBack(value);
  };

  // Update parent values on date change
  useEffect(() => {
    if (startDate) {
      setStartvalue(startDate);
    }
    if (endDate) {
      setEndvalue(endDate);
    }
  }, [startDate, endDate]);

  // Disable dates for start date
  const disableStartDate = (date) => {
    const today = dayjs().endOf("day");
    const maxDate = endDate || today; // Start date cannot exceed end date or today
    return date.isAfter(maxDate, "day");
  };

  // Disable dates for end date
  const disableEndDate = (date) => {
    const today = dayjs().endOf("day");
    const minDate = startDate || dayjs().startOf("day"); // End date cannot be before start date
    return date.isBefore(minDate, "day") || date.isAfter(today, "day");
  };

  return (
    <Box className="page_heading_div_excel">
      <Stack direction="row" spacing={2} alignItems="center">
        {showdatefilter && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div
              style={{
                width: "200px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <DatePicker
                label="From"
                size="small"
                value={startDate}
                onChange={(newValue) => setStartDate(dayjs(newValue))}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    style={{ width: "100%" }}
                  />
                )}
                format="DD-MM-YYYY"
                shouldDisableDate={disableStartDate}
              />
            </div>
            <div
              style={{
                width: "200px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <DatePicker
                label="To"
                size="small"
                value={endDate}
                onChange={(newValue) => setEndDate(dayjs(newValue))}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    style={{ width: "100%" }}
                  />
                )}
                format="DD-MM-YYYY"
                shouldDisableDate={disableEndDate}
              />
            </div>
          </LocalizationProvider>
        )}
      </Stack>
    </Box>
  );
};

export default ExcelDownloader;
