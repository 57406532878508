import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { BiSolidShoppingBags } from "react-icons/bi";
import { Autocomplete, Switch, TextField } from '@mui/joy';
import './index.css';
import { FormControlLabel } from "@mui/material";
import InputField from "../../../components/input";
import { useFormik } from "formik";
import { removeDataFromReducer, surprisebagDetails, updateInstruction } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { surprisebagDetails_api, update_instruction_api } from "../../../API/baseUrl";
import ToastMessage from "../../../utils/ToastMessage";
import LocalMallIcon from '@mui/icons-material/LocalMall';
import { styled } from '@mui/material/styles';


const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 52,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: `var(--colorused)`,
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? `var(--colorused)` : `var(--colorused)`,
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: `var(--colorused)`,
        border: `6px solid var(--colorused)`,
      },
      
     
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? `var(--colorused)` : `var(--colorused)`,
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));
  







const initialValues = {
    surpriseBagPackaging: "",
    surpriseBagPickupInstruction: "",
    surpriseBagBuffetFoodSafety: "",
    surpriseBagStoringAndAllergens: "",
}


function Information({ id ,shobtn}) {
    const options = ['one', 'two', 'three'];
    const defaultOption = options[0];

    const [edit, setEdit] = useState(false);
    const [activebtn, setActivebtn] = useState(false);
    const [packingval, setPackingval] = useState();
    const dispatch = useDispatch();
    const { updateInstructionsData } = useSelector((state) => state?.updateInstructionReduser || {});
    const { surprisebagDetailsData } = useSelector((state) => state?.surprisebagDetailsReduser || {});
    const [checked, setChecked] = React.useState(true);

    const handleswitchChange = (event) => {
        setChecked(event.target.checked);
        values.surpriseBagBuffetFoodSafety = event.target.checked
    };

    const handleEditBtn = () => {
        setEdit(!edit)
    }



    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } =
        useFormik({
            initialValues,
            validationSchema: "",
            onSubmit: (value) => submitForm(value)
        });


    const handleformsubmit = (e) => {
        e.preventDefault()
        handleSubmit()
    }



    useEffect(() => {
        const parameter = {
            url: `${surprisebagDetails_api}${id}`,
          };
        if (updateInstructionsData?.status) {
            ToastMessage('success', updateInstructionsData?.message);
            dispatch(removeDataFromReducer("UPDATE_INSTRUCTION_RESET"));
            dispatch(surprisebagDetails(parameter));
            setEdit(!edit)
        } else {
            ToastMessage('error', updateInstructionsData?.message);
        }
    }, [updateInstructionsData]);


    useEffect(() => {
        if (surprisebagDetailsData?.status) {
            values.surpriseBagPackaging = surprisebagDetailsData?.data.surpriseBagPackaging
            values.surpriseBagPickupInstruction = surprisebagDetailsData?.data.surpriseBagPickupInstruction
            values.surpriseBagBuffetFoodSafety = surprisebagDetailsData?.data.surpriseBagBuffetFoodSafety
            values.surpriseBagStoringAndAllergens = surprisebagDetailsData?.data.surpriseBagStoringAndAllergens
            setPackingval(surprisebagDetailsData?.data.surpriseBagPackaging)
            setChecked(surprisebagDetailsData?.data.surpriseBagBuffetFoodSafety == 1 ? true : false)
        } else {
            ToastMessage('error', surprisebagDetailsData?.message);
        }
    }, [surprisebagDetailsData]);



    const submitForm = () => {

        const parameter = {
            surpriseBagIdFk: id,
            surpriseBagPackaging: values.surpriseBagPackaging,
            surpriseBagPickupInstruction: values.surpriseBagPickupInstruction,
            surpriseBagBuffetFoodSafety: values.surpriseBagBuffetFoodSafety === true ? 1 : 0,
            surpriseBagStoringAndAllergens: values.surpriseBagStoringAndAllergens,
            url: update_instruction_api,
        };
        dispatch(updateInstruction(parameter));
    }

    const handlevalueChange = (e, value) => {
        if (value === "pickup") {
            values.surpriseBagPickupInstruction = e.target.value
            setActivebtn(true)
        }
        else {
            values.surpriseBagStoringAndAllergens = e.target.value
            setActivebtn(true)
        }

    }



    const handledropdownChange = (event, newValue) => {
        values.surpriseBagPackaging = newValue.label
        setPackingval(newValue)
        setActivebtn(true)
    }




    const packinglist = [
        { label: 'Food container and bag', id: 1 },
        { label: 'Food container and bag , customer may bring their own bag', id: 2 },
        { label: 'Food container, no bag', id: 3 },
    ]


    return (

        <div className='dashboardoverview_container_fluid'>
            <form onSubmit={handleformsubmit} className='dashboard_home_main_container'>
                <div className='d-flex'>
                    <h3 className='surprisebag_list_heading'>Instructions</h3>
                    {shobtn ? 
                    
                    <>
                        {
                        edit === true ? <p onClick={handleEditBtn} className='overview_edit_btn'>cancel</p> : <p onClick={handleEditBtn} className='overview_edit_btn'>Edit</p>
                    }
                    </>
                    :""
                    }
                   
                </div>

                <hr></hr>
                <div className='surprise_bag_list_container'>

                    <div className='overview_details_inside_contain'>

                        <div className='m-b-10'>
                            <h5 className='instructions_details_h'><LocalMallIcon sx={{ fontSize: "20px" }} />  Packaging</h5>
                            <p className='instruction_details_p'>To ensure a smooth pickup, select the packaging type you will offer.</p>

                            {
                                edit ?
                                    <Autocomplete
                                        className="autocomplete_instruction"
                                        placeholder=""
                                        options={packinglist}
                                        sx={{}}
                                        defaultValue={packingval}
                                        onChange={(event, newValue) => handledropdownChange(event, newValue)}
                                    />

                                    : <p className="instruction_value_p">{values.surpriseBagPackaging ? values.surpriseBagPackaging : "Food container and bag"}</p>
                            }

                        </div>


                        <div className='m-b-10 m-t-10'>
                            <h5 className='instructions_details_h'><LocalMallIcon sx={{ fontSize: "20px" }} /> Pick-up instructions</h5>
                            <p className='instruction_details_p'>You can add additional instructions regarding pickup here, and they will be shown in the app.</p>


                            {
                                edit ?
                                    <InputField label="" placeholder="For example: 'The store is located inside the train station'"
                                        name="surpriseBagPickupInstruction" value={values.surpriseBagPickupInstruction} type="text"
                                        // onChange={(e) => handlevalueChange(e, "pickup")}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    : <p className="instruction_value_p">{values.surpriseBagPickupInstruction ? values.surpriseBagPickupInstruction : "No additional instructions"}</p>
                            }




                        </div>

                        <div className='m-b-10  m-t-10'>
                            <h5 className='instructions_details_h'><LocalMallIcon sx={{ fontSize: "20px" }} /> Buffet food safety</h5>
                            <p className='instruction_details_p'>Let us know if this Magic Bag contains food from a buffet. Users will see buffet-specific food safety recommendations in the app.</p>

                            {
                                edit ?
                                    <p className="instruction_value_switch">This Magic Bag contains food from a buffet

                                        <FormControlLabel className="buffet_switch" control={
                                        //     <Switch
                                        //         //  color="#2a666b" 
                                        //         size="large"
                                        //         checked={checked}
                                        //         onChange={handleswitchChange}
                                        //         inputProps={{ 'aria-label': 'controlled' }}
                                        //     />

                                        // } 
                                        <IOSSwitch  onChange={handleswitchChange} checked={checked} inputProps={{ 'aria-label': 'controlled' }}/>}
                                        label="" /></p>


                                    : <p className="instruction_value_p">{checked ? "This Magic Bag contains food from a buffet" : "This Magic Bag doesn't contain food from a buffet"}</p>
                            }


                        </div>

                        <div className='m-b-10 m-t-10'>
                            <h5 className='instructions_details_h'><LocalMallIcon sx={{ fontSize: "20px" }} /> Storing and allergens</h5>
                            <p className='instruction_details_p'>You can add recommendations for storing and handling food, including warnings about allergens, here, and they will be shown in the app.</p>


                            {
                                edit ?
                                    <InputField label="" placeholder="For example: 'Store thr products refrigerated for a maximum of 2 days'" name="surpriseBagStoringAndAllergens" value={values.surpriseBagStoringAndAllergens} type="text"
                                        // onChange={(e) => handlevalueChange(e, "storing")}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    : <p className="instruction_value_p">{values.surpriseBagStoringAndAllergens ? values.surpriseBagStoringAndAllergens : "No additional instructions"}</p>
                            }


                        </div>


        



                    </div>



                </div>

                {edit && (
                    <ListGroup.Item>
                        <div style={{ textAlign: "center", margin: "10px" }}>
                            <button 
                            // disabled={!activebtn} 
                            type='submit' className="bttnnn1">Save changes</button>
                        </div>
                    </ListGroup.Item>
                )}
            </form>


        </div>


    );
}

export default Information;
