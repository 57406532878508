import React, { useEffect, useState } from "react";
import { Col, Dropdown, Form, Row } from "react-bootstrap";
import { bagsize_list_api } from "../../API/baseUrl";
import { getbagsize } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import ToastMessage from "../../utils/ToastMessage";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import "./index.css";

const BagSizeListComp = ({
  values,
  errors,
  handleBlur,
  handleChange,
  id,
  setContinueactive,
  setShowRecommendation
}) => {
  const dispatch = useDispatch();
  const { bagsizelistData } = useSelector(
    (state) => state?.bagsizelistreduser || {}
  );
  const [data, setData] = useState([]);
  const vendorIdFk = localStorage.getItem("restaurantTypeIdFK");

  const handleradiochange = (e) => {
    values.surpriseBagSizeIdFK = e.target.value;
    setContinueactive(true);
  };

  // useEffect(() => {
  //   if (bagsizelistData?.status) {
  //     setData(bagsizelistData?.data);
  //   } else {
  //     ToastMessage("error", bagsizelistData?.message);
  //   }
  // }, [bagsizelistData]);


  useEffect(() => {
    if (bagsizelistData?.status) {
      setData(bagsizelistData?.data);

      const hasMediumSize = bagsizelistData?.data?.some(item => 
        item.surpriseBagSize === "medium" || item.surpriseBagSize === "Medium"
      );
      setShowRecommendation(hasMediumSize)
    } else {
      ToastMessage("error", bagsizelistData?.message);
    }
  }, [bagsizelistData]);

  useEffect(() => {
    const parameter = {
      url: `${bagsize_list_api}${vendorIdFk}`,
    };
    dispatch(getbagsize(parameter));
  }, []);

  return (
    <div
      style={{
        // border: "1px solid #b8dbb3",
        borderRadius: "10px",
        margin: "3%",
        marginTop: "10px",
      }}
    >
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue=""
        name="radio-buttons-group"
        onChange={handleradiochange}
      >
        {data?.map((item, index) => (
          <div className="size_container bigsize" onClick={handleradiochange}>
            <FormControlLabel
              className=""
              value={item.surpriseBagSizeIdFK}
              control={
                <Radio
                  sx={{
                    color: `var(--colorused)`,
                    "&.Mui-checked": {
                      color: `var(--colorused)`,
                    },
                  }}
                />
              }
              // label={item.surpriseBagSize}
              label={
                <div className="bag_size_lable">
                  <p className="margin-0 ">{item.surpriseBagSize}</p>

                  {item.surpriseBagSize === "Medium" ? (
                    <p className="medium_recommended_value">
                      Recommended for you
                    </p>
                  ) : (
                    ""
                  )}
                  <div className="text-align-end">
                    <h3 className="margin-0 bagPrice"   sx={{ fontSize: "18px" }}>
                      {/* <CurrencyRupeeIcon
                        sx={{ fontSize: "18px" }}
                      />{" "} */}
                      ₹{item.surpriseBagRetailPrice}
                    </h3>
                    <p className="margin-0">minimum price</p>
                    <p className="margin-0 align">
                      {/* <CurrencyRupeeIcon
                        sx={{ fontSize: "18px" }}
                      />{" "} */}
                     ₹{item.surpriseBagSellingPrice} price in app
                    </p>
                  </div>
                </div>
              }
            />
          </div>
        ))}
      </RadioGroup>
    </div>
  );
};

export default BagSizeListComp;
