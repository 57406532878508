
import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import "./index.css"

export default function BagSizeCategory({ values, errors, handleBlur, handleChange ,continueactive, setContinueactive}) {

  const handleradiochange = (e) => {
    values.surpriseBagCategory = e.target.value
    setContinueactive(true)
  }


  return (
    <div>
      <FormControl sx={{ width: "50%", padding: "10px" }}>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue=""
          name="radio-buttons-group"
          onChange={handleradiochange}
        >
          <FormControlLabel className='formcontrol_radio' value="Meals" control={<Radio  sx={{
          color: `var(--colorused)`,
          '&.Mui-checked': {
            color: `var(--colorused)`,
          },
        }} />} label="Meals" />
          <FormControlLabel className='formcontrol_radio' value="Bread & Pastries" control={<Radio  sx={{
          color: `var(--colorused)`,
          '&.Mui-checked': {
            color: `var(--colorused)`,
          },
        }} />} label="Bread & Pastries" />
          <FormControlLabel className='formcontrol_radio' value="Groceries" control={<Radio sx={{
          color: `var(--colorused)`,
          '&.Mui-checked': {
            color: `var(--colorused)`,
          },
        }}  />} label="Groceries" />
          <FormControlLabel className='formcontrol_radio' value="Other" control={<Radio sx={{
          color: `var(--colorused)`,
          '&.Mui-checked': {
            color: `var(--colorused)`,
          },
        }} />} label="Other" />
        </RadioGroup>
      </FormControl>

    </div>
  );
}
