
export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const LOGIN_USER_RESET = 'LOGIN_USER_RESET';

export const SEND_EMAIL_REQUEST = 'SEND_EMAIL_REQUEST';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_ERROR = 'SEND_EMAIL_ERROR';
export const SEND_EMAIL_RESET = 'SEND_EMAIL_RESET';

export const UPLOAD_DOCUMENT_REQUEST = 'UPLOAD_DOCUMENT_REQUEST';
export const UPLOAD_DOCUMENT_SUCCESS = 'UPLOAD_DOCUMENT_SUCCESS';
export const UPLOAD_DOCUMENT_ERROR = 'UPLOAD_DOCUMENT_ERROR';
export const UPLOAD_DOCUMENT_RESET = 'UPLOAD_DOCUMENT_RESET';

export const DASHBOARD_DATA_REQUEST = 'DASHBOARD_DATA_REQUEST';
export const DASHBOARD_DATA_SUCCESS = 'DASHBOARD_DATA_SUCCESS';
export const DASHBOARD_DATA_ERROR = 'DASHBOARD_DATA_ERROR';
export const DASHBOARD_DATA_RESET = 'DASHBOARD_DATA_RESET';

export const NOTIFICATION_DATA_REQUEST = 'NOTIFICATION_DATA_REQUEST';
export const NOTIFICATION_DATA_SUCCESS = 'NOTIFICATION_DATA_SUCCESS';
export const NOTIFICATION_DATA_ERROR = 'NOTIFICATION_DATA_ERROR';
export const NOTIFICATION_DATA_RESET = 'NOTIFICATION_DATA_RESET';

export const RESTURANT_DETAILS_REQUEST = 'RESTURANT_DETAILS_REQUEST';
export const RESTURANT_DETAILS_SUCCESS = 'RESTURANT_DETAILS_SUCCESS';
export const RESTURANT_DETAILS_ERROR = 'RESTURANT_DETAILS_ERROR';
export const RESTURANT_DETAILS_RESET = 'RESTURANT_DETAILS_RESET';

export const RESTURANT_TYPE_REQUEST = 'RESTURANT_TYPE_REQUEST';
export const RESTURANT_TYPE_SUCCESS = 'RESTURANT_TYPE_SUCCESS';
export const RESTURANT_TYPE_ERROR = 'RESTURANT_TYPE_ERROR';
export const RESTURANT_TYPE_RESET = 'RESTURANT_TYPE_RESET';

export const CUSINS_LIST_REQUEST = 'CUSINS_LIST_REQUEST';
export const CUSINS_LIST_SUCCESS = 'CUSINS_LIST_SUCCESS';
export const CUSINS_LIST_ERROR = 'CUSINS_LIST_ERROR';
export const CUSINS_LIST_RESET = 'CUSINS_LIST_RESET';

export const MOBILE_VERIFY_REQUEST = 'MOBILE_VERIFY_REQUEST';
export const MOBILE_VERIFY_SUCCESS = 'MOBILE_VERIFY_SUCCESS';
export const MOBILE_VERIFY_ERROR = 'MOBILE_VERIFY_ERROR';
export const MOBILE_VERIFY_RESET = 'MOBILE_VERIFY_RESET';

export const MOBILE_OTP_REQUEST = 'MOBILE_OTP_REQUEST';
export const MOBILE_OTP_SUCCESS = 'MOBILE_OTP_SUCCESS';
export const MOBILE_OTP_ERROR = 'MOBILE_OTP_ERROR';
export const MOBILE_OTP_RESET = 'MOBILE_OTP_RESET';

export const RESTURANT_OTP_REQUEST = 'RESTURANT_OTP_REQUEST';
export const RESTURANT_OTP_SUCCESS = 'RESTURANT_OTP_SUCCESS';
export const RESTURANT_OTP_ERROR = 'RESTURANT_OTP_ERROR';
export const RESTURANT_OTP_RESET = 'RESTURANT_OTP_RESET';

export const VERIFY_OWNEROTP_REQUEST = 'VERIFY_OWNEROTP_REQUEST';
export const VERIFY_OWNEROTP_SUCCESS = 'VERIFY_OWNEROTP_SUCCESS';
export const VERIFY_OWNEROTP_ERROR = 'VERIFY_OWNEROTP_ERROR';
export const VERIFY_OWNEROTP_RESET = 'VERIFY_OWNEROTP_RESET';

export const VERIFY_RESTURANTOTP_REQUEST = 'VERIFY_RESTURANTOTP_REQUEST';
export const VERIFY_RESTURANTOTP_SUCCESS = 'VERIFY_RESTURANTOTP_SUCCESS';
export const VERIFY_RESTURANTOTP_ERROR = 'VERIFY_RESTURANTOTP_ERROR';
export const VERIFY_RESTURANTOTP_RESET = 'VERIFY_RESTURANTOTP_RESET';


export const CREATE_RESTURANT_REQUEST = 'CREATE_RESTURANT_REQUEST';
export const CREATE_RESTURANT_SUCCESS = 'CREATE_RESTURANT_SUCCESS';
export const CREATE_RESTURANT_ERROR = 'CREATE_RESTURANT_ERROR';
export const CREATE_RESTURANT_RESET = 'CREATE_RESTURANT_RESET';


export const SET_PASSWORD_REQUEST = 'SET_PASSWORD_REQUEST';
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS';
export const SET_PASSWORD_ERROR = 'SET_PASSWORD_ERROR';
export const SET_PASSWORD_RESET = 'SET_PASSWORD_RESET';


export const VENDOR_LOGIN_REQUEST = 'VENDOR_LOGIN_REQUEST';
export const VENDOR_LOGIN_SUCCESS = 'VENDOR_LOGIN_SUCCESS';
export const VENDOR_LOGIN_ERROR = 'VENDOR_LOGIN_ERROR';
export const VENDOR_LOGIN_RESET = 'VENDOR_LOGIN_RESET';

export const BANK_LIST_REQUEST = 'BANK_LIST_REQUEST';
export const BANK_LIST_SUCCESS = 'BANK_LIST_SUCCESS';
export const BANK_LIST_ERROR = 'BANK_LIST_ERROR';
export const BANK_LIST_RESET = 'BANK_LIST_RESET';

export const BAGSIZE_LIST_REQUEST = 'BAGSIZE_LIST_REQUEST';
export const BAGSIZE_LIST_SUCCESS = 'BAGSIZE_LIST_SUCCESS';
export const BAGSIZE_LIST_ERROR = 'BAGSIZE_LIST_ERROR';
export const BAGSIZE_LIST_RESET = 'BAGSIZE_LIST_RESET';

export const CREATE_BAG_REQUEST = 'CREATE_BAG_REQUEST';
export const CREATE_BAG_SUCCESS = 'CREATE_BAG_SUCCESS';
export const CREATE_BAG_ERROR = 'CREATE_BAG_ERROR';
export const CREATE_BAG_RESET = 'CREATE_BAG_RESET';


export const Bag_List_REQUEST = 'Bag_List_REQUEST';
export const Bag_List_SUCCESS = 'Bag_List_SUCCESS';
export const Bag_List_ERROR = 'Bag_List_ERROR';
export const Bag_List_RESET = 'Bag_List_RESET';


export const DOCUMENTS_DETAILS_REQUEST = 'DOCUMENTS_DETAILS_REQUEST';
export const DOCUMENTS_DETAILS_SUCCESS = 'DOCUMENTS_DETAILS_SUCCESS';
export const DOCUMENTS_DETAILS_ERROR = 'DOCUMENTS_DETAILS_ERROR';
export const DOCUMENTS_DETAILS_RESET = 'DOCUMENTS_DETAILS_RESET';


export const SURPRISEBAG_DETAILS_REQUEST = 'SURPRISEBAG_DETAILS_REQUEST';
export const SURPRISEBAG_DETAILS_SUCCESS = 'SURPRISEBAG_DETAILS_SUCCESS';
export const SURPRISEBAG_DETAILS_ERROR = 'SURPRISEBAG_DETAILS_ERROR';
export const SURPRISEBAG_DETAILS_RESET = 'SURPRISEBAG_DETAILS_RESET';


export const UPDATE_INSTRUCTION_REQUEST = 'UPDATE_INSTRUCTION_REQUEST';
export const UPDATE_INSTRUCTION_SUCCESS = 'UPDATE_INSTRUCTION_SUCCESS';
export const UPDATE_INSTRUCTION_ERROR = 'UPDATE_INSTRUCTION_ERROR';
export const UPDATE_INSTRUCTION_RESET = 'UPDATE_INSTRUCTION_RESET';


export const UPDATE_OVERVIEW_REQUEST = 'UPDATE_OVERVIEW_REQUEST';
export const UPDATE_OVERVIEW_SUCCESS = 'UPDATE_OVERVIEW_SUCCESS';
export const UPDATE_OVERVIEW_ERROR = 'UPDATE_OVERVIEW_ERROR';
export const UPDATE_OVERVIEW_RESET = 'UPDATE_OVERVIEW_RESET';


export const UPDATE_SHEDULE_REQUEST = 'UPDATE_SHEDULE_REQUEST';
export const UPDATE_SHEDULE_SUCCESS = 'UPDATE_SHEDULE_SUCCESS';
export const UPDATE_SHEDULE_ERROR = 'UPDATE_SHEDULE_ERROR';
export const UPDATE_SHEDULE_RESET = 'UPDATE_SHEDULE_RESET';


export const UPDATE_CALENDER_REQUEST = 'UPDATE_CALENDER_REQUEST';
export const UPDATE_CALENDER_SUCCESS = 'UPDATE_CALENDER_SUCCESS';
export const UPDATE_CALENDER_ERROR = 'UPDATE_CALENDER_ERROR';
export const UPDATE_CALENDER_RESET = 'UPDATE_CALENDER_RESET';






export const GET_PICKUPWINDOWDATA_REQUEST = 'GET_PICKUPWINDOWDATA_REQUEST';
export const GET_PICKUPWINDOWDATA_SUCCESS = 'GET_PICKUPWINDOWDATA_SUCCESS';
export const GET_PICKUPWINDOWDATA_ERROR = 'GET_PICKUPWINDOWDATA_ERROR';
export const GET_PICKUPWINDOWDATA_RESET = 'GET_PICKUPWINDOWDATA_RESET';


export const ORDER_LIST_REQUEST = 'ORDER_LIST_REQUEST';
export const ORDER_LIST_SUCCESS = 'ORDER_LIST_SUCCESS';
export const ORDER_LIST_ERROR = 'ORDER_LIST_ERROR';
export const ORDER_LIST_RESET = 'ORDER_LIST_RESET';


export const ORDER_DEATILS_REQUEST='ORDER_DEATILS_REQUEST';
export const ORDER_DEATILS_SUCCESS='ORDER_DEATILS_SUCCESS';
export const ORDER_DEATILS_ERROR='ORDER_DEATILS_ERROR';
export const ORDER_DEATILS_RESET='ORDER_DEATILS_RESET';


export const ACTIVE_SURPRISEBAG_REQUEST = 'ACTIVE_SURPRISEBAG_REQUEST';
export const ACTIVE_SURPRISEBAG_SUCCESS = 'ACTIVE_SURPRISEBAG_SUCCESS';
export const ACTIVE_SURPRISEBAG_ERROR = 'ACTIVE_SURPRISEBAG_ERROR';
export const ACTIVE_SURPRISEBAG_RESET = 'ACTIVE_SURPRISEBAG_RESET';


export const CHANGE_ORDERSTATUS_REQUEST = 'CHANGE_ORDERSTATUS_REQUEST';
export const CHANGE_ORDERSTATUS_SUCCESS = 'CHANGE_ORDERSTATUS_SUCCESS';
export const CHANGE_ORDERSTATUS_ERROR = 'CHANGE_ORDERSTATUS_ERROR';
export const CHANGE_ORDERSTATUS_RESET = 'CHANGE_ORDERSTATUS_RESET';


export const EDIT_SURPRISEBAG_REQUEST = 'EDIT_SURPRISEBAG_REQUEST';
export const EDIT_SURPRISEBAG_SUCCESS = 'EDIT_SURPRISEBAG_SUCCESS';
export const EDIT_SURPRISEBAG_ERROR = 'EDIT_SURPRISEBAG_ERROR';
export const EDIT_SURPRISEBAG_RESET = 'EDIT_SURPRISEBAG_RESET';


export const EDIT_LOGO_REQUEST = 'EDIT_LOGO_REQUEST';
export const EDIT_LOGO_SUCCESS = 'EDIT_LOGO_SUCCESS';
export const EDIT_LOGO_ERROR = 'EDIT_LOGO_ERROR';
export const EDIT_LOGO_RESET = 'EDIT_LOGO_RESET';


export const EDIT_COVERPHOTO_REQUEST = 'EDIT_COVERPHOTO_REQUEST';
export const EDIT_COVERPHOTO_SUCCESS = 'EDIT_COVERPHOTO_SUCCESS';
export const EDIT_COVERPHOTO_ERROR = 'EDIT_COVERPHOTO_ERROR';
export const EDIT_COVERPHOTO_RESET = 'EDIT_COVERPHOTO_RESET';


export const RESEND_MOBILEOTP_REQUEST = 'RESEND_MOBILEOTP_REQUEST';
export const RESEND_MOBILEOTP_SUCCESS = 'RESEND_MOBILEOTP_SUCCESS';
export const RESEND_MOBILEOTP_ERROR = 'RESEND_MOBILEOTP_ERROR';
export const RESEND_MOBILEOTP_RESET = 'RESEND_MOBILEOTP_RESET';


export const FORGET_PASSWORD_REQUEST = 'FORGET_PASSWORD_REQUEST';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_ERROR = 'FORGET_PASSWORD_ERROR';
export const FORGET_PASSWORD_RESET = 'FORGET_PASSWORD_RESET';


export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const RESET_PASSWORD_RESET = 'RESET_PASSWORD_RESET';


export const GET_STATUS_REQUEST = 'GET_STATUS_REQUEST';
export const GET_STATUS_SUCCESS = 'GET_STATUS_SUCCESS';
export const GET_STATUS_ERROR = 'GET_STATUS_ERROR';
export const GET_STATUS_RESET = 'GET_STATUS_RESET';


export const ADDMORE_SURPRISEBAG_REQUEST = 'ADDMORE_SURPRISEBAG_REQUEST';
export const ADDMORE_SURPRISEBAG_SUCCESS = 'ADDMORE_SURPRISEBAG_SUCCESS';
export const ADDMORE_SURPRISEBAG_ERROR = 'ADDMORE_SURPRISEBAG_ERROR';
export const ADDMORE_SURPRISEBAG_RESET = 'ADDMORE_SURPRISEBAG_RESET';


export const CREATE_TICKET_REQUEST = 'CREATE_TICKET_REQUEST';
export const CREATE_TICKET_SUCCESS = 'CREATE_TICKET_SUCCESS';
export const CREATE_TICKET_ERROR = 'CREATE_TICKET_ERROR';
export const CREATE_TICKET_RESET = 'CREATE_TICKET_RESET';


export const GET_PRIVACY_REQUEST = 'GET_PRIVACY_REQUEST';
export const GET_PRIVACY_SUCCESS = 'GET_PRIVACY_SUCCESS';
export const GET_PRIVACY_ERROR = 'GET_PRIVACY_ERROR';
export const GET_PRIVACY_RESET = 'GET_PRIVACY_RESET';


export const GET_FAQ_REQUEST = 'GET_FAQ_REQUEST';
export const GET_FAQ_SUCCESS = 'GET_FAQ_SUCCESS';
export const GET_FAQ_ERROR = 'GET_FAQ_ERROR';
export const GET_FAQ_RESET = 'GET_FAQ_RESET';


export const VERIFY_CODE_REQUEST = 'VERIFY_CODE_REQUEST';
export const VERIFY_CODE_SUCCESS = 'VERIFY_CODE_SUCCESS';
export const VERIFY_CODE_ERROR = 'VERIFY_CODE_ERROR';
export const VERIFY_CODE_RESET = 'VERIFY_CODE_RESET';


export const RESERVATION_LIST_REQUEST = 'RESERVATION_LIST_REQUEST';
export const RESERVATION_LIST_SUCCESS = 'RESERVATION_LIST_SUCCESS';
export const RESERVATION_LIST_ERROR = 'RESERVATION_LIST_ERROR';
export const RESERVATION_LIST_RESET = 'RESERVATION_LIST_RESET';


export const SPECIALDAYS_DATA_REQUEST = 'SPECIALDAYS_DATA_REQUEST';
export const SPECIALDAYS_DATA_SUCCESS = 'SPECIALDAYS_DATA_SUCCESS';
export const SPECIALDAYS_DATA_ERROR = 'SPECIALDAYS_DATA_ERROR';
export const SPECIALDAYS_DATA_RESET = 'SPECIALDAYS_DATA_RESET';



export const Excle_Download_REQUEST = 'Excle_Download_REQUEST';
export const Excle_Download_SUCCESS = 'Excle_Download_SUCCESS';
export const Excle_Download_ERROR = 'Excle_Download_ERROR';
export const Excle_Download_RESET = 'Excle_Download_RESET'


export const Support_Ticket_By_id_REQUEST = 'Support_Ticket_By_id_REQUEST';
export const Support_Ticket_By_id_SUCCESS = 'Support_Ticket_By_id_SUCCESS';
export const Support_Ticket_By_id_ERROR = 'Support_Ticket_By_id_ERROR';
export const Support_Ticket_By_id_RESET = 'Support_Ticket_By_id_RESET'


export const submitTicketChatData_REQUEST = 'submitTicketChatData_REQUEST';
export const submitTicketChatData_SUCCESS = 'submitTicketChatData_SUCCESS';
export const submitTicketChatData_ERROR = 'submitTicketChatData_ERROR';
export const submitTicketChatData_RESET = 'submitTicketChatData_RESET'


export const GetTicketChatData_REQUEST = 'GetTicketChatData_REQUEST';
export const GetTicketChatData_SUCCESS = 'GetTicketChatData_SUCCESS';
export const GetTicketChatData_ERROR = 'GetTicketChatData_ERROR';
export const GetTicketChatData_RESET = 'GetTicketChatData_RESET'
 
export const Change_Password_REQUEST = 'Change_Password_REQUEST';
export const Change_Password_SUCCESS = 'Change_Password_SUCCESS';
export const Change_Password_ERROR = 'Change_Password_ERROR';
export const Change_Password_RESET = 'Change_Password_RESET';
 
export const NOTIFICATION_READ_REQUEST = 'NOTIFICATION_READ_REQUEST';
export const NOTIFICATION_READ_SUCCESS = 'NOTIFICATION_READ_SUCCESS';
export const NOTIFICATION_READ_ERROR = 'NOTIFICATION_READ_ERROR';
export const NOTIFICATION_READ_RESET = 'NOTIFICATION_READ_RESET';
 
export const NOTIFICATION_COUNT_REQUEST = 'NOTIFICATION_COUNT_REQUEST';
export const NOTIFICATION_COUNT_SUCCESS = 'NOTIFICATION_COUNT_SUCCESS';
export const NOTIFICATION_COUNT_ERROR = 'NOTIFICATION_COUNT_ERROR';
export const NOTIFICATION_COUNT_RESET = 'NOTIFICATION_COUNT_RESET';


export const LOGOUT = 'LOGOUT';

