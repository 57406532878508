import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Select, MenuItem, Box, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import './index.css';
import { useDispatch, useSelector } from 'react-redux';
import { reservationListaction } from '../../redux/actions';
import ToastMessage from '../../utils/ToastMessage';
import { reservationList_api } from '../../API/baseUrl';
import { useNavigate } from 'react-router-dom';


const ReservationsTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { reservationListData } = useSelector(
    (state) => state?.reservationListDataReduser || {}
  );
  const [reservations, setReservations] = useState([])
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [sortBy, setSortBy] = useState("DESC");
  const [sortByColumnName, setSortByColumnName] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [pending, setPending] = useState(true);
  const [refresh, setrefresh] = useState(true);




  useEffect(() => {
    if (reservationListData?.status) {
      setReservations(reservationListData?.data);
      setTotalRows(reservationListData?.totalRows);
      setPending(false);
    } else {
      ToastMessage("error", reservationListData?.message);
      setPending(false);
    }
  }, [reservationListData]);

  useEffect(() => {
    const resturantId = localStorage.getItem("restaurantIdFK");
    const parameter = {
      offset: offset,
      limit: limit,
      search: searchText,
      columnName: sortByColumnName,
      sortBy: sortBy.toUpperCase(),
      url: `${reservationList_api}${resturantId}`,
    };
    dispatch(reservationListaction(parameter));
  }, [offset,refresh, limit, searchText, sortByColumnName, sortBy]);



  const handleNavigate = (orderIdFK) => {
    navigate(`/dashboard/createorderlist/${orderIdFK}`);
  };

  const handleRefresh = () => {
    setrefresh(!refresh)
  };

  
  return (
    <Paper className="reservation-table-container">
      <Box className="reservation-header">
        <Typography variant="h6" className="reservation-header-title" gutterBottom>
          Reservations
        </Typography>
        <Box>
          {/* <IconButton>
            <EditIcon />
          </IconButton> */}
          <IconButton onClick={handleRefresh}>
            <RefreshIcon />
          </IconButton>
        </Box>
      </Box>
      <Select defaultValue="All Surprise Bags" fullWidth className="reservation-select">
        <MenuItem value="All Surprise Bags">All Magic Bags</MenuItem>
        {/* Add more menu items if needed */}
      </Select>

      {reservations?.length ?
        <TableContainer component={Paper}>  
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="table-header-cell">Quantity</TableCell>
                <TableCell className="table-header-cell">Name</TableCell>
                <TableCell className="table-header-cell">Price</TableCell>
                <TableCell className="table-header-cell">Status</TableCell>
                <TableCell className="table-header-cell">ID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reservations.map((reservation) => (
                <TableRow key={reservation.id}>
                  <TableCell>{reservation.orderQuantity}</TableCell>
                  <TableCell>{reservation.surpriseBagCategory}</TableCell>
                  <TableCell>Rs. {reservation.totalOrderAmount}</TableCell>
                  <TableCell>
                    <span className={
                      reservation.orderStatus === 'Canceled'
                        ? 'status-canceled'
                        : reservation.orderStatus === 'Picked up'
                          ? 'status-picked-up'
                          : 'status-default'
                    }>
                      {reservation.orderStatus}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span className="id-cell" onClick={()=>handleNavigate(reservation?.orderId)}>
                      {reservation.orderId}
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        :

        <p style={{textAlign:"center"}}>No upcoming Reservations</p>
      }



    </Paper>
  );
};

export default ReservationsTable;