import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import InputField from '../../components/input';
import "./index.css"
import { Autocomplete, CircularProgress, TextareaAutosize, TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { useDispatch, useSelector } from 'react-redux';
import { createTicketAction, removeDataFromReducer } from '../../redux/actions';
import { support_create_api } from '../../API/baseUrl';
import ToastMessage from '../../utils/ToastMessage';
import { FileUploader } from 'react-drag-drop-files';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { addTicketShema } from '../../utils/schema';
const resturantId = localStorage.getItem('restaurantIdFK');


const purposelist = [
    {
        id: 1,
        purpose: "Payment related",
    },
    {
        id: 2,
        purpose: "Magic Bag related",
    },
    {
        id: 3,
        purpose: "Other",
    },
]


const initialValues = {
    file: "",
    // userIdFk : resturantId,
    userType: "vendor",
    mobileNumber: "",
    countryCode: "",
    userName: "",
    email: "",
    purpose: "",
    message: "",
}



const CreateTicket = ({ }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { createTicketData } = useSelector((state) => state?.createTicketReduser || {});
    const [mobilevalue, setMobilevalue] = useState("");
    const [countrycode, setCountrycode] = useState("");
    const [loading, setLoading] = useState(false);
    const [imageURL, setImageURL] = useState("");


    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } =
        useFormik({
            initialValues,
            validateOnBlur: 'true',
            validateOnChange: 'true',
            validationSchema: addTicketShema,
            onSubmit: (value) => submitForm(value)
        });


    const handleformSubmit = (e) => {
        e.preventDefault()
        const resturantId = localStorage.getItem('restaurantIdFK');
        const fd = new FormData();
        fd.append("file", values.file);
        fd.append("userIdFk", resturantId);
        handleSubmit(fd)
    }

    const submitForm = async (value) => {
        setLoading(true)
        const parameter = {
            data: value,
            url: support_create_api,
        };
        dispatch(createTicketAction(parameter));
    }


    useEffect(() => {
        if (createTicketData?.status) {
            setLoading(false)
            ToastMessage('success', createTicketData?.message);
            dispatch(removeDataFromReducer("CREATE_TICKET_RESET"));
            navigate("/dashboard/createTicket")
        } else {
            setLoading(false)
            ToastMessage('error', createTicketData?.message);
        }
    }, [createTicketData]);




    const phoneChange = (e) => {
        setMobilevalue(e.target.value)
        values.mobileNumber = e.target.value
        setCountrycode('+91')
        values.countryCode = '+91'
    }




    const handleKeyPress = (e) => {
        // Prevent non-numeric characters
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
        }
    };


    const handlefileChange = (file, type) => {
        setFieldValue(type, file)
        setImageURL(URL.createObjectURL(file))
    };

    const handlepurposechange = (data, val, type) => {
        setFieldValue("purpose", val.purpose)
    }

    const handleback = () => {
        navigate(-1)
    }

    const fileTypes = ["JPG", "JPEG", "PNG"];

    return (
        <div className='support_create_container'>

            <div className='add_support_heading' >
                <ArrowBackIcon sx={{cursor:"pointer" , marginBottom:"2px"}} onClick={handleback}/>
                <h3> Help Center</h3>
            </div>

            <div style={{ padding: "0px 30px" }}>  <p className='send_us_text'>Send us a message</p>
                <p className='expert_text'>Our experts will reach out to you soon</p></div>








            <div className='create_ticket_container'>
                <div>
                    <InputField label="User Name *" placeholder="User Name" name="userName" value={values.userName} type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {errors.userName && touched.userName ? (
                        <p className="form-error">{errors.userName}</p>
                    ) : null}
                </div>

                <div>
                    <InputField label="Email *" placeholder="Email" name="email" value={values.email} type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />

                    {errors.email && touched.email ? (
                        <p className="form-error">{errors.email}</p>
                    ) : null}
                </div>



                <div className=''>
                    <TextField
                        fullWidth
                        type="tel"
                        name="mobileNumber"
                        label="Mobile Number *"
                        value={values.mobileNumber}
                        onChange={(e) => phoneChange(e)}
                        onKeyPress={handleKeyPress}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">+91</InputAdornment>,
                            inputProps: {
                                maxLength: 10, // Set max length to 10 digits
                                pattern: "\\d*", // Only allow digits
                            },
                        }}
                        inputProps={{
                            onInput: (e) => {
                                // Prevent input if length is greater than 10
                                if (e.target.value.length > 10) {
                                    e.target.value = e.target.value.slice(0, 10);
                                }
                            }
                        }}
                    />
                    {errors.mobileNumber && touched.mobileNumber ? (
                        <p className="form-error">{errors.mobileNumber}</p>
                    ) : null}
                </div>


                <div>
                    <Autocomplete
                        disablePortal
                        disableClearable
                        options={purposelist}
                        getOptionLabel={(option) => option?.purpose}
                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                        onChange={(purposelist, val) => handlepurposechange(purposelist, val, "bankIdFk")}
                        renderInput={(params) => (
                            <TextField
                                onBlur={handleBlur}
                                name="purpose"
                                label="Purpose *"
                                {...params}
                            />
                        )}
                    />
                    {errors.purpose && touched.purpose ? (
                        <p className="form-error">{errors.purpose}</p>
                    ) : null}

                </div>





                <div style={{ display: "flex", flexDirection: "column" }} position="relative" className='imageUploadClass'>
                    <lable className="product_image_lable">Image</lable>
                    <div className="dropzone_container_Product">
                        <div>
                            <FileUploader
                                // multiple={false}
                                className="main_drop"
                                handleChange={(e) => handlefileChange(e, 'file')}
                                onBlur={handleBlur}
                                name="file"
                                //   types={fileTypes}
                                fullWidth
                            />
                            {errors.file && touched.file ? (
                                <p className="form-error">{errors.file}</p>
                            ) : null}



                            {imageURL ? (
                                <div className='preview_image'>
                                    <img src={imageURL} alt="banner" height={100} width={100} />

                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>

                <div>
                    <lable className="lable_name">Message *</lable>
                    <TextareaAutosize className='textarea_help' minRows={6} size='small' label="" placeholder="" name="message" value={values.message} type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {errors.message && touched.message ? (
                        <p className="form-error">{errors.message}</p>
                    ) : null}

                </div>






                <div className='align-end'>

                    <button className='nex_btn' type='button' onClick={handleformSubmit}>

                        {loading ? (
                            <CircularProgress size={18} style={{ color: "white" }} />
                        ) : (
                            "Submit"
                        )}
                    </button>

                </div>


            </div>




        </div>


    )
}

export default CreateTicket;